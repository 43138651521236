//=====================================================================================
// #1 - Base Imports
//=====================================================================================
import React from "react";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

//=====================================================================================
// #2 - Import * Services
//=====================================================================================
import Ad from "../../../../services/Ad";

//=====================================================================================
// #2 - Import * Material UI
//=====================================================================================
import Grid from "@material-ui/core/Grid";

//=====================================================================================
// #2 - Import * UI Components
//=====================================================================================
import Product from "../../../../components/GridProduct";
import { Container } from "../../../../components/design/layout/container";
import { Headline } from "../../../../components/design/foundation/headline";

//=====================================================================================
// #4 - Import * Styled Components
//=====================================================================================
import { WrapperContent, Content } from "./styles";

//=====================================================================================
// #5 - Instantiate Services
//=====================================================================================
const ad = new Ad();

function Choises(props) {

    const generateRandomNumber = () => Math.floor(Math.random() * 100) + 1;

    // const search = () => {
    //     this.ad
    //         .findAdsByUserSearch(null, 20, null, "top20", this.generateRandomNumber())
    //         .then((response) => {
    //             this.setState({ products: response.data.payload });
    //         })
    //         .catch((error) =>
    //             this.ad.formatErrorMessage(error.response, "HFIADU"),
    //         );
    // };

    // * States
    const [listAds, setListAds] = React.useState([]);

    // * Effect
    React.useEffect(() => {

        const scambChoicesAds = async () => {

            try {

                const response = await ad.findAdsByUserSearch(null, 20, null, "top20", generateRandomNumber(), null, null, null, null, null, null, null, null, null, props.website)
                const data = response.data?.payload;

                setListAds(responsePayload => [...responsePayload, data]);

            } catch (error) {
                ad.formatErrorMessage(error.response, "HFIADU");
            }

        }

        // * Calls To Functions
        scambChoicesAds();

    }, []);

    return (
        <>
            <Container withContainer={true} >
                <WrapperContent>

                    <Headline
                        type="medium"
                        title="Escolhas Scamb"
                        titleColor="#515157"
                        withLink={true}
                        linkText="ver mais"
                        link={{ pathname: "/resultados", search: `?tag=top20&limit=8&aleatory=${generateRandomNumber()}` }}
                        textAlign="left"
                    />

                    <Content>
                        <Grid container spacing={2}>
                            {listAds[0]?.map((item, index) =>
                                index < 10 ? (
                                    <Grid item md={3} sm={4} xs={6} key={index}>
                                        <Product
                                            ad={item}
                                            link={`/produto?id=${item._id}`}
                                            weight={item.tamanho_exibicao?.nome}
                                            idAd={item._id}
                                            image={ad.loadImage(item.fotos[0])}
                                            title={item.titulo}
                                            price={item.pontos_por?.$numberDecimal}
                                            type={item.tipo_anuncio}
                                            cashback={item.cashback}
                                            cashDiscount={item.valor_de}
                                            cashPrice={item.valor_por} 
                                            idAd={item._id}
                                        ></Product>
                                    </Grid>
                                ) : (
                                    <></>
                                )
                            )}
                        </Grid>
                    </Content>
                </WrapperContent>
            </Container>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
      website: state.websiteReducer.website
    };
};

export default withRouter(connect(mapStateToProps)(Choises));;