import { combineReducers } from 'redux';

import scamberReducer from './scamberReducer';
import adReducer from './adReducer';
import cartReducer from './cartReducer';
import balanceReducer from './balanceReducer';
import websiteReducer from './websiteReducer';

export default combineReducers({ 
    scamberReducer, adReducer, 
    cartReducer, balanceReducer,
    websiteReducer
});