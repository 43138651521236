//=====================================================================================
// #1 - Import * Styled Components
//=====================================================================================
import styled from 'styled-components';

export const Price = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const Fraction = styled(Price)`
    font-size: 0.8125rem;
    font-weight: 700;
    color: ${props => props.theme.color.black};
    
    .symbol {
        font-size: 8px;
    } 
`;

export const Symbol = styled.div`
    font-weight: 700;
    color: ${props => props.theme.color.black};
    margin-left: 0.125rem;
    text-transform: lowercase;
    position: relative;
    top: -0.125rem;
`
