import React, {
  Component,
  forwardRef,
  useState,
  useImperativeHandle,
} from "react";
import Product from "../components/GridProduct";
import StoreCard from "../components/StoreCard";
import Swal from "sweetalert2";
import Swal1 from "sweetalert";
import { connect } from 'react-redux';

import $ from "jquery";

// Modal Bootstrap
import { Button, Modal } from "react-bootstrap";

// Material ui
import { createMuiTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

//===============================================================================================================
// #4 - Import * React Sticky
//===============================================================================================================
import { StickyContainer, Sticky } from 'react-sticky';

//===============================================================================================================
// #4 - Import * Material UI Icons
//===============================================================================================================
import TuneIcon from '@material-ui/icons/Tune';

//===============================================================================================================
// #4 - Import * UI Components
//===============================================================================================================
import { Filter, FilterHead, FilterHeadOrderBy, HistoryFilters, RemoveFilter, MobileFiltersBar, FiltersBarItem } from "../components/Filters";


//===============================================================================================================
// #4 - Import * Font Awesome Icons
//===============================================================================================================
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faTimes,
  faAlignLeft,
  faLongArrowUp,
  faLongArrowDown,
  faBan,
} from "@fortawesome/pro-light-svg-icons";
import { faChevronDown, faChevronRight, faSearch } from "@fortawesome/pro-regular-svg-icons";
import { faCheck, faArrowRight } from "@fortawesome/pro-solid-svg-icons";

// Images

// Services
import Ad from "../services/Ad";
import Domain from '../services/Domain';
import Categorization from '../services/Categorization';
import Scamber from '../services/Scamber';

// Images icons
import arrowUpDowN from "../assets/images/arrows-up-down@2x.svg";
import noresult from "../assets/images/no-result.png";
import Avatar from "../assets/images/avatar.png";

import Helmet from 'react-helmet';

import ContentLoader from "react-content-loader";
import { faSolarSystem } from "@fortawesome/pro-duotone-svg-icons";


//===============================================================================================================
// #4 - Filter Category Back History
//===============================================================================================================
const HistoryCategory = ({ children }) => {

  return (
    <div className="wrap-back-list">
      <div className="back-list">
        {children}
      </div>
    </div>
  )
}

const HistoryItem = ({ text, onClick }) => {

  return (
    <button type="button" className="back-list-item" onClick={onClick}>
      <div className="icon">
        <Icon icon={faChevronLeft} />
      </div>
      <div className="text">
        {text}
      </div>
    </button>
  )
}

const MyLoader = (props) => (
  <ContentLoader
    speed={1}
    width="auto" //194
    height="auto" //265
    viewBox={"0 0 194 265"}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <circle cx="171" cy="223" r="18" />
    <rect x="2" y="203" rx="2" ry="2" width="138" height="11" />
    <rect x="3" y="222" rx="2" ry="2" width="28" height="10" />
    <rect x="-0" y="-8" rx="2" ry="2" width="208" height="201" />
  </ContentLoader>
)

const ModalLoadMore = forwardRef((props, ref) => {
  // Bootstrap Modal
  const [show, setShow] = useState(false);

  // Handle Modal
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useImperativeHandle(ref, () => ({
    openModal: () => handleShow(),
    closeModal: () => handleClose(),
  }));

  return (
    <>
      <Modal
        show={show}
        size={!props.modalSize ? "size-45w" : props.modalSize}
        onHide={handleClose}
        className="full-width"
        centered
      >
        <Modal.Header closeButton>
          <section className="_view-head">
            <div className="_view-head-content" style={{}}>
              <Button className="_back-history btn" onClick={handleClose}>
                <Icon icon={faChevronLeft} />
              </Button>
              <Modal.Title> {props.modalTitle} </Modal.Title>
            </div>
          </section>
        </Modal.Header>
        <Modal.Body className="_modal-filter">{props.children}</Modal.Body>
      </Modal>
    </>
  );
});

const ListSize = ({ title, children }) => {

  // * States
  const [open, setOpen] = useState(false);

  return (
    <div className={` ${open ? "wrap-size active-size" : "wrap-size no-active"}`}>
      <button type="button" className="btn-filter-size list-size-item" onClick={() => setOpen(!open)}>
        <h2 className="_filter-title ">{title}</h2>
        <div className="chevron-icon">
          <Icon icon={faChevronRight} />
        </div>
      </button>

      {open && (
        <button type="button" className="btn-filter-size back-list-sizes" onClick={() => setOpen(!open)}>
          <div className="chevron-icon">
            <Icon icon={faChevronLeft} />
          </div>
          <h2 className="_filter-title ">{title}</h2>
        </button>
      )}

      {open && (
        <>
          {children}
        </>
      )}
    </div>
  )
}

const ListSizePanel = ({ children }) => {

  return (
    <div className="size-panel">
      {children}
    </div>
  )
}

class Results extends Component {

  constructor() {
    super();

    // Refs
    this.refLoadMoreModal = React.createRef();
    this.refLoadMoreSize = React.createRef();
    this.refLoadMoreBrand = React.createRef();

    this.state = {
      isLoading: false,
      searchByClick: true,
      adsSeller: null,
      searchedStores : null,
      limit: 8,
      pageNumber: 0,
      amountItensNextPage: 0,
      searchString: "",
      idCategory: "",
      value: [0, 0],
      listConditions: [],
      listBrands: [],
      listCategorizations: [],
      listActiveCategorizations: [],
      listActiveSizes: [],
      searchFilters: {
        categorizations: []
      },
      key: '',
      category: '',
      tag: '',
      panelListSize: false,
      filterOpened: { order: false, categories: false, size: false, brand: false, condition: false, delivery: false, points: false},
      searchByStore: false,
      showcaseId: '',
    };

    this.inputBrand = '';
    this.ad = new Ad();
    this.domain = new Domain();
    this.categorization = new Categorization();
    this.scamber = new Scamber();

    this.pageNumber = 0;
    this.aleatory = null;
  }

  loadResultsByUrlParams = (newSearch) => {

    this.setState({ isLoading: !this.state.isLoading })

    let urlParams = new URLSearchParams(this.props.location.search);
    let keyParam = urlParams.get('key');
    let categoryParam = urlParams.get('category');
    let tagParam = urlParams.get('tag');
    let pageNumberParam = urlParams.get('pageNumber');
    let aleatory = urlParams.get('aleatory');
    let price = urlParams.get('price');
    let categoryList = urlParams.get('categoryList');
    let brand = urlParams.get('brand');
    let condition = urlParams.get('condition');
    let deliveryTypes = urlParams.get('deliveryTypes');
    let points = urlParams.get('points');
    let size = urlParams.get('size');
    let limit = urlParams.get('limit');
    let loja = urlParams.get('loja');
    let showcaseId = urlParams.get('showcaseId');

    if (limit) {
      this.setState({ limit: Number(limit) })
    } else {
      limit = this.state.limit
    }

    this.pageNumber = pageNumberParam;
    this.aleatory = aleatory;

    if(loja) {
      this.searchByStore(limit, loja);
      this.setState({ 'key': loja });
      this.setState({ 'searchByStore': true });
    } else {
      this.search(
        keyParam,
        limit,
        categoryParam,
        tagParam,
        //{ sum: 1 },
        //newSearch ? newSearch : false,
        { sum: 0 },
        false,
        price ? price : null,
        points ? points : null,
        condition ? condition : null,
        categoryList ? categoryList : null,
        brand ? brand : null,
        deliveryTypes ? deliveryTypes : null,
        aleatory ? aleatory : null,
        size ? size : null,
        showcaseId ? showcaseId : null,
      );
  
      // Setando Variaveis de busca(key,category,tag) no state
      this.setState({ 'key': keyParam })
      this.setState({ 'category': categoryParam })
      this.setState({ 'tag': tagParam })
      this.setState({ 'searchByStore': false });
      this.setState({ 'showcaseId': showcaseId });
  
      if (points) {
        let pontos = points.split('-');
        this.setState({ preco_min: pontos[0] });
        this.setState({ preco_max: pontos[1] });
      }
  
      let searchFiltersCopy = this.state.searchFilters;
      searchFiltersCopy.price = price ? { '_id': price, 'nome': price === -1 ? "Maior Preço" : "Menor Preço" } : null;
      searchFiltersCopy.deliveryTypes = deliveryTypes ? { _id: deliveryTypes, 'nome': deliveryTypes === "operador_logistico" ? "Correios" : "Entrega em maos" } : null;
  
      this.setState({ searchFilters: searchFiltersCopy })
    }
  }

  componentDidUpdate(prevProps) {
    //to detect URL query params update and backbutton
    if (this.props.location.search !== prevProps.location.search && this.props.history.action !== 'REPLACE') {
      let urlParams = new URLSearchParams(this.props.location.search);

      let newSearchParam = Boolean(urlParams.get('newSearch'));
      let category = urlParams.get('category');
      let categoryList = urlParams.get('categoryList');
      let points = urlParams.get('points');
      let size = urlParams.get('size');
      let limit = urlParams.get('limit');

      window.scrollTo(0, 0);

      let searchFilters = this.state.searchFilters;

      if (!category && !categoryList) {
        this.handleURLParams('categoryList', null);
        searchFilters.categorizations = [];
        localStorage.setItem('categorizations', []);
      } else if (category && searchFilters.categorizations) {
        this.handleURLParams('categoryList', null);
        searchFilters.categorizations = [];
      }

      if (!points) {
        this.setState({ preco_min: undefined });
        this.setState({ preco_max: undefined });
        searchFilters.points = null;
      }

      if (!size) {
        searchFilters.size = null;
      }

      if (!limit) {
        this.setState({ limit: 8 })
      }


      this.setState({ searchFilters: searchFilters });

      var currentSelected = null;
      var current = null;
      var upSpeed = 300;
      let tabs = $("._tabs");

      $("._dropdown-select").click(function () {
        $(this).children("._form-select-content").slideDown(upSpeed);

        if (current != this) {
          $(current).children("._form-select-content").slideUp(upSpeed);
          current = this;
        } else {
          current = null;
        }
      });

      $("._select-content").on("click", function () {
        let fieldValue = $(this).find("._current-item-name").text();

        if (currentSelected != this) {
          $(this)
            .closest("._dropdown-select")
            .find("._the-label > ._span-name")
            .text(fieldValue);
          currentSelected = this;
        } else currentSelected = null;
      });

      $(tabs).on("click", "._tab-item", function () {
        $(this)
          .parents(tabs)
          .find("._active")
          .removeClass("_active")
          .end()
          .end()
          .addClass("_active");
      });


      this.loadFiltersOptions();
      this.loadResultsByUrlParams(newSearchParam); // Carrega os resultados de acordo com os filtros da url
    }
  }

  componentWillUnmount() {
    let scroll = window.scrollY;

    if (this.props.history.location.pathname === '/produto') {
      localStorage.setItem('scrollY', scroll);
    }
  }

  componentDidMount() {
    var currentSelected = null;
    var current = null;
    var upSpeed = 300;
    let tabs = $("._tabs");

    $("._dropdown-select").click(function () {
      $(this).children("._form-select-content").slideDown(upSpeed);

      if (current != this) {
        $(current).children("._form-select-content").slideUp(upSpeed);
        current = this;
      } else {
        current = null;
      }
    });

    $("._select-content").on("click", function () {
      let fieldValue = $(this).find("._current-item-name").text();

      if (currentSelected != this) {
        $(this)
          .closest("._dropdown-select")
          .find("._the-label > ._span-name")
          .text(fieldValue);
        currentSelected = this;
      } else currentSelected = null;
    });

    $(tabs).on("click", "._tab-item", function () {
      $(this)
        .parents(tabs)
        .find("._active")
        .removeClass("_active")
        .end()
        .end()
        .addClass("_active");
    });


    let urlParams = new URLSearchParams(this.props.location.search);
    let newSearchParam = Boolean(urlParams.get('newSearch'));


    this.loadFiltersOptions();
    this.loadResultsByUrlParams(newSearchParam); // Carrega os resultados de acordo com os filtros da url

    const ids = document.querySelectorAll("#filtros-desktop > .filtro");

    const activeFilter = (index) => {
      ids.forEach((filter) => {
        filter.classList.remove("active");
        filter.children[0].addEventListener("click", () => {
          
          if ( (" " + filter.className + " ").replace(/[\n\t]/g, " ").indexOf("active ") > -1 ) {
            ids[index].classList.remove("active");
            
          } else {
            filter.classList.add("active");

          }
        })
      });

      ids[index].classList.add("active");
    };

    ids.forEach((filter, index) => {
      filter.addEventListener("click", () => {
        activeFilter(index);
        filter.classList.add("active")
      });
    });
  }

  removeApplyedFilters = () => {
    Swal.fire('Aguarde...');
    Swal.showLoading();

    let categorys = document.querySelectorAll("#removeCategory");

    if (categorys) {
      categorys.forEach(element => {
        element.click();
      });
    }

    //this.setState({ preco_min: 0 });
    //this.setState({ preco_max: 9999 });

    this.setState({ preco_min: undefined });
    this.setState({ preco_max: undefined });
    // this.setState({ showcaseId: undefined });
    if (document.querySelector("#removeBrand")) document.querySelector("#removeBrand").click();
    if (document.querySelector("#removeCondition")) document.querySelector("#removeCondition").click();
    if (document.querySelector("#removeFrete")) document.querySelector("#removeFrete").click();
    if (document.querySelector("#removeOrdenarPor")) document.querySelector("#removeOrdenarPor").click()

    /*setTimeout(() => {
      this.handleURLParams('price', null);
    }, 100);

    setTimeout(() => {
      this.handleURLParams('categoryList', null);
    }, 100);

    setTimeout(() => {
      this.handleURLParams('brand', null);
    }, 100);

    setTimeout(() => {
      this.handleURLParams('condition', null);
    }, 100);

    setTimeout(() => {
      this.handleURLParams('deliveryTypes', null);
    }, 100);

    setTimeout(() => {
      this.handleURLParams('size', null);
    }, 100);

    setTimeout(() => {
      this.handleURLParams('points', null);
    }, 100)

    setTimeout(() => {
      this.handleURLParams('category', null);
    }, 100);*/

    this.handleURLParams(null, null, [
      { key: 'price', value: undefined },
      { key: 'categoryList', value: undefined },
      { key: 'brand', value: undefined },
      { key: 'condition', value: undefined },
      { key: 'deliveryTypes', value: undefined },
      { key: 'size', value: undefined },
      { key: 'points', value: undefined },
      { key: 'category', value: undefined },
      // { key: 'showcaseId', value: undefined }
    ])

    let listCategorizationReseted = [...this.state.listCategorizations]

    this.setState({
      category: undefined,
      listActiveCategorizations: listCategorizationReseted,
      searchFiltersApplied: {
        categorizations: []
      },
      searchFilters: {
        categorizations: []
      }
    }, () => {
      this.saveSelectedCategorizations([]);
      document.querySelector("#btnAplicarFiltros").click();
    });

    Swal.close();


    // this.loadResultsByUrlParams( true );
  }

  setActiveCategoryOnLoad(categorizations, index, listCategorizations) {
    if ((categorizations.length - 1) >= index) {
      let activeCategory = listCategorizations.filter((category) => category._id === categorizations[index]._id);

      if (activeCategory.length > 0) {
        let childListCategorizations = this.applyCategorizationFilter(activeCategory[0], null, true);
        this.setActiveCategoryOnLoad(categorizations, index + 1, childListCategorizations);
      }
    }
  }

  loadFiltersOptions = () => {

    let urlParams = new URLSearchParams(this.props.location.search);
    let brand = urlParams.get('brand');
    let condition = urlParams.get('condition');
    let category = urlParams.get('categoryList');
    let categoryHome = urlParams.get('category');
    let size = urlParams.get('size');

    //LISTA DE CATEGORIZACOES
    this.categorization.list().then((response) => {
      if (response.status === 200) {
        this.setState({ listCategorizations: response.data.payload });
        this.setState({ listActiveCategorizations: response.data.payload });

        if (category || categoryHome) {
          let categorizations = JSON.parse(localStorage.getItem('categorizations'))
          this.setActiveCategoryOnLoad(categorizations, 0, response.data.payload);

          /*let categorizationIdsSelected = categorizations.map(
            (selectedCategorization) => {
              return selectedCategorization._id;
            }
          );

          this.domain
            .findSizeGroup(categorizationIdsSelected)
            .then((response) => {
              if (response.status === 200) {
                const tamanhos = response.data.payload.tamanhos;
                this.setState({ listActiveSizes: tamanhos });

                let urlParams = new URLSearchParams(this.props.location.search);
                let size = urlParams.get('size');

                let filteredCondition = tamanhos.filter((item, index) => item._id === size && size);
                let searchFiltersCopy = this.state.searchFilters;
                searchFiltersCopy.size = filteredCondition[0];
                this.setState({ searchFilters: searchFiltersCopy });
              }
            })
            .catch(error => {
              if (error.response.status !== 404) {
                return this.domain.formatErrorMessage(error.response, 'RFISDO')
              } else {
                this.setState({ listActiveSizes: [] });
              }
            })*/
        }
      }
    })
      .catch((error) => {
        this.categorization.formatErrorMessage(error.response, 'RLICA')
        console.log("ERROR", error.message);
      });

    //LISTA DE CONDIÇÕES
    this.domain.listConditions().then((response) => {
      if (response.status === 200) {
        this.setState({ listConditions: response.data.payload });

        let filteredCondition = response.data.payload.filter((item, index) => item._id === condition && condition);
        let searchFiltersCopy = this.state.searchFilters;
        searchFiltersCopy.condition = filteredCondition[0];
        this.setState({ searchFilters: searchFiltersCopy });
      }
    }).catch((error) =>
      this.domain.formatErrorMessage(error.response, 'RLICDO')
    );

    //LISTA DE MARCAS
    this.domain.listBrands().then((response) => {
      if (response.status === 200) {
        this.setState({ listBrands: response.data.payload });

        let filteredBrand = response.data.payload.filter((item, index) => item._id === brand && brand);
        let searchFiltersCopy = this.state.searchFilters;
        searchFiltersCopy.brand = filteredBrand[0];
        this.setState({ searchFilters: searchFiltersCopy });
      }
    }).catch((error) =>
      this.domain.formatErrorMessage(error.response, 'RLIBDO1')
    );

    this.domain.listSizeGroups(this.props.website).then((response) => {
      if (response.status === 200) {
        this.setState({ listActiveSizes: response.data.payload })

        let filteredSize = response.data.payload.reduce((sizeSelect, groupSize) => {
          const sizeFinded = groupSize.tamanhos.filter((item => item._id === size && size))[0];
          if(sizeFinded) {
            sizeSelect = sizeFinded;
          }

          return sizeSelect;
        }, undefined);

        let searchFiltersCopy = this.state.searchFilters;
        searchFiltersCopy.size = filteredSize;
        this.setState({ searchFilters: searchFiltersCopy });
      }
    }).catch((error) =>
      this.domain.formatErrorMessage(error.response, 'RLISG')
    )

  }

  checkMinMaxValues = (preco_min, preco_max) => {
    if (!preco_min && !preco_max) {
      return undefined;
    } else if (preco_min && !preco_max) {
      return `${preco_min}-99999`
    } else if (!preco_min && preco_max) {
      return `0-${preco_max}`
    } else {
      return `${preco_min}-${preco_max}`
    }
  }

  applyFilter = (filterName, filterValue, modalRef, executeSearch) => {
    let searchFilters = this.state.searchFilters;

    this.handleURLParams(filterName, filterValue?._id);

    if (!filterValue) {
      delete searchFilters[filterName];
    } else {
      searchFilters[filterName] = filterValue;
    }

    this.setState({ searchFilters: searchFilters });
    if (modalRef) modalRef.current.closeModal();

    //CHAMANDO BUSCA
    if (executeSearch) {
      this.search(
        this.state.key,
        this.state?.limit,
        this.state.category,
        this.state.tag,
        { sum: 0 },
        false,
        this.state.searchFilters.price ? this.state.searchFilters.price._id : null,
        this.checkMinMaxValues(this.state.preco_min, this.state.preco_max),
        this.state.searchFilters.condition ? this.state.searchFilters.condition._id : null,
        this.state.searchFilters.categorizations.length > 0 ? this.formatCategoryList(this.state.searchFilters) : null,
        this.state.searchFilters.brand ? this.state.searchFilters.brand._id : null,
        this.state.searchFilters.deliveryTypes ? this.state.searchFilters.deliveryTypes._id : null,
        this.aleatory,
        this.state.searchFilters.size ? this.state.searchFilters.size._id : null,
        this.state.showcaseId ? this.state.showcaseId : null
      );
    }

  }

  resetInput = (inputName) => {
    $(`input[name="${inputName}"]:checked`).prop('checked', false);
  }

  clone = (obj) => {
    if (null == obj || "object" != typeof obj) return obj;
    var copy = obj.constructor();
    for (var attr in obj) {
      if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
    }

    // Handle Array
    if (obj instanceof Array) {
      copy = [];
      for (var i = 0, len = obj.length; i < len; i++) {
        copy[i] = this.clone(obj[i]);
      }
      return copy;
    }
    return copy;
  }

  saveSelectedCategorizations = (categorizations) => {
    let listToSave = this.clone(categorizations);

    localStorage.setItem('categorizations', JSON.stringify(listToSave.map(cat => {
      delete cat.oldCategorizationList;
      delete cat.categorias;
      delete cat.tipos_produtos;
      delete cat.produtos;

      return cat;
    })));
  }

  applyCategorizationFilter = (categorization, modalRef, notSaveFilter, loadSize) => {
    let searchFilters = this.state.searchFilters;

    this.handleURLParams('categoryList', categorization._id);

    categorization.oldCategorizationList = this.state.listActiveCategorizations;
    searchFilters.categorizations.push(categorization);
    this.setState({ searchFilters: searchFilters });

    if (modalRef) modalRef.current.closeModal();

    /*if (loadSize) {
      let categorizationIdsSelected = searchFilters.categorizations.map(
        (selectedCategorization) => {
          return selectedCategorization._id;
        }
      );

      this.domain
        .findSizeGroup(categorizationIdsSelected)
        .then((response) => {
          if (response.status === 200) {
            const tamanhos = response.data.payload.tamanhos;
            this.setState({ listActiveSizes: tamanhos });

            let urlParams = new URLSearchParams(this.props.location.search);
            let size = urlParams.get('size');

            let filteredCondition = tamanhos.filter((item, index) => item._id === size && size);
            let searchFiltersCopy = this.state.searchFilters;
            searchFiltersCopy.size = filteredCondition[0];
            this.setState({ searchFilters: searchFiltersCopy });
          }
        })
        .catch(error => {
          if (error.response.status !== 404) {
            return this.domain.formatErrorMessage(error.response, 'RFISDO')
          } else {
            this.setState({ listActiveSizes: [] });
          }
        })
    }*/

    if (categorization.categorias && categorization.categorias.length > 0) {
      this.setState({ listActiveCategorizations: categorization.categorias });
      if (!notSaveFilter) this.saveSelectedCategorizations(searchFilters.categorizations);

      return categorization.categorias;

    } else if (
      categorization.tipos_produtos &&
      categorization.tipos_produtos.length > 0
    ) {

      this.setState({ listActiveCategorizations: categorization.tipos_produtos });
      if (!notSaveFilter) this.saveSelectedCategorizations(searchFilters.categorizations);

      return categorization.tipos_produtos

    } else if (categorization.produtos && categorization.produtos.length > 0) {

      this.setState({ listActiveCategorizations: categorization.produtos });
      if (!notSaveFilter) this.saveSelectedCategorizations(searchFilters.categorizations);

      return categorization.produtos;

    } else {

      this.setState({ listActiveCategorizations: [] });
      if (!notSaveFilter) this.saveSelectedCategorizations(searchFilters.categorizations);

      return [];

    }

    // this.handleURLParams(categorization);

    //CHAMANDO BUSCA
    /*this.search(
      this.state.key,
      this.state?.limit,
      this.state.category,
      this.state.tag,
      { sum: 0 },
      false,
      this.state.searchFilters.price ? this.state.searchFilters.price._id : null,
      this.checkMinMaxValues(this.state.preco_min, this.state.preco_max),
      this.state.searchFilters.condition ? this.state.searchFilters.condition._id : null,
      this.state.searchFilters.categorizations.length > 0 ? this.formatCategoryList(this.state.searchFilters) : null,
      this.state.searchFilters.brand ? this.state.searchFilters.brand._id : null,
      this.state.searchFilters.deliveryTypes ? this.state.searchFilters.deliveryTypes._id : null,
      this.aleatory,
      this.state.searchFilters.size ? this.state.searchFilters.size._id : null
    );*/
  }

  removeCategorizationFilter = (categorization, index, executeSearch) => {

    let searchFilters = this.state.searchFilters;

    let oldCategorizationList;
    if (!categorization.oldCategorizationList) {
      oldCategorizationList = this.state.listCategorizations;
      searchFilters.categorizations = [];
    } else {
      oldCategorizationList = categorization.oldCategorizationList;
      searchFilters.categorizations = searchFilters.categorizations.slice(0, index);
    }

    if (searchFilters.categorizations.length === 0) {
      this.handleURLParams('categoryList', null);

      setTimeout(() => this.handleURLParams('size', null), 1000);
      //this.setState({ listActiveSizes: [] });
      searchFilters.size = null;

      localStorage.setItem('categorizations', []);
    }

    this.setState({ listActiveCategorizations: oldCategorizationList, searchFilters: searchFilters });

    //CHAMANDO BUSCA
    if (executeSearch) {
      this.search(
        this.state.key,
        this.state?.limit,
        this.state.category,
        this.state.tag,
        { sum: 0 },
        false,
        this.state.searchFilters.price ? this.state.searchFilters.price._id : null,
        this.checkMinMaxValues(this.state.preco_min, this.state.preco_max),
        this.state.searchFilters.condition ? this.state.searchFilters.condition._id : null,
        this.state.searchFilters.categorizations.length > 0 ? this.formatCategoryList(this.state.searchFilters) : null,
        this.state.searchFilters.brand ? this.state.searchFilters.brand._id : null,
        this.state.searchFilters.deliveryTypes ? this.state.searchFilters.deliveryTypes._id : null,
        this.aleatory,
        this.state.searchFilters.size ? this.state.searchFilters.size._id : null,
        this.state.showcaseId ? this.state.showcaseId : null
      );
    }

  }

  formatCategoryList = (searchFilters) => {
    return searchFilters.categorizations.reduce((stringCategory, category, index) => {
      if (index === searchFilters.categorizations.length - 1) {
        stringCategory += category._id
      } else {
        stringCategory += `${category._id},`
      }

      return stringCategory;
    }, "")
  }

  /*changePage = (key, limit, category, tag, countPage, newSearch, price, points, condition, categoryList, brand, deliveryType, size) => {
    if (!newSearch) {
      if (countPage.sum) {
        this.pageNumber = parseInt(this.pageNumber) + parseInt(countPage.sum);
        this.search(key, limit, category, tag, countPage, newSearch, price, points, condition, categoryList, brand, deliveryType, this.aleatory, size);
      }
      if (countPage.subtraction) {
        this.pageNumber = parseInt(this.pageNumber) - parseInt(countPage.subtraction);
        this.search(key, limit, category, tag, countPage, newSearch, price, points, condition, categoryList, brand, deliveryType, this.aleatory, size);
      }

    }
  }*/

  loadMore = () => {
    this.setState({ limit: this.state.limit += 8 })

    // this.loadScamberAdsByStoreUrl(this.state.storeUrl,this.state.limit);
    this.setState({
      showFilter: false,
    });
    this.search(
      this.state.key,
      this.state?.limit,
      this.state.category,
      this.state.tag,
      { sum: 0 },
      false,
      this.state.searchFilters.price ? this.state.searchFilters.price._id : null,
      this.checkMinMaxValues(this.state.preco_min, this.state.preco_max),
      this.state.searchFilters.condition ? this.state.searchFilters.condition._id : null,
      this.state.searchFilters.categorizations.length > 0 ? this.formatCategoryList(this.state.searchFilters) : null,
      this.state.searchFilters.brand ? this.state.searchFilters.brand._id : null,
      this.state.searchFilters.deliveryTypes ? this.state.searchFilters.deliveryTypes._id : null,
      this.aleatory,
      this.state.searchFilters.size ? this.state.searchFilters.size._id : null,
      this.state.showcaseId ? this.state.showcaseId : null
    );

    // Adicionando o numero de itens url.
    this.handleURLParams('limit', this.state.limit);

  }

  loadMoreStores = () => {
    this.setState({ limit: this.state.limit += 8 })

    this.setState({
      showFilter: false,
    });
    this.searchByStore(this.state.limit, this.state.key);

    // Adicionando o numero de itens url.
    this.handleURLParams('limit', this.state.limit);

  }

  handleURLParams = (key, value, listOfKeyValues) => {
    let urlParams = new URLSearchParams(this.props.location.search);

    if (listOfKeyValues && listOfKeyValues.length > 0) {
      listOfKeyValues.map(keyValue => {
        if (!keyValue.value) {
          urlParams.delete(keyValue.key);
        } else if (urlParams.get(keyValue.key)) {
          urlParams.set(keyValue.key, keyValue.value)
        } else {
          urlParams.append(keyValue.key, keyValue.value)
        }
      })
    } else {
      if (!value) {
        urlParams.delete(key);
      } else if (urlParams.get(key)) {
        urlParams.set(key, value)
      } else {
        urlParams.append(key, value)
      }
    }


    // console.log(urlParams.toString())
    this.props.history.replace({ search: urlParams.toString() });
    // window.history.pushState('','',`${this.props.location.pathname}?${urlParams.toString()}`);
  }

  searchByStore = (limit, store) => {
    this.setState({ isLoading: true });

    this.scamber.findScamberStoreBySearch(limit, store, this.props.website).then(response => {
      this.setState({ searchedStores: response.data.payload, isLoading: false });
    }).catch((error) =>
      this.scamber.formatErrorMessage(error.response, 'RFIST'),
    );
  }

  search = (key, limit, category, tag, countPage, newSearch, price, points, condition, categoryList, brand, deliveryType, aleatory, size, showcaseId) => {
    this.setState({ isLoading: true });

    // Swal1("Aguarde...");
    // Swal.showLoading();
    // tratando & comercial
    
    setTimeout(() => {
      let newFiltersApplieds = { ...this.state.searchFilters };
      newFiltersApplieds.categorizations = this.clone(newFiltersApplieds.categorizations);
  
      this.setState({ searchFiltersApplied: newFiltersApplieds });
    }, 500);
    
    // let newFiltersApplieds = { ...this.state.searchFilters };
    // newFiltersApplieds.categorizations = this.clone(newFiltersApplieds.categorizations)

    // this.setState({ searchFiltersApplied: newFiltersApplieds })

    if (key) {
      if (key.indexOf('&') !== -1) key = key.replace(/&/g, 'ampersand');
    }

    if (newSearch) this.pageNumber = 1;

    this.ad
      .findAdsByUserSearch(
        key ? key : null,
        limit ? limit : 8,
        category ? category : null,
        tag ? tag : null,
        aleatory ? aleatory : null,
        newSearch ? 1 : this.pageNumber,
        price ? price : null,
        points ? points : null,
        condition ? condition : null,
        categoryList ? categoryList : null,
        brand ? brand : null,
        deliveryType ? deliveryType : null,
        null,
        size ? size : null,
        this.props.website,
        showcaseId ? showcaseId : null
      )
      .then((response) => {

        // Swal1.close();

        if (response.data.payload.length <= 0)
          this.setState({ pageNumber: (this.pageNumber -= 1) });
        this.setState({ adsSeller: response.data.payload, isLoading: false });

        let scrollY = localStorage.getItem('scrollY');
        if (scrollY) {
          window.scrollTo(scrollY, scrollY);
          localStorage.removeItem('scrollY');
        }
      })
      .catch((error) =>
        this.ad.formatErrorMessage(error.response, 'RFIUAD1'),
      );
    /*this.ad
      .findAdsByUserSearch(
        key ? key : null,
        limit ? limit : 8,
        category ? category : null,
        tag ? tag : null,
        aleatory ? aleatory : null,
        newSearch ? 2 : parseInt(this.pageNumber) + 1,
        price ? price : null,
        points ? points : null,
        condition ? condition : null,
        categoryList ? categoryList : null,
        brand ? brand : null,
        deliveryType ? deliveryType : null,
        null,
        size ? size : null
      )
      .then((response) => {

        this.setState({ isLoading: false })

        // Swal1.close();
        // if(response.data.payload.length <= 0) this.setState({ pageNumber: (this.state.pageNumber -= 1) });
        this.setState({ amountItensNextPage: response.data.payload.length });
      })
      .catch((error) =>
        this.ad.formatErrorMessage(error.response, 'RFIUAD2'),
      );*/

    // Adicionando o numero da pagina na url.
    //this.handleURLParams('pageNumber', this.pageNumber);
    setTimeout(() => {
      this.handleURLParams('points', points);
    }, 500);
  };

  filterBrands = (e, filter) => {
    if (e) e.preventDefault();
    // this.inputBrand = e.target.value;
    if (filter) if (filter.indexOf('&') !== -1) filter = filter.replace(/&/g, 'ampersand');
    this.domain.listBrands(filter).then((response) => {
      if (response.status === 200) {
        this.setState({ listBrands: response.data.payload });
      }
    }).catch((error) =>
      this.domain.formatErrorMessage(error.response, 'RLIBDO2'),
    );
  }

  // Functions Filters

  handleShowFilter = () => {
    this.setState({
      showFilter: !this.state.showFilter,
    });
  };

  handleCloseFilter = () => {
    this.setState({
      showFilter: false,
    });
  };

  handleShowOrderFilter = () => {
    this.setState({
      showOrderFilter: !this.state.showOrderFilter,
    });
  };

  handleCloseOrderFilter = () => {
    this.setState({
      showOrderFilter: false,
    });
  };

  removeFilterBrand = (filterName, filterValue, modalRef, executeSearch) => {
    let searchFilters = this.state.searchFilters;

    this.handleURLParams(filterName, filterValue?._id);

    if (!filterValue) {
      delete searchFilters[filterName];
    } else {
      searchFilters[filterName] = filterValue;
    }

    this.setState({ searchFilters: searchFilters });
    if (modalRef) modalRef.current.closeModal();

    // RESETANDO A LISTA DE MARCAS
    this.filterBrands(null, '');

    if (executeSearch) {
      this.search(
        this.state.key,
        this.state?.limit,
        this.state.category,
        this.state.tag,
        { sum: 0 },
        false,
        this.state.searchFilters.price ? this.state.searchFilters.price._id : null,
        this.checkMinMaxValues(this.state.preco_min, this.state.preco_max),
        this.state.searchFilters.condition ? this.state.searchFilters.condition._id : null,
        this.state.searchFilters.categorizations.length > 0 ? this.formatCategoryList(this.state.searchFilters) : null,
        this.state.searchFilters.brand ? this.state.searchFilters.brand._id : null,
        this.state.searchFilters.deliveryTypes ? this.state.searchFilters.deliveryTypes._id : null,
        this.aleatory,
        this.state.searchFilters.size ? this.state.searchFilters.size._id : null,
        this.state.showcaseId ? this.state.showcaseId : null
      );
    }
  }

  hasChindren = (categorization) => {
    if (categorization.categorias && categorization.categorias.length >= 0) {
      return true;
    } else if (
      categorization.tipos_produtos &&
      categorization.tipos_produtos.length >= 0
    ) {
      return true;
    } else if (categorization.produtos && categorization.produtos.length >= 0) {
      return true;
    }

    return false;
  };

  openFilter = (filterName) => {

    let filter = this.state.filterOpened;

    for ( let key in filter ) {

      if(key === filterName) {
        filter[key] = !filter[key]
      } else {
        filter[key] = false;
      }
    }

    this.setState({
      filterOpened: filter
    });
  }

  render() {
    const handleChange = (newValue) => {
      this.setState(newValue);
    };

    const muiTheme = createMuiTheme({
      overrides: {
        MuiSlider: {
          thumb: {
            color: "#14233e",
          },
          track: {
            color: "#14233e",
          },
          rail: {
            color: "black",
          },
        },
      },
    });

    return (
      <>
        <div id="view_results">
          <Helmet title={`${this.props.site_properties?.title} - Página de busca` } />
          
          <StickyContainer>
            <Sticky>
              {({ style }) => (

                <div className="wrapper-mobile-filters-head" style={style}>
                  <>
                    {!this.state.adsSeller?.length === 0 ? null : (
                      <>
                        <MobileFiltersBar>
                          {/* <FiltersBarItem text={`${this.state.adsSeller?.length > 0 ? this.state.adsSeller?.length : 0} itens`} /> */}
                          <FiltersBarItem text="Ordenar" icon={<Icon icon={faChevronDown} />} className="chevron" onClick={this.handleShowOrderFilter} />
                          <FiltersBarItem text="Filtros" icon={<TuneIcon />} className="chevron" onClick={this.handleShowFilter} />
                        </MobileFiltersBar>

                        {this.state.searchFiltersApplied && (this.state.searchFiltersApplied.price || this.state.searchFiltersApplied.categorizations?.length > 0 ||
                          this.state.searchFiltersApplied.size || this.state.searchFiltersApplied.brand || this.state.searchFiltersApplied.condition ||
                          this.state.searchFiltersApplied.deliveryTypes || this.state.preco_min || this.state.preco_max) ?
                          <div className="current-selected-filters">
                            <div className="current-filters">
                              <h2>Filtros<br /> ativos:</h2>
                              <HistoryFilters>
                                {this.state.searchFiltersApplied.price ? (
                                  <RemoveFilter text={this.state.searchFiltersApplied.price.nome} subtitle="Ordenar por:" onClick={() => { this.applyFilter("price", undefined, null, true); this.resetInput('ordenation'); }} />
                                ) : null}

                                {this.state.searchFiltersApplied.categorizations?.length > 0 ? (
                                  <>
                                    {this.state.searchFiltersApplied.categorizations.map((categorization, index) =>
                                      <RemoveFilter text={categorization.nome} subtitle="Categorias:" onClick={() => this.removeCategorizationFilter(categorization, index, true)} />
                                    )}
                                  </>
                                ) : null}

                                {this.state.searchFiltersApplied.size ? (
                                  <RemoveFilter text={this.state.searchFiltersApplied.size.nome} subtitle="Tamanho:" onClick={() => { this.applyFilter("size", undefined, null, true); this.resetInput('size') }} />
                                ) : null}

                                {this.state.searchFiltersApplied.brand ? (
                                  <RemoveFilter text={this.state.searchFiltersApplied.brand.nome} subtitle="Marcas:" onClick={(e) => { this.removeFilterBrand("brand", undefined, null, true); this.resetInput('select_brand'); }} />
                                ) : null}

                                {this.state.searchFiltersApplied.condition ? (
                                  <RemoveFilter text={this.state.searchFiltersApplied.condition.nome} subtitle="Condição:" onClick={() => { this.applyFilter("condition", undefined, null, true); this.resetInput('condition'); }} />
                                ) : null}

                                {this.state.searchFiltersApplied.deliveryTypes ? (
                                  <RemoveFilter text={this.state.searchFiltersApplied.deliveryTypes.nome} subtitle="Frete:" onClick={() => { this.applyFilter("deliveryTypes", undefined, null, true); this.resetInput('select_delivery_type'); }} />
                                ) : null}

                                {this.state.preco_min || this.state.preco_max ? (
                                  <>
                                    <RemoveFilter text={`Minimo ${this.state.preco_min ? this.state.preco_min : 0} / Máximo ${this.state.preco_max ? this.state.preco_max : 99999}`} subtitle="Pontos:" onClick={() =>
                                      this.setState({ preco_min: undefined, preco_max: undefined }, () => {
                                        this.search(
                                          this.state.key,
                                          8,
                                          this.state.category,
                                          this.state.tag,
                                          { sum: 0 },
                                          true,
                                          this.state.searchFilters.price ? this.state.searchFilters.price._id : null,
                                          this.checkMinMaxValues(this.state.preco_min, this.state.preco_max),
                                          this.state.searchFilters.condition ? this.state.searchFilters.condition._id : null,
                                          this.state.searchFilters.categorizations.length > 0 ? this.formatCategoryList(this.state.searchFilters) : null,
                                          this.state.searchFilters.brand ? this.state.searchFilters.brand._id : null,
                                          this.state.searchFilters.deliveryTypes ? this.state.searchFilters.deliveryTypes._id : null,
                                          this.aleatory,
                                          this.state.searchFilters.size ? this.state.searchFilters.size._id : null,
                                          this.state.showcaseId ? this.state.showcaseId : null
                                        )
                                      })
                                    } />
                                  </>
                                ) : null}

                              </HistoryFilters>
                            </div>
                            <div className="clear-filters">
                              <button type="button" className="btn" onClick={(e) => { e.preventDefault(); this.removeApplyedFilters(); }}>
                                Limpar filtros
                        </button>
                            </div>
                          </div>
                          : <></>
                        }
                      </>
                    )}
                  </>
                </div>


              )}
            </Sticky>


            {!this.state.searchByStore ? (
              <section className="_store-content">
              {!this.state.adsSeller?.length === 0 ? <></> : (
                <div className="filter">
                  {/* <div className="_active-filters--mobile">
                  <button type="button" className="filters-count">
                    <span className="text">28 itens</span>
                  </button>
                  <button type="button" onClick={this.handleShowOrderFilter}>
                    Ordenar
                    <div className="icon">
                      <Icon icon={faChevronDown} />
                    </div>
                  </button>
                  <button type="button" onClick={this.handleShowFilter}>
                    Filtros
                    <div className="icon">
                      <TuneIcon />
                    </div>
                  </button>
                </div> */}

                  <div className={this.state.showOrderFilter ? "_filter-order _filter-show" : "_filter-order"} >

                    <FilterHeadOrderBy filterTitle="Ordenar" onClick={this.handleShowOrderFilter} />

                    <form onSubmit={(e) => e.preventDefault()} className="_form-order-by">
                      <Filter filterTitle="Ordenar por" open={this.state.filterOpened.order} openOrder={true} activeFilterSelected={this.state.searchFilters.price ? "active" : "no-active"} onClick={() => this.openFilter("order")} activeFilters={
                        <>
                          {this.state.searchFilters.price ? (<span>{this.state.searchFilters.price.nome}</span>) : null}
                        </>
                      }>

                        {this.state.searchFilters.price ? (
                          <HistoryFilters>
                              <RemoveFilter text={this.state.searchFilters.price.nome} onClick={() => { this.applyFilter("price", undefined); this.resetInput('ordenation') }} />
                          </HistoryFilters>
                        ) : null}

                        <label className="_filter-label">
                          <input onClick={() => this.applyFilter("price", { _id: -1, nome: "Maior Preço" })} type="radio" name="ordenation" />
                          <span className="_filter-title">Maior preço</span>
                          <div className="check-mark"> <Icon icon={faCheck} /></div>
                        </label>

                        <label className="_filter-label">
                          <input type="radio" onClick={() => this.applyFilter("price", { _id: 1, nome: "Menor Preço" })} name="ordenation" />
                          <span className="_filter-title">Menor preço</span>
                          <div className="check-mark"> <Icon icon={faCheck} /></div>
                        </label>

                        {/* {this.state.searchFilters.price ? (
                        <button type="button" id="removeOrdenarPor" onClick={() => this.applyFilter("price", undefined)} className="_filter-selected">
                          {this.state.searchFilters.price.nome}
                          <Icon icon={faTimes} />
                        </button>
                      ) :
                        (
                          <>
                            <label className="_filter-label">
                              <input onClick={() => this.applyFilter("price", { _id: -1, nome: "Maior Preço" })} type="radio" name="filter-input-name" />
                              <span className="_filter-title">Maior preço</span>
                              <div className="check-mark"> <Icon icon={faCheck} /></div>
                            </label>

                            <label className="_filter-label">
                              <input type="radio" onClick={() => this.applyFilter("price", { _id: 1, nome: "Menor Preço" })} name="filter-input-name" />
                              <span className="_filter-title">Menor preço</span>
                              <div className="check-mark"> <Icon icon={faCheck} /></div>
                            </label>
                          </>
                        )
                      } */}

                        <div className="filter-actions">

                          <Button
                            onClick={(e) => {
                              e.preventDefault();
                              this.removeApplyedFilters();
                            }}
                            type="submit"
                            autoFocus
                            className="apply-filters"
                          >
                            Limpar
                          </Button>

                          <Button
                            onClick={(e) => {
                              this.openFilter("order");
                              e.preventDefault();
                              window.scrollTo(0, 0);
                              this.setState({
                                showOrderFilter: false,
                                limit: 8
                              });
                              setTimeout(() => this.handleURLParams('limit', '8'), 2000);
                              this.search(
                                this.state.key,
                                8,
                                this.state.category,
                                this.state.tag,
                                { sum: 0 },
                                true,
                                this.state.searchFilters.price ? this.state.searchFilters.price._id : null,
                                this.state.searchFilters.points ? this.state.searchFilters.points._id : null,
                                this.state.searchFilters.condition ? this.state.searchFilters.condition._id : null,
                                this.state.searchFilters.categorizations.length > 0 ? this.formatCategoryList(this.state.searchFilters) : null,
                                this.state.searchFilters.brand ? this.state.searchFilters.brand._id : null,
                                this.state.searchFilters.deliveryTypes ? this.state.searchFilters.deliveryTypes._id : null,
                                this.aleatory,
                                this.state.searchFilters.size ? this.state.searchFilters.size._id : null,
                                this.state.showcaseId ? this.state.showcaseId : null
                              );
                            }}
                            type="submit"
                            color="primary"
                            autoFocus
                            id="btnAplicarFiltros"
                            className="apply-filters"
                          >
                            Aplicar <Icon icon={faCheck} />
                          </Button>
                        </div>

                      </Filter>

                    </form>
                  </div>

                  <div className={this.state.showFilter ? "_wrap-filters _filter-show" : "_wrap-filters"}>

                    <form id="form-filters" className="_form _sidebar">
                      <FilterHead title="Filtros Geral" onClick={this.handleCloseFilter}>

                        <div className="clear-filters">
                          <button type="submit" className="btn" onClick={(e) => { e.preventDefault(); this.removeApplyedFilters(); }}>
                            Limpar filtros
                        </button>
                        </div>

                      </FilterHead>

                      <Filter filterTitle="Categorias" open={this.state.filterOpened.categories} activeFilterSelected={this.state.searchFilters.categorizations?.length > 0 ? "active" : "no-active"} onClick={() => this.openFilter("categories")} closeFilter={this.handleCloseFilter} activeFilters={
                        <>
                          {this.state.searchFilters.categorizations?.length > 0 ? (
                            <>
                              {this.state.searchFilters.categorizations.map((categorization, index) =>
                                <span>{categorization.nome}</span>
                              )}
                            </>
                          ) : (<></>)}
                        </>
                      }>

                        {/* <HistoryCategory>

                        {this.state.searchFilters.categorizations?.length > 0 ? (
                          <>
                            {this.state.searchFilters.categorizations.map((categorization, index) =>
                              <HistoryItem text={categorization.nome} onClick={() => this.removeCategorizationFilter(categorization, index)} />
                            )}
                          </>
                        ) : (<></>)}
                      </HistoryCategory> */}


                        {this.state.searchFilters.categorizations?.length > 0 ? (
                          <HistoryFilters>
                            <>
                              {this.state.searchFilters.categorizations.map((categorization, index) =>
                                <RemoveFilter text={categorization.nome} onClick={() => this.removeCategorizationFilter(categorization, index)} />
                              )}
                            </>
                          </HistoryFilters>
                        ) : (<></>)}


                        {/* {this.state.searchFilters.categorizations?.length > 0 ? (
                        <>
                          {this.state.searchFilters.categorizations.map((categorization, index) =>
                          (<button key={index} type="button" id="removeCategory" onClick={() => this.removeCategorizationFilter(categorization, index)} className="_filter-selected">
                            {categorization.nome}
                            <Icon icon={faTimes} />
                            {console.log("remove: ", this.state.searchFilters.categorizations)}
                          </button>)
                          )}
                        </>
                      ) : (<></>)} */}

                        <div className="wrap-filter-results">
                          {this.state.listActiveCategorizations?.length > 0 ? this.state.listActiveCategorizations.map((categorization, index) => (
                            <label key={index} className="_filter-label">
                              <input onClick={() => this.applyCategorizationFilter(categorization, null, null, true)} type="radio" name="categorization" checked={false} />
                              <span className="_filter-title">{categorization.nome}</span>

                              {this.hasChindren(categorization) ? (<div className="chevron-icon"> <Icon icon={faChevronRight} /> </div>) : (<div className="check-mark"> <Icon icon={faCheck} /> </div>)}

                            </label>
                          )) : (<></>)}
                        </div>


                        {/* {this.state.listActiveCategorizations?.length > 7 ?
                        (<button
                          type="button"
                          className="_loadmore-in-modal"
                          onClick={() => this.refLoadMoreModal.current.openModal()}
                        >
                          Ver mais
                        </button>) : (<></>)
                      } */}

                        {/* {this.state.listActiveCategorizations?.length > 7 ?
                        (<button
                          type="button"
                          className="_loadmore-in-modal"
                          onClick={() => this.refLoadMoreModal.current.openModal()}
                        >
                          Ver mais
                        </button>) : (<></>)
                      } */}

                        <div className="filter-actions">

                          <Button
                            onClick={(e) => {
                              this.openFilter("order");
                              e.preventDefault();
                              this.removeApplyedFilters();
                            }}
                            type="submit"
                            autoFocus
                            className="apply-filters"
                          >
                            Limpar
                        </Button>

                          <Button
                            onClick={this.handleCloseFilter, (e) => {
                              this.openFilter("categories");
                              e.preventDefault();
                              window.scrollTo(0, 0);
                              this.setState({
                                showFilter: false,
                                searchByClick: false,
                                limit: 8
                              });
                              setTimeout(() => this.handleURLParams('limit', '8'), 2000);
                              this.search(
                                this.state.key,
                                8,
                                this.state.category,
                                this.state.tag,
                                { sum: 0 },
                                true,
                                this.state.searchFilters.price ? this.state.searchFilters.price._id : null,
                                this.checkMinMaxValues(this.state.preco_min, this.state.preco_max),
                                this.state.searchFilters.condition ? this.state.searchFilters.condition._id : null,
                                this.state.searchFilters.categorizations.length > 0 ? this.formatCategoryList(this.state.searchFilters) : null,
                                this.state.searchFilters.brand ? this.state.searchFilters.brand._id : null,
                                this.state.searchFilters.deliveryTypes ? this.state.searchFilters.deliveryTypes._id : null,
                                this.aleatory,
                                this.state.searchFilters.size ? this.state.searchFilters.size._id : null,
                                this.state.showcaseId ? this.state.showcaseId : null
                              );
                            }}
                            type="submit"
                            color="primary"
                            autoFocus
                            id="btnAplicarFiltros"
                            className="apply-filters"
                          >
                            Aplicar <Icon icon={faCheck} />
                          </Button>
                        </div>

                      </Filter>

                      {this.state.listActiveSizes?.length > 0 ? (<Filter className="filter-sizes" open={this.state.filterOpened.size} activeFilterSelected={this.state.searchFilters.size ? "active" : "no-active"} onClick={() => this.openFilter("size")} filterTitle="Tamanho" activeFilters={
                        <>
                          {this.state.searchFilters.size ? (<span>{this.state.searchFilters.size.nome}</span>) : null}
                        </>
                      }>

                        {this.state.searchFilters.size ? (
                          <HistoryFilters>
                            <>
                              <RemoveFilter text={this.state.searchFilters.size.nome} onClick={() => { this.applyFilter("size", undefined); this.resetInput('size'); }} />

                            </>
                          </HistoryFilters>
                        ) : null}

                        {/* {this.state.searchFilters.size ? (
                        <button type="button" id="removeSize" onClick={() => this.applyFilter("size", undefined)} className="_filter-selected">
                          {this.state.searchFilters.size.nome}
                          <Icon icon={faTimes} />
                        </button>
                      ) : <> */}
                        {/*{this.state.listActiveSizes.slice(0, 3).map((size) => (
                          <label key={size.nome} className="_filter-label">
                            <input onClick={() => this.applyFilter("size", size)} type="radio" name="size" />
                            <span className="_filter-title">{size.nome}</span>
                            {/*<span className="_filter-count">(759)</span>*
                          </label>
                        ))
                        }*/}
                        {/* </>
                      } */}
                        {this.state.listActiveSizes?.map((sizeGroup, index) => (

                          <ListSize title={sizeGroup.nome}>
                            <ListSizePanel>

                              {this.state.searchFilters.size ? (
                                <HistoryFilters>
                                  <RemoveFilter text={this.state.searchFilters.size.nome} onClick={() => { this.applyFilter("size", undefined); this.resetInput('size'); }} />
                                </HistoryFilters>
                              ) : null}
                              <div className="wrap-filter-results">

                                <Grid container spacing={1}>

                                  {sizeGroup.tamanhos.map((size, index) => (
                                    <Grid item lg={3} md={3} sm={3} xs={3} key={index}>
                                      <label>
                                        <input type="radio" className="btn-select--filter bordered" onClick={() => this.applyFilter("size", size, this.refLoadMoreSize)} name="size" />
                                        <span className="title">
                                          {size.nome}
                                        </span>
                                        <span className="overlay"></span>
                                        {/* <div className="check-mark">

                                      <Icon icon={faCheck} />
                                      </div> */}
                                      </label>
                                    </Grid>
                                  ))}

                                </Grid>
                              </div>

                              <div className="filter-actions">

                                <Button
                                  onClick={(e) => {
                                    this.openFilter("order");
                                    e.preventDefault();
                                    this.removeApplyedFilters();
                                  }}
                                  type="submit"
                                  autoFocus
                                  className="apply-filters"
                                >
                                  Limpar
                              </Button>

                                <Button
                                  onClick={this.handleCloseFilter, (e) => {
                                    this.openFilter("size");
                                    e.preventDefault();
                                    window.scrollTo(0, 0);
                                    this.setState({
                                      showFilter: false,
                                      searchByClick: false,
                                      limit: 8
                                    });
                                    setTimeout(() => this.handleURLParams('limit', '8'), 2000);
                                    this.search(
                                      this.state.key,
                                      8,
                                      this.state.category,
                                      this.state.tag,
                                      { sum: 0 },
                                      true,
                                      this.state.searchFilters.price ? this.state.searchFilters.price._id : null,
                                      this.checkMinMaxValues(this.state.preco_min, this.state.preco_max),
                                      this.state.searchFilters.condition ? this.state.searchFilters.condition._id : null,
                                      this.state.searchFilters.categorizations.length > 0 ? this.formatCategoryList(this.state.searchFilters) : null,
                                      this.state.searchFilters.brand ? this.state.searchFilters.brand._id : null,
                                      this.state.searchFilters.deliveryTypes ? this.state.searchFilters.deliveryTypes._id : null,
                                      this.aleatory,
                                      this.state.searchFilters.size ? this.state.searchFilters.size._id : null,
                                      this.state.showcaseId ? this.state.showcaseId : null
                                    );
                                  }}
                                  type="submit"
                                  color="primary"
                                  autoFocus
                                  id="btnAplicarFiltros"
                                  className="apply-filters"
                                >
                                  Aplicar <Icon icon={faCheck} />
                                </Button>
                              </div>
                            </ListSizePanel>
                          </ListSize>

                        ))}
                        {/* <div className="_wrap-items _sizes">


                        {this.state.listActiveSizes?.map((sizeGroup, index) => (
                          <>
                            {console.log("Name: ", this.state.listActiveSizes)}
                            <div className="FilterHead">
                              <div key={index} className="_filter-selected">
                                {sizeGroup.nome}
                              </div>
                            </div>
                            <Grid container spacing={2}>

                              {sizeGroup.tamanhos.map((size, index) => (
                                <Grid item lg={2} md={2} sm={2} xs={4} key={index}>
                                  <button type="button" className="btn-select--filter bordered" onClick={() => this.applyFilter("size", size, this.refLoadMoreSize)} name="size">
                                    <span>
                                      {size.nome}
                                    </span>
                                    <Icon icon={faCheck} />
                                  </button>
                                </Grid>
                              ))}

                            </Grid>
                          </>
                        ))
                        }
                      </div> */}

                        {/* {!this.state.searchFilters.size ?
                        (<button
                          type="button"
                          className="_loadmore-in-modal"
                          onClick={() => this.refLoadMoreSize.current.openModal()}
                        >
                          Selecionar
                        </button>) : (<></>)
                      } */}

                      </Filter>) : (<></>)
                      }

                      <Filter filterTitle="Marcas" open={this.state.filterOpened.brand} activeFilterSelected={this.state.searchFilters.brand ? "active" : "no-active"}  onClick={() => this.openFilter("brand")} className="filter-brand" closeFilter={this.handleCloseFilter} activeFilters={
                        this.state.searchFilters.brand ? (<span>{this.state.searchFilters.brand.nome}</span>) : null
                      }>

                        <form onSubmit={(e) => this.filterBrands(e, this.inputBrand)} className="form form-filter _brands">

                          <div className="search">

                            <div className="form-group search mobile">
                              <input onChange={(e) => this.inputBrand = e.target.value} type="text" placeholder="Busque por 'Zara'" />
                              <button type="submit">
                                <Icon icon={faSearch} className="fa-question-circle" />
                              </button>
                            </div>

                          </div>



                          <div className="brand-list">

                            {/* {this.state.searchFilters.brand ? (
                            <HistoryCategory>

                              <HistoryItem text={this.state.searchFilters.brand.nome} onClick={(e) => this.removeFilterBrand("brand", undefined)} />

                            </HistoryCategory>
                          ) : null} */}

                            {this.state.searchFilters.brand ? (
                              <HistoryFilters>
                                <RemoveFilter text={this.state.searchFilters.brand.nome} onClick={(e) => { this.removeFilterBrand("brand", undefined); this.resetInput('select_brand'); }} />
                              </HistoryFilters>
                            ) : null}

                            {this.state.listBrands.slice(0, 8).map((brand, index) => (
                              <div className="brand-item" key={index}>
                                <label>
                                  <input type="radio" name="select_brand" onClick={() => this.applyFilter("brand", brand, this.refLoadMoreBrand)} />
                                  <div className="brand-title">
                                    {brand.nome}
                                  </div>
                                  <div className="check-mark"> <Icon icon={faCheck} /> </div>
                                </label>
                              </div>
                            ))}


                            {/* <Grid container spacing={2}>
                            {this.state.listBrands.slice(0, 8).map((brand, index) => (
                              <Grid item lg={4} md={4} sm={12} xs={12} key={index}>
                                <button type="button" className="btn-select--filter" onClick={() => this.applyFilter("brand", brand, this.refLoadMoreBrand)}>
                                  {brand.nome}
                                  <Icon icon={faCheck} />
                                </button>
                              </Grid>
                            ))}
                          </Grid> */}
                          </div>
                        </form>

                        <div className="filter-actions">

                          <Button
                            onClick={(e) => {
                              this.openFilter("order");
                              e.preventDefault();
                              this.removeApplyedFilters();
                            }}
                            type="submit"
                            autoFocus
                            className="apply-filters"
                          >
                            Limpar
                        </Button>

                          <Button
                            onClick={(e) => {
                              this.openFilter("brand");
                              e.preventDefault();
                              window.scrollTo(0, 0);
                              this.setState({
                                showFilter: false,
                                searchByClick: false,
                                limit: 8
                              });
                              setTimeout(() => this.handleURLParams('limit', '8'), 2000);
                              this.search(
                                this.state.key,
                                8,
                                this.state.category,
                                this.state.tag,
                                { sum: 0 },
                                true,
                                this.state.searchFilters.price ? this.state.searchFilters.price._id : null,
                                this.checkMinMaxValues(this.state.preco_min, this.state.preco_max),
                                this.state.searchFilters.condition ? this.state.searchFilters.condition._id : null,
                                this.state.searchFilters.categorizations.length > 0 ? this.formatCategoryList(this.state.searchFilters) : null,
                                this.state.searchFilters.brand ? this.state.searchFilters.brand._id : null,
                                this.state.searchFilters.deliveryTypes ? this.state.searchFilters.deliveryTypes._id : null,
                                this.aleatory,
                                this.state.searchFilters.size ? this.state.searchFilters.size._id : null,
                                this.state.showcaseId ? this.state.showcaseId : null
                              );
                            }}
                            type="submit"
                            color="primary"
                            autoFocus
                            id="btnAplicarFiltros"
                            className="apply-filters"
                          >
                            Aplicar <Icon icon={faCheck} />
                          </Button>
                        </div>


                        {/* {this.state.searchFilters.brand ? (
                        <button type="button" id="removeBrand" onClick={(e) => this.removeFilterBrand("brand", undefined)} className="_filter-selected">
                          {this.state.searchFilters.brand.nome}
                          <Icon icon={faTimes} />
                        </button>
                      ) : <>
                        {this.state.listBrands.length > 0 ? this.state.listBrands.slice(0, 7).map((brand, index) => (
                          <label key={index} className="_filter-label">
                            <input onClick={() => this.applyFilter("brand", brand)} type="radio" name="brand" />
                            <span className="_filter-title">{brand.nome}</span>
                          </label>
                        )) : (<></>)}
                      </>
                      } */}

                        {/* {!this.state.searchFilters.brand ?
                        (<button
                          type="button"
                          className="_loadmore-in-modal"
                          onClick={() => this.refLoadMoreBrand.current.openModal()}
                        >
                          Ver mais
                        </button>) : (<></>)
                      } */}

                      </Filter>

                      <Filter filterTitle="Condição" open={this.state.filterOpened.condition} activeFilterSelected={this.state.searchFilters.condition ? "active" : "no-active"} onClick={() => this.openFilter("condition")} closeFilter={this.handleCloseFilter} activeFilters={
                        this.state.searchFilters.condition ? (<span>{this.state.searchFilters.condition.nome}</span>) : null
                      }>

                        {/* {this.state.searchFilters.condition ? (
                        <HistoryCategory>

                          <HistoryItem text={this.state.searchFilters.condition.nome} onClick={() => this.applyFilter("condition", undefined)} />

                        </HistoryCategory>
                      ) : null} */}

                        {this.state.searchFilters.condition ? (
                          <HistoryFilters>
                            <RemoveFilter text={this.state.searchFilters.condition.nome} onClick={() => { this.applyFilter("condition", undefined); this.resetInput('condition'); }} />
                          </HistoryFilters>
                        ) : null}

                        {this.state.listConditions.length > 0 ? this.state.listConditions.map((condition) => (
                          <label key={condition.nome} className="_filter-label">
                            <input onClick={() => this.applyFilter("condition", condition)} type="radio" name="condition" />
                            <span className="_filter-title">{condition.nome}</span>
                            <div className="check-mark"> <Icon icon={faCheck} /> </div>
                          </label>
                        )) : null
                        }

                        {/* {this.state.searchFilters.condition ? (
                        <button type="button" id="removeCondition" onClick={() => this.applyFilter("condition", undefined)} className="_filter-selected">
                          {this.state.searchFilters.condition.nome}
                          <Icon icon={faTimes} />
                        </button>
                      ) : <>
                        {this.state.listConditions.length > 0 ? this.state.listConditions.map((condition) => (
                          <label key={condition.nome} className="_filter-label">
                            <input onClick={() => this.applyFilter("condition", condition)} type="checkbox" name="condition" />
                            <span className="_filter-title">{condition.nome}</span>
                            <div className="check-mark"> <Icon icon={faCheck} /> </div>
                          </label>
                        )) : (<></>)
                        }
                      </>
                      } */}

                        <div className="filter-actions">

                          <Button
                            onClick={(e) => {
                              this.openFilter("order");
                              e.preventDefault();
                              this.removeApplyedFilters();
                            }}
                            type="submit"
                            autoFocus
                            className="apply-filters"
                          >
                            Limpar
                        </Button>

                          <Button
                            onClick={(e) => {
                              this.openFilter("condition");
                              e.preventDefault();
                              window.scrollTo(0, 0);
                              this.setState({
                                showFilter: false,
                                searchByClick: false,
                                limit: 8
                              });
                              setTimeout(() => this.handleURLParams('limit', '8'), 2000);
                              this.search(
                                this.state.key,
                                8,
                                this.state.category,
                                this.state.tag,
                                { sum: 0 },
                                true,
                                this.state.searchFilters.price ? this.state.searchFilters.price._id : null,
                                this.checkMinMaxValues(this.state.preco_min, this.state.preco_max),
                                this.state.searchFilters.condition ? this.state.searchFilters.condition._id : null,
                                this.state.searchFilters.categorizations.length > 0 ? this.formatCategoryList(this.state.searchFilters) : null,
                                this.state.searchFilters.brand ? this.state.searchFilters.brand._id : null,
                                this.state.searchFilters.deliveryTypes ? this.state.searchFilters.deliveryTypes._id : null,
                                this.aleatory,
                                this.state.searchFilters.size ? this.state.searchFilters.size._id : null,
                                this.state.showcaseId ? this.state.showcaseId : null
                              );
                            }}
                            type="submit"
                            color="primary"
                            autoFocus
                            id="btnAplicarFiltros"
                            className="apply-filters"
                          >
                            Aplicar <Icon icon={faCheck} />
                          </Button>
                        </div>

                      </Filter>

                      <Filter filterTitle="Frete" open={this.state.filterOpened.delivery} activeFilterSelected={this.state.searchFilters.deliveryTypes? "active" : "no-active"} onClick={() => this.openFilter("delivery")} closeFilter={this.handleCloseFilter} activeFilters={
                        this.state.searchFilters.deliveryTypes ? (<span>{this.state.searchFilters.deliveryTypes.nome}</span>) : null
                      }>

                        {/* {this.state.searchFilters.deliveryTypes ? (
                        <HistoryCategory>

                          <HistoryItem text={this.state.searchFilters.deliveryTypes.nome} onClick={() => this.applyFilter("deliveryTypes", undefined)} />

                        </HistoryCategory>
                      ) : null} */}

                        {this.state.searchFilters.deliveryTypes ? (
                          <HistoryFilters>
                            <RemoveFilter text={this.state.searchFilters.deliveryTypes.nome} onClick={() => { this.applyFilter("deliveryTypes", undefined); this.resetInput('select_delivery_type'); }} />
                          </HistoryFilters>
                        ) : null}

                        <label className="_filter-label">
                          <input onClick={() => this.applyFilter("deliveryTypes", { _id: "operador_logistico", nome: "Correios" })} type="radio" name="select_delivery_type" />
                          <span className="_filter-title">Correios</span>
                          <div className="check-mark"> <Icon icon={faCheck} /> </div>
                        </label>

                        <label className="_filter-label">
                          <input type="radio" onClick={() => this.applyFilter("deliveryTypes", { _id: "entrega_maos", nome: "Entrega em mãos" })} name="select_delivery_type" />
                          <span className="_filter-title">Entrega em mãos</span>
                          <div className="check-mark"> <Icon icon={faCheck} /> </div>
                        </label>

                        {/* {this.state.searchFilters.deliveryTypes ? (
                        <button type="button" id="removeFrete" onClick={() => this.applyFilter("deliveryTypes", undefined)} className="_filter-selected">
                          {this.state.searchFilters.deliveryTypes.nome}
                          <Icon icon={faTimes} />
                        </button>
                      ) :
                        (
                          <>
                            <label className="_filter-label">
                              <input onClick={() => this.applyFilter("deliveryTypes", { _id: "operador_logistico", nome: "Correios" })} type="radio" name="filter-input-name" />
                              <span className="_filter-title">Correios</span>
                            </label>

                            <label className="_filter-label">
                              <input type="radio" onClick={() => this.applyFilter("deliveryTypes", { _id: "entrega_maos", nome: "Entrega em mãos" })} name="filter-input-name" />
                              <span className="_filter-title">Entrega em mãos</span>
                            </label>
                          </>
                        )
                      } */}

                        <div className="filter-actions">

                          <Button
                            onClick={(e) => {
                              this.openFilter("order");
                              e.preventDefault();
                              this.removeApplyedFilters();
                            }}
                            type="submit"
                            autoFocus
                            className="apply-filters"
                          >
                            Limpar
                        </Button>

                          <Button
                            onClick={(e) => {
                              this.openFilter("delivery");
                              e.preventDefault();
                              window.scrollTo(0, 0);
                              this.setState({
                                showFilter: false,
                                searchByClick: false,
                                limit: 8
                              });
                              setTimeout(() => this.handleURLParams('limit', '8'), 2000);
                              this.search(
                                this.state.key,
                                8,
                                this.state.category,
                                this.state.tag,
                                { sum: 0 },
                                true,
                                this.state.searchFilters.price ? this.state.searchFilters.price._id : null,
                                this.checkMinMaxValues(this.state.preco_min, this.state.preco_max),
                                this.state.searchFilters.condition ? this.state.searchFilters.condition._id : null,
                                this.state.searchFilters.categorizations.length > 0 ? this.formatCategoryList(this.state.searchFilters) : null,
                                this.state.searchFilters.brand ? this.state.searchFilters.brand._id : null,
                                this.state.searchFilters.deliveryTypes ? this.state.searchFilters.deliveryTypes._id : null,
                                this.aleatory,
                                this.state.searchFilters.size ? this.state.searchFilters.size._id : null,
                                this.state.showcaseId ? this.state.showcaseId : null
                              );
                            }}
                            type="submit"
                            color="primary"
                            autoFocus
                            id="btnAplicarFiltros"
                            className="apply-filters"
                          >
                            Aplicar <Icon icon={faCheck} />
                          </Button>
                        </div>

                      </Filter>

                      <Filter filterTitle="Pontos" open={this.state.filterOpened.points} activeFilterSelected={this.state.preco_min >= 0 && this.state.preco_max >= 1 ? "active" : "no-active"} onClick={() => this.openFilter("points")} closeFilter={this.handleCloseFilter} activeFilters={
                        <>
                          {this.state.preco_min >= 0 && this.state.preco_max >= 1 ? (
                            <>
                              <span>Minimo {this.state.preco_min}</span> <span>Máximo {this.state.preco_max}</span>
                            </>
                          ) : null}

                        </>
                      }>
                        <div className="form-group">
                          <div className="_min-max">
                            <div className="input-group">
                              <input
                                type="number"
                                onChange={(e) => this.setState({ preco_min: e.target.value })}
                                value={this.state.preco_min}
                                className="form-control"
                                placeholder="Minimo"
                                aria-describedby="min-money"
                              />
                            </div>

                            <div className="icon">
                              <Icon icon={faArrowRight} />
                            </div>

                            <div className="input-group">
                              <input
                                type="number"
                                onChange={(e) => this.setState({ preco_max: e.target.value })}
                                value={this.state.preco_max}
                                className="form-control"
                                placeholder="Máximo"
                                aria-describedby="max-money"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="filter-actions">

                          <Button
                            onClick={(e) => {
                              this.openFilter("order");
                              e.preventDefault();
                              this.removeApplyedFilters();
                            }}
                            type="submit"
                            autoFocus
                            className="apply-filters"
                          >
                            Limpar
                        </Button>

                          <Button
                            onClick={(e) => {
                              this.openFilter("points");
                              e.preventDefault();
                              window.scrollTo(0, 0);
                              this.setState({
                                showFilter: false,
                                searchByClick: false,
                                limit: 8
                              });
                              setTimeout(() => this.handleURLParams('limit', '8'), 2000);
                              this.search(
                                this.state.key,
                                8,
                                this.state.category,
                                this.state.tag,
                                { sum: 0 },
                                true,
                                this.state.searchFilters.price ? this.state.searchFilters.price._id : null,
                                this.checkMinMaxValues(this.state.preco_min, this.state.preco_max),
                                this.state.searchFilters.condition ? this.state.searchFilters.condition._id : null,
                                this.state.searchFilters.categorizations.length > 0 ? this.formatCategoryList(this.state.searchFilters) : null,
                                this.state.searchFilters.brand ? this.state.searchFilters.brand._id : null,
                                this.state.searchFilters.deliveryTypes ? this.state.searchFilters.deliveryTypes._id : null,
                                this.aleatory,
                                this.state.searchFilters.size ? this.state.searchFilters.size._id : null,
                                this.state.showcaseId ? this.state.showcaseId : null
                              );
                            }}
                            type="submit"
                            color="primary"
                            autoFocus
                            id="btnAplicarFiltros"
                            className="apply-filters"
                          >
                            Aplicar <Icon icon={faCheck} />
                          </Button>
                        </div>

                      </Filter>
                      
                      <button
                            onClick={(e) => {
                              this.openFilter("order");
                              e.preventDefault();
                              this.removeApplyedFilters();
                            }}
                            type="submit"
                            autoFocus
                            className="btn btn-transparent clear-filters-desk"
                          >
                            Limpar filtros
                        </button>

                      {/* <button type="button" 
                      onClick={() => this.applyFilter("points", {_id: 
                        `${this.state.preco_min? this.state.preco_min: 0 }-${this.state.preco_max? this.state.preco_max: 999999999}`, nome: "points"})} 
                      disabled={true}
                      className="_apply-price">
                      Aplicar
                      <Icon icon={faCheck} />
                    </button> */}

                      {/* <div className="_apply-finter-button">
                      <Button
                        onClick={(e) => {
                          e.preventDefault();
                          window.scrollTo(0, 0);
                          this.setState({
                            showFilter: false,
                            searchByClick: false,
                            limit: 8
                          });
                          setTimeout(() => this.handleURLParams('limit', '8'), 2000);
                          this.search(
                            this.state.key,
                            8,
                            this.state.category,
                            this.state.tag,
                            { sum: 0 },
                            true,
                            this.state.searchFilters.price ? this.state.searchFilters.price._id : null,
                            this.checkMinMaxValues(this.state.preco_min, this.state.preco_max),
                            this.state.searchFilters.condition ? this.state.searchFilters.condition._id : null,
                            this.state.searchFilters.categorizations.length > 0 ? this.formatCategoryList(this.state.searchFilters) : null,
                            this.state.searchFilters.brand ? this.state.searchFilters.brand._id : null,
                            this.state.searchFilters.deliveryTypes ? this.state.searchFilters.deliveryTypes._id : null,
                            this.aleatory,
                            this.state.searchFilters.size ? this.state.searchFilters.size._id : null
                          );
                        }}
                        type="submit"
                        color="primary"
                        autoFocus
                        id="btnAplicarFiltros"
                        className="apply-filters"
                      >
                        Aplicar filtros
                        <Icon icon={faCheck} />

                      </Button>
                      <Button
                        style={{ marginTop: "10px", backgroundColor: "gray" }}
                        onClick={(e) => {
                          e.preventDefault();
                          this.removeApplyedFilters();
                        }}
                        type="submit"
                        autoFocus
                        className="apply-filters"
                      >
                        Remover filtros
                        <Icon icon={faBan} />

                      </Button>
                    </div> */}
                    </form>
                    
                    {/* <div className="count-results">
                    {`${this.state.adsSeller?.length > 0 ? this.state.adsSeller?.length : 0} itens`}
                    </div> */}
                  </div>
                </div>
              )}

          <div className="_only-desk">
            {this.state.adsSeller?.length === 0 ? <></> : (
              <h1 className="_title-results">
                {this.state.key && "Resultados para: "}

                <strong>
                  {this.state.key && `"${this.state.key.replace(/ampersand/g, '&')}"`}
                </strong>
              </h1>
            )}
          </div>

              <div className="_store-products">

                <div className="_only-mobl">
                  {this.state.adsSeller?.length === 0 ? <></> : (
                    <h1 className="_title-results">
                      {this.state.key && "Resultados para: "}

                      <strong>
                        {this.state.key && `"${this.state.key.replace(/ampersand/g, '&')}"`}
                      </strong>
                    </h1>
                  )}
                </div>


                <div className="_products">

                  {this.state.isLoading && (
                    <Grid container spacing={2}>
                      {this.state.searchByClick && [1, 2, 3, 4, 5, 6, 7, 8].map(() => (
                        <Grid item lg={3} md={4} sm={4} xs={6}>
                          <MyLoader className="loading-product" />
                        </Grid>
                      ))}
                    </Grid>
                  )}

                  {this.state.adsSeller?.length === 0 ? (

                    <div className="no-results">
                      <div className="icon">
                        <img src={noresult} />
                      </div>
                      <div className="content">
                        <h1 className="title">Ops! Sua busca não encontrou<br /> resultados.</h1>
                        <ul>
                          <li>- Tente buscar palavras menos específicas.</li>
                          <li>- Tente busca palavras diferentes.</li>
                          <li>- Digite no minimo 3 caracteres.</li>
                        </ul>
                      </div>
                    </div>

                  ) : (
                    <Grid container spacing={2}>
                      {this.state.adsSeller?.map((ad, index) => {
                        return (
                          <>
                            {this.state.isLoading && (
                              <Grid item lg={3} md={4} sm={4} xs={6}>
                                <MyLoader className="loading-product" />
                              </Grid>
                            )}

                            {!this.state.isLoading && ad.ativo === true ? (
                              <Grid item lg={3} md={4} sm={4} xs={6} key={index}>
                                <Product
                                  ad={ad}
                                  link={`/produto?id=${ad._id}`}
                                  image={
                                    this.ad.loadImage(ad.fotos[0])
                                      ? this.ad.loadImage(ad.fotos[0])
                                      : ""
                                  }
                                  weight={ad.tamanho_exibicao?.nome}
                                  title={ad.titulo}
                                  discount={
                                    ad.pontos_de
                                      ? ad.pontos_de.$numberDecimal
                                      : ""
                                  }
                                  price={
                                    ad.pontos_por
                                      ? ad.pontos_por.$numberDecimal
                                      : ""
                                  }
                                  type={ad.tipo_anuncio}
                                  cashback={ad.cashback}
                                  cashDiscount={ad.valor_de}
                                  cashPrice={ad.valor_por} 
                                  idAd={ad._id}
                                ></Product>
                              </Grid>
                            ) : (
                              <></>
                            )}
                          </>
                        );
                      })}
                    </Grid>
                  )}

                  {/* <Grid container spacing={2}>
                  {this.state.adsSeller ? (
                    this.state.adsSeller.map((ad, index) => {
                      return (
                        <>
                          {ad.ativo === true ? (
                            <Grid item lg={3} md={4} sm={4} xs={6} key={index}>
                              <Product
                                link={`/produto?id=${ad._id}`}
                                image={
                                  this.ad.loadImage(ad.fotos[0])
                                    ? this.ad.loadImage(ad.fotos[0])
                                    : ""
                                }
                                title={ad.titulo}
                                discount={
                                  ad.pontos_de
                                    ? ad.pontos_de.$numberDecimal
                                    : ""
                                }
                                price={
                                  ad.pontos_por
                                    ? ad.pontos_por.$numberDecimal
                                    : ""
                                }
                                idAd={ad._id}
                              ></Product>
                            </Grid>
                          ) : (
                            <></>
                          )}
                        </>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </Grid> */}
                </div>
                {/*<div className="_wrap-load-more">
                {this.state.adsSeller?.length > 0 && this.state.adsSeller && (
                  <React.Fragment>
                    {this.pageNumber - 1 <= 0 ? (
                      <button
                        type="submit"
                        className="btn btn-red"
                        disabled
                      >
                        ← Anterior
                      </button>
                    ) : (
                      <button
                        type="submit"
                        onClick={(e) => {
                          this.changePage(
                            this.state.key,
                            this.state?.limit,
                            this.state.category,
                            this.state.tag,
                            { subtraction: 1 },
                            false,
                            this.state.searchFilters.price ? this.state.searchFilters.price._id : null,
                            this.checkMinMaxValues(this.state.preco_min, this.state.preco_max),
                            this.state.searchFilters.condition ? this.state.searchFilters.condition._id : null,
                            this.state.searchFilters.categorizations.length > 0 ? this.formatCategoryList(this.state.searchFilters) : null,
                            this.state.searchFilters.brand ? this.state.searchFilters.brand._id : null,
                            this.state.searchFilters.deliveryTypes ? this.state.searchFilters.deliveryTypes._id : null,
                            this.state.searchFilters.size ? this.state.searchFilters.size._id : null
                          );
                        }}
                        className="btn btn-red"
                      >
                        ← Anterior
                      </button>
                    )}
                    {this.state.adsSeller.length < 8 ||
                      this.state.amountItensNextPage === 0 ? (
                      <button type="submit" className="btn btn-red" disabled>
                        Próxima →
                      </button>
                    ) : (
                      <button
                        type="submit"
                        onClick={(e) => {
                          this.setState({ searchByClick: false });
                          this.changePage(
                            this.state.key,
                            this.state?.limit,
                            this.state.category,
                            this.state.tag,
                            { sum: 1 },
                            false,
                            this.state.searchFilters.price ? this.state.searchFilters.price._id : null,
                            this.checkMinMaxValues(this.state.preco_min, this.state.preco_max),
                            this.state.searchFilters.condition ? this.state.searchFilters.condition._id : null,
                            this.state.searchFilters.categorizations.length > 0 ? this.formatCategoryList(this.state.searchFilters) : null,
                            this.state.searchFilters.brand ? this.state.searchFilters.brand._id : null,
                            this.state.searchFilters.deliveryTypes ? this.state.searchFilters.deliveryTypes._id : null,
                            this.state.searchFilters.size ? this.state.searchFilters.size._id : null
                          );
                        }}
                        className="btn btn-red"
                        id="nextPage"
                      >
                        Próxima →
                      </button>
                    )}
                  </React.Fragment>
                )}
              </div>*/}

                <div className="_wrap-load-more">
                  {this.state.limit <= this.state.adsSeller?.length ? (<button type="submit" onClick={() => this.loadMore()} className="btn btn-red">Carregar mais</button>) : null}

                </div>
              </div>

            </section>
            ) : (
              <section className="_store-content">
                <div className="_only-desk">
                  {this.state.adsSeller?.length === 0 ? <></> : (
                    <h1 className="_title-results">
                      {this.state.key && "Resultados para: "}
                      <strong>
                        {this.state.key && `"${this.state.key.replace(/ampersand/g, '&')}"`}
                      </strong>
                    </h1>
                  )}
                </div>
                <div className="_store-products">
                  <div className="_products">
                    {this.state.isLoading && (
                      <Grid container spacing={2}>
                        {this.state.searchByClick && [1, 2, 3, 4, 5, 6, 7, 8].map(() => (
                          <Grid item lg={3} md={4} sm={4} xs={6}>
                            <MyLoader className="loading-product" />
                          </Grid>
                        ))}
                      </Grid>
                    )}

                    {this.state.searchedStores?.length === 0 ? (

                      <div className="no-results">
                        <div className="icon">
                          <img src={noresult} />
                        </div>
                        <div className="content">
                          <h1 className="title">Ops! Sua busca não encontrou<br /> resultados.</h1>
                          <ul>
                            <li>- Tente buscar palavras menos específicas.</li>
                            <li>- Tente busca palavras diferentes.</li>
                            <li>- Digite no minimo 3 caracteres.</li>
                          </ul>
                        </div>
                      </div>

                    ) : (
                      <Grid container spacing={2}>
                        {this.state.searchedStores?.map((store, index) => {
                          return (
                            <>
                              {this.state.isLoading && (
                                <Grid item lg={3} md={4} sm={4} xs={6}>
                                  <MyLoader className="loading-product" />
                                </Grid>
                              )}

                              {!this.state.isLoading ? (
                                <Grid item lg={3} md={4} sm={4} xs={6} key={index}>
                                  <StoreCard
                                    link={`/loja/${store.minha_loja.url}`}
                                    image={store.imagens && store.imagens.avatar ? this.scamber.loadImage(store.imagens.avatar) : Avatar}
                                    title={store.minha_loja.titulo}
                                  />
                                </Grid>
                              ) : (
                                <></>
                              )}
                            </>
                          );
                        })}
                      </Grid>
                    )}
                  </div>
                  
                    <div className="_wrap-load-more">
                      {this.state.limit <= this.state.searchedStores?.length ? (<button type="submit" onClick={() => this.loadMoreStores()} className="btn btn-red">Carregar mais</button>) : null}
                    </div>
                </div>
              </section>
            )}
          </StickyContainer>
        </div>
        {/* MODAIS */}
        <ModalLoadMore modalTitle="Categorias" ref={this.refLoadMoreModal}>
          <form className="form form-filter">

            <div className="search">

              {/* <div className="form-group search mobile">
              <input type="text" placeholder="Busque por 'Blusas'" />
              <button type="submit">
                <Icon icon={faSearch} className="fa-question-circle"/>
              </button>
            </div> */}

            </div>

            <div className="_wrap-items">
              <Grid container spacing={2}>
                {this.state.listActiveCategorizations?.map((categorization, index) => (
                  <Grid item lg={4} md={4} sm={12} xs={12} key={index}>
                    {/* <div className="_filter-item">
                <label className="_filter-label">
                  <input onClick={() => this.applyCategorizationFilter(categorization, this.refLoadMoreModal)} type="radio" name="categorization" />
                  <span className="_filter-title">{categorization.nome}</span>
                </label>
              </div> */}

                    <button type="button" className="btn-select--filter" onClick={() => this.applyCategorizationFilter(categorization, this.refLoadMoreModal, null, true)} name="categorization">
                      {categorization.nome}
                      <Icon icon={faCheck} />
                    </button>
                  </Grid>
                ))
                }
              </Grid>
            </div>
          </form>
        </ModalLoadMore>

        <ModalLoadMore modalTitle="Selecione o tamanho" ref={this.refLoadMoreSize} modalSize="size-32w">
          <form className="form form-filter">
            {/* 
            <div className="search">

              <div className="form-group search mobile">
              <input type="text" placeholder="Busque por 'Blusas'" />
              <button type="submit">
                <Icon icon={faSearch} className="fa-question-circle"/>
              </button>
            </div>

            </div> */}

            <div className="_wrap-items _sizes">
              {/*{this.state.searchFilters.categorizations?.length > 0 ? (
                <div className="FilterHead">
                  {this.state.searchFilters.categorizations.map((categorization, index) =>
                  (<div key={index} className="_filter-selected">
                    {categorization.nome} <span className="dot">·</span>
                  </div>)
                  )}
                </div>
                  ) : (<></>)}*/}


              {this.state.listActiveSizes?.map((sizeGroup, index) => (
                <>
                  <div className="FilterHead">
                    <div key={index} className="_filter-selected">
                      {sizeGroup.nome}
                    </div>
                  </div>
                  <Grid container spacing={2}>

                    {sizeGroup.tamanhos.map((size, index) => (
                      <Grid item lg={2} md={2} sm={2} xs={4} key={index}>
                        <button type="button" className="btn-select--filter bordered" onClick={() => this.applyFilter("size", size, this.refLoadMoreSize)} name="size">
                          <span>
                            {size.nome}
                          </span>
                          <Icon icon={faCheck} />
                        </button>
                      </Grid>
                    ))}

                  </Grid>
                </>
              ))
              }
            </div>
          </form>
        </ModalLoadMore>

        <ModalLoadMore modalTitle="Marca" ref={this.refLoadMoreBrand}>
          <form onSubmit={(e) => this.filterBrands(e, this.inputBrand)} className="form form-filter _brands">

            <div className="search">

              <div className="form-group search mobile">
                <input onChange={(e) => this.inputBrand = e.target.value} type="text" placeholder="Busque por 'Zara'" />
                <button type="submit">
                  <Icon icon={faSearch} className="fa-question-circle" />
                </button>
              </div>

            </div>

            <div className="_wrap-items">


              <Grid container spacing={2}>
                {this.state.listBrands.map((brand, index) => (
                  <Grid item lg={4} md={4} sm={12} xs={12} key={index}>
                    <button type="button" className="btn-select--filter" onClick={() => this.applyFilter("brand", brand, this.refLoadMoreBrand)}>
                      {brand.nome}
                      <Icon icon={faCheck} />
                    </button>
                  </Grid>
                ))}
              </Grid>
            </div>
          </form>
        </ModalLoadMore>

      </>
    );
  }
}

const mapStateToProps = (state) => ({
  site_properties: state.websiteReducer.site_properties,
  website: state.websiteReducer.website
})

export default connect(mapStateToProps, {})(Results);
