
class EventEmitter {
    
    events = {};
    
    dispatch(event, data) {
        if(!this.events[event]) return;
        this.events[event].forEach(callback => callback(data));
    }

    subscribe(event, callback) {
        if(!this.events[event]) this.events[event] = [];
        this.events[event].push(callback);
    }

    unsubscribe(event) {
    
        if( event && this.events && this.events[event] && this.events[event].length > 0) {

            this.events[event].pop();
        }
    }
}

export default new EventEmitter();