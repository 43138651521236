import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import $ from "jquery";

// Icons
import {
  faChevronLeft,
  faCog,
  faPaperPlane,
  faImage,
  faPaperclip,
  faTimes,
} from "@fortawesome/pro-light-svg-icons";

// Images
import Avatar from "../assets/images/avatar.png";
import AvatarProposal from "../assets/images/user-proposal.png";

class MessengerInbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messenger: false,
      dropdown: false,
    };
  }

  handleShowMessenger = () => {
    this.setState({
      messenger: true,
    });
  };

  handleCloseMessenger = () => {
    this.setState({
      messenger: false,
    });
  };

  handleDDown = () => {
    this.setState({
      dropdown: !this.state.dropdown,
    });
  };

  render() {
    return (
      <>
        {this.state.messenger ? (
          <section className="_wrap-messenger">
            <form className="_chat-form">
              <section className="_chat-header">
                <div className="_cols">
                  <div className="_col _col-left">
                    <div className="_current-user">
                      <span className="user-image _rounded-image__with-notification-icon _1x">
                        <img src={Avatar} alt="Avatar" />
                      </span>
                      <span className="username">Loja Emilia</span>
                    </div>
                  </div>

                  <div className="_col _col-right">
                    <div className="_chat-config">
                      <button
                        type="button"
                        className="btn btn-transparent _disabled _options"
                        onClick={this.handleDDown}
                      >
                        <FontAwesomeIcon icon={faCog}></FontAwesomeIcon>
                      </button>

                      {this.state.dropdown ? (
                        <div className="_dropdown-menu" id="chat-options">
                          <nav className="_list-nav">
                            <ul>
                              <li>
                                <Link to="/#!">Denunciar usuário</Link>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      ) : null}
                    </div>

                    <button
                      type="button"
                      className="btn btn-transparent _disabled _options"
                      onClick={this.handleCloseMessenger}
                    >
                      <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
                    </button>
                  </div>
                </div>
              </section>

              <section className="_chat-content">
                <div className="_chat-messages">
                  <div className="message-date">21 DE MAIO DE 2020 17:58</div>

                  <div className="_wrap-received">
                    <div className="_from-user">
                      <span className="user-image _rounded-image__with-notification-icon _1x">
                        <img src={AvatarProposal} alt="Avatar" />
                      </span>
                    </div>

                    <div className="_received">
                      <div className="_who-sent">Marina Hertz</div>
                      <div className="_wrap-chat-messages">
                        <div className="chat-message-bubble received">
                          Oi, tudo bem?
                        </div>
                        <div className="chat-message-bubble received">
                          Voce aceita 450 pontos na bolsa?
                        </div>
                        <div className="chat-message-bubble received">
                          ainda tem a bolsa?
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="_wrap-sent">
                    <div className="_sent">
                      <div className="_wrap-chat-messages">
                        <div className="chat-message-bubble sent">Oi</div>
                        <div className="chat-message-bubble sent">
                          ainda não vendi
                        </div>
                        <div className="chat-message-bubble sent">
                          tem interesse?
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="_wrap-received">
                    <div className="_from-user">
                      <span className="user-image _rounded-image__with-notification-icon _1x">
                        <img src={AvatarProposal} alt="Avatar" />
                      </span>
                    </div>

                    <div className="_received">
                      <div className="_who-sent">Marina Hertz</div>
                      <div className="_wrap-chat-messages">
                        <div className="chat-message-bubble received">
                          Oi, tudo bem?
                        </div>
                        <div className="chat-message-bubble received">
                          Voce aceita 450 pontos na bolsa?
                        </div>
                        <div className="chat-message-bubble received">
                          ainda tem a bolsa?
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section className="_chat-footer">
                <section className="_chat-send">
                  <div className="_wrap-send">
                    <div className="send-text">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Digite uma mensagem..."
                      />
                    </div>
                    <div className="send-message">
                      <button
                        type="button"
                        className="btn btn-transparent _disabled"
                      >
                        <FontAwesomeIcon icon={faPaperPlane}></FontAwesomeIcon>
                      </button>
                    </div>
                  </div>
                </section>

                <section className="_chat-attachments">
                  <div className="_wrap-attachments">
                    <div className="attachment-file">
                      <label
                        htmlFor="uploadPhoto"
                        className="attachment"
                        title="Anexar uma foto"
                      >
                        <input
                          type="file"
                          accept="image/x-png, image/jpeg"
                          name="upload_photo"
                          id="uploadPhoto"
                          multiple="multiple"
                          className="form-control-file"
                        />
                        <button type="button" className="btn btn-transparent">
                          <FontAwesomeIcon icon={faImage}></FontAwesomeIcon>
                        </button>
                      </label>
                    </div>
                    <div className="attachment-file">
                      <label
                        htmlFor="uploadFile"
                        className="attachment"
                        title="Anexar um arquivo"
                      >
                        <input
                          type="file"
                          accept="image/x-png, image/jpeg"
                          name="upload_photo"
                          id="uploadFile"
                          multiple="multiple"
                          className="form-control-file"
                        />
                        <button type="button" className="btn btn-transparent">
                          <FontAwesomeIcon icon={faPaperclip}></FontAwesomeIcon>
                        </button>
                      </label>
                    </div>
                  </div>
                </section>
              </section>
            </form>
          </section>
        ) : null}
      </>
    );
  }
}

export default MessengerInbox;
