//=====================================================================================
// #1 - Import * Styled Components
//=====================================================================================
import styled from 'styled-components';

export const Branding = styled.div `

    a {
        display: inline-block;

        img {
            width: 28px;
        }
    }
`;