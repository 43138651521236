import styled from 'styled-components';

export const WrapperSuccess = styled.div`
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.875rem 0;

    .box {
        border: 0 !important;
        box-shadow: none !important;

        .box-content {
            padding: 1.875rem 0.9375rem;
        }
    }
`;

export const Wrapper = styled.div``;

export const WrapHeadline = styled.div`
    background-color: #f6f6f9;
    padding: 1.875rem 0.9375rem;
`;

export const WrapWizard = styled.div`
    max-width: 45rem;
    margin: 0 auto;
    padding-bottom: 0.9375rem;
    border-radius: 0.25rem;
    
    ${({theme}) => theme.media('xs', `
        margin-top: 1.25rem;
        margin-bottom: 1.875rem;
        border: 0;
    `)}
    
    ${({theme}) => theme.media('md', `
        margin-top: 3.75rem;
        margin-bottom: 3.75rem;
        border: 0.0625rem solid ${({theme}) => theme.color.ice};
    `)}
`;

export const WizardContent = styled.div`
    padding-left: 15px;
    padding-right: 15px;

    div {
        .hdline {
            .hline {
                margin: 0;
                h1 {
                    font-family: "Comfortaa",cursive !important;
                }
            }
        }
    }
`;

export const HDLine = styled.div`
    text-align: center;
    max-width: 420px;
    margin: 0 auto;
    margin-bottom: 1.25rem;
`;

export const Title = styled.h1`
    font-size: 1rem;
    font-weight: ${({theme}) => theme.fontWeight.fw700};
    color: ${({theme}) => theme.color.black};
    text-align: center;
    line-height: 1.6;

    span {
        display: block;
        font-size: 0.875rem;
        margin-top: 0.3125rem;
    }
`;

export const WizardActions = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 30px;

    button {
        width: 100%;
        border: 0;
        
        ${({theme}) => theme.media('xs', `
            max-width: none !important;
        `)}
        
        ${({theme}) => theme.media('md', `
            max-width: 140px !important;
        `)}

        &:last-child {
            margin-left: 15px;
        }
    }
`;

export const ButtonSubmit = styled.button`
    background-color: ${ props => props.buttonColor };
    padding: ${props => props.buttonSize === "medium" && "0.719rem 1.264rem" || props.buttonSize === "small" && "0.469rem 0.75rem" };
    font-size: 13px;
    font-weight: 700;
    border: 0;
    max-width: ${props => props.maxWidth ? props.maxWidth+"px" : `300px`} ;//300px;
    width: 100%;
    border-radius: 0.25rem;
    color: ${({theme}) => theme.color.white};

    &:disabled {
      opacity: 0.5;
    }
    
    a {
        color: ${props => props.textColor};
        position: relative;
        display: block;

        svg {
          height: 18px !important;
          width: auto !important;

          path {
            fill: ${props => props.iconColor};
          }
        }
    }
`;