//===============================================================================================================
// #1 - Import * React
//===============================================================================================================
import React from "react";

//===============================================================================================================
// #2 - Import * Header
//===============================================================================================================
import Header from "./header";

//===============================================================================================================
// #3 - Import * Footer
//===============================================================================================================
import Footer from './../components/Footer';

const Layout = (props) => (

    <>
        <Header/>
            {props.children}
        <Footer/>
    </>
)

export default Layout;