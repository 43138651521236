import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";

// Material - UI
import {
    Grid,
    TextField,
    InputAdornment,
    Dialog as DialogMui,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    useMediaQuery,
    IconButton,
    Slide,
    makeStyles,
    Typography,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import {
    faCheck,
    faEye,
    faEyeSlash,
    faTimes,
    faChevronLeft,
} from "@fortawesome/pro-light-svg-icons";

// Styles
const useStyles = makeStyles({
    center: {
        margin: "auto",
    },
    resize: {
        fontSize: "10px",
    },
    inputSize: {
        height: 50,
    },
    svgSize: {
        width: "18px !important",
        height: "auto !important",
    },
});


// Modal Dialog Base
const Dialog = forwardRef((props, ref) => {
    // Styles
    const classes = useStyles();
    const matches = useMediaQuery("(min-width:600px)");

    // Material ui Modal
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useImperativeHandle(ref, () => ({
        openModal: () => handleClickOpen(),
        closeModal: () => handleClose(),
    }));

    return (
        <>
            <DialogMui
                fullScreen={fullScreen}
                fullWidth={true}
                open={open ? true : false}
                maxWidth={"sm"}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                {...props}
            >
                <DialogTitle className="dialog-title" id="responsive-dialog-title">
                    <div className="_view-head-content">
                        <button type="button" className="btn" onClick={handleClose}>
                            <FontAwesomeIcon
                                icon={matches ? faTimes : faChevronLeft}
                            ></FontAwesomeIcon>
                        </button>
                        <div className="_title-view">{props.dialogTitle}</div>
                    </div>
                </DialogTitle>
                <DialogContent className={props.className}>
                    <Typography component={"span"} variant={"body2"}>
                        {props.children}
                    </Typography>
                </DialogContent>
            </DialogMui>
        </>
    );
});

// Material ui Dialog Slide
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default Dialog;