import Service from './Service';

class Loyalty extends Service {

    constructor() {
        super()
    }

    balance() {
        return this.axiosInstance.get(`/loyalty/balance`);
    }

}

export default Loyalty;