import React, { Component, setState } from "react";
import { Link, withRouter, useHistory } from "react-router-dom";
import Breadcrumb from "../components/Breadcrumbs";
import MessengerInbox from "../components/MessengerInbox";
import ProductItem from '../components/GridProduct';

import { connect } from 'react-redux';
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/scss/image-gallery.scss";
import Slider from "react-slick";

// * Utils
import Convert from "../utils/ConvertPointsToPrice"

// Modals
import ModalBase from "../components/modals/ModalBase";
import ModalShippingOptions from "./modals/ModalShippingOptions";
import ModalTaxs from "./modals/ModalTaxs";
import ModalDialog from "../components/modals/Proposal/ModalProposal";

// Material UI
import Grid from '@material-ui/core/Grid';

// Services
import Ad from '../services/Ad';
import Favorite from '../services/Favorito';
import ShortURL from '../services/ShortURL';

//=====================================================================================
// #4 - Import * FontAwesome Icons
//=====================================================================================
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQuestionCircle,
  faInfoCircle,
  faTruck,
  faHandHoldingBox,
  faHeart,
  faShoppingBag,
  faChevronRight,
  faLongArrowLeft,
  faTimes,
  faShare,
  faCommentAltLines,
  faCopy,
  faTrashAlt
} from "@fortawesome/pro-light-svg-icons";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { faHeart as faHeartSolid } from "@fortawesome/pro-solid-svg-icons";
import CoverIMG from "./../assets/images/cover.png";
// Icons
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";

//Images
import LogoStore from "../assets/images/avatar.png";

import Helmet from 'react-helmet';

//=====================================================================================
// #2 - Import * UI Components
//=====================================================================================
import { AdvertiserCard } from "../components/ui/advertiser-card";
import { ProductThumbnail } from "../components/ui/product-thumbnail";

import Scamber from '../services/Scamber';
import Cart from '../services/Cart';
import Anuncios from '../services/Ad';

//=====================================================================================
// #3 - Import * Sweet Alert
//=====================================================================================
import Swal1 from 'sweetalert';
import Swal from 'sweetalert2';

//=====================================================================================
// #6 - Import * Modals
//=====================================================================================
import ShoppingCartDialog from "../components/modals/shopping-cart";

//=====================================================================================
// #7 - Import * Bootstrap Components
//=====================================================================================
import Button from 'react-bootstrap/Button'
import EventEmitter from './../services/Events';
import formatNumberToBRL from "../utils/FormatNumberToBRL";
class Product extends Component {
  constructor(props) {
    super(props);
    this.ad = new Ad();
    this.favorite = new Favorite();
    this.shortUrl = new ShortURL();
    this.scamber = new Scamber();
    this.cart = new Cart();
    this.anuncios = new Anuncios();
    this.convert = new Convert();

    // * Create React Refs
    this.childTaxs = React.createRef();
    this.childShippingOptions = React.createRef();
    this.childMessenger = React.createRef();
    this.refModalDialogProposal = React.createRef();
    this.refShoppingCart = React.createRef();

    this.state = {
      slider: null,
      sliderThumbs: null,
      showSharedButtons: false,
      messenger: false,
      iconShare: true,
      iconFavorite: true,
      activeIconBag: false,
      activeIconHeart: false,
      iconShoppingBag: false,
      checkedList: false,
      disableInsert: false,
      pointsToPrice: 0
    };
    this.state = {
      adData: [
        { scamberInfos: [] },
        { categorizacao: [] },
      ],
      storeUrl: '',
      selectedImages: [0,0,0,0],
      mobileGallery: [0],
      adsSeller: []
    }
  }

  // * Open Dialog Shopping Cart
  showShoppingCardDialog = () => {
    // if (!this.props.scamber) {
    //   return window.location.href = "/login"
    // }
    this.insertProductIntoCart(this.state.adData);
  };

  // Delete Cart Item
  deleteCartItem = (itemASerRemovido) => {

    Swal.fire({
      title: "Deletar produto?",
      icon: "warning",
      buttons: [
        'Fechar',
        'Confirmar'
      ],
      dangerMode: true
    })
      .then((isConfirm) => {
        if (isConfirm) {
          this.cart.deleteCart(itemASerRemovido, null, this.props.website)
            .then((result) => {
              Swal.fire({ title: "Produto removido!", icon: "success", buttons: 'Ok', dangerMode: true });
              EventEmitter.dispatch('cartChange');
            })
            .catch((error) => {
              this.favorite.formatErrorMessage(error.response, 'PDELCA')
            })
        }
      });
  }

  insertProductIntoCart = (ad) => {

    EventEmitter.dispatch('startItemCart');

    let scamberAd = typeof (ad.scamber) === 'string' ? ad.scamber : ad.scamber[0]._id;
    if (this.props.cart && (this.props.cart.scamber_vendedor._id !== scamberAd)) {

      return Swal.fire({
        title: 'Você já tem itens adicionados na sua sacola',
        icon: 'info',
        html:
          'Deseja limpar a sacola?',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText:
          'Sim!',
        cancelButtonText:
          'Não',
      }).then((result) => {
        if (result.isConfirmed) {
          this.insertProduct(ad);

        }
      })

    } else {
      this.insertProduct(ad);
    }

  }

  insertProduct = (ad) => {
    let requestObj = {
      ad: ad._id
    }

    const dataLayerObj = {
      event: "AddToCart",
      title: ad.titulo,
      id: ad._id,
      url: ad.url_encurtada
    }

    const Toast = Swal.mixin({
      toast: true,
      position: 'bottom',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })

    this.cart.insertCart(requestObj, this.props.website)
      .then((response) => {
        //callback()
        Toast.fire({
          icon: 'success',
          title: 'Novos itens adicionados à sacola'
        })

        EventEmitter.dispatch('cartChange');
        EventEmitter.dispatch('endItemCart');

        this.cart.changeStatusAdsOfCart({ status: 'bloqueado' }, this.props.website)
          .then((response) => {
            if (!this.props.scamber) {
              this.props.history.push({ pathname: '/login', state: { cart: true } })
            } else {
              this.props.history.push('/comprar')
            }
          })
          .catch((error) => {
            this.cart.formatErrorMessage(error.response, 'PCHSTADC');
          });
      })
      .catch((error) => {
        this.cart.formatErrorMessage(error.response, 'PINCA');
        EventEmitter.dispatch('endItemCart');
      })
  }

  openModalProposal = () => {
    this.refModalDialogProposal.current.openModal()
  };

  openModalTaxs = () => {
    this.childTaxs.current.handleShow();
  };

  openModalShippingOptions = () => {
    this.childShippingOptions.current.handleShow();
  };

  handleToggleShared = () => {
    this.setState({
      showSharedButtons: !this.state.showSharedButtons,
      iconShare: !this.state.iconShare
    });
  };

  loadIfIsFavorited = (idAd) => {
    this.favorite.find(idAd)
      .then((response) => {

        this.setState({ iconFavorite: false });
        this.setState({ activeIconHeart: !false });
      })
      .catch((error) => {
        if (error.response.status === 404) return this.setState({ iconFavorite: true })
        this.favorite.formatErrorMessage(error.response, 'PFIFA');
      })
  }

  handleFavorite = (idAd) => {

    this.setState({ activeIconHeart: !this.state.activeIconHeart });

    this.favorite.create({ 'idAd': idAd })
      .then((response) => this.loadIfIsFavorited(idAd))
      .catch((error) => {
        this.favorite.formatErrorMessage(error.response, 'PCRFA')
      })
  }

  handleMessengerClick = () => {
    this.childMessenger.current.handleShowMessenger();
  };

  componentDidMount() {

    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    this.setState({ iconFavorite: true })
    let paramsString = this.props.location.search;
    const params = new URLSearchParams(paramsString);
    let idAd = params.get('id');
    let reload = params.get('reload')
    if (!idAd) return this.props.history.push('/');
    this.setState({ idAd: idAd })

    this.setState({
      slider: this.featuredSlider,
      sliderThumbs: this.sliderThumbnail,
      'idAd': idAd
    });

    setTimeout(() => {
      if (this.props.cart) {
        let ad = this.props.cart.itens?.filter(item => item._id === idAd);

        if (ad.length > 0) {
          this.setState({ activeIconBag: true })
        }
      }
    }, 1000)

    this.loadAdInformation(idAd);
    if (this.props.scamber) this.loadIfIsFavorited(idAd);
    this.loadScamberSellerAds(idAd);

    EventEmitter.subscribe('startItemCart', () => {
      this.setState({ disableInsert: true })
    })

    EventEmitter.subscribe('endItemCart', () => {
      this.setState({ disableInsert: false })
    })
  }

  loadScamberSellerAds = (idAd) => {
    this.ad.findOtherAdsOfSeller(idAd, this.props.website)
      .then((response) => this.setState({ adsSeller: response.data.payload }))
      .catch((error) => {
        this.ad.formatErrorMessage(error.response, 'PFIOAD')
      })
  }

  loadAdInformation = (adId) => {
    this.ad.findForAll(adId, this.props.website)
      .then((response) => {
        this.setState({ 'adData': response.data?.payload })
        this.setState({ 'storeUrl': response.data.payload?.scamberInfos[0].minha_loja?.url })

        this.state.adData.fotos.map((foto, index) =>
          this.ad.loadImageFile(foto).then((file) => {
            this.setSelectedImages({ target: { files: [file] } }, index);
          })
        );
      })
      .catch((error) => this.ad.formatErrorMessage(error.response, 'PFIALAD'));


    let requestObj = {
      "idAd": "",
      "urlInfos": {
        "long_url": ""
      }
    }

    requestObj.idAd = adId;
    requestObj.urlInfos.long_url = window.location.href;
    this.shortUrl.shortAdURL(requestObj)
      .then((response) => {
        this.setState({ urlPersonalizada: response.data.payload })
      })
      .catch((error) => this.shortUrl.formatErrorMessage(error.response, 'PURLAD'));
  }

  componentDidUpdate() {
    // Atualizando a pagina se mudar o idAd
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    let paramsString = this.props.location.search;
    const params = new URLSearchParams(paramsString);
    let idAd = params.get('id');

    if (this.state.idAd !== idAd) {

      if (!idAd) return this.props.history.push('/');
      Swal.fire('Aguarde...');
      Swal.showLoading();
      this.setState({ 'idAd': idAd })
      this.loadAdInformation(idAd);
      if (this.props.scamber) this.loadIfIsFavorited(this.props.idAd);
      this.loadScamberSellerAds(idAd);
      Swal.close();
    }


    if (this.props.cart && !this.state.checkedList) {
      this.setState({ checkedList: true })
      let ad = this.props.cart.itens?.filter(item => {

        return item._id === this.state.adData._id
      });

      if (ad.length > 0) {
        this.setState({ activeIconBag: true })
      }
    }

  }

  setSelectedImages = async (e, index) => {
    var reader = new FileReader();
    var file = e.target.files[0];
    var url = reader.readAsDataURL(file);
    let state = this.state;
    let context = this;

    reader.onloadend = function (e) {
      var image = new Image();
      image.src = e.target.result;
      image.onload = function () {
        let selectedImages = context.state.selectedImages;
        let mobileGallery = context.state.mobileGallery;
        
        if (this.width < this.height) {
          selectedImages.splice(index, 1, { show: reader.result, file: file, orientation: '_portrait' });
          mobileGallery.splice(index, 1, { show: reader.result, file: file, orientation: '_portrait' });
          context.setState({ selectedImages: selectedImages })
          context.setState({ mobileGallery: mobileGallery })
        } else if (this.width > this.height) {
          selectedImages.splice(index, 1, { show: reader.result, file: file, orientation: '_landscape' });
          mobileGallery.splice(index, 1, { show: reader.result, file: file, orientation: '_landscape' });
          context.setState({ selectedImages: selectedImages })
          context.setState({ mobileGallery: mobileGallery })
        } else {
          selectedImages.splice(index, 1, { show: reader.result, file: file, orientation: '_square' });
          mobileGallery.splice(index, 1, { show: reader.result, file: file, orientation: '_square' });
          context.setState({ selectedImages: selectedImages })
          context.setState({ mobileGallery: mobileGallery })
        }
      }
    }
  };

  copyShortUrl = () => {
    const el = document.createElement('textarea');
    el.value = this.state.urlPersonalizada;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  toggleTawkToChat = (e) => {
    e.preventDefault();
    window.tawkto.showWidget();
    window.tawkto.toggle();

    //SET ATTRIBUTES
    window.tawkto.setAttributes({
      'name': this.props.scamber ? this.props.scamber.nome : '',
      'email': this.props.scamber ? this.props.scamber.email : '',
      'hash': window.hashInBase64,
    }, function (error, teste) {
    });

    //ADD EVENT
    window.tawkto.addEvent('solicitacao-compra-produto', {
      'name': this.props.scamber ? this.props.scamber.nome : ' Usuário deslogado.',
      'email': this.props.scamber ? this.props.scamber.email : ' Usuário deslogado.',
      'nomeDaLoja': this.state.adData.scamberInfos ? this.state.adData.scamberInfos[0].minha_loja ? this.state.adData.scamberInfos[0].minha_loja.titulo : 'Não cadastrado' : 'Não cadastrado',
      'tituloDoAnuncio': this.state.adData?.titulo ? this.state.adData?.titulo : '',
      'quantidadeDePontos': this.state.adData.pontos_por?.$numberDecimal ? this.state.adData.pontos_por?.$numberDecimal : '',
      'URLDoAnuncio': this.state.urlPersonalizada,
      // 'hash': window.hashInBase64,
    }, function (error, teste) {
    });

  }

  render() {

    // * Slider Params
    const params = {
      showNav: false,
      showFullscreenButton: false,
      showPlayButton: false,
      infinite: false,
      thumbnailPosition: "bottom",
      showBullets: true,
      lazyLoad: true,
    };

    const params_carousel = {
      dots: true,
      infinite: false,
      speed: 500,
      autoplay: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      adaptiveHeight: true,
    };

    return (
      <>
        <ShoppingCartDialog
          ref={this.refShoppingCart}
          dialogTitle="Minha Sacola"
          className="shopping-cart-dialog"
          checkoutUrl="/checkout/"
        >
          <AdvertiserCard
            storeLogo={this.scamber.loadImage(this.props.cart?.scamber_vendedor?.imagens?.avatar ? this.props.cart?.scamber_vendedor.imagens.avatar : CoverIMG)}
            storeLink={`/loja/${this.props.cart?.scamber_vendedor?.minha_loja?.url}`}
            storeTitle={this.props.cart?.scamber_vendedor?.minha_loja?.titulo}
            storeLocation={this.props.cart?.scamber_vendedor.endereco[0].cidade + ' - ' + this.props.cart?.scamber_vendedor.endereco[0].estado}
            button={true}
          />

          {this.props.cart?.itens?.length > 0 ?
            this.props.cart.itens.map((product, index) => {
              return (
                <ProductThumbnail key={index}
                  ad={product}
                  image={this.scamber.loadImage(product.fotos[0] ? product.fotos[0] : CoverIMG)}
                  title={product.titulo}
                  // oldPrice={product.}
                  price={product.pontos_por?.$numberDecimal}
                  freightType={product.operador_logistico ? 'correios' : 'entrega_maos'}
                  productUrl={`/produto?id=${product._id}`}
                >

                  <Button
                    className="trash-item"
                    color="primary"
                    autoFocus
                    type="button"
                    onClick={() => this.deleteCartItem(product._id, null, this.props.website)}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </Button>
                </ProductThumbnail>
              )
            })
            : <></>}

        </ShoppingCartDialog>

        <div id="view_product">
          <Helmet title={`${this.props.site_properties?.title} - Detalhes Produto`} />
          <Breadcrumb></Breadcrumb>

          <section className="_wrap-messenger-inbox">
            <MessengerInbox ref={this.childMessenger}></MessengerInbox>
          </section>

          <div className="layout-main">
            <div className="layout-col layout-col-left">
              <div className="_wrap-product-gallery">
                <div className="go-back">
                  <a href="#!" onClick={(e) => { e.preventDefault(); this.props.history.goBack() }} className="btn btn-transparent">
                    <FontAwesomeIcon icon={faLongArrowLeft}></FontAwesomeIcon>
                  </a>
                </div>

                <div className="ImageGallery">
                  {this.state.selectedImages[0] !== 0 ?
                    <ImageGallery
                      {...params}
                      items={this.state.selectedImages.reduce(
                        (list, image, index) => {
                          if (image !== 0) {
                            list.push({
                              original: image.show,
                              thumbnail: image.show,
                            });
                          }

                          return list;
                        },
                        []
                      )}
                    />
                    :
                    <></>
                  }
                </div>

                <div className="SlickSliderRoot">

                  {this.state.mobileGallery[0] !== 0 && (
                    <Slider {...params_carousel} ref={slider => (this.slider = slider)} className="slider-container product-gallery">
                      {this.state?.mobileGallery?.map((el, i) => (
                        <div className="item" key={i}>
                          <img src={el.show} alt="1" />
                        </div>
                      ))}
                    </Slider>
                  )}
                </div>

              </div>
            </div>

            <div className="layout-col layout-col-right">
              <div className="_buttons-actions">
                <div className="share">
                  <button
                    type="button"
                    className="btn btn-transparent action"
                    onClick={this.handleToggleShared}
                  >
                    <FontAwesomeIcon icon={this.state.iconShare ? faTimes : faShare}></FontAwesomeIcon>
                  </button>

                  {this.state.showSharedButtons ? (
                    <ul className="_social-share-list">
                      <li className="_share-item">
                        <a onClick={this.copyShortUrl} href="#!">
                          <FontAwesomeIcon icon={faCopy}></FontAwesomeIcon>
                        </a>
                      </li>
                      <li style={{ opacity: ".5" }} className="_share-item">
                        <a href="#!">
                          <FontAwesomeIcon icon={faWhatsapp}></FontAwesomeIcon>
                        </a>
                      </li>
                      <li style={{ opacity: ".5" }} className="_share-item">
                        <a href="#!">
                          <FontAwesomeIcon icon={faFacebookF}></FontAwesomeIcon>
                        </a>
                      </li>
                      <li style={{ opacity: ".5" }} className="_share-item">
                        <a href="#!">
                          <FontAwesomeIcon icon={faInstagram}></FontAwesomeIcon>
                        </a>
                      </li>
                      <li style={{ opacity: ".5" }} className="_share-item">
                        <a href="#!">
                          <FontAwesomeIcon icon={faLinkedinIn}></FontAwesomeIcon>
                        </a>
                      </li>
                    </ul>
                  ) : null}
                </div>

                {this.props.scamber ?
                  <button
                    id={this.state.iconFavorite? 'adicionar_favoritos': 'remover_favoritos'}
                    type="button"
                    className={this.state.activeIconHeart ? "btn btn-transparent action active" : "btn btn-transparent action"}
                    onClick={() => this.handleFavorite(this.state.idAd)}
                  >
                    <FontAwesomeIcon icon={this.state.iconFavorite ? faHeart : faHeartSolid}></FontAwesomeIcon>
                  </button>
                  : <></>}

                {!this.state.adData.length && !this.state.adData.dados_compra ?
                  <button id={this.state.activeIconBag? 'remover_sacola':'adicionar_sacola'} disabled={this.state.disableInsert} type="button"
                    className={this.state.activeIconBag ? "btn btn-transparent action active" : "btn btn-transparent action"}
                    onClick={this.showShoppingCardDialog}
                  //onClick={(e)=> this.toggleTawkToChat(e)}
                  >
                    {this.state.activeIconBag && (
                      <span className="check">
                        <FontAwesomeIcon icon={faCheck} />
                      </span>
                    )}
                    <FontAwesomeIcon icon={faShoppingBag} />
                  </button> : <></>}
              </div>

              <section className="_short-description">
                <Link
                  to="/"
                  className="small-link-store _color _gray _12px _fw700"
                >
                  {this.state.adData.scamberInfos ? this.state.adData.scamberInfos[0].minha_loja?.titulo : <></>}
                </Link>

                <div className="item-title">
                  <h1 className="item-title--primary _color _black _fw400">
                    {this.state.adData?.titulo}
                  </h1>
                </div>

                {this.state.adData ?

                  !this.state.adData.tipo_anuncio || this.state.adData.tipo_anuncio === 'scamb' ?
                    (
                      <>
                        <div className="item-price">
                          <div className="_product-price _2x">
                            {this.state.adData.pontos_de?.$numberDecimal && (Number(this.state.adData.pontos_de?.$numberDecimal) > (this.state.adData.pontos_por?.$numberDecimal)) ? (
                              <div className="ui-item__discount-price">
                                <>
                                  <span className="price-tag-fraction">{this.state.adData.pontos_de?.$numberDecimal}</span>
                                  <span className="price-tag-symbol">pts</span>
                                </>
                              </div>
                            ) : ""}
                            <div className="ui-item__price">
                              <span className="price-tag-fraction">{this.state.adData.pontos_por?.$numberDecimal}</span>
                              <span className="price-tag-symbol">pts</span>
                            </div>
                          </div>
                        </div>

                        <div className="tax-scamb">
                          <div className="tax-scamb--title _color _gray _12px">
                            <strong className="_fw700">Equivalente a </strong>
                            <span className="_13px _fw700">
                              R${this.convert.setConversion(Number(this.state.adData.pontos_por?.$numberDecimal))}
                            </span>

                          </div>
                        </div>

                        <div className="insufficient-points">
                          <div className="insufficient-points--title _color _gray _12px">
                            Não tem pontos suficientes?
                            <Link
                              to="/comprar-pontos"
                              className="redirect-to-buy--points _color _blue _fw700"
                            >
                              Compre aqui
                            </Link>
                          </div>
                        </div>

                        <div className="tax-scamb">
                          <div className="tax-scamb--title _color _gray _12px">
                            <strong className="_fw700">Taxa Scamb</strong> <span className="_11px">a ser paga pelo comprador</span>
                            <span className="_13px _fw700">R${formatNumberToBRL(Number(this.state.adData.taxa_scamb))}</span>
                            <button
                              className="btn btn-transparent"
                              onClick={this.openModalTaxs}
                            >
                              <FontAwesomeIcon icon={faQuestionCircle}></FontAwesomeIcon>
                            </button>
                          </div>
                        </div>
                      </>
                    ) :
                    (
                      <>
                        <div className="item-price">
                          <div className="_product-price _2x">
                            {this.state.adData.valor_de && (this.state.adData.valor_de > this.state.adData.valor_por) ? (
                              <div className="ui-item__discount-price">
                                <>
                                  <span className="price-tag-symbol">R$&nbsp;</span>
                                  <span className="price-tag-fraction">{formatNumberToBRL(this.state.adData.valor_de)}</span>
                                </>
                              </div>
                            ) : ""}
                            <div className="ui-item__price">
                              <span className="price-tag-symbol">R$&nbsp;</span>
                              <span className="price-tag-fraction">{formatNumberToBRL(this.state.adData.valor_por)}</span>
                            </div>
                          </div>
                        </div>

                        <div className="tax-scamb">
                          <div className="tax-scamb--title _color _gray _12px">
                            {this.state.adData.cashback ?
                              (<>
                                <strong className="_fw700">Ganhe </strong>
                                <span className="_13px _fw700">
                                  {/*formatNumberToBRL(this.state.adData.cashback)*/}
                                  {this.state.adData.cashback}
                                </span>
                                <strong>pointsback</strong>
                              </>) :
                              (
                                <>
                                  <strong className="_fw700">Não possui pointsback </strong>
                                </>
                              )
                            }

                          </div>
                        </div>
                      </>
                    )

                  : (<></>)
                }

                <div className="shipping-options">
                  <div className="shipping-options--title _color _gray _12px">
                    Opções de frete disponíveis
                    <button
                      className="btn btn-transparent"
                      onClick={this.openModalShippingOptions}
                    >
                      <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>
                    </button>
                  </div>

                  <div className="shipping-options-items">

                    {this.state.adData.operador_logistico ?
                      <div className="shipping-options-item _active">
                        <div className="item-icon">
                          <FontAwesomeIcon icon={faTruck}></FontAwesomeIcon>
                        </div>
                        <div className="item-info">Correios</div>
                      </div>
                      :
                      <div className="shipping-options-item">
                        <div className="item-icon">
                          <FontAwesomeIcon icon={faTruck}></FontAwesomeIcon>
                        </div>
                        <div className="item-info">Correios</div>
                      </div>
                    }

                    {this.state.adData.entrega_maos ?
                      <div className="shipping-options-item _active">
                        <div className="item-icon">
                          <FontAwesomeIcon
                            icon={faHandHoldingBox}
                          ></FontAwesomeIcon>
                        </div>
                        <div className="item-info">Entrega em mãos</div>
                      </div>
                      :
                      <div className="shipping-options-item">
                        <div className="item-icon">
                          <FontAwesomeIcon
                            icon={faHandHoldingBox}
                          ></FontAwesomeIcon>
                        </div>
                        <div className="item-info">Entrega em mãos</div>
                      </div>
                    }

                  </div>
                </div>

                <div className="_wrap-actions">

                  {!this.state.adData.length && !this.state.adData.dados_compra ?
                    <>
                      <a id="comprar_produto" onClick={() => !this.state.disableInsert ? this.showShoppingCardDialog() : ''} href="#!" className="btn btn-red btn-buy _fw700">
                        {/*<a onClick={(e)=>this.toggleTawkToChat(e)} href="#!" className="btn btn-red btn-buy _fw700">*/}
                        Comprar
                      </a>

                      {this.props.website === 'scamb' ?
                        (<>
                          <button
                            id="simular_compra"
                            onClick={() => !this.state.disableInsert ? this.showShoppingCardDialog() : ''}
                            type="button"
                            className="btn btn-red btn-border make-offer _fw700"
                          // onClick={this.openModalProposal}
                          >
                            Simular compra
                          </button>
                          <div className="_wrap-chat-button">
                            <button
                              id="iniciar_chat"
                              onClick={(e) => this.toggleTawkToChat(e)}
                              type="button"
                              className="btn btn-neutral btn-border _fw700 _btn-chat"
                            // onClick={this.handleMessengerClick}
                            >
                              <span>Iniciar chat</span>
                              <FontAwesomeIcon icon={faCommentAltLines}></FontAwesomeIcon>
                            </button>
                          </div>
                        </>) : (<></>)
                      }


                    </> :
                    <></>
                  }

                  {!this.state.adData.length && this.state.adData.dados_compra ?
                    <p style={{ textAlign: 'center', fontWeight: 'bold' }}>Este item já foi vendido</p> : <></>}

                </div>
              </section>
            </div>
          </div>

          <div className="layout-main-informations _med-container">
            <div className="layout-col layout-col-left">
              <section className="_long-description">
                <h1 className="_long-descriptionr-title _title _color _black _fw700">
                  Descrição
                </h1>

                <p className="_long-description-text _color _black _fw600">
                  {this.state.adData.descricao}
                  <br />
                  <br />
                  {/* composição: algodão */}
                  <br />
                  <br />
                  {/* medidas: cintura: 80.0cm comprimento: 29.0cm quadril: 98.0cm */}
                </p>
              </section>

              <section className="_characteristics">
                <h1 className="_characteristics-title _title _color _black _fw700">
                  Características
                </h1>

                <ul className="_characteristics-list">
                  <li>
                    <div className="_key">marca</div>
                    <div className="_value">{this.state.adData.marca ? this.state.adData.marca[0].nome : <></>}</div>
                  </li>
                  <li>
                    <div className="_key">Condição</div>
                    {this.state.adData.condicao ? <div className="_value">{this.state.adData.condicao[0].nome}</div> : <></>}
                  </li>
                  <li>

                    <div className="_key">Categoria</div>

                    {this.state.adData.categorizacao ?
                      this.state.adData.categorizacao[0].categorias ? <div className="_value">{this.state.adData.categorizacao[0].categorias.nome} {this.state.adData.categorizacao[0].categorias.tipos_produtos.nome} {this.state.adData.categorizacao[0].categorias.tipos_produtos.produtos.nome}</div>
                        :
                        this.state.adData.categorizacao[0] && <div className="_value">{this.state.adData.categorizacao[0].nome} {this.state.adData.categorizacao[0].tipos_produtos && this.state.adData.categorizacao[0].tipos_produtos.nome} {this.state.adData.categorizacao[0].tipos_produtos && this.state.adData.categorizacao[0].tipos_produtos.produtos.nome}</div>
                      : <></>}

                  </li>

                  <li>
                    {this.state.adData?.tamanho ?
                      <React.Fragment>
                        <div className="_key">Tamanho</div>
                        <div className="_value">{this.state.adData.tamanho.tamanhos[0].nome}
                        </div>
                      </React.Fragment>
                      : <></>}
                  </li>

                </ul>
              </section>

            </div>

            <div className="layout-col layout-col-right">
              <section className="_advertiser">
                <div className="_advertiser-title _color _black _14px _fw700">
                  Anunciante
                </div>

                <div className="_advertiser-card">
                  <Link to={"/loja/" + this.state.storeUrl} className="_advertiser-card-logo">

                    {this.state.adData.scamberInfos ?
                      this.state.adData.scamberInfos[0].imagens?.avatar ?
                        <img src={this.ad.loadImage(this.state.adData.scamberInfos[0].imagens.avatar)} alt="Avatar" /> : <img src={LogoStore} alt="Avatar" />
                      :
                      <></>
                    }

                  </Link>
                  <Link to={"/loja/" + this.state.storeUrl} className="_advertiser-card-title--location">
                    {this.state.adData.scamberInfos ? this.state.adData.scamberInfos[0].minha_loja?.titulo : <></>}
                    <span className="title-location"> {this.state.adData.scamberInfos ? this.state.adData.scamberInfos[0].endereco[0]?.cidade : <></>} - {this.state.adData.scamberInfos ? this.state.adData.scamberInfos[0].endereco[0]?.estado : <></>}</span>
                  </Link>
                  <div className="_advertiser-card-follow">
                    <button type="button" className="btn btn-follow">
                      Seguir
                    </button>
                  </div>

                  <div className="_advertiser-card-redirect">
                    <Link to={"/loja/" + this.state.storeUrl} className="btn">
                      <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
                    </Link>
                  </div>
                </div>
              </section>

              <section
                className="section-seller-info _off-status"
                style={{ display: "none" }}
              >
                <div className="seller-title _color _black _14px _fw700">
                  Classicação
                </div>

                <div className="_wrap-seller-status">
                  <div className="seller-status _color _gray _12px _fw700">
                    Este vendendor ainda não possui vendas suficientes para gerar
                    um historico de reputação.
                  </div>
                </div>
              </section>
              <section className="section-seller-info">
                {/* <div className="seller-title _color _black _14px _fw700">
                Classicação
              </div> */}

                <div className="_wrap-seller-status">

                  {/* Não vai ser mais usado */}
                  <ul className="reputation-thermometer _is-hidden">
                    <li className="reputation-thermometer-item reputation-thermometer-1">
                      <span>Vermelho</span>
                    </li>
                    <li className="reputation-thermometer-item reputation-level reputation-thermometer-2">
                      <span>Laranja</span>
                    </li>
                    <li className="reputation-thermometer-item reputation-thermometer-3">
                      <span>Amarelo</span>
                    </li>
                    <li className="reputation-thermometer-item reputation-thermometer-4">
                      <span>Verde claro</span>
                    </li>
                    <li className="reputation-thermometer-item reputation-thermometer-5">
                      <span>Verde</span>
                    </li>
                  </ul>
                </div>

                <div className="reputation-info-items _is-hidden">
                  <div className="reputation-info-item">
                    <div className="reputation-info-item--wrap">
                      {/* <div className="item-icon">
                      <FontAwesomeIcon icon={faCalendarAlt}></FontAwesomeIcon>
                      <span className="check-mark">
                        <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                      </span>
                    </div>
                    <div className="item-text _color _gray _10px text-center _fw700">
                      Tempo no Scamb
                      <span className="tag">2 anos mais</span>
                    </div> */}
                    </div>
                  </div>

                  {/* <div className="reputation-info-item">
                  <div className="reputation-info-item--wrap">
                    <div className="item-icon">
                      <FontAwesomeIcon icon={faTags}></FontAwesomeIcon>
                      <span className="check-mark">
                        <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                      </span>
                    </div>
                    <div className="item-text _color _gray _10px text-center _fw700">
                      Produtos Vendidos
                      <span className="tag">287</span>
                    </div>
                  </div>
                </div> */}

                  {/* <div className="reputation-info-item">
                  <div className="reputation-info-item--wrap">
                    <div className="item-icon">
                      <FontAwesomeIcon icon={faShoppingCart}></FontAwesomeIcon>
                      <span className="check-mark">
                        <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                      </span>
                    </div>
                    <div className="item-text _color _gray _10px text-center _fw700">
                      Produtos Comprados
                      <span className="tag">81</span>
                    </div>
                  </div>
                </div> */}
                </div>

              </section>
            </div>
          </div>

          <div className="_wrap-more-products">
            <div className="_med-container">
              <section className="more-products">
                <h1 className="headline _color _gray-bolder _fw700">
                  Mais produtos do vendedor <Link to={"/loja/" + this.state.storeUrl}>Ver mais</Link>
                </h1>


                {/* Recently Added */}
                {/* <Slider {...paramsRecentlyAdded}> */}
                <Grid container spacing={2}>
                  {this.state.adsSeller && (
                    this.state.adsSeller.slice(0, 5).map((ad, index) =>
                      <Grid item md={3} sm={4} xs={6} key={index} onClick={() => {this.slider.slickGoTo(0); this.setState({ mobileGallery: [0] })}}>
                        <ProductItem
                          ad={ad}
                          link={`/produto?id=${ad._id}`}
                          image={this.ad.loadImage(ad.fotos[0]) ? this.ad.loadImage(ad.fotos[0]) : ''}
                          weight={ad.tamanho_exibicao?.nome}
                          title={ad.titulo}
                          discount={ad.pontos_de ? ad.pontos_de.$numberDecimal : ''}
                          price={ad.pontos_por ? ad.pontos_por?.$numberDecimal : ''}
                          type={ad.tipo_anuncio}
                          cashback={ad.cashback}
                          cashDiscount={ad.valor_de}
                          cashPrice={ad.valor_por}
                          idAd={ad._id}
                        >
                        </ProductItem>
                      </Grid>
                    )
                  )}
                </Grid>
                {/* </Slider> */}
                {/* END ./ Recently Added */}
              </section>
            </div>
          </div>

          {/* Modal Taxas Scamb */}
          <ModalBase
            ref={this.childTaxs}
            modalTitle="Taxa Scamb"
            modalSubTitle={`
          Você não paga nada para listar produtos no Scamb e ter sua loja ativa.
          Porém, a cada transação efetivada, o Scamb cobra uma taxa de administração para apoiar a manutenção da plataforma.
          A taxa é sempre paga pelo COMPRADOR. Não há taxas para o vendedor.
          O valor da taxa Scamb varia de acordo com o valor de pontos da transação.
          Veja aqui a tabela de valores da taxa Scamb.
				`}
          >
            <ModalTaxs></ModalTaxs>
          </ModalBase>

          <ModalBase
            ref={this.childShippingOptions}
            modalTitle="Opções de entrega"
            modalSubTitle="Qual a melhor opção para mim?"
          >
            <ModalShippingOptions></ModalShippingOptions>
          </ModalBase>

          {/* Modal Proposta Produto */}
          <ModalDialog
            dialogTitle="Fazer oferta"
            btnTitle="Aplicar"
            className="_modal-weight"
            ref={this.refModalDialogProposal}
          ></ModalDialog>
        </div>
      </>
    );
  }
}


const mapStateToProps = state => {
  return {
    scamber: state.scamberReducer.scamber,
    cart: state.cartReducer.cart,
    site_properties: state.websiteReducer.site_properties,
    website: state.websiteReducer.website
  }
}

export default withRouter(connect(mapStateToProps)(Product));