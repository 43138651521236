//===============================================================================================================
// #1 - Base Imports
//===============================================================================================================
import React from "react";

//===============================================================================================================
// #2 - Import * Icons
//===============================================================================================================
import { FontAwesomeIcon as I } from "@fortawesome/react-fontawesome";

//===============================================================================================================
// #3 - Import * Styled Components
//===============================================================================================================
import { Popup } from "./styles";

//===============================================================================================================
// #3 - Import * Banner Popup Image
//===============================================================================================================
import BannerPopup from "../../../assets/images/banner_popup.png";


function ExitIntent({ onClick }) {

    return (

        <Popup>
            <div className="_wrap-new-account-banner">
                <button type="button" className="btn btn-transparent _close-modal" onClick={onClick}>
                    <I icon={["far", "times"]} />
                </button>

                <div className="_wrap-new-account-banner--inside">
                    <div className="columns">
                        <div className="column image" style={{ backgroundImage: `url(${BannerPopup})` }}></div>
                        <div className="column">
                            <iframe id="subscribe-form" src="https://cdn.forms-content.sg-form.com/27812b41-b408-11eb-b234-da4bb3e8477b" />
                        </div>
                    </div>

                </div>
            </div>
        </Popup>
    )
}

export default ExitIntent;