import React, { Component } from "react";

class ChatProduct extends Component {

  render() {

    let action_status;
    const message_type = this.props.messageType;

    function markup(action_text, classTag) {
        return (
          <>
            <span className={"_tag " + classTag}>{action_text}</span>
          </>
        );
      }

    switch (message_type) {
        case "proposal":
          action_status = markup(
            "Proposta",
            "proposal"
          );
          break;
        case "doubt":
          action_status = markup(
            "Conversa",
            "doubt"
          );
          break;
        case "purchase":
          action_status = markup(
            "Compra e Venda",
            "purchase"
            );
          break;
      }

    return (

      <section className="_chat-product">
        <div className="_cols">
          <div className="_col _col-left">
            <div className="_product">
              <div className="product-image">
                <img src={this.props.productImage} alt="" />
              </div>
              <div className="_product-info">
                <div className="_product-tag">
                  {action_status}
                  <span className="_tag-username"> {this.props.buyerName} </span>
                </div>

                <div className="_product-item">
                  <span className="_product-item-tag">
                    {this.props.productTitle}
                  </span>
                </div>

                <div className="_product-price _1x">
                  <div className="ui-item__price">
                    <span className="price-tag-fraction"> {this.props.productPrice} </span>
                    <span className="price-tag-symbol">pts</span>
                  </div>
                </div>
              </div>
            </div>

            {this.props.children}

          </div>
        </div>
      </section>

    );

  }

}

export default ChatProduct;
