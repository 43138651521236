//===========================================================
// #1 - Base Imports
//===========================================================
import React from "react";

//===========================================================
// #2 - Import * FontAwesome Icons
//===========================================================
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/pro-light-svg-icons";


export const NothingMessage = (props) => {
  return (
    <div className="_nothing">
      <div className="icon">
        <Icon icon={faExclamationCircle} />
      </div>
      <div className="text">
        {props.children}
      </div>
    </div>
  );
};