import React, { useState, forwardRef, useRef, useImperativeHandle, useEffect, useCallback, } from "react";
import { useHistory } from "react-router-dom";

import { Button as ButtonStrap, Modal } from "react-bootstrap";
import WrapView from "./layout/WrapView";
import InputMask from "react-input-mask";

import styled from 'styled-components';

// Forms
import { useForm } from "react-hook-form";

// Material - UI
import { Grid, TextField, useMediaQuery, makeStyles } from "@material-ui/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/pro-solid-svg-icons";
import { faCheck, faFileUser } from "@fortawesome/pro-regular-svg-icons";
import { faChevronRight, faChevronLeft, faAddressCard } from "@fortawesome/pro-light-svg-icons";


// Services
import Endereco from './../services/Endereco';
import Scamber from './../services/Scamber';
import CreateIuguAccount from "./../services/CreateIuguAccount";
import Extract from './../services/Extract';
import Store from './../services/Store';
import { setScamber } from './../redux/actions/scamberActions';
import { connect } from 'react-redux';

// JQuery
import $ from "jquery";
import "jquery-mask-plugin";
import Swal from "sweetalert2";

// data
import { banks, accountType } from "../data/bank-account";
import Autocomplete from '@material-ui/lab/Autocomplete';

import { Wizard } from '../components/design/navigation/wizard';
import Button from "../components/design/form/button";

import { WizardActions, WizardContent, HDLine, Title, ButtonSubmit } from './complete-account/styles';

import Helmet from 'react-helmet';

const steps = ['Completar conta', 'Dados pessoais', 'Conta bancária'];

const useStyles = makeStyles({
  center: {
    margin: "auto",
  },
  resize: {
    fontSize: '10px'
  },
  inputSize: {
    height: 50
  }
});

const Dialog = forwardRef((props, ref) => {
  // Bootstrap Modal
  const [show, setShow] = useState(false);

  // Handle Modal
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useImperativeHandle(ref, () => ({
    openModal: () => handleShow(),
    closeModal: () => handleClose(),
  }));

  return (
    <>
      <Modal show={show} onHide={handleClose} className="full-width" centered>
        <Modal.Header closeButton>
          <section className="_view-head">
            <div className="_view-head-content">
              <ButtonStrap className="_back-history btn" onClick={handleClose}>
                <FontAwesomeIcon icon={faChevronLeft}></FontAwesomeIcon>
              </ButtonStrap>
              <Modal.Title>{props.title}</Modal.Title>
            </div>
          </section>
        </Modal.Header>
        <Modal.Body>{props.children}</Modal.Body>
      </Modal>
    </>
  );
});

const WrapAccountWizard = styled.div`
padding-bottom: 30px;
  .form-hd {
    padding-bottom: 0 !important;

    h1 {
      margin-bottom: 0 !important;
    }
  }

  div {
    .wizard {
      margin-top: 0 !important;
    }
  }
`;

function CompleteRegistration(props) {
  const refAddressModal = useRef();
  const refCreditCardModal = useRef();
  const refBankAccountModal = useRef();

  // Utils
  const classes = useStyles();
  const matches = useMediaQuery('(min-width:600px)');
  const history = useHistory();


  //Enable Buttons
  const [disabledAddress, setDisabledAdrs] = useState(false);
  const [disabledWizard, setDisabledWizard] = useState(false);
  const [disabledCreditCardButton, setDisabledButton] = useState(true);
  const [disabledCreditCard, setDisabled] = useState(false);
  const [valueFieldCredt, setValueCredt] = useState("")
  const [cep, setCep] = useState("");
  const [disableField, setDisableField] = useState(true);
  const [titulo, setTitulo] = useState("");

  // novos
  const [userAddress, setUserAddress] = useState({});
  const [selectedBank, setSelectedBank] = React.useState({ ag: "", cc: "" });

  // useForms
  const { register, handleSubmit, errors, formState, reset } = useForm({ mode: "onChange" });
  const { register: registerAddress, handleSubmit: handleSubmitAddress, errors: errorsAddress, reset: resetAddress } = useForm({ mode: "onChange" });
  const { register: registerForm, handleSubmit: handleSubmitForm, errors: errorsForm, reset: resetForm } = useForm({ mode: "onChange" });

  const endereco = new Endereco();
  const scamber = new Scamber();
  const iugu_account = new CreateIuguAccount();
  const extract = new Extract();

  const closeModalAddress = () => refAddressModal.current.closeModal();
  const closeModalCreditCard = () => refCreditCardModal.current.closeModal();

  const loadScamberData = () => {
    Swal.fire('Aguarde...');
    Swal.showLoading();
    scamber.findScamber().then((scamberData) => {
      Swal.close();
      if (scamberData.status === 200) props.setScamber(scamberData.data.payload);
    }).catch((error) => scamber.formatErrorMessage(error.response, 'CRLOSC'));
  }

  const onSubmitAddress = (data) => {
    Swal.fire('Aguarde...');
    Swal.showLoading();

    endereco.update(data).then((response) => {

      if (response.status === 200) {

        Swal.fire({
          icon: "success",
          title: "Endereço adicionado!",
          timer: 4000,
          timerProgressBar: true,
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonText: "fechar",
        }).then((eComplete) => {
          if (eComplete) {
            setDisabledAdrs(true);
            setDisabledButton(!disabledCreditCardButton);
            closeModalAddress();
            loadScamberData();
          }
        });

      }

    }).catch((error) => endereco.formatErrorMessage(error.response, 'CRENUP'))

  };

  const onSubmitPersonalData = (data) => {
    const store = new Store();

    if (!data.titulo) return Swal.fire('Nome da loja não preenchido.')

    scamber.updateScamber(data).then((response) => {
      if (response.status === 200) {

        data["url"] = titulo.normalize('NFD').replace(/[^a-zA-Zs]/g, '').toLowerCase();
        store.saveStore(data).then((response) => {
          if (response.status === 200) {
            Swal.fire({
              icon: "success",
              title: "Informações salvas com sucesso!",
              timer: 4000,
              timerProgressBar: true,
              showCancelButton: true,
              showConfirmButton: false,
              cancelButtonText: "fechar",
            }).then((its_ok) => {
              if (its_ok) {
                setDisableField(!disableField);
                setDisabled(true);
                closeModalCreditCard();
                loadScamberData();
              }
            });

          }
        }).catch((error) => store.formatErrorMessage(error.response, 'CRSTSV'));
      }

    }).catch((error) => scamber.formatErrorMessage(error.response, 'CRSCUP'));

  };

  const goTo = () => {
    loadScamberData();
    props.history.push("/criar-novo");
  }

  useEffect(() => {
    setDisableField(!disableField);
    loadScamberData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkScamberAddress = useCallback((scamber) => {
    if (scamber) {
      const phone = `(${scamber.telefone[0].ddd}) ${scamber.telefone[0].numero.substring(0, 5)}-${scamber.telefone[0].numero.substring(5, 9)}`
      const response_address = {
        telephone: `${phone}`
      };
      resetAddress(response_address);
    }

    if (scamber && scamber.endereco && scamber.endereco.length > 0) {
      setDisabledAdrs(true);
      setDisabledButton(false);

      const { cpf, nome } = scamber;
      const obj = { cpf: cpf, titular_conta: nome };

      let endereco = scamber.endereco[0];

      const phone = `(${scamber.telefone[0].ddd}) ${scamber.telefone[0].numero.substring(0, 5)}-${scamber.telefone[0].numero.substring(5, 9)}`
      const response_address = {
        cep: endereco.cep,
        state: endereco.estado,
        city: endereco.cidade,
        address: endereco.logradouro,
        bairro: endereco.bairro,
        complemento: endereco.complemento,
        numero: endereco.numero,
        telephone: `${phone}`
      };

      resetAddress(response_address);
      resetForm(obj);

    } else {
      setDisabledAdrs(false);
      setDisabledButton(true);
    }
  }, []);

  const initFormValues = useCallback((profile) => {
    if (profile) {
      //REMOVING ALL EMPTY SPACES ON STRING
      if (profile.telefone && profile.telefone.length > 0) {
        profile.celular = `(${profile.telefone[0].ddd}) ${profile.telefone[0].numero.substring(0, 5)}-${profile.telefone[0].numero.substring(5, 9)}`;
      }
      reset(profile);
    }
  }, [reset]);

  const checkScamberPersonalData = useCallback((scamber) => {
    //preenchendo o form com os dados que o usuario tiver preenchido
    initFormValues(scamber);
    if (scamber && (scamber.cpf || scamber.cnpj) && scamber.telefone.length > 0 && scamber.minha_loja) {
      // setDisabledAdrs(true);
      setDisabled(true)
    }
  }, [initFormValues]);


  useEffect(() => {
    checkScamberAddress(props.scamber);
    checkScamberPersonalData(props.scamber);
  }, [checkScamberAddress, checkScamberPersonalData, props.scamber]);

  const handleCepChange = (e) => {
    setCep(e.target.value);
  }

  useEffect(() => {
    // * Field Masks
    $(".cpf").mask("000.000.000-00", { reverse: false });
    $(".celular").mask("(00) 00000-0000");
    $(".cep").mask("00000-000");
    // $(".bank-ag").mask(selectedBank?.ag || "0000-0");
    // $(".bank-cc").mask(selectedBank?.cc || "00000000-0");
    $(".house_number").mask("00000");
    $(".cpf").mask("000.000.000-00", { reverse: false });
    $(".phone").mask("(00) 00000-0000");

  });

  useEffect(() => {

    if (props.scamber) {
      loadIuguAccount();
    }


  }, [props.scamber]); //eslint-disable-line

  const findAddressByCEP = () => {

    if (cep.length === 9) {
      endereco.findCep(cep).then((address) => {


        const formatedAddress = {
          cep: address.data.cep,
          estado: address.data.estado,
          logradouro: address.data.logradouro,
          cidade: address.data.localidade,
          estado: address.data.uf,
          bairro: address.data.bairro
        }

        const response_address = {
          cep: address.data.cep,
          state: address.data.uf,
          city: address.data.localidade,
          address: address.data.logradouro,
          bairro: address.data.bairro,
          complemento: address.data.complemento,
        };

        // if (address.data) {
        //   reset(formatedAddress);
        //   resetAddress(response_address)
        // }

        if (address.data) {
          reset(formatedAddress);
          resetAddress(response_address);
        } else {
          Swal.fire("", "CEP inválido", "info");
        }
      }).catch((error) => Swal.fire('info', 'Não foi possível consultar o endereço', ''));
    }
  }

  // Novos **************************************************

  const handleFilterBank = (bankName) => {

    const bankList = [...banks];

    const filterBankName = bankList.find((bank) => bankName === bank.title);

    return setSelectedBank((bankPrev) => bankPrev = filterBankName);

  }

  const submitAddressAccountBank = async (data) => {
    if (data) {

      try {
        const { cep, state, city, address, numero, bairro, telephone, complemento } = await data;

        setUserAddress((prevAddress) => {
          return {
            cep: cep,
            state: state,
            city: city,
            address: address,
            numero: numero,
            bairro: bairro,
            telephone: telephone,
            complemento: complemento,
          }
        })
        wizardNavigation("next")

      } catch (error) {
        Swal.fire("", "Preencha o formulário corretamente.", "info");
      }
    }
  };

  const submitBankAccount = async (data) => {

    Swal.fire({
      title: "Aguarde...",
      allowEscapeKey: true,
      allowOutsideClick: false,
      showCloseButton: false,
      showConfirmButton: false,
      showLoaderOnConfirm: true,
      didOpen: () => {
        Swal.showLoading()
      }
    });

    const { cpf, bank, account_type, bank_ag, bank_dig_ag, bank_cc, bank_dig_cc } = await data;
    const { telephone, cep, address, city, state, numero } = userAddress;

    const bankAccount = {
      cpf: cpf,
      telephone: telephone,
      address: {
        cep: cep,
        address: `${address}, ${numero}`,
        city: city,
        state: state
      },
      bankAccountInfo: {
        bank: bank,
        account_type: account_type,
        bank_ag: `${bank_ag}${bank_dig_ag ? '-' + bank_dig_ag : ''}`,
        bank_cc: `${bank_cc}${bank_dig_cc ? '-' + bank_dig_cc : ''}`,
      }
    }

    try {

      const response = await iugu_account.create(bankAccount);

      if (response.status === 200) {

        Swal.fire({
          title: "Dados alterados!",
          html: `a aprovação da conta bancária leva até 24 horas para ser efetivada. <br /><br />` +
            `você pode acompanhar o status clicando em “Meu Perfil” no menu. <br /><br />` +
            `até que a sua conta bancária seja aprovada, você pode cadastrar produtos mas eles não ficam disponíveis para venda.<br /> 
          Depois da aprovação, eles são automaticamente liberados.`,
          icon: "success",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          dangerMode: true
        }).then((isConfirm) => {
          if (isConfirm) {
            refBankAccountModal.current.closeModal();
            setDisabledWizard(prev => prev = true)
          }
        });
      }

    } catch (error) {
      const response = error.response.data.payload;

      if (response && response.errors) {
        Swal.fire("", response.errors[0].msg, "info");
      } else {
        Swal.fire("", error.response.data.message, "info");
      }
    }

  }

  // Wizard
  const wizardRef = useRef();

  const [activeStep, setActiveStep] = useState(0);

  const handleNextStep = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);
  const handlePrevStep = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

  const wizardNavigation = (action) => {

    switch (action) {
      case "next":
        wizardRef.current.nextStep(handleNextStep())
        break;

      case "prev":
        wizardRef.current.prevStep(handlePrevStep())
        break;

      default:
        setActiveStep(0);
        break;
    }
  }

  const loadIuguAccount = () => {
    extract.loadCashBalance().then((balanceData) => {
      if (balanceData.status === 200 && balanceData.data.payload) {
        const dataPayload = balanceData.data.payload;

        if (dataPayload !== null) {
          setDisabledWizard(true);
        } else {
          setDisabledWizard(false);
        }

      }
    }).catch((error) => {
      extract.formatErrorMessage(error.response, 'MWLLIBL');
    })
  }

  return (
    <>
      <Helmet title={`${props.site_properties?.title} - Completar Registro`} />

      <Dialog ref={refBankAccountModal} title="Complete dados de sua conta">
        <WrapAccountWizard>
          <form className="form-hd">
            <h1 className="_content-title">
              para que você possa receber o valor de suas próximas vendas!
            </h1>
          </form>

          <Wizard steps={steps} activeStep={0} ref={wizardRef}>

            <WizardContent>
              <div style={{ display: `${activeStep === 0 ? 'block' : 'none'}` }}>
                <HDLine>
                  <Title>
                    A partir de Dezembro temos uma mudança importante no Scamb!<br />
                    Vendedores passam a receber em Reais pela venda de seus produtos.
                  </Title>
                  <br />
                  <Title>
                    Por isso, precisamos que todos os usuários que têm produtos à
                    venda no Scamb atualizem seus cadastros, incluindo conta corrente
                    para receber o valor de vendas futuras.
                  </Title>
                  <br />
                  <Title>
                    Clique aqui para atualizar seus dados e não perder
                    nenhuma oportunidade de venda!
                  </Title>
                </HDLine>

                <WizardActions>
                  <Button
                    type="default"
                    buttonSize="medium"
                    buttonColor="#E9E9E9"
                    textColor="#777"
                    buttonText="Voltar"
                    onClick={() => wizardNavigation("prev")}
                    disabled={activeStep === 0}
                    maxWidth={140}
                  />

                  <Button
                    type="submit"
                    buttonSize="medium"
                    buttonColor="#14233E"
                    textColor="#FFFFFF"
                    buttonText="Próximo"
                    onClick={() => wizardNavigation("next")}
                    maxWidth={140}
                  />
                </WizardActions>
              </div>

              <div style={{ display: `${activeStep === 1 ? 'block' : 'none'}` }}>

                <HDLine>
                  <Title>
                    Preencha seus dados
                    <span>
                      Endereço e conta devem ser do CPF cadastrado no Scamb.
                    </span>
                  </Title>
                </HDLine>
                <form onSubmit={handleSubmitAddress(submitAddressAccountBank)}>

                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        onChange={handleCepChange}
                        onBlur={findAddressByCEP}
                        id="outlined-cc-number"
                        label="Cep *"
                        type="text"
                        variant="outlined"
                        name="cep"
                        size={matches ? "small" : "small"}
                        error={!!errorsAddress.cep}
                        inputProps={{ className: "cep" }}
                        fullWidth
                        inputRef={registerAddress({
                          required: true,
                          minLength: 8,
                        })}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="outlined-state"
                        label="Estado *"
                        variant="outlined"
                        size={matches ? "small" : "small"}
                        name="state"
                        error={!!errorsAddress.state}
                        fullWidth
                        inputProps={{ maxLength: 2 }}
                        inputRef={registerAddress({
                          required: true,
                        })}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="outlined-city"
                        label="Cidade *"
                        variant="outlined"
                        size={matches ? "small" : "small"}
                        name="city"
                        error={!!errorsAddress.city}
                        fullWidth
                        inputRef={registerAddress({
                          required: true,
                        })}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        id="outlined-address"
                        label="Endereço *"
                        variant="outlined"
                        size={matches ? "small" : "small"}
                        name="address"
                        error={!!errorsAddress.address}
                        fullWidth
                        inputRef={registerAddress({
                          required: true,
                        })}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        id="outlined-address-number"
                        label="Número *"
                        type="number"
                        variant="outlined"
                        inputProps={{ className: "house_number" }}
                        size={matches ? "small" : "small"}
                        name="numero"
                        error={!!errorsAddress.numero}
                        fullWidth
                        inputRef={registerAddress({
                          required: true,
                        })}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="outlined-neighborhood"
                        label="Bairro *"
                        variant="outlined"
                        size={matches ? "small" : "small"}
                        name="bairro"
                        error={!!errorsAddress.bairro}
                        fullWidth
                        inputRef={registerAddress({
                          required: true,
                        })}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="outlined-telephone"
                        label="Telefone *"
                        variant="outlined"
                        size={matches ? "small" : "small"}
                        name="telephone"
                        error={!!errorsAddress.telephone}
                        fullWidth
                        inputProps={{ className: "phone" }}
                        inputRef={registerAddress({
                          required: true,
                        })}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="outlined-complement"
                        label="Complemento"
                        variant="outlined"
                        name="complemento"
                        size={matches ? "small" : "small"}
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                  </Grid>

                  <WizardActions>
                    <Button
                      type="default"
                      buttonSize="medium"
                      buttonColor="#E9E9E9"
                      textColor="#777"
                      buttonText="Voltar"
                      onClick={() => wizardNavigation("prev")}
                      disabled={activeStep === 0}
                      maxWidth={140}
                    />

                    <ButtonSubmit
                      type="submit"
                      buttonSize="medium"
                      buttonColor="#14233E"
                      textColor="#FFFFFF"
                      maxWidth={140}
                    >Próximo</ButtonSubmit>
                  </WizardActions>

                </form>
              </div>

              <div style={{ display: `${activeStep === 2 ? 'block' : 'none'}` }}>

                <HDLine>
                  <Title>
                    Conta bancária
                    <span>
                      Preencha os dados da sua conta bancária.
                    </span>
                  </Title>
                </HDLine>

                <form onSubmit={handleSubmitForm(submitBankAccount)}>

                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        id="outlined-owner-account-name"
                        label="Nome titular da conta *"
                        variant="outlined"
                        size={matches ? "small" : "small"}
                        name="titular_conta"
                        error={!!errorsForm.titular_conta}
                        fullWidth
                        inputRef={registerForm({
                          required: true,
                        })}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        onChange={handleCepChange}
                        onBlur={findAddressByCEP}
                        id="outlined-cpf"
                        label="CPF *"
                        type="text"
                        variant="outlined"
                        name="cpf"
                        size={matches ? "small" : "small"}
                        error={!!errorsForm.cpf}
                        inputProps={{ className: "cpf" }}
                        fullWidth
                        inputRef={registerForm({
                          required: true,
                          minLength: 8,
                        })}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid item md={12} xs={12} >
                      <Autocomplete
                        fullWidth
                        size="small"
                        id="bank-list"
                        options={banks}
                        getOptionLabel={(option) => option.title}
                        renderInput={(params) => <TextField {...params} label="Banco" name="bank" variant="outlined" error={!!errorsForm.bank} inputRef={registerForm({
                          required: true,
                        })} />}
                        onInputChange={(_, newInputValue) => handleFilterBank(newInputValue)}
                      />
                    </Grid>

                    <Grid item md={12} xs={12} >
                      <Autocomplete
                        fullWidth
                        size="small"
                        id="bank-account-type"
                        options={accountType}
                        getOptionLabel={(option) => option.title}
                        renderInput={(params) => <TextField {...params} label="Tipo de conta" name="account_type" variant="outlined" error={!!errorsForm.account_type} inputRef={registerForm({
                          required: true,
                        })} />}
                      />
                    </Grid>

                    <Grid item xs={selectedBank?.dig_ag ? 7 : 12}>
                      <TextField
                        id="outlined-bank-ag"
                        label="Agência *"
                        variant="outlined"
                        size={matches ? "small" : "small"}
                        name="bank_ag"
                        error={!!errorsForm.bank_ag}
                        fullWidth
                        inputProps={{ className: "bank-ag" }}
                        inputRef={registerForm({
                          required: true,
                        })}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    {selectedBank?.dig_ag && (
                      <Grid item xs={5}>
                        <TextField
                          id="outlined-bank-dig-ag"
                          label="Digito Verificador *"
                          variant="outlined"
                          size={matches ? "small" : "small"}
                          name="bank_dig_ag"
                          error={!!errorsForm.bank_dig_ag}
                          fullWidth
                          // inputProps={{ className: "bank-ag" }}
                          inputRef={registerForm({
                            required: true,
                          })}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    )}

                    <Grid item xs={selectedBank?.dig_cc ? 7 : 12}>
                      <TextField
                        id="outlined-bank-cc"
                        label="Número da conta *"
                        variant="outlined"
                        size={matches ? "small" : "small"}
                        name="bank_cc"
                        error={!!errorsForm.bank_cc}
                        fullWidth
                        inputProps={{ className: "bank-cc" }}
                        inputRef={registerForm({
                          required: true,
                        })}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    {selectedBank?.dig_cc && (
                      <Grid item xs={5}>
                        <TextField
                          id="outlined-bank-dig-cc"
                          label="Digito Verificador *"
                          variant="outlined"
                          size={matches ? "small" : "small"}
                          name="bank_dig_cc"
                          error={!!errorsForm.bank_dig_cc}
                          fullWidth
                          // inputProps={{ className: "bank-ag" }}
                          inputRef={registerForm({
                            required: true,
                          })}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    )}

                  </Grid>
                  <WizardActions>
                    <Button
                      type="default"
                      buttonSize="medium"
                      buttonColor="#E9E9E9"
                      textColor="#777"
                      buttonText="Voltar"
                      onClick={() => wizardNavigation("prev")}
                      disabled={activeStep === 0}
                      maxWidth={140}
                    />

                    {activeStep === steps.length - 1 ? (

                      <ButtonSubmit
                        type="submit"
                        buttonSize="medium"
                        buttonColor="#14233E"
                        textColor="#FFFFFF"
                        maxWidth={140}
                      >Finalizar</ButtonSubmit>
                    ) : (

                      <ButtonSubmit
                        type="submit"
                        buttonSize="medium"
                        buttonColor="#14233E"
                        textColor="#FFFFFF"
                        maxWidth={140}
                      >Próximo</ButtonSubmit>
                    )}


                  </WizardActions>
                </form>
              </div>

            </WizardContent>

          </Wizard>
        </WrapAccountWizard>
      </Dialog>

      <Dialog ref={refAddressModal} title="Adicionar endereço">
        <form onSubmit={handleSubmit(onSubmitAddress)}>
          {/*  Form Body */}
          <h1 className="_content-title">
            Cadastre um endereço a sua conta Scamb. Este será o endereço padrão
            de todos os seus anúncios.
          </h1>
          <section className="_form-body">
            <Grid container spacing={2}>

              <Grid item xs={12}>
                <InputMask
                  mask="99999-999"
                  onChange={e => setValueCredt(e.target.value)}
                  onChange={handleCepChange}
                  onBlur={findAddressByCEP}
                >
                  <TextField
                    id="outlined-cc-number"
                    label="Cep *"
                    type="text"
                    variant="outlined"
                    name="cep"
                    size={matches ? "small" : "medium"}
                    error={!!errors.cep}
                    fullWidth
                    inputRef={register({
                      required: true,
                      minLength: 8,
                    })}
                  />
                </InputMask>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="outlined-neighborhood"
                  label="Estado *"
                  variant="outlined"
                  size={matches ? "small" : "medium"}
                  name="estado"
                  error={!!errors.estado}
                  fullWidth
                  inputProps={{ maxLength: 2 }}
                  inputRef={register({
                    required: true,
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="outlined-neighborhood"
                  label="Cidade *"
                  variant="outlined"
                  size={matches ? "small" : "medium"}
                  name="cidade"
                  error={!!errors.cidade}
                  fullWidth
                  inputRef={register({
                    required: true,
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  id="outlined-address"
                  label="Endereço *"
                  variant="outlined"
                  size={matches ? "small" : "medium"}
                  name="logradouro"
                  error={!!errors.logradouro}
                  fullWidth
                  inputRef={register({
                    required: true,
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="outlined-address-number"
                  label="Número *"
                  type="number"
                  variant="outlined"
                  size={matches ? "small" : "medium"}
                  name="numero"
                  error={!!errors.numero}
                  fullWidth
                  inputRef={register({
                    required: true,
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-neighborhood"
                  label="Bairro *"
                  variant="outlined"
                  size={matches ? "small" : "medium"}
                  name="bairro"
                  error={!!errors.bairro}
                  fullWidth
                  inputRef={register({
                    required: true,
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-complement"
                  label="Complemento"
                  variant="outlined"
                  name="complemento"
                  size={matches ? "small" : "medium"}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </section>

          <ButtonStrap
            type="submit"
            className={"btn btn-primary btn-fluid btn-submit-modal " + classes.center}
            color="primary"
            autoFocus
            style={{ height: (matches ? 35 : 40) }}
            disabled={
              !formState.dirty || (formState.dirty && !formState.isValid)
            }
          >
            Confirmar
            <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
          </ButtonStrap>
          {/*  END ./ Form Body */}
        </form>
      </Dialog>

      <Dialog ref={refCreditCardModal} title="Adicionar informações">
        <form onSubmit={handleSubmit(onSubmitPersonalData)}>
          {/*  Form Body */}
          <h1 className="_content-title">
            Cadastre algumas informações pessoais como CPF e telefone celular, para realizar seu primeiro anúncio!
          </h1>
          <section className="_form-body _credit-card-form">

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className="form-group">
                  <TextField
                    id="outlined-name"
                    label={"CPF *"}
                    type="text"
                    variant={"outlined"}
                    size={matches ? "small" : "medium"}
                    name="cpf"
                    error={!!errors.cpf}
                    fullWidth
                    disabled={disableField}
                    inputProps={{ className: "cpf" }}
                    inputRef={register({
                      required: true,
                    })}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="form-group">
                  <TextField
                    id="outlined-name"
                    label={"Celular *"}
                    type="text"
                    variant={"outlined"}
                    size={matches ? "small" : "medium"}
                    name="celular"
                    error={!!errors.celular}
                    fullWidth
                    disabled={disableField}
                    inputProps={{ className: "celular" }}
                    inputRef={register({
                      required: true,
                    })}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
              </Grid>
            </Grid>
            <h1 className="_content-title">
              Agora nos informe qual sera o nome da sua loja?
            </h1>
            <Grid item xs={12}>
              <div className="form-group">
                <TextField
                  id="outlined-name"
                  label={"Nome da loja *"}
                  type="text"
                  onChange={(e) => setTitulo(e.target.value)}
                  // helperText={errors.titulo?.type === "minLength" && "é muito curto (minimo 5 caracteres)" || !!errors.titulo && "Preencha o nome da loja"}
                  variant={"outlined"}
                  size={matches ? "small" : "medium"}
                  name="titulo"
                  error={!!errors.titulo}
                  fullWidth
                  disabled={disableField}
                  inputProps={{ className: "titulo" }}
                  inputRef={register({
                    required: true,
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </Grid>
            <div className="form-group">
              <h1 className="_content-title"><b>URL da sua loja sera:</b> {"https://scamb.com.vc/loja/" + titulo.normalize('NFD').replace(/[^a-zA-Zs]/g, '').toLowerCase()}</h1>
            </div>
          </section>
          {/*  END ./ Form Body */}

          <ButtonStrap
            type="submit"
            className={"btn btn-primary btn-fluid btn-submit-modal " + classes.center}
            color="primary"
            autoFocus
            style={{ height: (matches ? 35 : 40) }}
            disabled={
              !formState.dirty && !formState.isValid
            }
          >
            Confirmar
            <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
          </ButtonStrap>
        </form>
      </Dialog>

      <WrapView
        headline="Antes de adicionar um produto você precisa completar o seu cadastro."
        stepTitle="Complete seu cadastro"
        className="_screen-conf"
      >

        <button
          type="button"
          className="btn _box-check"
          onClick={() => refAddressModal.current.openModal()}
          disabled={disabledAddress}
        >
          <div className="_col-left">
            <div className="_wrap-icon">
              <span
                className={
                  disabledAddress ? "_check-icon _checked" : "_check-icon"
                }
              >
                <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
              </span>
              <div className="_icon">
                <FontAwesomeIcon icon={faMapMarkerAlt}></FontAwesomeIcon>
              </div>
            </div>

            <div className="_text">Clique aqui para adicionar um endereço</div>
          </div>
          <div className="_col-right">
            <div className="_icon">
              <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
            </div>
          </div>
        </button>

        <button
          type="button"
          className="btn _box-check"
          onClick={() => refCreditCardModal.current.openModal()}
          disabled={disabledCreditCard}
        >
          <div className="_col-left">
            <div className="_wrap-icon">
              <span
                className={
                  disabledCreditCard ? "_check-icon _checked" : "_check-icon"
                }
              >
                <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
              </span>
              <div className="_icon">
                <FontAwesomeIcon icon={faAddressCard}></FontAwesomeIcon>
              </div>
            </div>

            <div className="_text">
              Clique aqui para adicionar algumas informações.
            </div>
          </div>
          <div className="_col-right">
            <div className="_icon">
              <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
            </div>
          </div>
        </button>

        <button
          type="button"
          className="btn _box-check"
          onClick={() => refBankAccountModal.current.openModal()}
          disabled={disabledWizard}
        >
          <div className="_col-left">
            <div className="_wrap-icon">
              <span
                className={
                  disabledWizard ? "_check-icon _checked" : "_check-icon"
                }
              >
                <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
              </span>
              <div className="_icon">
                <FontAwesomeIcon icon={faFileUser}></FontAwesomeIcon>
              </div>
            </div>

            <div className="_text">
              Dados de conta bancária
            </div>
          </div>
          <div className="_col-right">
            <div className="_icon">
              <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
            </div>
          </div>
        </button>

        {/* END ./ Form Head */}

        <ButtonStrap
          type="submit"
          className={"btn btn-primary btn-fluid btn-submit-modal " + classes.center}
          color="primary"
          autoFocus
          style={{ height: (matches ? 35 : 40) }}
          onClick={() => goTo()}
          disabled={
            !disabledAddress || !disabledCreditCard || !disabledWizard
          }
        >
          Continuar
          <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
        </ButtonStrap>
      </WrapView>
    </>
  );
}

const mapStateToProps = state => {
  return {
    scamber: state.scamberReducer.scamber,
    balance: state.balanceReducer.balance,
    site_properties: state.websiteReducer.site_properties
  }
}

export default connect(mapStateToProps, { setScamber })(CompleteRegistration);