//===============================================================================================================
// #1 - Base Imports
//===============================================================================================================
import React from "react";

//===============================================================================================================
// #3 - Import * React Helmet
//===============================================================================================================
import Helmet from 'react-helmet';

//===============================================================================================================
// #3 - Import * UI Components
//===============================================================================================================
import Button from "../../../components/design/form/button";

//===============================================================================================================
// #4 - Import * Font Awesome Icons
//===============================================================================================================
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";

//===============================================================================================================
// #5 - Import * Styled Components
//===============================================================================================================
import { Wrapper, Main, Content, IconChecked, Title, Text } from "./styles";

function BuyThanks() {
    
    // * States
    // const [origin, setOrigin] = React.useState([]);

    // // * Effetcs
    // React.useEffect(() => {


    //     // * Handle URL Params
    //     const query = document.location.search;
    //     const urlParams = new URLSearchParams(query);
    //     const dataByParams = {
    //         origin: urlParams.get("origin"),
    //         event: urlParams.get("event"),
    //         name: urlParams.get("name"),
    //         email: urlParams.get("email"),
    //         phone: urlParams.get("phone"),
    //     }

    //     // * Set Page Origin
    //     setOrigin(data => [...data, dataByParams.origin])
        
    //     // * Send DataLayer
    //     window.dataLayer = window.dataLayer || [];        
    //     window.dataLayer.push(dataByParams);
        
    // }, []);

    return (
        <Wrapper>
            
            <Helmet title="Scamb - Conta criada com sucesso!" />

            <Main>
                <Content>
                    <IconChecked>
                        <Icon id="checked" icon={faCheck} />
                    </IconChecked>

                    {/* <Title>Parabéns! {origin[0] === "create-account" && "Cadastro realizado com sucesso." || origin[0] === "login" && "Conta validada com sucesso!"} </Title> */}
                    <Title>Parabéns! Cadastro realizado com sucesso. </Title>

                    <Text marginTop={10}>
                        Agora você já é um Scamber!
                    </Text>

                    <Text marginTop={25} marginBottom={20}>
                        Clique no botão abaixo e deixe os dados do<br/> seu perfil completos para agilizar<br/> na hora de comprar e vender .
                    </Text>

                    <Button
                        type="default"
                        buttonSize="medium"
                        buttonColor="#2B3951"
                        textColor="#FFFFFF"
                        buttonText="Meu perfil"
                        borderColor="#2B3951"
                        buttonLink="/meu-perfil"
                        maxWidth={200}
                    />

                </Content>
            </Main>
        </Wrapper>
    )
}

export default BuyThanks;