//===========================================================
// #1 - Base Imports
//===========================================================
import React, { useState } from "react";

//===========================================================
// #2 - Import * FontAwesome Icons
//===========================================================
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-light-svg-icons";


export const SelectBox = (props) => {

    return (
        <div className={props.className ? `select-box ${props.className}` : "select-box"} onClick={props.onClick}>
            <div className="_cols">
                <label onClick={props.onClick}>
                    <div className="_col">
                        <div className="icon">
                            {props.icon}
                        </div>
                        <div className="text">
                            {props.titleContent}
                        </div>
                    </div>

                    {/* <div className="_col">
                        {props.input}
                        <div className="check">
                            <Icon icon={faCheck} />
                        </div>
                    </div> */}
                    {props.input}

                    {props.input && (
                        <div className="check">
                            <Icon icon={faCheck} />
                        </div>
                    )}
                    <span className="overlay"></span>
                </label>
            </div>
            <div className="content">
                {props.children}
            </div>
        </div>
    )
}

export const SelectBoxExpand = (props) => {

    // * States
    const [panel, setPanel] = useState(false);

    // * Functions
    const handlePanel = () => {
        // setPanel(true)

        if (!panel) {
            setPanel(true)
        } else {
            setPanel(false)
        }
    };

    return (
        <div className="select-box _expand">
            <div className="_cols">
                <label>
                    <div className="_col">
                        <div className="icon">
                            {props.input}
                            <div className="check">
                                <Icon icon={faCheck} />
                            </div>
                            {props.icon}
                        </div>
                        <div className="text">
                            {props.titleContent}
                        </div>
                    </div>
                </label>

                <div className="_col" onClick={handlePanel}>
                    <Icon icon={panel ? faChevronUp : faChevronDown} />
                </div>

            </div>
            {panel && (
                <div className="content">
                    {props.children}
                </div>
            )}
        </div>
    )
}