import { SET_CART } from '../actionTypes';

const initialState = { }

export default function(state = initialState, action) {
    switch(action.type) {
        case SET_CART:
            const { cart } = action.payload;
            return { ...state, cart: cart }
        default:
            return state;
    }
}