import Layout from "../../components/account/Layout";

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useForm, Controller } from "react-hook-form";
import InputMask from "react-input-mask";

// JQuery
import $ from "jquery";
import "jquery-mask-plugin";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Material - UI
import {
  makeStyles,
  useMediaQuery,
  Grid,
  TextField,
  InputAdornment,
} from "@material-ui/core";

// Icons
import { faCreditCardBlank } from "@fortawesome/pro-solid-svg-icons";

import {
  faUser,
  faCalendarStar,
  faCalendarAlt,
  faLockAlt,
  faTimes,
  faEdit,
  faCheck,
  faCreditCardBlank as faCreditCardLt,
} from "@fortawesome/pro-light-svg-icons";

import { faMapMarkerAlt } from "@fortawesome/pro-solid-svg-icons";

// Images
import Visa from "../../assets/images/credit-card/visa.png";
import Mastercard from "../../assets/images/credit-card/mastercard.png";
import AmericanExpress from "../../assets/images/credit-card/americanExpress.png";
import DinersClub from "../../assets/images/credit-card/DinersClub.png";

// Services 
import CreditCard from '../../services/CreditCard';
import Endereco from '../../services/Endereco';

import {connect} from 'react-redux';

import Helmet from 'react-helmet';


// Styles
const useStyles = makeStyles({
  center: {
    margin: "auto",
    display: "block",
  },
  resize: {
    fontSize: "10px",
  },
  inputSize: {
    height: 50,
  },
  svgSize: {
    width: "18px !important",
    height: "auto !important",
  },
});


function AddPaymentMethod(props) {
  const matches = useMediaQuery("(min-width:600px)");

  const { register, handleSubmit, errors, control,reset } = useForm({
    mode: "onChnage",
  });
  const [showForm, editForm] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [mainFormInfo, currentFormInfo] = useState(true);
  const [mainFormCc, currentFormCc] = useState(false);
  const [disableField, setDisableField] = useState(true);
  const [valueField, setValue] = useState("");
  const [creditCardType, setCreditCardType] = useState(null);
  let   [address, setAddress] = useState({});
  let   [cep, setCep] = useState({});


  const toggleIcon = () => {
    editForm(!showForm);
    setDisabled(!disabled);
    setDisableField(!disableField);
  };




  let endereco = new Endereco();
  let creditCard = new CreditCard();

  const onSubmit = (data) => {

    let formattedData = {
      "creditCard":{},
      "address":{}
    }

    formattedData.creditCard["bandeira"]      = creditCardType? creditCardType: "none";
    formattedData.creditCard["numero_cartao"] = data.numero_cartao;
    formattedData.creditCard["nome"]          = data.nome.toUpperCase();
    formattedData.creditCard["mes_expiracao"] = data.mes_expiracao;
    formattedData.creditCard["ano_expiracao"] = data.ano_expiracao;
    formattedData.creditCard["cvv"]           = data.cvv;

    formattedData.address["bairro"]           = data.bairro;
    formattedData.address["cep"]              = data.cep;
    formattedData.address["cidade"]           = data.cidade;
    formattedData.address["complemento"]      = data.complemento;
    formattedData.address["estado"]           = data.estado;
    formattedData.address["logradouro"]       = data.logradouro;
    formattedData.address["numero"]           = data.numero;

    creditCard.save(formattedData).then((response) =>{

      let address = response.data.payload; 
      reset(address)
      if(response.status === 200){

        Swal.fire({
          icon: "success",
          title: "Cartão adicionado!",
          timer: 4000,
          timerProgressBar: true,
          html:
            "Este endereço de cobrança sera associado ao novo cartão que voce está cadastrando em sua conta.",
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonText: "fechar",
        }).then((e) => {
          if (e) {
            props.history.push('minha-carteira');
          }
        });

      }

    }).catch (( error ) => endereco.formatErrorMessage(error.response, 'APMSVCC1'))

    // editForm(false);
    // setDisabled(!disabled);
  };

  useEffect(() => {
    initFormValues(props.address);
  }, [props.address]);

  useEffect(() => {
    editForm(!showForm);
    setDisabled(!disabled);
    setDisableField(!disableField);
  }, []);


  useEffect(() => {
    $(".cep").mask("00000-000");
    $(".mes").mask("00");
    $(".ano").mask("0000");
    $(".cvv").mask("000");

    $(".house_number").mask("00000");
  });

  const initFormValues = (address) => {
    if(address && address.length > 0){
      reset(address[0]); 
    } 
  }

  let handleChange = (event) => {
    setCep(event.target.value);
  }

  let findAddressByCEP = () => {
      
    if(cep.length === 9){
      endereco.findCep(cep).then(( address ) => {

        let formatedAddress = {
          cep:        address.data.cep,
          estado:     address.data.estado,
          logradouro: address.data.logradouro,
          cidade:     address.data.localidade,
          estado:     address.data.uf,
          bairro:     address.data.bairro
        }

        if(address.data){
          reset(formatedAddress); 
        }
      }).catch((error)=> Swal.fire('info','Não foi possível consultar o endereço',''));
    }
  }


  let checkCreditCardType = (e) =>{

    let fieldSize = e.target.value.replace(' ','').replace(/[^0-9]+/g,'').length;
    let cardnumber = e.target.value.replace(' ','').replace(/[^0-9]+/g,'');
    let emptyFields = 16 - cardnumber.length;
    let zeros = "";
    for(let count = 0; count < emptyFields; count++){
      zeros += "0"
    }

      var cards = {
        visa           : /^4[0-9]{12}(?:[0-9]{3})/,
        mastercard     : /^5[1-5][0-9]{14}/,
        DinersClub     : /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
        americanExpress: /^3[47][0-9]{13}/,
      };

      // discover       : /^6(?:011|5[0-9]{2})[0-9]{12}/,
      // hipercard      : /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
      // elo            : /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
      // jcb            : /^(?:2131|1800|35\d{3})\d{11}/,       
      // aura           : /^(5078\d{2})(\d{2})(\d{11})$/     


      for (var flag in cards) {
          if(cards[flag].test(parseInt((cardnumber + zeros)))) {
            return setCreditCardType(flag);
          }
      }       

      return setCreditCardType(null);

  }

  const onSubmitCreditCard = (data) => {

    if(creditCardType) data["bandeira"] = creditCardType;
    // Removing mask before insert on database
    data["numero"] = data["numero"].replace(' ','').replace(/[^0-9]+/g,'');

    let creditCard = new CreditCard();
    creditCard.save(data).then((response )=>{
      if(response.status === 200){
        Swal.fire({
          icon: "success",
          title: "Cartão adicionado!",
          timer: 4000,
          timerProgressBar: true,
          html: "Seu novo cartão de crédito foi adicionado na sua conta Scamb.",
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonText: "fechar",
        }).then((e) => {
          if (e) {
            props.history.push('minha-carteira');
          }
        });
      }
        
    }).catch (( error ) => creditCard.formatErrorMessage(error.response, 'APMSVCC2'));


  };

  const handleForms = () => {
    currentFormInfo(!mainFormInfo);
    currentFormCc(!mainFormCc);
  };

  return (
    <Layout>
      <Helmet title={`${props.site_properties?.title} - Adicionar métodos de pagamento`} />
      <div id="view_add-payment">
        <h1 className="_title-box">
          <span>
            <FontAwesomeIcon icon={faCreditCardBlank} />
          </span>
          Adicionar método de pagamento
        </h1>

        {/* <PaymentMethod></PaymentMethod> */}
        <div id="wrap-add-payment">
          {mainFormInfo ? (
            <div className="_wrap-form-panel">
              <form
                className="_form _validate"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="_wrap-form-header">
                  <h1 className="_title-box">Informações de cobrança</h1>
                  {/* <div className="actions">
                    <button
                      type="button"
                      className="btn btn-transparent _edit-cancel"
                      onClick={toggleIcon}
                    >
                      <FontAwesomeIcon
                        icon={showForm ? faTimes : faEdit}
                      ></FontAwesomeIcon>
                    </button>
                    {showForm ? (
                      <button
                        type="submit"
                        className="btn btn-transparent _confirm"
                      >
                        <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                      </button>
                    ) : null}
                  </div> */}
                </div>

                {/* <div className="_set-user-location">
                  <h1 className="_user-location-title">Meu endereço padrão</h1>

                  <div className="_wrap-location">
                    <div className="_icon">
                      <FontAwesomeIcon icon={faMapMarkerAlt}></FontAwesomeIcon>
                    </div>

                    <div className="_wrap-current-location">
                      <div className="_location-title">CEP: {props.address? props.address[0].cep : <></>}</div>
                      <div className="_location">
                        {props.address? props.address[0].logradouro : <></>} - {props.address? props.address[0].bairro : <></>},&nbsp; 
                        {props.address? props.address[0].cidade : <></>} - {props.address? props.address[0].estado : <></>}
                      </div>
                    </div>
                  </div>
                </div> */}

                {showForm ? (
                  <>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <div className="form-group">
                          {disableField ? (
                            <label className="_label">
                              Cep <span>*</span>
                            </label>
                          ) : null}
                          <TextField
                            id="outlined-cep"
                            onBlur={findAddressByCEP}
                            onChange={handleChange}
                            inputProps={{className:'cep'}}
                            label={disableField ? null : "Cep *"}
                            type="text"
                            variant={disableField ? "filled" : "outlined"}
                            size={matches ? "small" : "small"}
                            name="cep"
                            error={!!errors.cep}
                            fullWidth
                            disabled={disableField}
                            inputRef={register({
                              required: true,
                            })}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={disableField ? 12 : 6}>
                        <div className="form-group">
                          {disableField ? (
                            <label className="_label">
                              Estado <span>*</span>
                            </label>
                          ) : null}
                          <TextField
                            id="outlined-state"
                            label={disableField ? null : "Estado *"}
                            type="text"
                            variant={disableField ? "filled" : "outlined"}
                            size={matches ? "small" : "small"}
                            name="estado"
                            error={!!errors.estado}
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            disabled={disableField}
                            inputRef={register({
                              required: true,
                            })}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={disableField ? 12 : 6}>
                        <div className="form-group">
                          {disableField ? (
                            <label className="_label">
                              Cidade <span>*</span>
                            </label>
                          ) : null}
                          <TextField
                            id="outlined-city"
                            label={disableField ? null : "Cidade *"}
                            type="text"
                            variant={disableField ? "filled" : "outlined"}
                            size={matches ? "small" : "small"}
                            name="cidade"
                            error={!!errors.cidade}
                            fullWidth
                            disabled={disableField}
                            inputRef={register({
                              required: true,
                            })}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={disableField ? 12 : 9}>
                        <div className="form-group">
                          {disableField ? (
                            <label className="_label">
                              Endereço <span>*</span>
                            </label>
                          ) : null}
                          <TextField
                            id="outlined-username"
                            label={disableField ? null : "Endereço *"}
                            type="text"
                            variant={disableField ? "filled" : "outlined"}
                            size={matches ? "small" : "small"}
                            name="logradouro"
                            error={!!errors.logradouro}
                            fullWidth
                            disabled={disableField}
                            inputRef={register({
                              required: true,
                            })}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={disableField ? 12 : 3}>
                        <div className="form-group">
                          {disableField ? (
                            <label className="_label">
                              Número <span>*</span>
                            </label>
                          ) : null}
                          <TextField
                            id="outlined-number"
                            label={disableField ? null : "Número *"}
                            type="text"
                            inputProps={{className:'house_number'}}
                            variant={disableField ? "filled" : "outlined"}
                            size={matches ? "small" : "small"}
                            name="numero"
                            error={!!errors.numero}
                            fullWidth
                            disabled={disableField}
                            inputRef={register({
                              required: true,
                            })}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div className="form-group">
                          {disableField ? (
                            <label className="_label">
                              Bairro <span>*</span>
                            </label>
                          ) : null}
                          <TextField
                            id="outlined-neighborhood"
                            label={disableField ? null : "Bairro *"}
                            type="text"
                            variant={disableField ? "filled" : "outlined"}
                            size={matches ? "small" : "small"}
                            name="bairro"
                            error={!!errors.bairro}
                            fullWidth
                            disabled={disableField}
                            inputRef={register({
                              required: true,
                            })}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div className="form-group">
                          {disableField ? (
                            <label className="_label">
                              Complemento <span>*</span>
                            </label>
                          ) : null}
                          <TextField
                            id="outlined-complement"
                            label={disableField ? null : "Complemento *"}
                            type="text"
                            variant={disableField ? "filled" : "outlined"}
                            size={matches ? "small" : "small"}
                            name="complemento"
                            error={!!errors.complemento}
                            fullWidth
                            disabled={disableField}
                            inputRef={register({
                              required: false,
                            })}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </div>
                      </Grid>
                    </Grid>
                    {/* aqui */}
                    <br/>
                    <div className="_wrap-form-header">
                      <h1 className="_title-box">Dados do cartão</h1>
                    </div>

                    <ul className="_payment-methods-list">
                      <li>
                        <div className="creadit-card--flag">
                          <img src={Visa} alt="" />
                        </div>
                      </li>
                      <li>
                        <div className="creadit-card--flag">
                          <img src={Mastercard} alt="" />
                        </div>
                      </li>
                      <li>
                        <div className="creadit-card--flag">
                          <img src={AmericanExpress} alt="" />
                        </div>
                      </li>
                      <li>
                        <div className="creadit-card--flag">
                          <img src={DinersClub} alt="" />
                        </div>
                      </li>
                    </ul>

                  <Grid container spacing={2}>

                    <Grid item xs={12}>
                    <InputMask
                      mask="9999 9999 9999 9999"
                      value={valueField}
                      onChange={e => setValue(e.target.value)}
                    >
                      <TextField
                        id="outlined-cc-number"
                        label="Número do cartão *"
                        type="text"
                        value={valueField}
                        onInput={checkCreditCardType}
                        onPaste={checkCreditCardType}
                        variant="outlined"
                        name="numero_cartao"
                        size={matches ? "small" : "small"}
                        // error={!!errors.numero}
                        fullWidth
                        inputRef={register({
                          required: true,
                          minLength: 8,
                        })}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {
                                creditCardType?
                                  <div className="creadit-card--flag">
                                    <img style={{width:"15.75px"}} src={require(`../../assets/images/credit-card/${creditCardType}.png`)} alt="Flag credit card" />
                                  </div>
                                :
                                <FontAwesomeIcon
                                  icon={faCreditCardLt}
                                ></FontAwesomeIcon>
                              }
                            </InputAdornment>
                          ),
                        }}
                      />
                    </InputMask>

                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      id="outlined-cc-name"
                      label="Nome do titular cartão *"
                      variant="outlined"
                      name="nome"
                      size={matches ? "small" : "small"}
                      error={!!errors.nome}
                      fullWidth
                      inputRef={register({
                        required: true,
                        minLength: 4,
                      })}
                      inputProps={{
                        style: { textTransform: 'uppercase' }
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <FontAwesomeIcon icon={faUser}></FontAwesomeIcon>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-cc-month"
                      label="Mês *"
                      type="number"
                      variant="outlined"
                      name="mes_expiracao"
                      size={matches ? "small" : "small"}
                      error={!!errors.mes_expiracao}
                      fullWidth
                      inputRef={register({
                        required: true,
                        minLength: 2,
                      })}
                      inputProps={{className:'mes'}}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <FontAwesomeIcon
                              icon={faCalendarStar}
                            ></FontAwesomeIcon>
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-cc-year"
                      label="Ano *"
                      type="number"
                      variant="outlined"
                      name="ano_expiracao"
                      size={matches ? "small" : "small"}
                      error={!!errors.ano_expiracao}
                      fullWidth
                      inputRef={register({
                        required: true,
                        minLength: 4,
                      })}
                      inputProps={{className:'ano'}}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <FontAwesomeIcon
                              icon={faCalendarAlt}
                            ></FontAwesomeIcon>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                    <Grid item xs={4}>
                      <TextField
                        id="outlined-cc-number"
                        label="Cvv *"
                        type="number"
                        variant="outlined"
                        name="cvv"
                        size={matches ? "small" : "small"}
                        error={!!errors.cvv}
                        fullWidth
                        inputRef={register({
                          required: true,
                          minLength: 2,
                        })}
                        inputProps={{className:'cvv'}}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <FontAwesomeIcon icon={faLockAlt}></FontAwesomeIcon>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                    <div className="form-group-button">
                      <button
                        type="button"
                        className="btn btn-cancel"
                        onClick={handleForms}
                      >
                        Voltar
                      </button>
                      <button type="submit" className="btn btn-success">
                        Continuar
                      </button>
                    </div>
                  </>
                ) : null}
              </form>
            </div>
          ) : null}

          
        </div>
      </div>
    </Layout>
  );
}

const mapStateToProps = state => {
  return {
    address: state.scamberReducer.scamber?.endereco,
    site_properties: state.websiteReducer.site_properties
  }
}

export default connect(mapStateToProps)(AddPaymentMethod);
