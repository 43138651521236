import Service from './Service';

class CreateIuguAccount extends Service {

  constructor() {
    super();
  }

  create(data) {
    return this.axiosInstance.post(`/marketplace/create-account`, data);
  }

  checkAccountStatus() {
    return this.axiosInstance.get(`/iugu/balances`);
  }

}

export default CreateIuguAccount;