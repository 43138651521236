import React, { Component } from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { Link, withRouter } from 'react-router-dom'

class Breadcrumbs extends Component {

    constructor(){
        super();
        this.state ={
            urls:[]
        }
    }

    componentDidMount(){
        let ultimasURLs = sessionStorage.getItem('recentlyUrl');
        this.setState({urls: JSON.parse(ultimasURLs)});
    }

    goHistoryBack = (step) => {
        const stepToGoBack = (this.state.urls.length -1) - step;
        this.props.history.go(stepToGoBack * -1);
    }

    render() {
        
        return (
            
            <section id="breadcrumbs">
                <div className="_med-container">
                    <Breadcrumb>
                       {this.state.urls? 
                           this.state.urls.map((item,index)=>
                            <Breadcrumb.Item key={index} onClick={() => this.goHistoryBack(index)}>{item.name === '/'? 'Home' : item.name.replace('/','')}</Breadcrumb.Item>
                           )
                       :<></>}
                    </Breadcrumb>
                </div>
            </section>
        
        );
        
    };
    
};

export default withRouter(Breadcrumbs);