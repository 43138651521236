import React from "react";
import Showcase from '../../../services/Showcase';

import Grid from '@material-ui/core/Grid';

export const HighlightBox = ({ type, device, title, filters, subtitile, images, filterType, showcaseId }) => {
    const showcase = new Showcase();

    const openFilters = (filter) => {
        localStorage.setItem("categorizations", JSON.stringify(filter.categoryList));
        const categoriaList = filter.categoryList.length > 0 && filter.categoryList[filter.categoryList.length - 1]._id;
        const key = filter.filterbyKey;
        const condition = filter.condition;
        const points = filter.points;
        const orderBy = filter.orderBy;
        const brand = filter.brand;
        const deliveryType = filter.operador_logistico ? 'operador_logistico' : filter.entrega_maos ? 'entrega_maos' : false;
                
        if(filterType === 'products') {
            window.location.href = `/resultados?pageNumber=1${`&showcaseId=${showcaseId}&aleatory=89` }`;
        } else {
            window.location.href = `/resultados?pageNumber=1${key ? "&key="+key : '' }${categoriaList ? '&categoryList='+categoriaList : '' }${brand ? '&brand='+brand : ''}${condition ? '&condition='+condition : ''}${orderBy ? '&price='+orderBy: ''}${deliveryType ? '&deliveryTypes='+deliveryType : ''}${points ? '&points='+points : ''}`;
        }
    }
    
    return (
        <div className={`highlight-box ${device === "celular" ? "smartphone" : "desktop"}`}>
            <div className="title">
                <div>
                    <h1> {title} </h1>
                    <small> {subtitile} </small>
                </div>
                <a href="#" onClick={() => openFilters(filters)}>veja mais!</a>
            </div>

            {type === "oneBaners" && device === "celular" && (

                <div className={`highlight-box type-2-columns`}>

                    {/* <Grid container className="Container" spacing={2}> */}

                        {/* <Grid item xs={12} sm={12}> */}
                            <div className="WrapAnchorPicture">

                                <a className="AnchorPicture" href="#" onClick={() =>openFilters(filters)}>
                                    <div className="WrapPicture">
                                        <picture>
                                            <source srcset={showcase.loadImage(images[0])} media="(min-width: 0px) and (max-width: 599px)" />
                                            <img alt="" title="" src={showcase.loadImage(images[0])} />
                                        </picture>
                                    </div>
                                </a>
                            </div>
                        {/* </Grid> */}

                    {/* </Grid> */}

                </div>

            )}

            {type === "twoBanners" && (

                <div className={`highlight-box type-2-columns`}>

                    <Grid container className="Container" spacing={2}>

                        <Grid item xs={5} sm={5}>
                            <div className="WrapAnchorPicture">

                                <a className="AnchorPicture" href="#" onClick={() =>openFilters(filters)}>
                                    <div className="WrapPicture">
                                        <picture>
                                            <source srcset={showcase.loadImage(images[0])} media="(min-width: 0px) and (max-width: 599px)" />
                                            <img alt="" title="" src={showcase.loadImage(images[0])} />
                                        </picture>
                                    </div>
                                </a>
                            </div>
                        </Grid>

                        <Grid item xs={7} sm={7}>
                            <div className="WrapAnchorPicture">

                                <a className="AnchorPicture" href="#" onClick={() =>openFilters(filters)}>
                                    <div className="WrapPicture">
                                        <picture>
                                            <source srcset={showcase.loadImage(images[1])} media="(min-width: 0px) and (max-width: 599px)" />
                                            <img src={showcase.loadImage(images[1])} alt="Scamb" />
                                        </picture>
                                    </div>
                                </a>
                            </div>
                        </Grid>


                    </Grid>

                </div>

            )}

            {type === "threeBaners" && (

                <div className={`highlight-box type-2-columns _x2-1`}>

                    <Grid container className="Container" spacing={2}>

                        <Grid item xs={7} sm={7}>
                            <Grid item xs={12} sm={12} className="SmallPicture">
                                <div className="WrapAnchorPicture">

                                    <a className="AnchorPicture" href="#" onClick={() =>openFilters(filters)}>
                                        <div className="WrapPicture">
                                            <picture>
                                                <source srcset={showcase.loadImage(images[0])} media="(min-width: 0px) and (max-width: 599px)" />
                                                <img src={showcase.loadImage(images[0])} alt="Scamb" />
                                            </picture>
                                        </div>
                                    </a>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} className="SmallPicture">
                                <div className="WrapAnchorPicture">

                                    <a className="AnchorPicture" href="#" onClick={() =>openFilters(filters)}>
                                        <div className="WrapPicture">
                                            <picture>
                                                <source srcset={showcase.loadImage(images[1])} media="(min-width: 0px) and (max-width: 599px)" />
                                                <img src={showcase.loadImage(images[1])} alt="Scamb" />
                                            </picture>
                                        </div>
                                    </a>
                                </div>
                            </Grid>
                        </Grid>

                        <Grid item xs={5} sm={5}>
                            <div className="WrapAnchorPicture">

                                <a className="AnchorPicture" href="#" onClick={() =>openFilters(filters)}>
                                    <div className="WrapPicture">
                                        <picture>
                                            <source srcset={showcase.loadImage(images[2])} media="(min-width: 0px) and (max-width: 599px)" />
                                            <img alt="" title="" src={showcase.loadImage(images[2])} />
                                        </picture>
                                    </div>
                                </a>
                            </div>
                        </Grid>

                    </Grid>

                </div>
            )}

        </div>
    )
}