//===============================================================================================================
// #1 - Base Imports
//===============================================================================================================
import React from "react";

//===============================================================================================================
// #2 - Import * Services
//===============================================================================================================
import consultOrderDataLayer from "../../../services/Pedido";

//===============================================================================================================
// #3 - Import * Utilities
//===============================================================================================================
import ContentLoader from "react-content-loader"

//===============================================================================================================
// #4 - Import * React Router Dom
//===============================================================================================================
import { Link } from "react-router-dom";

//===============================================================================================================
// #5 - Import * UI Components
//===============================================================================================================
import Button from "../../../components/design/form/button";

//===============================================================================================================
// #6 - Import * Font Awesome Icons
//===============================================================================================================
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";

//===============================================================================================================
// #7 - Import * Styled Components
//===============================================================================================================
import { Wrapper, Main, Content, IconChecked, Title, Text } from "./styles";

//===============================================================================================================
// #8 - Import * Styled Components
//===============================================================================================================
const order = new consultOrderDataLayer();

//===============================================================================================================
// #8 - Set Content Loader
//===============================================================================================================
const MyLoader = (props) => (
    <ContentLoader
        rtl
        speed={2}
        width={328}
        height={300}
        viewBox="0 0 328 300"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
    >
        <rect x="27" y="156" rx="3" ry="3" width="262" height="7" />
        <rect x="29" y="90" rx="3" ry="3" width="270" height="8" />
        <rect x="69" y="170" rx="3" ry="3" width="178" height="7" />
        <circle cx="159" cy="45" r="32" />
        <rect x="51" y="114" rx="3" ry="3" width="211" height="6" />
        <rect x="47" y="185" rx="3" ry="3" width="211" height="6" />
        <rect x="85" y="213" rx="3" ry="3" width="160" height="36" />
    </ContentLoader>
)

//===============================================================================================================
// #9 - Show Content Credit Card
//===============================================================================================================
const ContentCreditCard = ({ transactionId }) => {

    return (
        <Content>
            <IconChecked>
                <Icon id="checked" icon={faCheck} />
            </IconChecked>

            <Title>
                Pedido realizado com sucesso!<br />
                Assim que o pagamento for aprovado, daremos sequência ao seu pedido!    
            </Title>

            <Text marginTop={15}>
                N. Pedido: {transactionId}
            </Text>

            <Text marginTop={30} marginBottom={20}>
                Você pode acompanhar o status do seu pedido em<br /> "<Link to="/minhas-compras"><u>Minhas compras</u></Link>"
            </Text>

            <Text marginTop={15}>
                Se você escolheu a opção “Correios”, o vendedor tem até 7 dias úteis para postar o produto.
            </Text>

            <Text marginTop={15}>
                Se você escolheu a opção “Entrega em Mãos”, logo entraremos em contato para ajudar a coordenar a entrega com o vendedor.
            </Text>

            <Button
                type="default"
                buttonSize="medium"
                buttonColor="#2B3951"
                textColor="#FFFFFF"
                buttonText="Continuar comprando"
                borderColor="#2B3951"
                buttonLink="/"
                maxWidth={200}
            />

        </Content>
    )
}

//===============================================================================================================
// #10 - Show Content Credit Card
//===============================================================================================================
const ContentPixBoleto = ({ transactionId, billing_link }) => {

    return (
        <Content>
            <IconChecked>
                <Icon id="checked" icon={faCheck} />
            </IconChecked>

            <Title>Pedido realizado com sucesso!</Title>

            <Text marginTop={15}>
                N. Pedido: {transactionId}
            </Text>

            <Text marginTop={15} marginBottom={20}>
                Você tem até 3 dias úteis para realizar o pagamento. Caso não seja efetuado a sua compra será cancelada automaticamente.
            </Text>

            <Button
                type="default"
                buttonSize="medium"
                buttonColor="#2B3951"
                textColor="#FFFFFF"
                buttonText="Ver fatura"
                borderColor="#2B3951"
                buttonLink={billing_link ? billing_link : "#billing-link"}
                maxWidth={200}
                linkType="anchor"
                target="_new"
            />

            <Text marginTop={30}>
                Acompanhe tudo sobre o seu pedido <br /> em "<Link to="/minhas-compras"><u>Minhas compras</u></Link>"
            </Text>

        </Content>
    )
}

function CheckoutThanks() {

    // * States
    const [orderData, setOrderData] = React.useState([]);

    // * Get URL Params
    const query = document.location.search;
    const params = new URLSearchParams(query);
    const orderParam = params.get("order");

    // * Change Message By Transaction Type
    let contentType;
    let transactionPaymentMethod = orderData[0]?.transactionPaymentMethod;

    switch (transactionPaymentMethod) {
        case "Cartão":
            contentType = <ContentCreditCard transactionId={orderData[0]?.transactionId} />;
            break;

        case "Pix":
            contentType = <ContentPixBoleto transactionId={orderData[0]?.transactionId} billing_link={orderData[0]?.transactionInvoice} />;
            break;

        case "Boleto":
            contentType = <ContentPixBoleto transactionId={orderData[0]?.transactionId} billing_link={orderData[0]?.transactionInvoice} />;
            break;

        default: contentType = <MyLoader />
    }

    // * Effects
    React.useEffect(() => {

        const orderById = async () => {

            try {
                const response = await order.consultOrderDataLayer(orderParam);
                const data = await response.data.payload;
                window.dataLayer = await window.dataLayer || [];
                
                function waitForFbq(callback){
                    if(typeof fbq !== 'undefined'){
                        callback()
                    } else {
                        setTimeout(function () {
                            waitForFbq(callback)
                        }, 100)
                    }
                }

                waitForFbq(function () {
                    window.dataLayer.push( {event: "purchase", ...data, pagePath: `${window.location.pathname}?${orderParam}`});
                })
                
                // window.dataLayer.push( {event: "purchase", ...data, pagePath: `${window.location.pathname}?${orderParam}`});

                setOrderData(dataPayload => [...dataPayload, data]);

            } catch (error) {
                order.formatErrorMessage(error.response, "CFMPDO");
            }

        }


        // * Call to function
        orderById();

    }, []);

    return (
        <Wrapper>
            <Main>
                {contentType}
            </Main>
        </Wrapper>
    )
}

export default CheckoutThanks;