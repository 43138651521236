import React, { Component } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import Layout from "../../../components/account/Layout";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from 'react-redux';

import { faCog } from "@fortawesome/pro-light-svg-icons";

import Scamber from '../../../services/Scamber';
import CreateIuguAccount from "../../../services/CreateIuguAccount";
import StoreForm from './StoreForm';
import PersonalForm from './PersonalForm';
import AddressForm from './AddressForm';

import { setScamber } from './../../../redux/actions/scamberActions';
import Helmet from 'react-helmet';
import { CurrentPage } from "../../../components/ui/current-page";

import { Popup, WrapIcon, Content, Title, Subtitle, Button, Column } from "./styles";

const AccountStatus = ({ title, text, link, icon, showArrowIcon, iconColor }) => {

  return (
    <Popup>
      <Link to={link}>
        <Column>
          <WrapIcon iconColor={iconColor}>{icon}</WrapIcon>

          <Content>
            <Title>
              {title}
            </Title>
            <Subtitle>
              {text}
            </Subtitle>
          </Content>
        </Column>
        {showArrowIcon && (
          <Column>
            <Button type="button">
              <Icon icon={["far", "chevron-right"]} />
            </Button>
          </Column>
        )}
      </Link>
    </Popup>
  )
}

class MyProfile extends Component {

  constructor() {
    super();
    this.state = {
      isLoadingPopup: false,
      scamber_account: {
        validated: "",
        waiting: "",
        notvalidated: "",
      }
    }
  }

  checkIuguAccount = async () => {

    const iugu_account = new CreateIuguAccount();
    this.setState({ isLoadingPopup: false });

    try {
      const response = await iugu_account.checkAccountStatus();

      if (response.data.payload !== null) {
        const isValidated = response.data.payload.isValidated;

        if (isValidated) {
          this.setState({
            isLoadingPopup: true,
            scamber_account: {
              validated: "validated",
            }
          })
        } else {
          this.setState({
            isLoadingPopup: true,
            scamber_account: {
              waiting: "waiting",
            }
          })
        }

        return;
      } else {
        this.setState({
          isLoadingPopup: true,
          scamber_account: {
            notvalidated: "notvalidated",
          }
        })
      }

    } catch (err) {
      Swal.fire("", "Erro ao checar conta iugu", "warning");
    }

  };

  loadScamberData = () => {
    Swal.fire('Aguarde...');
    Swal.showLoading();
    const scamber = new Scamber();
    scamber.findScamber().then((scamberData) => {

      Swal.close();
      if (scamberData.status === 200) this.props.setScamber(scamberData.data.payload);

    }).catch((error) => Swal.fire({ type: 'info', html: error.response.data.message, title: '' }));
  }

  componentDidMount() {
    this.checkIuguAccount();
  }

  render() {

    let contentType;
    const account_state = this.state.scamber_account;
    const key = Object.keys(account_state)

    switch (account_state[key]) {
      case account_state["validated"]:
        contentType = <AccountStatus
          title="Conta validada!"
          link="#!!" text="Seu cadastro foi atualizado com sucesso."
          icon={<Icon icon={["far", "check"]} />} iconColor="green"
          showArrowIcon={false}
        />;
        break;

      case account_state["waiting"]:
        contentType = <AccountStatus
          title="Aguardando validação"
          link="#!!" text="A análise da sua conta pode levar até 24h para ser concluída."
          icon={<Icon icon={["far", "clock"]} />} iconColor="#e7b78a"
          showArrowIcon={false}
        />;
        break;

      case account_state["notvalidated"]:
        contentType = <AccountStatus
          title="Conta ainda não validada"
          link="/completar-conta" text="Clique aqui para completar os dados da sua conta scamb."
          icon={<Icon icon={["far", "engine-warning"]} />} iconColor="#c54644"
          showArrowIcon={true}
        />;
        break;

      default: contentType = <AccountStatus
        title="Sua conta não foi aprovada"
        link="/completar-conta" text="Verifique novamente os dados inseridos."
        icon={<Icon icon={["far", "engine-warning"]} />} iconColor="#f16339"
        showArrowIcon={true}
      />;
    }

    return (
      <Layout className="_profile-width">
        {this.state.isLoadingPopup ? contentType : ""}
        <div id="my-profile" className="_content">
          <CurrentPage icon={<FontAwesomeIcon icon={faCog} />} title="Meu Perfil" />
          <Helmet title={`${this.props.site_properties?.title} - Meu perfil`} />

          <StoreForm store={this.props.scamber?.minha_loja} cnpj={this.props.scamber?.cnpj ? true : false} reloadScamber={this.loadScamberData} title="Minha Loja"></StoreForm>
          <PersonalForm profile={this.props.scamber} reloadScamber={this.loadScamberData} title="Dados Pessoais"></PersonalForm>
          {/* <AddressForm address={this.props.scamber?.endereco} reloadScamber={this.loadScamberData} title="Endereço"></AddressForm> */}

        </div>
      </Layout >
    );
  }
}

const mapStateToProps = state => {
  return {
    scamber: state.scamberReducer.scamber,
    site_properties: state.websiteReducer.site_properties
  }
}

export default connect(mapStateToProps, { setScamber })(MyProfile);
