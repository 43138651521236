import React from "react";

function OfficialStores (props) {
  
    return (
      <>
        {/* UI CARD */}
        <div className="_ui-card official-stores-item _link">
          {/* WRAPPER */}
          <div className="_ui-item-wrapper">
            <div className="official-stores-item-background" style={{backgroundImage: `url(${props.banner})`}}>
              {props.percentOff && (
              <div className="promotion-percent">

                <span> {props.percentOff} </span>
                  off
                </div>
                )}
            </div>

            <div className="official-stores-item-logo">
              <div className="logo">
                <img src={props.logo} alt="" />
              </div>
              <span className="title-wrapper _color _black _15px _fw700">
                {props.title}
              </span>
            </div>

            <div className="official-stores-item-items">
              {/*  ./components/OficialStoreProducts  */}
              {props.children}
            </div>

          </div>
          {/* END ./ WRAPPER */}
        </div>
        {/* END ./ UI CARD */}
      </>
    );
  
}

export default OfficialStores;
