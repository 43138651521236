//=====================================================================================
// #1 - Import * Styled Components
//=====================================================================================
import styled from 'styled-components';

//===============================================================================================================
// #1 - Import * React
//===============================================================================================================
import { Link } from "react-router-dom";

export const WrapAnchorPicture = styled.div`
    ${props => props.theme.media("xs", `
        height: 154px;
    `)}

    ${props => props.theme.media("sm", `
        height: 168px;
    `)}

    ${props => props.theme.media("md", `
        height: 250px;
    `)}
`;

export const AnchorPicture = styled(Link)`
    display: block;
    height: 100%;
    width: 100%;
    overflow: hidden;
`

export const WrapPicture = styled.div`
    display: block;
    height: 100%;
    width: 100%;
  
    picture {
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border: 0;
            max-width: 100%;
            display: inline-block;
            vertical-align: middle;
    }
    
    }
`

