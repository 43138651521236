//===========================================================
// #1 - Base Imports
//===========================================================
import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
  useCallback,
  useEffect,
} from "react";

import { Link } from "react-router-dom";
import { format } from 'date-fns';
import { connect } from 'react-redux';

//===========================================================
// #2 - Import * Services
//===========================================================
import ListOrders from '../../services/ListOrders';
import ScamberFormaPagamento from "../../services/ScamberFormasPagamento";

//===========================================================
// #2 - Import * Account Layout Base
//===========================================================
import Layout from "../../components/account/Layout";

//===========================================================
// #3 - Import * Sweet Alert
//===========================================================
import Swal from "sweetalert";
import Swal2 from 'sweetalert2';

//===========================================================
// #4 - Import * React Hook Form
//===========================================================
import { useForm } from "react-hook-form";

//===========================================================
// #5 - Import * { Button } Bootstrap
//===========================================================
import { Button } from "react-bootstrap";

//===========================================================
// #6 - Import * UI Components
//===========================================================
import { Price, PricePoints } from "../../components/ui/price";
import {
  Accordion,
  AccordionCard,
  AccordionInside,
  AccordionContent,
} from "../../components/ui/accordion";
import { ListContent, ListItem } from "../../components/ui/list-content";
import { ProductThumbnail } from "../../components/ui/product-thumbnail";
import { ProductThumbnailItems } from "../../components/ui/product-thumbnail";
import { Timeline, Status } from "../../components/ui/order-timeline";
import { Checkbox } from "../../components/ui/checkbox";
import { StatusMessage } from "../../components/ui/status-message";
import { GreenList, GreenListItem } from "../../components/ui/green-list";
import { BoxPrint } from "../../components/ui/box-print";
import { CurrentPage } from "../../components/ui/current-page";
import { AdvertiserCard } from "../../components/ui/advertiser-card";
import { NothingMessage } from "../../components/ui/nothing-message";
import { SelectBox, SelectBoxExpand } from "../../components/ui/select-box";

//===============================================================================================================
// #11 - Credit Card Flags
//===============================================================================================================
import Visa from "../../assets/images/credit-card/visa.png";
import Mastercard from "../../assets/images/credit-card/mastercard.png";
import AmericanExpress from "../../assets/images/credit-card/americanExpress.png";
import DinersClub from "../../assets/images/credit-card/DinersClub.png";
import Pix from "../../assets/images/pix-logo.png";

//=====================================================================================
// #3 - Import * Imput Mask
//=====================================================================================
import InputMask from "react-input-mask";

//=====================================================================================
// #7 - Import * Boostrap Modals
//=====================================================================================
import { Modal } from "react-bootstrap";

//===========================================================
// #7 - Import * FontAwesome Icons
//===========================================================
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import {
  faCreditCardBlank as faCreditCardLt,
  faCalendarStar,
  faCalendarAlt,
  faShoppingCart,
  faLockAlt,
  faUser,
  faUserLock,
  faTruck,
  faThumbsUp,
  faCheck,
  faCommentAltLines,
  faHandHoldingBox,
  faChevronRight,
  faChevronLeft,
  faTimes,
  faBox,
  faBarcodeAlt,
  faHourglass,
  faClock,
  faFileAlt,
  faShippingFast,
  faPrint,
  faPlus,
  faCreditCard
} from "@fortawesome/pro-light-svg-icons";

import { faPlus as faPlusRegular } from "@fortawesome/pro-regular-svg-icons";
import { faBoxCheck, faEye, faTruck as faTruckSolid, faMapMarkerAlt } from "@fortawesome/pro-solid-svg-icons";

//===========================================================
// #8 - Import * Material UI Components
//===========================================================
import {
  Dialog,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  Slide,
  Typography,
  Grid,
  TextField,
  InputAdornment,
  makeStyles,
} from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { useTheme } from "@material-ui/core/styles";
import Helmet from "react-helmet";

//===========================================================
// #9 - Import * Images
//===========================================================
import Avatar from "./../../assets/images/avatar.png";
import CoverIMG from "../../assets/images/cover.png";

import Shipment from './../../services/Shipment';
import Pedido from './../../services/Pedido';
import CancelarPedido from './../../services/CancelarPedido';
import EventEmitter from "./../../services/Events";
import ScamberFormasPagamento from './../../services/ScamberFormasPagamento';
import PedidoShopping from "./../../services/PedidoShopping";
import CancelarPedidoShopping from './../../services/CancelarPedidoShopping';

import formatNumberToBRL from "../../utils/FormatNumberToBRL";


import $ from 'jquery';

//===========================================================
// #10 - Create Material UI Modal Dialog
//===========================================================
const ModalDialog = forwardRef((props, ref) => {
  // * Material UI Styles - useMediaQuery
  const matches = useMediaQuery("(min-width:600px)");

  // # Define States
  const [open, setOpen] = React.useState(false);

  // * Material UI Styles - useTheme
  const theme = useTheme();

  // # Define Material UI Modal Dialog Fullscreen on mobile
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // # Define Functions

  // * Set Material UI Modal Dialog Open
  const handleClickOpen = () => {
    setOpen(true);
  };

  // * Set Material UI Modal Dialog Close
  const handleClose = () => {
    setOpen(false);
  };

  // * Set Function Using useImperativeHandle();
  useImperativeHandle(ref, () => ({
    openModal: () => handleClickOpen(),
    closeModal: () => handleClose(),
  }));

  // * Capture Event onSubmit Form with
  // * React Hook Forms
  const onSubmit = (data) => {
    // * Close Material UI Modal Dialog After Submit
    setOpen(false);

    // * If Submit Success, Show Sweet Alert Dialog
    Swal.fire({
      icon: "success",
      title: "Proposta enviada!",
      text: `Sua proposta foi enviada e, assim que o vendedor responder, mandaremos uma notificação para você!`,
      timer: 6000,
      timerProgressBar: true,
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonText: "fechar",
    }).then((eComplete) => {
      if (eComplete) {
        console.log("ok");
      }
    });
  };

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={true}
        open={open}
        maxWidth={"sm"}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle className="dialog-title" id="responsive-dialog-title">
          <div className="_view-head-content">
            <button type="button" className="btn" onClick={handleClose}>
              <Icon icon={matches ? faTimes : faChevronLeft}></Icon>
            </button>
            <div className="_title-view">{props.dialogTitle}</div>
          </div>
        </DialogTitle>
        <DialogContent className={props.className}>
          <Typography component={"span"} variant={"body2"}>
            <div className="subtitle">{props.subTitle}</div>

            {props.children}
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
});

// * Material UI Dialog Slide Transition Effect
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

//===========================================================
// #11 - Material UI Set Panel
//===========================================================
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
};

//===============================================================================================================
// #14 - Modal - Add Credit Card
//===============================================================================================================
const ModalCreditCard = forwardRef((props, ref) => {
  // Bootstrap Modal
  const [show, setShow] = useState(false);

  // Handle Modal
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useImperativeHandle(ref, () => ({
    openModal: () => handleShow(),
    closeModal: () => handleClose(),
  }));

  return (
    <>
      <Modal show={show} onHide={handleClose} className="full-width CreditCardModal" centered>
        <Modal.Header closeButton>
          <section className="_view-head">
            <div className="_view-head-content">
              <Button className="_back-history btn" onClick={handleClose}>
                <Icon icon={faChevronLeft} />
              </Button>
              <Modal.Title>Adicionar informações</Modal.Title>
            </div>
          </section>
        </Modal.Header>
        <Modal.Body>{props.children}</Modal.Body>
      </Modal>
    </>
  );
});

//===========================================================
// #13 - Services
//===========================================================
const scamberFormaPagamento = new ScamberFormaPagamento();

//===============================================================================================================
// #16 - Default
//===============================================================================================================
const useStyles = makeStyles({
  center: {
    margin: "auto",
  },
  resize: {
    fontSize: "10px",
  },
  inputSize: {
    height: 50,
  },
});

function MyPurchases(props) {
  // * States
  const [tabValue, setTabValue] = useState(0);
  const [orders, setOrders] = useState([]);
  const [buttonState, setButtonState] = useState({ buttonConfirm: true });
  const [show] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState([]);
  const [checked, setChecked] = useState();
  const [returnOrder, setReturnOrder] = useState();
  const [returnOrderShipment, setReturnOrderShipment] = useState(0);
  const [reason, setReason] = useState("");
  const [tagUrls, setTagUrls] = useState([]);
  const [creditCardType, setCreditCardType] = useState(null);
  const [valueField, setValue] = useState("");
  const [cep, setCep] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [cvv, setCvv] = useState("");
  const [valueFieldCpf, setValueCpf] = useState("");
  const [showInvoice, setShowInvoice] = useState(false);

  const listofOrders = new ListOrders();

  // * Material UI Styles - useMediaQuery
  const matches = useMediaQuery("(min-width:600px)");
  const classes = useStyles();

  // * Create React UI Dialog Refs
  const refModalReturnProduct = useRef();
  const refModalPrintLabelSuccess = useRef();
  const refModalReturnProductConfirm = useRef();
  const refModalCancelProduct = useRef();
  const refModalCancelProductImp = useRef();
  const refModalPrintLabel = useRef();
  const refCreditCardModal = useRef();

  const closeModalCreditCard = () => refCreditCardModal.current.closeModal();

  //* React Hook Forms
  const { register, handleSubmit, errors, formState, reset } = useForm({
    mode: "onChange",
  });

  // * Load Scamber Payment Methods
  const loadPaymentForms = () => {
    scamberFormaPagamento
      .find()
      .then((response) => {
        setPaymentMethod(response.data.payload);
      })
      .catch((error) => {
        console.log("ERROR", error)
      });
  };

  let setCheckValue = (index, qtdCheckbox) => {
    if (index === "boleto") {
      let i = 0;

      while (i < qtdCheckbox) {
        document.querySelector(`input[name="${i}check"]`).checked = false;
        i++;
      }

      document.querySelector(`input[name="pix"]`).checked = false;
      document.querySelector(`input[name="boleto"]`).checked = true;
      setChecked("boleto");

    } else if (index === "pix") {
      let i = 0;

      while (i < qtdCheckbox) {
        document.querySelector(`input[name="${i}check"]`).checked = false;
        i++;
      }

      document.querySelector(`input[name="boleto"]`).checked = false;
      document.querySelector(`input[name="pix"]`).checked = true;
      setChecked("pix");


    } else {
      let qtdCheckbox = 100;
      let i = 0;
      while (i < qtdCheckbox) {
        if (document.querySelector(`input[name="${i}check"]`)) {
          document.querySelector(`input[name="${i}check"]`).checked = false;
        }
        i++;
      }

      if (document.querySelector(`input[name="${index}check"]`) != null) {
        document.querySelector(`input[name="${index}check"]`).checked = true;
        setChecked(index);
      }

      document.querySelector(`input[name="boleto"]`).checked = false;
      document.querySelector(`input[name="pix"]`).checked = false;

    }
  };

  useEffect(() => {
    loadPaymentForms();
  }, []);

  const openDialogModal = (refModal) => refModal.current.openModal();
  const closeDialogModal = (refModal) => refModal.current.closeModal();

  // * Capture Event onSubmit Form with
  // * React Hook Forms
  // * Form - Submit Return Product Form
  const onSubmit = (data) => {
    // * Close
    const shipment = new Shipment();

    Swal2.fire('Calculando frete para devolução... Aguarde');
    Swal2.showLoading()
    shipment.calculateReturnOrderShipment(returnOrder._id).then((result) => {
      Swal2.close();

      setReturnOrderShipment(result.data.payload.custom_price);
      setReason(data.reason);

    }).catch((error) => {
      shipment.formatErrorMessage(error.response, 'MPCROS');
    });

    closeDialogModal(refModalReturnProduct);

    setTimeout(() => openDialogModal(refModalReturnProductConfirm), 300);
  };


  // * UI Modal Dialog Confirm Cancel Product
  const handleConfirmCancelProduct = () => {
    Swal({
      title: "Confirmar cancelamento?",
      text: `Ao clicar em confirmar, o sistema irá
        cancelar a sua compra.
        Seus pontos Scamb, taxas e frete serão
        Estornados.`,
      icon: "warning",
      buttons: ["Cancelar", "Confirmar"],
      dangerMode: true,
    }).then(function (isConfirm) {
      if (isConfirm) {
        Swal({
          title: "Tudo pronto!",
          text: `Fique atendo a sua caixa de email.
            Em até 12 horas enviaremos entraremos em
            contato.`,
          icon: "success",
          buttons: ["Ok", false],
          dangerMode: true,
        }).then(function (isConfirm) {
          if (!isConfirm) {
            setTimeout(() => closeDialogModal(refModalCancelProductImp), 500);
          }
        });
      } else {
        Swal.close();
      }
    });
  };

  // * UI Modal Dialog Close Confirm Return Product
  const handleCloseModalReturnProductConfirm = () => {
    closeDialogModal(refModalReturnProductConfirm);

    setButtonState({ buttonCancelSale: true, buttonReturnProduct: false });
  };

  // * UI Modal Dialog Close Return Product
  const handleCloseModalReturnProduct = () => {
    closeDialogModal(refModalPrintLabelSuccess);

    setButtonState({ buttonCancelSale: true, buttonReturnProduct: false });
  };

  // * Swal Confirm Product Receipt
  const handleProductReceipt = (order) => {
    Swal2.fire({
      title: "Confirmar recebimento?",
      html: `<p>Ao clicar em confirmar, você afirma que
        recebeu o produto. Caso desejar você pode <a href="#!" id="devolver_produto">Devolver o produto</a></p>`,
      icon: "warning",
      showConfirmButton: true,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
      //buttons: ["Cancelar", "Confirmar"],
      //dangerMode: true,
    }).then(function (actions) {
      if (actions.isConfirmed) {
        finishOrder(order._id, order.tipo_pedido);
        /*
            - Estado inicial de {buttonState.buttonConfirm} é {true}.
            - Caso o usuário confirme que recebeu o produto o estado de
            - {buttonState.buttonConfirm} é marcado como {false} e esconde o
            - componente <ListItem />
          */
        setButtonState({ buttonConfirm: false });
      } else {
        Swal2.close();
      }
    });

    $('#devolver_produto').on('click', () => {

      const pedido = new Pedido();
      const pedidoShopping = new PedidoShopping();

      Swal2.fire('Finalizando o pedido... Aguarde');
      Swal2.showLoading();

      if(!order.tipo_pedido || order.tipo_pedido === 'scamb') {
        pedido.finishOrder(order._id).then(() => {
          Swal2.close();
          setReturnOrder(order); openDialogModal(refModalReturnProduct)
        }).catch((error) => {
          pedido.formatErrorMessage(error.response, 'MPFIDOR')
        })
      } else if(order.tipo_pedido === 'shopping') {
        pedidoShopping.finishOrderShopping(order._id).then(() => {
          Swal2.close();
          setReturnOrder(order); openDialogModal(refModalReturnProduct)
        }).catch((error) => {
          pedido.formatErrorMessage(error.response, 'MPFIDOR')
        })
      }
     

    });


  };

  // * Material UI Tabs
  // * Props Tab
  function tabProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  // * Material UI Tabs
  // * On Change Tab
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleImagens = (itens) => {
    const arrayImages = [];

    itens.map(item => {
      return arrayImages.push(listofOrders.loadImage(item.fotos[0]))
    });

    return arrayImages;
  }

  const handleTitles = (itens) => {
    const allTitles = [];

    itens.map(iten => {
      allTitles.push(iten.titulo);
    });

    return allTitles.join(' / ');
  }

  const handleAdress = (adress) => {
    if(adress) {
      const adrress = `${adress.logradouro}, Nº ${adress.numero}, Bairro: 
      ${adress.bairro}, 
      ${adress.complemento === null || adress.complemento === undefined
          ? '' : `Complemento: ${adress?.complemento}, `}  
      ${adress.cidade}/${adress.estado}
      , CEP: ${adress.cep}`;
  
      return adrress;
    }
  }

  const getOrders = useCallback(async () => {
    await listofOrders.listShoppingOrders().then((response) => {
      setOrders(response.data.payload);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getOrders()
  }, [getOrders])

  const checkStatus = (historico_status_pedido, status_pedido, data_alteracao, callback) => {

    const status = {}

    if (historico_status_pedido) {
      for (let count = 0; count < historico_status_pedido.length; count++) {
        const order_status = historico_status_pedido[count];

        if (order_status.status_pedido) {
          if (!status[order_status.status_pedido.toLowerCase()]) {
            status[order_status.status_pedido.toLowerCase()] = { data: getFormattedDate(order_status.data_inclusao), hora: getFormattedHour(order_status.data_inclusao) }
          }
        }

      }
    } else {
      if (status_pedido) {
        status[status_pedido.toLowerCase()] = { data: getFormattedDate(data_alteracao), hora: getFormattedHour(data_alteracao) }
      }
    }

    return callback(status);
  }

  const getFormattedDate = (dateTime) => {
    if(!dateTime) return '';

    const data = dateTime.split('T')[0];

    var dia = data.split("-")[2];
    var mes = data.split("-")[1];
    var ano = data.split("-")[0];

    return ("0" + dia).slice(-2) + '/' + ("0" + mes).slice(-2) + '/' + ano;
  }

  const getFormattedHour = (dateTime) => {
    if(!dateTime) return '';
    return dateTime.split('T')[1].split('.')[0];
  }

  const updateTrackingReturnOrderStatus = (orderID, index, type) => {
    const shipment = new Shipment();

    Swal2.fire('Verificando status do pedido... Aguarde');
    Swal2.showLoading()

    if(!type || type === 'scamb') {

      shipment.updateReturnOrderTrackingStatus(orderID).then((result) => {
        Swal2.close();
  
        let ordersUpdated = [...orders];
        let orderChanged = { ...orders[index] };
  
        orderChanged.historico_status_pedido = result.data.payload.historico_status_pedido;
        orderChanged.status_pedido = result.data.payload.status_pedido;
  
        ordersUpdated[index] = orderChanged;
  
        setOrders(ordersUpdated);
  
      }).catch((error) => {
        shipment.formatErrorMessage(error.response, 'MPTRUP')
      });

    } else if(type === 'shopping') {

      shipment.updateReturnOrderTrackingStatusShopping(orderID).then((result) => {
        Swal2.close();
  
        let ordersUpdated = [...orders];
        let orderChanged = { ...orders[index] };
  
        orderChanged.historico_status_pedido = result.data.payload.historico_status_pedido;
        orderChanged.status_pedido = result.data.payload.status_pedido;
  
        ordersUpdated[index] = orderChanged;
  
        setOrders(ordersUpdated);
  
      }).catch((error) => {
        shipment.formatErrorMessage(error.response, 'MPTRUPS')
      });

    }   

  }

  const updateTrackingStatus = (orderID, index) => {
    const shipment = new Shipment();

    Swal2.fire('Verificando status do pedido... Aguarde');
    Swal2.showLoading()
    shipment.updateTrackingStatus(orderID).then((result) => {
      Swal2.close();

      let ordersUpdated = [...orders];
      let orderChanged = { ...orders[index] };

      orderChanged.historico_status_pedido = result.data.payload.historico_status_pedido;
      orderChanged.status_pedido = result.data.payload.status_pedido;

      ordersUpdated[index] = orderChanged;

      setOrders(ordersUpdated);

    }).catch((error) => {
      shipment.formatErrorMessage(error.response, 'MPTRUP')
    });

  }

  const finishOrder = (orderID, orderType) => {
    const pedido = new Pedido();
    const pedidoShopping = new PedidoShopping();

    Swal2.fire('Finalizando o pedido... Aguarde');
    Swal2.showLoading()


    if(!orderType || orderType === 'scamb') {

      pedido.finishOrder(orderID).then(() => {

        getOrders();
        Swal2.close();
  
        Swal({
          title: "Tudo pronto!",
          text: `Você confirmou que recebeu o
            produto.`,
          icon: "success",
          buttons: ["Ok", false],
          dangerMode: true,
        });
  
      }).catch((error) => {
        pedido.formatErrorMessage(error.response, 'MPFIOR')
      })

    } else if(orderType === 'shopping') {

      pedidoShopping.finishOrderShopping(orderID).then(() => {

        getOrders();
        Swal2.close();
  
        Swal({
          title: "Tudo pronto!",
          text: `Você confirmou que recebeu o
            produto.`,
          icon: "success",
          buttons: ["Ok", false],
          dangerMode: true,
        });
  
      }).catch((error) => {
        pedido.formatErrorMessage(error.response, 'MPFIOR')
      })

    }

  }

  const handleCancelOrder = (orderID, type) => {
    const cancelarPedido = new CancelarPedido();
    const cancelarPedidoShopping = new CancelarPedidoShopping();

    Swal({
      title: "Confirma o cancelamento??",
      icon: "warning",
      buttons: ["Não", "Sim"],
      dangerMode: true,
    }).then((isConfirm) => {
      if (isConfirm) {

        Swal2.fire('Efetuando cancelamento... Aguarde');
        Swal2.showLoading();

        if(!type || type === 'scamb' ) {
          
          cancelarPedido.reversalOrder(orderID).then((response) => {
            Swal2.close();
            Swal({
              title: "Seu pedido foi cancelado.",
              text: `A devolução dos pontos, taxa e frete será realizada automaticamente.\n
              Para mais detalhes, verifique o email que te enviamos agora.`,
              icon: "success",
            }).then(() => {
              listofOrders.listShoppingOrders().then((response) => {
                setOrders(response.data.payload);
              });
              EventEmitter.dispatch("balanceChange");
            })
          }).catch(err => {
            Swal2.close();
            cancelarPedido.formatErrorMessage(err.response, 'MPCAPE');
          });

        } else if(type === 'shopping') {

          cancelarPedidoShopping.reversalOrderShopping(orderID).then((response) => {
            Swal2.close();
            Swal({
              title: "Seu pedido foi cancelado.",
              text: `O estorno do valor e frete será realizado automaticamente.\n
              Para mais detalhes, verifique o email que te enviamos agora.`,
              icon: "success",
            }).then(() => {
              listofOrders.listShoppingOrders().then((response) => {
                setOrders(response.data.payload);
              });
              EventEmitter.dispatch("balanceChange");
            })
          }).catch(err => {
            Swal2.close();
            cancelarPedido.formatErrorMessage(err.response, 'MPCAPES');
          });          

        }

      
      } else {
        Swal.close();
      }
    });
  };

  // * Capture Event onSubmit Form with
  // * React Hook Forms
  // * Form - Submit Cancel Product Form
  const onSubmitCancelProduct = (data) => {
    closeDialogModal(refModalCancelProduct);
    setTimeout(() => openDialogModal(refModalCancelProductImp), 300);
  };

  let onSubmitReturnOrder = (e) => {
    e.preventDefault();

    let paymentMethod;
    let cardID;

    if (checked === 'boleto') {
      paymentMethod = 'bank_slip';
    } else if (checked === 'pix') {
      paymentMethod = 'pix';
    } else if(checked !== null) {
      cardID = document.querySelector(`input[name="${checked}check"]`)?.id;
      paymentMethod = 'card';
    }

    if (!paymentMethod)
      return Swal.fire({
        icon: "info",
        html: "Você precisa selecionar uma forma de pagamento!",
        title: "",
      });

    Swal2.fire("Aguarde... efetuando solicitação de devolução");
    Swal2.showLoading();

    const paymentInfos = { cardId: cardID, paymentMethod, reason };

    const pedido = new Pedido();

    if(!returnOrder.tipo_pedido || returnOrder.tipo_pedido === 'scamb') {

      pedido.doOrderReturn(returnOrder._id, paymentInfos).then((response) => {

        Swal2.fire({
          html: "",
          icon: "success",
          title: "Sua solicitação de devolução do pedido , foi efetuada com sucesso.",
        }).then(() => {
  
          if (paymentMethod !== 'card')
            window.open(response.data.payload.devolucao.gateway_infos.url_fatura, "_blank");
  
          listofOrders.listShoppingOrders().then((response) => {
            setOrders(response.data.payload);
          });
  
          handleCloseModalReturnProductConfirm();
  
        });
  
      }).catch((error) => { console.log(error.message, 'msg'); pedido.formatErrorMessage(error.response, 'MPDEOR') })

    } else if(returnOrder.tipo_pedido === 'shopping') {

      pedido.doOrderReturnShopping(returnOrder._id, paymentInfos).then((response) => {

        Swal2.fire({
          html: "",
          icon: "success",
          title: "Sua solicitação de devolução do pedido , foi efetuada com sucesso.",
        }).then(() => {
  
          if (paymentMethod !== 'card')
            window.open(response.data.payload.devolucao.gateway_infos.url_fatura, "_blank");
  
          listofOrders.listShoppingOrders().then((response) => {
            setOrders(response.data.payload);
          });
  
          handleCloseModalReturnProductConfirm();
  
        });
  
      }).catch((error) => { console.log(error.message, 'msg'); pedido.formatErrorMessage(error.response, 'MPDEORS') })

    }

    
  };

  const generateCompleteReturnOrderTag = (orderID, type) => {
    const shipment = new Shipment();

    Swal2.fire('Gerando etiqueta de devolução... Aguarde');
    Swal2.showLoading()

    if(!type || type === 'scamb') {

      shipment.generateCompleteReturnOrderTag(orderID).then((result) => {

        Swal2.close();
        let listTagUrls = [result.data.payload];
        setTagUrls(listTagUrls);
  
        listofOrders.listShoppingOrders().then((response) => {
          setOrders(response.data.payload);
        });
  
        closeDialogModal(refModalPrintLabel);
        refModalPrintLabelSuccess.current.openModal();
  
      }).catch((error) => {
        shipment.formatErrorMessage(error.response, 'MSGETAD')
      });

    } else if(type === 'shopping') {

      shipment.generateCompleteReturnOrderTagShopping(orderID).then((result) => {

        Swal2.close();
        let listTagUrls = [result.data.payload];
        setTagUrls(listTagUrls);
  
        listofOrders.listShoppingOrders().then((response) => {
          setOrders(response.data.payload);
        });
  
        closeDialogModal(refModalPrintLabel);
        refModalPrintLabelSuccess.current.openModal();
  
      }).catch((error) => {
        shipment.formatErrorMessage(error.response, 'MSGETADS')
      });

    }

    

  }

  const printTag = (orderID, type) => {
    const shipment = new Shipment();

    Swal2.fire('Localizando etiqueta de devolução... Aguarde');
    Swal2.showLoading()

    if(!type || type === 'scamb') {

      shipment.printReturnOrderTag(orderID).then((result) => {

        Swal2.close();
        let listTagUrls = [result.data.payload];
  
        setTagUrls(listTagUrls);
  
        refModalPrintLabelSuccess.current.openModal();
  
      }).catch((error) => {
  
        listofOrders.listShoppingOrders().then((response) => {
          setOrders(response.data.payload);
        });
  
        shipment.formatErrorMessage(error.response, 'MSPETAD')
      });

    } else if(type === 'shopping') {

      shipment.printReturnOrderTagShopping(orderID).then((result) => {

        Swal2.close();
        let listTagUrls = [result.data.payload];
  
        setTagUrls(listTagUrls);
  
        refModalPrintLabelSuccess.current.openModal();
  
      }).catch((error) => {
  
        listofOrders.listShoppingOrders().then((response) => {
          setOrders(response.data.payload);
        });
  
        shipment.formatErrorMessage(error.response, 'MSPETAD')
      });

    }

   

  }

  // * Check Credit Card Flag Type
  let checkCreditCardType = (e) => {
    let cardnumber = e.target.value.replace(" ", "").replace(/[^0-9]+/g, "");
    let emptyFields = 16 - cardnumber.length;
    let zeros = "";
    for (let count = 0; count < emptyFields; count++) {
      zeros += "0";
    }

    var cards = {
      visa: /^4[0-9]{12}(?:[0-9]{3})/,
      mastercard: /^5[1-5][0-9]{14}/,
      DinersClub: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
      americanExpress: /^3[47][0-9]{13}/,
    };

    for (var flag in cards) {
      if (cards[flag].test(parseInt(cardnumber + zeros))) {
        return setCreditCardType(flag);
      }
    }

    return setCreditCardType(null);
  };

  const onSubmitCreditCard = (creditCard, e) => {
    let formattedObject = {
      token_cartao: "",
      cardInfos: {
        bandeira: creditCardType ? creditCardType : "none",
        numero_cartao: creditCard.numero_cartao,
        nome: creditCard.nome,
        mes_expiracao: creditCard.mes_expiracao,
        ano_expiracao: creditCard.ano_expiracao,
        cvv: creditCard.cvv,
        id_endereco: props.scamber?.endereco[0]._id,
        cpf: creditCard.numero_cpf,
      },
    };

    scamberFormaPagamento
      .createPaymentForm(formattedObject)
      .then((response) => {

        // * Clear Form
        e.target.reset();
        setValue("");
        setMonth("");
        setYear("");
        setCvv("");
        setValueCpf("");

        Swal.fire({
          icon: "success",
          title: "Cartão de crédito adicionado!",
          timer: 4000,
          timerProgressBar: true,
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonText: "Ok",
        }).then((eComplete) => {
          if (eComplete) {
            closeModalCreditCard();
            loadPaymentForms();

          }
        });
      })
      .catch((error) => {
        scamberFormaPagamento.formatErrorMessage(error.response, 'BPPFCR');
      });



  };

  // * if exists address
  // const checkAdress = () => {
  //   if (props.scamber?.endereco.length) {
  //     refCreditCardModal.current.openModal();
  //   } else {
  //     refAddressModal.current.openModal();
  //   }

  // };

  const checkDevolutionDate = (order) => {
    let dataVerificacao;
    if(order.historico_status_pedido && order.historico_status_pedido.length > 0) {
      const ultimoStatus = order.historico_status_pedido[order.historico_status_pedido.length -1];
      dataVerificacao = ultimoStatus.data_inclusao;
    } else {
      dataVerificacao = order.data_alteracao;
    }
    
    var dataAtual = new Date();
    
    var dataFinalizacaoSeteDias = new Date(dataVerificacao);
    dataFinalizacaoSeteDias.setDate(dataFinalizacaoSeteDias.getDate() + 7);

    if(dataAtual > dataFinalizacaoSeteDias) {
      return false
    }

    return true;
  }

  const checkInvoice = (order) => {
    const formasPagamento = new ScamberFormasPagamento();
    
    formasPagamento.find().then(response => {
      const scamberFormaPagamento = response.data.payload;
      const paymentWithCard = scamberFormaPagamento[0].cartoes.find(cartao => cartao._id === order.forma_pagamento);
      
      if(!paymentWithCard) {
        return setShowInvoice(true)
      } 
    }).catch(() => { return null });

    if(showInvoice) {
      console.log(order.gateway_infos?.url_fatura);
      return (
        <button type="button" className="small-button" onClick={() => {window.open(order.gateway_infos?.url_fatura, "_blank")}}>
          Visualizar Fatura
          <Icon icon={faEye} />
        </button>
      )
    } else return null
    
  }

  console.log("Orders: ", orders);

  return (
    <>
      <Helmet title={`${props.site_properties?.title} - Minhas compras`} />

      <ModalDialog
        ref={refModalCancelProductImp}
        dialogTitle="Cancelar produto"
        subTitle="Antes de cancelar a compra observe:"
        className="_modal-action-product"
      >
        <div className="_body-modal">
          <GreenList>
            <GreenListItem title="Todos os seus pontos serão estornados para sua carteira Scamb."></GreenListItem>
            <GreenListItem title="Valor do frete e da taxa Scamb serão estornados para.."></GreenListItem>
          </GreenList>

          <BoxPrint
            type="info"
            icon={<Icon icon={faBox} />}
            title="Clicando no botão confirmar, sua solicitação será
                    processada e em até 12 horas enviaremos um
                    e-mail confirmando o cancelamento da compra."
          >
            <Button
              className="btn-submit"
              color="primary"
              autoFocus
              style={{ height: matches ? 45 : 55 }}
              type="button"
              onClick={handleConfirmCancelProduct}
            >
              Confirmar
              <Icon icon={faCheck}></Icon>
            </Button>
          </BoxPrint>
        </div>
      </ModalDialog>

      <ModalDialog
        ref={refModalCancelProduct}
        dialogTitle="Cancelar produto"
        subTitle="Selecione o motivo do cancelamento da compra abaixo."
        className="_modal-action-product"
      >
        <form
          className="_form-modal-product"
          onSubmit={handleSubmit(onSubmitCancelProduct)}
        >
          <Checkbox title="Recebi o produto com um problema" alignCenter={true}>
            <input
              type="radio"
              id="reason_1"
              name="reason"
              ref={register({ required: true })}
            />
          </Checkbox>

          <Checkbox title="Recebi o pacote sem o produto" alignCenter={true}>
            <input
              type="radio"
              id="reason_2"
              name="reason"
              ref={register({ required: true })}
            />
          </Checkbox>

          <Checkbox title="O pacote nunca chegou" alignCenter={true}>
            <input
              type="radio"
              id="reason_3"
              name="reason"
              ref={register({ required: true })}
            />
          </Checkbox>

          <Checkbox
            title="Tenho um problema com o pagamento"
            alignCenter={true}
          >
            <input
              type="radio"
              id="reason_4"
              name="reason"
              ref={register({ required: true })}
            />
          </Checkbox>

          {errors.reason && (
            <StatusMessage
              type="error"
              messageText="Você deve selecionar um dos tópicos acima antes de prosseguir."
            />
          )}

          <Button
            className="btn-submit"
            color="primary"
            autoFocus
            style={{ height: matches ? 35 : 40, justifyContent: `center` }}
            type="submit"
            disabled={
              !formState.dirty || (formState.dirty && !formState.isValid)
            }
          >
            Continuar
            <Icon icon={faChevronRight}></Icon>
          </Button>
        </form>
      </ModalDialog>

      <ModalDialog
        ref={refModalReturnProductConfirm}
        dialogTitle="Devolver produto"
        subTitle="Antes de avançar com a devolução, observe:"
        className="_modal-action-product product-return"
      >
        <div className="_body-modal">
          <GreenList>
            <GreenListItem title="Você tem até 7 dias corridos a partir do recebimento do produto para solicitar a devolução."></GreenListItem>
            <GreenListItem title="Após solicitar a devolução, vc tem até 3 dias para enviar o produto."></GreenListItem>
            <GreenListItem title="Em casos de devolução, tanto o valor da taxa Scamb quanto o valor do frete não são devolvidos."></GreenListItem>
            <GreenListItem title="O valor do frete da devolução é por sua conta."></GreenListItem>
            <li class="green-list-item _color _gray _font _comfortaa _fw700 _13px">Mais detalhes nos <Link to="/termos-e-condicoes" className="_color _nude" style={{ marginLeft: "5px" }} > {" "} Termos de Uso</Link>.</li>
          </GreenList>

          <section className="_block">
            <h6 className="_block-title">
              Resumo
            </h6>

            <ProductThumbnailItems
              images={
                returnOrder?.itens?.map((item) => item.fotos[0] ? listofOrders.loadImage(item.fotos[0]) : CoverIMG)
              }
            />

            <ListContent bgColor="#FFFFFF">
              <ListItem title="Frete">
                <Price value={returnOrderShipment} />
              </ListItem>
            </ListContent>

          </section>

          <section className="_block">
            <h6 className="_block-title">
              Formas de pagamento
            </h6>

            {paymentMethod[0]?.cartoes?.length > 0 ? (
              paymentMethod[0]?.cartoes.map((item, index) => (
                <SelectBox
                  key={index}
                  icon={
                    <img
                      src={
                        item.bandeira === "visa"
                          ? Visa
                          : item.bandeira === "mastercard"
                            ? Mastercard
                            : item.bandeira === "diners" ||
                              item.bandeira === "DinersClub"
                              ? DinersClub
                              : item.bandeira === "amex" ||
                                item.bandeira === "americanExpress"
                                ? AmericanExpress
                                : Visa
                      }
                      alt={item.bandeira}
                    />
                  }
                  input={
                    <input
                      id={item._id}
                      type="radio"
                      name={index + "check"}
                      onClick={(e) =>
                        setCheckValue(
                          index,
                          paymentMethod[0]?.cartoes.length
                        )
                      }
                    />
                  }
                  titleContent={
                    <ListContent>
                      <ListItem
                        title={
                          item.bandeira === "visa"
                            ? "Visa"
                            : item.bandeira === "mastercard"
                              ? "Mastercard"
                              : item.bandeira === "diners" ||
                                item.bandeira === "DinersClub"
                                ? "DinersClub"
                                : item.bandeira === "amex" ||
                                  item.bandeira === "American Express"
                                  ? "americanexpress"
                                  : "visa"
                        }
                      >
                        {item.numero_cartao}
                      </ListItem>
                    </ListContent>
                  }
                />
              ))
            ) : (
              <>
                <SelectBox
                  onClick={() => window.location.href = "/metodos-de-pagamento"}
                  className="active _new-cc"
                  icon={
                    <>
                      <Icon icon={faCreditCard} />
                      <div className="plus-add">
                        <Icon icon={faPlusRegular} />
                      </div>
                    </>
                  }
                  input={
                    <input
                      type="radio"
                      name={"check"}
                    />
                  }
                  titleContent={"Cartão de crédito"}
                />
              </>
            )}

            <SelectBox
              icon={<img src={Pix} />}
              input={
                <input
                  id="pix"
                  type="radio"
                  onClick={(e) => setCheckValue("pix", paymentMethod[0]?.cartoes.length)}
                  name="pix"
                />
              }
              titleContent={
                <ListContent>
                  <ListItem> Pix </ListItem>
                </ListContent>
              }
            />

            <SelectBox
              icon={<Icon icon={faBarcodeAlt} />}
              input={
                <input
                  id="boleto"
                  type="radio"
                  onClick={(e) => setCheckValue("boleto", paymentMethod[0]?.cartoes.length)}
                  name="boleto"
                />
              }
              titleContent={
                <ListContent>
                  <ListItem> Boleto bancário </ListItem>
                </ListContent>
              }
            />

          </section>

        </div>

        <Button
          className="btn-submit"
          color="primary"
          autoFocus
          style={{ height: matches ? 35 : 40, justifyContent: `center` }}
          type="submit"
          onClick={(e) => onSubmitReturnOrder(e)}
        >
          Finalizar
            <Icon icon={faChevronRight}></Icon>
        </Button>
      </ModalDialog>

      <ModalDialog
        ref={refModalPrintLabel}
        dialogTitle="Gerar etiqueta"
        subTitle="Faça a emissão da etiqueta"
        className="_modal-action-product"
      >
        <div className="_body-modal">
          <BoxPrint
            type="info"
            icon={<Icon icon={faBox} />}
            title="Clique no botão abaixo para realizar
                    a emissão da etiqueta."
          >
            <Button
              className="btn-submit"
              color="primary"
              autoFocus
              style={{ height: matches ? 45 : 55 }}
              type="button"
              onClick={() => generateCompleteReturnOrderTag(returnOrder._id, returnOrder.tipo_pedido)}
            >
              Emitir etiqueta
              <Icon icon={faFileAlt}></Icon>
            </Button>
          </BoxPrint>
        </div>

      </ModalDialog>

      <ModalDialog
        ref={refModalPrintLabelSuccess}
        dialogTitle="Aqui está sua etiqueta do pedido!"
        subTitle="Etiqueta de devolução do pedido! Agora basta imprimir e enviar :)."
        className="_modal-action-product"
      >
        <div className="_body-modal">

          <BoxPrint
            type="success"
            icon={<Icon icon={faBoxCheck} />}
            title="Você pode visualizar e imprimir sua etiqueta clicando no botão imprimir logo abaixo."
          >
          </BoxPrint>
        </div>

        {tagUrls && tagUrls.length > 0 ? tagUrls.map(url =>
          <>
            <p style={{ textAlign: 'center', paddingTop: '20px' }}>Caso você não consiga visualizar a etiqueta abaixo <a target="_blank" href={url}>clique aqui.</a></p>
            <div key={url} className="print-preview-iframe">
              <iframe src={url} frameBorder="0"></iframe>
            </div>
          </>
        ) : <></>
        }

        <Button
          className="btn-submit"
          color="primary"
          autoFocus
          style={{ height: matches ? 35 : 40, justifyContent: `center` }}
          type="submit"
          onClick={handleCloseModalReturnProduct}
        >
          Fechar
          </Button>
      </ModalDialog>

      <ModalDialog
        ref={refModalReturnProduct}
        dialogTitle="Devolver produto"
        subTitle="O que aconteceu com sua compra?"
        className="_modal-action-product"
      >
        <form className="_form-modal-product" onSubmit={handleSubmit(onSubmit)}>
          <Checkbox title="O produto não serviu." alignCenter={true}>
            <input
              type="radio"
              id="reason_1"
              name="reason"
              value="O produto não serviu."
              ref={register({ required: true })}
            />
          </Checkbox>

          <Checkbox title="Não gostou da cor ou modelo." alignCenter={true}>
            <input
              type="radio"
              id="reason_2"
              name="reason"
              value="Não gostou da cor ou modelo."
              ref={register({ required: true })}
            />
          </Checkbox>

          <Checkbox title="Comprou o produto errado." alignCenter={true}>
            <input
              type="radio"
              id="reason_3"
              name="reason"
              value="Comprou o produto errado."
              ref={register({ required: true })}
            />
          </Checkbox>

          <Checkbox title="O produto apresenta algum defeito." alignCenter={true}>
            <input
              type="radio"
              id="reason_4"
              name="reason"
              value="O produto apresenta algum defeito."
              ref={register({ required: true })}
            />
          </Checkbox>

          <Checkbox title="Arrependeu-se da compra." alignCenter={true}>
            <input
              type="radio"
              id="reason_5"
              name="reason"
              value="Arrependeu-se da compra."
              ref={register({ required: true })}
            />
          </Checkbox>

          <Checkbox title="O produto está com defeito." alignCenter={true}>
            <input
              type="radio"
              id="reason_6"
              name="reason"
              value="O produto está com defeito."
              ref={register({ required: true })}
            />
          </Checkbox>

          <Checkbox title="O produto não veio como descrito no anúncio." alignCenter={true}>
            <input
              type="radio"
              id="reason_7"
              name="reason"
              value="O produto não veio como descrito no anúncio."
              ref={register({ required: true })}
            />
          </Checkbox>

          <Checkbox title="O produto foi entregue em desacordo com o pedido." alignCenter={true}>
            <input
              type="radio"
              id="reason_8"
              name="reason"
              value="O produto foi entregue em desacordo com o pedido."
              ref={register({ required: true })}
            />
          </Checkbox>

          <Checkbox title="O produto demorou demais para chegar e já não precisa mais dele." alignCenter={true}>
            <input
              type="radio"
              id="reason_9"
              name="reason"
              value="O produto demorou demais para chegar e já não precisa mais dele."
              ref={register({ required: true })}
            />
          </Checkbox>

          {errors.reason && (
            <StatusMessage
              type="error"
              messageText="Você deve selecionar um dos tópicos acima antes de prosseguir."
            />
          )}

          <Button
            className="btn-submit"
            color="primary"
            autoFocus
            style={{ height: matches ? 35 : 40, justifyContent: `center` }}
            type="submit"
            disabled={
              !formState.dirty || (formState.dirty && !formState.isValid)
            }
          >
            Continuar
            <Icon icon={faChevronRight}></Icon>
          </Button>
        </form>
      </ModalDialog>

      <ModalCreditCard ref={refCreditCardModal} onClick={() => { reset({}); refCreditCardModal.current.closeModal() }}>
        <form onSubmit={handleSubmit(onSubmitCreditCard)}>
          <h1 className="_content-title">
            Não faremos nenhuma cobrança automática em seu cartão. Ele só será
            utilizado com sua autorização.
          </h1>
          <ul className="_payment-methods-list">
            <li>
              <div className="creadit-card--flag">
                <img src={Visa} alt="" />
              </div>
            </li>
            <li>
              <div className="creadit-card--flag">
                <img src={Mastercard} alt="" />
              </div>
            </li>
            <li>
              <div className="creadit-card--flag">
                <img src={AmericanExpress} alt="" />
              </div>
            </li>
            <li>
              <div className="creadit-card--flag">
                <img src={DinersClub} alt="" />
              </div>
            </li>
          </ul>

          {/*  Form Body */}
          <section className="_form-body _credit-card-form">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputMask
                  mask="9999 9999 9999 9999"
                  defaultValue={valueField}
                  onChange={(e) => setValue(e.target.value)}
                >
                  <TextField
                    id="outlined-cc-number"
                    label="Número do cartão *"
                    type="text"
                    defaultValue={valueField}
                    variant="outlined"
                    name="numero_cartao"
                    onInput={checkCreditCardType}
                    onPaste={checkCreditCardType}
                    size={matches ? "small" : "small"}
                    error={!!errors.numero_cartao}
                    fullWidth
                    inputRef={register({
                      required: true,
                      minLength: 8,
                    })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {creditCardType ? (
                            <div className="creadit-card--flag">
                              <img
                                style={{ width: "15.75px" }}
                                src={require(`../../assets/images/credit-card/${creditCardType}.png`)}
                                alt="Flag credit card"
                              />
                            </div>
                          ) : (
                            <Icon icon={faCreditCardLt} />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </InputMask>
              </Grid>

              <Grid item xs={4}>
                <InputMask
                  mask="99"
                  defaultValue={month}
                  onChange={(e) => setMonth(e.target.value)}
                >
                  <TextField
                    id="outlined-cc-month"
                    label="Mês *"
                    type="text"
                    variant="outlined"
                    name="mes_expiracao"
                    size={matches ? "small" : "small"}
                    error={!!errors.mes_expiracao}
                    fullWidth
                    inputRef={register({
                      required: true,
                    })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Icon icon={faCalendarStar} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </InputMask>
              </Grid>
              <Grid item xs={4}>
                <InputMask
                  mask="9999"
                  defaultValue={year}
                  onChange={(e) => setYear(e.target.value)}
                >
                  <TextField
                    id="outlined-cc-year"
                    label="Ano *"
                    type="text"
                    variant="outlined"
                    name="ano_expiracao"
                    size={matches ? "small" : "small"}
                    error={!!errors.ano_expiracao}
                    fullWidth
                    inputRef={register({
                      required: true,
                    })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Icon icon={faCalendarAlt} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </InputMask>
              </Grid>
              <Grid item xs={4}>
                <InputMask
                  mask="999"
                  defaultValue={cvv}
                  onChange={(e) => setCvv(e.target.value)}
                >
                  <TextField
                    id="outlined-cc-number"
                    label="Cvv *"
                    type="text"
                    variant="outlined"
                    name="cvv"
                    size={matches ? "small" : "small"}
                    error={!!errors.cvv}
                    fullWidth
                    inputRef={register({
                      required: true,
                      minLength: 3,
                      maxLength: 4,
                    })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Icon icon={faLockAlt} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </InputMask>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-cc-name"
                  label="Nome do titular cartão *"
                  type="text"
                  variant="outlined"
                  name="nome"
                  size={matches ? "small" : "small"}
                  error={!!errors.nome}
                  fullWidth
                  inputRef={register({
                    required: true,
                    minLength: 4,
                  })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon icon={faUser} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <InputMask
                  mask="999.999.999-99"
                  defaultValue={valueFieldCpf}
                  onChange={(e) => setValueCpf(e.target.value)}
                >
                  <TextField
                    id="outlined-cc-number"
                    label="CPF *"
                    type="text"
                    defaultValue={valueFieldCpf}
                    variant="outlined"
                    name="numero_cpf"
                    size={matches ? "small" : "small"}
                    error={!!errors.numero_cpf}
                    fullWidth
                    inputRef={register({
                      required: true,
                      minLength: 8,
                    })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Icon icon={faUserLock} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </InputMask>
              </Grid>
            </Grid>

            <div className="_set-user-location">
              <div className="_wrap-location">
                <div className="_icon">
                  <Icon icon={faMapMarkerAlt} />
                </div>

                <div className="_wrap-current-location">
                  <div className="_location-title">
                    {props.scamber?.endereco ? (
                      props.scamber.endereco.map((item, index) => (
                        <div key={index} className="_location">
                          {item.principal &&
                            "CEP: " +
                            item.cep +
                            " " +
                            item.logradouro +
                            ", n" +
                            item.numero +
                            " - " +
                            item.bairro +
                            ", " +
                            item.cidade +
                            " - " +
                            item.estado}
                        </div>
                      ))
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*  END ./ Form Body */}

          <Button
            type="submit"
            className={
              "btn btn-primary btn-fluid btn-submit-modal " + classes.center
            }
            color="primary"
            autoFocus
            style={{ height: matches ? 35 : 40 }}
            disabled={
              !formState.dirty || (formState.dirty && !formState.isValid)
            }
          >
            Confirmar
            <Icon icon={faCheck} />
          </Button>
        </form>
      </ModalCreditCard>

      <Layout>
        <div id="my-purchases" className="_content">
          <div className="_wrap-title-and-tabs">
            <CurrentPage
              icon={<Icon icon={faShoppingCart} />}
              title="Minhas Compras"
            />

            <Tabs
              value={tabValue}
              onChange={handleChange}
              aria-label="Tabs My Purchases"
              className="_material-tabs"
            >
              <Tab label="Em aberto" {...tabProps(0)} />
              <Tab label="Devolução" {...tabProps(1)} />
              <Tab label="Concluídas" {...tabProps(2)} />
            </Tabs>
          </div>

          {/* 
           /* Material UI Tab Panel - Em Aberto
          */}
          <TabPanel value={tabValue} index={0}>
            {orders.length > 0 ? orders.map((order, index) => (order.status_pedido.indexOf('_DEVOLUCAO') === -1  && (order.vendedor_creditado === false || order.cashback_creditado === false  || order.status_pedido !== 'FINALIZADO') && order.status_pedido !== 'PAGAMENTO_NAO_APROVADO' && order.status_pedido !== 'EXPIRADO' && order.status_pedido !== 'CANCELADO' && order.status_pedido !== 'REEMBOLSADO')
              && (
                <Accordion
                  origin={order?.tipo_pedido ? order?.tipo_pedido : "scamb" }
                  key={order._id}
                  productStatus={order.status_pedido.toLowerCase()}
                  productStatusSeller={false}
                  className="_subitems"
                  onClick={(opened) => opened && (order.status_pedido === 'ETIQUETA_GERADA' || order.status_pedido === 'POSTADO') ? updateTrackingStatus(order._id, index) : ''}
                  child={
                    <>
                      <ProductThumbnailItems
                        images={handleImagens(order.itens)}
                        title={handleTitles(order.itens)}
                        dateTitle="Data da compra"
                        date={format(new Date(order.data_inclusao), 'dd/MM/yyyy')}
                        viewInvoice={order.status_pedido === 'PAGAMENTO_PENDENTE' ? checkInvoice(order) : null}
                      />
                    </>
                  }
                >
                  <ListContent bgColor="#FFFFFF">
                    {order?.scamber_vendedor?.map(vendedor => (
                      <ListItem title="Vendedor" key={vendedor._id}>
                        <AdvertiserCard
                          storeLogo={vendedor?.imagens?.avatar ? listofOrders.loadImage(vendedor?.imagens?.avatar) : Avatar}
                          storeLink={`/loja/${vendedor?.minha_loja?.url}`}
                          storeTitle={vendedor?.minha_loja?.titulo}
                        />
                      </ListItem>
                    ))}

                    <ListItem title="Número do pedido">
                      {order._id}
                    </ListItem>
                    
                    {(order.status_pedido == 'POSTADO' || order.status_pedido == 'ETIQUETA_GERADA') 
                    || (order.status_pedido === 'FINALIZADO' && order.vendedor_creditado === false) 
                    || (order.status_pedido === 'FINALIZADO' && order.cashback_creditado === false)? (
                      <ListItem title="Confirmar recebimento">
                        <button type="button" className="btn-received" onClick={() => handleProductReceipt(order)}>
                          Sim! recebi o produto
                      <span>
                            <Icon icon={faThumbsUp} />
                          </span>
                        </button>
                      </ListItem>
                    ):(<></>)}

                    {(order.status_pedido === 'PAGO' && order.tipo_entrega === 'IN_HANDS') && (
                      <ListItem title="Confirmar retirada">
                        <button type="button" className="btn-received" onClick={() => handleProductReceipt(order)}>
                          Sim! Já estou com meu produto em mãos
                        <span>
                            <Icon icon={faThumbsUp} />
                          </span>
                        </button>
                      </ListItem>
                    )}

                    <ListItem title="">
                      Status do pedido
                    </ListItem>

                    <ListItem title="" bgColor="#F9F9F9">
                      <Timeline>

                        {checkStatus(order.historico_status_pedido, order.status_pedido, order.data_alteracao, (status) => {

                          if (order.tipo_entrega === 'LOGISTICS') {
                            return (
                              <>

                                <Status icon={<Icon icon={faCheck} />} title="Entregue"
                                  className={status.finalizado ? '_active' : 'no-active'}
                                  date={status.finalizado?.data}
                                  hour={status.finalizado?.hora}>Link</Status>

                                <Status icon={<Icon icon={faShippingFast} />} title="Enviado"
                                  className={status.postado || status.finalizado ? '_active' : 'no-active'}
                                  subtitle={
                                    status.postado && order.urls_rastreio && order.urls_rastreio.length > 0 ?
                                      order.urls_rastreio.map((infoRastreio) => <a href={infoRastreio.url} target="_new"> Rastrear meu pedido ({infoRastreio.url.split('/')[4]}) </a>) : <></>
                                  }
                                  date={status.postado?.data}
                                  hour={status.postado?.hora}></Status>

                                <Status icon={<Icon icon={faClock} />} title="Aguardando Envio"
                                  className={status.etiqueta_gerada || status.postado || status.finalizado ? '_active' : 'no-active'}
                                  date={status.etiqueta_gerada?.data}
                                  hour={status.etiqueta_gerada?.hora}>Link</Status>

                                <Status icon={<Icon icon={faCheck} />} title="Pago"
                                  className={status.pago || status.etiqueta_gerada || status.postado || status.finalizado ? '_active' : 'no-active'}
                                  date={status.pago?.data}
                                  hour={status.pago?.hora}>Link</Status>

                                <Status icon={<Icon icon={faHourglass} />} title="Aguardando Pagamento"
                                  className={status.pagamento_pendente || status.pago || status.etiqueta_gerada || status.postado || status.finalizado ? '_active' : 'no-active'}
                                  date={status.pagamento_pendente?.data}
                                  hour={status.pagamento_pendente?.hora}>Link</Status>


                              </>
                            )
                          } else {
                            return (
                              <>

                                <Status icon={<Icon icon={faCheck} />} title="Entregue"
                                  className={status.finalizado ? '_active' : 'no-active'}
                                  date={status.finalizado?.data}
                                  hour={status.finalizado?.hora}>Link</Status>

                                <Status icon={<Icon icon={faClock} />} title="Aguardando retirada em mãos"
                                  className={status.pago || status.finalizado ? '_active' : 'no-active'}
                                  date={status.pago?.data}
                                  hour={status.pago?.hora}>Link</Status>

                                <Status icon={<Icon icon={faCheck} />} title="Pago"
                                  className={status.pago || status.finalizado ? '_active' : 'no-active'}
                                  date={status.pago?.data}
                                  hour={status.pago?.hora}>Link</Status>

                                <Status icon={<Icon icon={faHourglass} />} title="Aguardando Pagamento"
                                  className={status.pagamento_pendente || status.pago || status.etiqueta_gerada || status.postado || status.finalizado ? '_active' : 'no-active'}
                                  date={status.pagamento_pendente?.data}
                                  hour={status.pagamento_pendente?.hora}>Link</Status>

                              </>
                            )
                          }

                        })}

                      </Timeline>


                    </ListItem>
                    
                    {order.tipo_pedido !== 'shopping' ? (
                      <>
                        <ListItem title="Total em pontos">
                          <PricePoints value={order.valor_pontos} />
                        </ListItem>

                        <ListItem title="Taxa em Scamb">
                          <Price value={parseFloat((order.valor_taxa !== undefined ? order.valor_taxa : order.taxa_valor)).toFixed(2)} />
                        </ListItem>
                      </>
                      ) : (
                        <>
                          <ListItem title="Total dos produtos">
                            <Price
                              value={formatNumberToBRL(parseFloat(order.valor_total_anuncios))}
                            />
                          </ListItem>
                          

                          <ListItem title="Pointsback">
                            <PricePoints value={ order.valor_total_cashback} />
                          </ListItem>
                        </>
                      )
                    }                    

                    {/* <ListItem title="Taxa em reais">
                      <Price value={order.valor_taxa} />
                    </ListItem> */}
                    <ListItem title="Frete" className="flex">
                      <Price value={parseFloat(order.valor_frete? order.valor_frete:0).toFixed(2)} />
                      <div className="_frete">
                        <Icon icon={order.tipo_entrega ? (order.tipo_entrega === 'IN_HANDS' ? faHandHoldingBox : faTruck) : undefined} />
                        {order.tipo_entrega ? (order.tipo_entrega === 'IN_HANDS' ? 'Em mãos' : 'Correios') : ' - '}
                      </div>
                    </ListItem>

                    {order.valor_faltante? 
                      (<><ListItem title="Valor de pontos comprados no checkout">
                        <Price value={parseFloat(order.valor_faltante).toFixed(2)} />
                        equivalente à <strong>{order.valor_pontos_faltante} pontos</strong>
                      </ListItem></>):(<></>)
                    }

                    <ListItem title="Total em reais">
                      <Price value={parseFloat(order.valor).toFixed(2)} />
                    </ListItem>

                    <ListItem title="Endereço da entrega">
                      {handleAdress(order.endereco_entrega)}
                    </ListItem>

                    {order.tipo_entrega !== 'IN_HANDS' && order.status_pedido !== 'CANCELADO' && checkDevolutionDate(order) && (order.vendedor_creditado === false || order.cashback_creditado === false) ?
                      <ListItem title="Devolução">
                        <button type="button" className="small-button" onClick={() => { setReturnOrder(order); openDialogModal(refModalReturnProduct) }}>
                          Devolver o pedido
                            <Icon icon={faChevronRight} />
                        </button>
                      </ListItem> : <></>
                    }

                    {(order.status_pedido === 'PAGO' || order.status_pedido === 'PAGAMENTO_PENDENTE') && (
                      <ListItem title="Pedido">

                        <button type="button" className="small-button" onClick={() => handleCancelOrder(order._id, order.tipo_pedido)}>
                          Cancelar Pedido
                          <Icon icon={faChevronRight} />
                        </button>
                      </ListItem>
                    )}

                    <ListItem title="Produtos">
                      {order.itens && order.itens.map((item) => (
                        <ProductThumbnail
                          key={item._id}
                          image={item.fotos[0] ? listofOrders.loadImage(item.fotos[0]) : CoverIMG}
                          title={item.titulo}
                          dateTitle="Valor em pontos"
                          pricePoints={item.pontos_por?.$numberDecimal}
                          productUrl={`/produto?id=${item._id}`}
                          type={item.tipo_anuncio}
                          cashback={item.cashback}
                          cashDiscount={item.valor_de}
                          cashPrice={item.valor_por} 
                        />
                      ))}
                    </ListItem>

                  </ListContent>
                </Accordion>
              )) :
              <section className="_block">
                <NothingMessage>
                  Você não possui nenhuma compra em aberto
                </NothingMessage>
              </section>
            }
          </TabPanel>

          <TabPanel value={tabValue} index={1}>

            {orders.length > 0 ? orders.map((order, index) => (order.status_pedido.indexOf('_DEVOLUCAO') !== -1) && (

              <Accordion
                origin={order?.tipo_pedido ? order?.tipo_pedido : "scamb" }
                key={order._id}
                productStatus={order.status_pedido.toLowerCase()}
                className="_subitems"
                onClick={(opened) => opened && (order.status_pedido === 'ETIQUETA_GERADA_DEVOLUCAO' || order.status_pedido === 'POSTADO_DEVOLUCAO') ? updateTrackingReturnOrderStatus(order._id, index, order.tipo_pedido) : ''}
                child={
                  <ProductThumbnailItems
                    images={handleImagens(order.itens)}
                    title={handleTitles(order.itens)}
                    dateTitle="Data da compra"
                    date={format(new Date(order.data_inclusao), 'dd/MM/yyyy')}
                    printLabel={
                      order.tipo_entrega == 'LOGISTICS' && !order.devolucao?.url_etiquetas ?
                        <button type="button" className="small-button" onClick={() => { setReturnOrder(order); openDialogModal(refModalPrintLabel); }}>
                          Gerar etiqueta
                      <Icon icon={faPrint} />
                        </button>
                        : null
                    }
                    viewLabel={
                      order.tipo_entrega == 'LOGISTICS' && order.devolucao?.url_etiquetas ?
                        <ListContent bgColor="#FFFFFF">
                          <ListItem title="Etiqueta">
                            <button type="button" className="small-button" onClick={() => printTag(order._id, order.tipo_pedido)} disabled={false}>
                              Visualizar etiqueta
                            <Icon icon={faEye} />
                            </button>
                          </ListItem>
                        </ListContent>
                        : null
                    }
                  />
                }
              >
                <ListContent bgColor="#FFFFFF">
                  {order.scamber_vendedor.map(vendedor => (
                    <ListItem title="Vendedor" key={vendedor._id}>
                      <AdvertiserCard
                        storeLogo={vendedor?.imagens?.avatar ? listofOrders.loadImage(vendedor?.imagens?.avatar) : Avatar}
                        storeLink={`/loja/${vendedor?.minha_loja?.url}`}
                        storeTitle={vendedor?.minha_loja?.titulo}
                      />
                    </ListItem>
                  ))}
                  <ListItem title="Frete" className="flex">
                    <Price value={order.devolucao.transportadora_frete.custom_price} />
                    <div className="_frete">
                      <Icon icon={faTruck} />
                      Correios
                    </div>
                  </ListItem>

                  <ListItem title="Número do pedido">
                    {order._id}
                  </ListItem>

                  <ListItem title="">
                    Status do pedido
                  </ListItem>

                  <ListItem title="" bgColor="#F9F9F9">
                    <Timeline>

                      {checkStatus(order.devolucao.historico_status_devolucao, order.status_pedido, order.data_alteracao, (status) =>
                        <>
                          <Status icon={<Icon icon={faCheck} />} title="Finalizado"
                            className={status.finalizado_devolucao ? '_active' : 'no-active'}
                            date={status.finalizado_devolucao?.data}
                            hour={status.finalizado_devolucao?.hora}>Link</Status>

                          <Status icon={<Icon icon={faShippingFast} />} title="Enviado"
                            className={status.postado_devolucao || status.finalizado_devolucao ? '_active' : 'no-active'}
                            subtitle={
                              status.postado_devolucao && order.devolucao?.urls_rastreio && order.devolucao?.urls_rastreio.length > 0 ?
                                order.devolucao?.urls_rastreio.map((infoRastreio) => <a href={infoRastreio.url} target="_new"> Rastrear meu pedido ({infoRastreio.url.split('/')[4]}) </a>) : <></>
                            }
                            date={status.postado_devolucao?.data}
                            hour={status.postado_devolucao?.hora}></Status>

                          <Status icon={<Icon icon={faClock} />} title="Aguardando Envio"
                            className={status.etiqueta_gerada_devolucao || status.postado_devolucao || status.finalizado_devolucao ? '_active' : 'no-active'}
                            date={status.etiqueta_gerada_devolucao?.data}
                            hour={status.etiqueta_gerada_devolucao?.hora}>Link</Status>

                          <Status icon={<Icon icon={faCheck} />} title="Frete Pago"
                            className={status.pago_devolucao || status.etiqueta_gerada_devolucao || status.postado_devolucao || status.finalizado_devolucao ? '_active' : 'no-active'}
                            date={status.pago_devolucao?.data}
                            hour={status.pago_devolucao?.hora}>Link</Status>

                          <Status icon={<Icon icon={faHourglass} />} title="Frete Aguardando Pagamento"
                            className={status.pagamento_pendente_devolucao || status.pago_devolucao || status.etiqueta_gerada_devolucao || status.postado_devolucao || status.finalizado_devolucao ? '_active' : 'no-active'}
                            date={status.pagamento_pendente_devolucao?.data}
                            hour={status.pagamento_pendente_devolucao?.hora}>Link</Status>
                        </>
                      )}

                    </Timeline>
                  </ListItem>

                  {order.tipo_pedido !== 'shopping' ? (
                      <>
                        <ListItem title="Total em pontos">
                          <PricePoints value={order.valor_pontos} />
                        </ListItem>

                        <ListItem title="Taxa em Scamb">
                          <Price value={parseFloat((order.valor_taxa !== undefined ? order.valor_taxa : order.taxa_valor)).toFixed(2)} />
                        </ListItem>
                      </>
                    ) : (
                        <>
                          <ListItem title="Total dos produtos">
                            <Price
                              value={formatNumberToBRL(parseFloat(order.valor_total_anuncios))}
                            />
                          </ListItem>
                          

                          <ListItem title="Pointsback">
                            <PricePoints value={ order.valor_total_cashback} />
                          </ListItem>
                        </>
                      )
                    }

                    {/* <ListItem title="Taxa em reais">
                      <Price value={order.valor_taxa} />
                    </ListItem> */}
                    <ListItem title="Frete" className="flex">
                      <Price value={parseFloat(order.valor_frete? order.valor_frete:0).toFixed(2)} />
                      <div className="_frete">
                        <Icon icon={order.tipo_entrega ? (order.tipo_entrega === 'IN_HANDS' ? faHandHoldingBox : faTruck) : undefined} />
                        {order.tipo_entrega ? (order.tipo_entrega === 'IN_HANDS' ? 'Em mãos' : 'Correios') : ' - '}
                      </div>
                    </ListItem>

                    {order.valor_faltante? 
                      (<><ListItem title="Valor de pontos comprados no checkout">
                        <Price value={parseFloat(order.valor_faltante).toFixed(2)} />
                        equivalente à <strong>{order.valor_pontos_faltante} pontos</strong>
                      </ListItem></>):(<></>)
                    }

                  <ListItem title="Total em reais">
                    <Price value={parseFloat(order.valor).toFixed(2)} />
                  </ListItem>

                  <ListItem title="Endereço da entrega">
                    {handleAdress(order.endereco_entrega)}
                  </ListItem>

                  <ListItem title="Produtos">
                    {order.itens && order.itens.map((item) => (
                      <ProductThumbnail
                        key={item._id}
                        image={item.fotos[0] ? listofOrders.loadImage(item.fotos[0]) : CoverIMG}
                        title={item.titulo}
                        dateTitle="Valor em pontos"
                        pricePoints={item.pontos_por?.$numberDecimal}
                        productUrl={`/produto?id=${item._id}`}
                        type={item.tipo_anuncio}
                        cashback={item.cashback}
                        cashDiscount={item.valor_de}
                        cashPrice={item.valor_por} 
                      />
                    ))}
                  </ListItem>
                </ListContent>

              </Accordion>


            )) :
              <section className="_block">
                <NothingMessage>
                  Você não possui nenhuma compra concluída
            </NothingMessage>
              </section>
            }

          </TabPanel>

          {/* 
           /* Material UI Tab Panel - Concluídas
          */}
          <TabPanel value={tabValue} index={2}>
            {/* 
              /* productStatus possui 3 estados:
              /* productStatus="delivered"
              /* productStatus="canceled"
              /* productStatus="returned"
              /* productStatus="paid"
              /* productStatus="waiting"
            */}
            {orders.length > 0 ? orders.map(order => (order.status_pedido.indexOf('_DEVOLUCAO') === -1 && ((order.status_pedido === 'FINALIZADO' && order.vendedor_creditado !== false) && (order.status_pedido === 'FINALIZADO' && order.cashback_creditado !== false) || order.status_pedido === 'EXPIRADO' || order.status_pedido === 'CANCELADO' || order.status_pedido === 'REEMBOLSADO' || order.status_pedido === 'PAGAMENTO_NAO_APROVADO'  )) && (
              <Accordion
                origin={order?.tipo_pedido ? order?.tipo_pedido : "scamb" }
                key={order._id}
                productStatus={order.status_pedido.toLowerCase()}
                productStatusSeller={true}
                className="_subitems"
                child={
                  <ProductThumbnailItems
                    images={handleImagens(order.itens)}
                    title={handleTitles(order.itens)}
                    dateTitle="Data da compra"
                    date={format(new Date(order.data_inclusao), 'dd/MM/yyyy')}
                  />
                }
              >
                <ListContent bgColor="#FFFFFF">
                  {order.scamber_vendedor.map(vendedor => (
                    <ListItem title="Vendedor" key={vendedor._id}>
                      <AdvertiserCard
                        storeLogo={vendedor?.imagens?.avatar ? listofOrders.loadImage(vendedor?.imagens?.avatar) : Avatar}
                        storeLink={`/loja/${vendedor?.minha_loja?.url}`}
                        storeTitle={vendedor?.minha_loja?.titulo}
                      />
                    </ListItem>
                  ))}


                  <ListItem title="Número do pedido">
                    {order._id}
                  </ListItem>

                  {(order.status_pedido === 'FINALIZADO' && order.vendedor_creditado === false) 
                    || (order.status_pedido === 'FINALIZADO' && order.cashback_creditado === false) && (
                      <ListItem title="Confirmar recebimento">
                        <button type="button" className="btn-received" onClick={() => handleProductReceipt(order)}>
                          Sim! recebi o produto
                          <span>
                            <Icon icon={faThumbsUp} />
                          </span>
                        </button>
                      </ListItem>
                    )}

                  <ListItem title="">
                    Status do pedido
                  </ListItem>

                  <ListItem title="" bgColor="#F9F9F9">
                    <Timeline>

                      {checkStatus(order.historico_status_pedido, order.status_pedido, order.data_alteracao, (status) => {
                        if (order.tipo_entrega === 'LOGISTICS') {
                          return (
                            <>

                              <Status icon={<Icon icon={faCheck} />} title="Entregue"
                                className={status.finalizado ? '_active' : 'no-active'}
                                date={status.finalizado?.data}
                                hour={status.finalizado?.hora}>Link</Status>

                              <Status icon={<Icon icon={faShippingFast} />} title="Enviado"
                                className={status.postado || status.finalizado ? '_active' : 'no-active'}
                                subtitle={
                                  status.postado && order.urls_rastreio && order.urls_rastreio.length > 0 ?
                                    order.urls_rastreio.map((infoRastreio) => <a href={infoRastreio.url} target="_new"> Rastrear meu pedido ({infoRastreio.url.split('/')[4]}) </a>) : <></>
                                }
                                date={status.postado?.data}
                                hour={status.postado?.hora}></Status>

                              <Status icon={<Icon icon={faClock} />} title="Aguardando Envio"
                                className={status.etiqueta_gerada || status.postado || status.finalizado ? '_active' : 'no-active'}
                                date={status.etiqueta_gerada?.data}
                                hour={status.etiqueta_gerada?.hora}>Link</Status>

                              <Status icon={<Icon icon={faCheck} />} title="Pago"
                                className={status.pago || status.etiqueta_gerada || status.postado || status.finalizado ? '_active' : 'no-active'}
                                date={status.pago?.data}
                                hour={status.pago?.hora}>Link</Status>

                              <Status icon={<Icon icon={faHourglass} />} title="Aguardando Pagamento"
                                className={status.pagamento_pendente || status.pago || status.etiqueta_gerada || status.postado || status.finalizado ? '_active' : 'no-active'}
                                date={status.pagamento_pendente?.data}
                                hour={status.pagamento_pendente?.hora}>Link</Status>


                            </>
                          )
                        } else {
                          return (
                            <>

                              <Status icon={<Icon icon={faCheck} />} title="Entregue"
                                className={status.finalizado ? '_active' : 'no-active'}
                                date={status.finalizado?.data}
                                hour={status.finalizado?.hora}>Link</Status>

                              <Status icon={<Icon icon={faClock} />} title="Aguardando retirada em mãos"
                                className={status.pago || status.finalizado ? '_active' : 'no-active'}
                                date={status.pago?.data}
                                hour={status.pago?.hora}>Link</Status>

                              <Status icon={<Icon icon={faCheck} />} title="Pago"
                                className={status.pago || status.finalizado ? '_active' : 'no-active'}
                                date={status.pago?.data}
                                hour={status.pago?.hora}>Link</Status>

                              <Status icon={<Icon icon={faHourglass} />} title="Aguardando Pagamento"
                                className={status.pagamento_pendente || status.pago || status.etiqueta_gerada || status.postado || status.finalizado ? '_active' : 'no-active'}
                                date={status.pagamento_pendente?.data}
                                hour={status.pagamento_pendente?.hora}>Link</Status>

                            </>
                          )
                        }
                      })}

                    </Timeline>

                  </ListItem>

                  {order.tipo_pedido !== 'shopping' ? (
                      <>
                        <ListItem title="Total em pontos">
                          <PricePoints value={order.valor_pontos} />
                        </ListItem>

                        <ListItem title="Taxa em Scamb">
                          <Price value={parseFloat((order.valor_taxa !== undefined ? order.valor_taxa : order.taxa_valor)).toFixed(2)} />
                        </ListItem>
                      </>
                      ) : (
                        <>
                          <ListItem title="Total dos produtos">
                            <Price
                              value={formatNumberToBRL(parseFloat(order.valor_total_anuncios))}
                            />
                          </ListItem>
                          

                          <ListItem title="Pointsback">
                            <PricePoints value={ order.valor_total_cashback} />
                          </ListItem>
                        </>
                      )
                    }

                  {/* <ListItem title="Taxa em reais">
                    <Price value={order.valor_taxa} />
                  </ListItem> */}
                  <ListItem title="Frete" className="flex">
                    <Price value={parseFloat(order.valor_frete? order.valor_frete:0).toFixed(2)} />
                    <div className="_frete">
                      <Icon icon={order.tipo_entrega ? (order.tipo_entrega === 'IN_HANDS' ? faHandHoldingBox : faTruck) : undefined} />
                      {order.tipo_entrega ? (order.tipo_entrega === 'IN_HANDS' ? 'Em mãos' : 'Correios') : ' - '}
                    </div>
                  </ListItem>

                  {order.valor_faltante? 
                    (<><ListItem title="Valor de pontos comprados no checkout">
                      <Price value={parseFloat(order.valor_faltante).toFixed(2)} />
                      equivalente à <strong>{order.valor_pontos_faltante} pontos</strong>
                    </ListItem></>):(<></>)
                  }

                  <ListItem title="Total em reais">
                    <Price value={parseFloat(order.valor).toFixed(2)} />
                  </ListItem>

                  <ListItem title="Endereço da entrega">
                    {handleAdress(order.endereco_entrega)}
                  </ListItem>

                  {order.tipo_entrega !== 'IN_HANDS' && order.status_pedido !== 'CANCELADO' && checkDevolutionDate(order) && order.vendedor_creditado === false ?
                    <ListItem title="Devolução">
                      <button type="button" className="small-button" onClick={() => { setReturnOrder(order); openDialogModal(refModalReturnProduct) }}>
                        Devolver o pedido
                          <Icon icon={faChevronRight} />
                      </button>
                    </ListItem> : <></>
                  }

                  <ListItem title="Produtos">
                    {order.itens && order.itens.map((item) => (
                      <ProductThumbnail
                        key={item._id}
                        image={item.fotos[0] ? listofOrders.loadImage(item.fotos[0]) : CoverIMG}
                        title={item.titulo}
                        dateTitle="Valor em pontos"
                        pricePoints={item.pontos_por?.$numberDecimal}
                        productUrl={`/produto?id=${item._id}`}
                        type={item.tipo_anuncio}
                        cashback={item.cashback}
                        cashDiscount={item.valor_de}
                        cashPrice={item.valor_por} 
                      />
                    ))}
                  </ListItem>

                </ListContent>
              </Accordion>
            )) :
              <section className="_block">
                <NothingMessage>
                  Você não possui nenhuma compra concluída
                </NothingMessage>
              </section>
            }
          </TabPanel>
        </div>
      </Layout>
    </>
  );
}

const mapStateToProps = (state) => ({
  site_properties: state.websiteReducer.site_properties
})

export default connect(mapStateToProps, {})(MyPurchases);
