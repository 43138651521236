//=====================================================================================
// #1 - Base Imports
//=====================================================================================
import React from "react";

//===============================================================================================================
// #4 - Import * Icons
//===============================================================================================================
import { FontAwesomeIcon as I } from "@fortawesome/react-fontawesome";

//=====================================================================================
// #2 - Import * Styled Components
//=====================================================================================
import { SearchForm as Search, FormField, Submit, SwitchSearch, SwitchLabel, SwitchMark } from "./styles";

export const SubmitSearch = ({ type }) => <Submit type={type}> <I icon={["far", "search"]} /> </Submit>;

export const SearchField = ({ type, name, value, placeholder, onClick, onChange }) => <FormField placeholder={placeholder} type={type} value={value} name={name} onClick={onClick} onChange={onChange} />;

export const SwitchField = ({ type, name, id, defaultChecked, onChange }) => {
    return (
        <input
            type={type} name={name}
            id={id}
            defaultChecked={defaultChecked ? defaultChecked : false}
            onChange={onChange}
        />
    )
}

export const Mark = ({ text }) => <SwitchMark className="switch-mark">{text}</SwitchMark>

export const Label = ({ children }) => <SwitchLabel>{children}</SwitchLabel>

export const Switch = ({ ref, children }) => <SwitchSearch className="switch" ref={ref}> {children} </SwitchSearch>;

export const SearchForm = ({ onSubmit, children }) => <Search onSubmit={onSubmit}> {children} </Search>;

export default Search;