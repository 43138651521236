//===============================================================================================================
// #1 - Import * React
//===============================================================================================================
import React, { Component } from 'react';

//===============================================================================================================
// #2 - Import * React Router Dom 
//===============================================================================================================
import { BrowserRouter } from 'react-router-dom';

//===============================================================================================================
// #3 - Import * React Router Dom 
//===============================================================================================================
import Routes from "./Routes";

//===============================================================================================================
// #4 - Import * Configs
//===============================================================================================================
import './configs/ReactotronConfig';

//===============================================================================================================
// #5 - Import * Utils 
//===============================================================================================================
import ScrollToTop from './utils/ScrollToTop';

//===============================================================================================================
// #6 - Import * Global Styles Sass 
//===============================================================================================================
import './assets/css/index.scss';

//===============================================================================================================
// #7 - Import * Reux 
//===============================================================================================================
import { connect } from 'react-redux';
import { PathFinder, getSiteProperties }  from './PathFinder';
import { setWebsite, setSiteProperties } from './redux/actions/websiteActions';

//===============================================================================================================
// #8 - Import Library * Font Awesome Icons 
//===============================================================================================================
import "./fontawesome";

//===============================================================================================================
// #9 - Import * Styled Components
//===============================================================================================================
import { ThemeProvider } from "styled-components";

//===============================================================================================================
// #10 - Import Styles * Styled Components
//===============================================================================================================
import { GlobalStyles } from "./styled/1-base/global";
import { base } from "./styled/1-base";
import { theme } from "./styled/5-theme";

//===============================================================================================================
// #11 - Building Style Object
//===============================================================================================================
const theming = { ...theme, ...base }

class App extends Component {

    constructor(props) {
        super(props);
        
        let website = PathFinder();
        let site_properties = getSiteProperties();
        
        props.setWebsite(website);
        props.setSiteProperties(site_properties);
    }
    
    render () {
        
        return (
            <BrowserRouter >
                <ScrollToTop>
                    <ThemeProvider theme={theming}>
                        <GlobalStyles />
                        <Routes />
                    </ThemeProvider>
                </ScrollToTop>
            </BrowserRouter>
        )
        
    }
    
}

export default connect(() => {}, { setWebsite, setSiteProperties })(App);