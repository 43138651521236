import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from "@fortawesome/pro-light-svg-icons";

const back = () => window.history.go(-1);

const WrapView = (props) => {

    return (
        <>
            <section className={"wrap-view " +props.className}>
                <section className="_view-head">
                    <div className="_view-head-content">
                        <button type="button" className="_back-history btn" onClick={back}>
                            <FontAwesomeIcon icon={faChevronLeft}></FontAwesomeIcon>
                        </button>
                        <div className="_title-view">
                           {props.stepTitle}
                        </div>
                    </div>
                </section>

                <section className="_view-content">

                    <h1 className="_content-title">
                        {props.headline}
                    </h1>

                    {props.children}

                </section>
            </section>
        </>
    )
};

export default WrapView;