//=====================================================================================
// #1 - Import * Styled Components
//=====================================================================================
import styled from 'styled-components';

export const WrapperContent = styled.div`

    @media (min-width: 240px) {
        margin: 40px 0;
    }

    @media (min-width: 768px) {
        margin-top: 40px;
    }
`;

export const Content = styled.div`
    margin-top: 15px;
`;