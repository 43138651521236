import React from "react";

const text = (val, max) => {
  let letters = val.substring(0, max);

  if (val.length <= 25) {
    return letters;
  } else {
    return letters + "...";
  }
};

function ProductThumbnail(props) {
  const product_title   = props.productTitle;
  const store_title     = props.storeTitle
  const image           = props.productImage;
  const price           = props.price;


  return (
    <div className="_product">
      <div className="_product-image">
        <img src={image} alt="" />
      </div>
      <div className="_product-info">
        <div className="_store-title">{store_title}</div>
        <div className="_product-title">{text(product_title, 30)}</div>
        <div className="_product-price _1x">
          <div className="ui-item__price">
            <span class="price-tag-fraction">{price}</span>
            <span class="price-tag-symbol">pts</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductThumbnail;
