//=====================================================================================
// #1 - Base Imports
//=====================================================================================
import React from "react";

//=====================================================================================
// #2 - Import * React Router Dom
//=====================================================================================
import { Link } from "react-router-dom";

//=====================================================================================
// #3 - Import * Styled Components Style
//=====================================================================================
import { Wrapper, Column } from "./styles";

export const Headline = ({ className, textAlign, title, titleColor, subTitle, subTitleColor, withLink, linkText, link, type, children }) => {

    return (

        // <Headline
        //     type="medium"
        //     title="Seja um Scamber!"
        //     titleColor="#515157"
        //     subTitleColor="#515157"
        //     subTitle="O novo jeito de comprar e vender segunda mão"
        //     withLink={true}    
        //     linkText="Ver mais.."
        //     textAlign="left"
        // />

        <Wrapper type={type} className="hdline">
            <Column textAlign={textAlign} type={type} titleColor={titleColor} subTitleColor={subTitleColor} className={className}>
                <h1> {title} </h1>

                {subTitle && (
                    <h2>
                        {subTitle}
                    </h2>
                )}

                {children && (
                    <h2> {children} </h2>
                )}
            </Column>

            {withLink && (
                <Column>
                    <Link to={link ? link : "#link-not-defined" } > {linkText} </Link>
                </Column>
            )}

        </Wrapper>
    )
}