//===========================================================
// #1 - Base Imports
//===========================================================
import React from "react";

//===========================================================
// #2 - Import * UI Components
//===========================================================
import { ListContent, ListItem } from "../list-content";

//===========================================================
// #3 - Import * FontAwesome Icons
//===========================================================
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/pro-light-svg-icons";


export const BoxInfo = (props) => {

    return (
        <div className="box-information">
          {props.edit && (
            <button type="button" className="btn button-action" onClick={props.edit}>
              <Icon icon={faEdit} />
            </button>
          )}
            <div className="_set-user-location">
              <div className="_wrap-location">
                <div className="_icon">
                  {props.icon}
                </div>
  
                <div className="_wrap-current-location">
                <div className="_location-title">{props.title}</div>
                  <div className="_location">
                    {props.text}
                  </div>
                </div>
              </div>
            </div>
        </div>
    )
}