import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from 'sweetalert2';
import { connect } from 'react-redux';

// Icons
import {
  faCloudUpload,
  faCameraAlt,
  faStar,
} from "@fortawesome/pro-light-svg-icons";

import { 
	faStar as faStarSolid
} from "@fortawesome/pro-solid-svg-icons";

//Images
import CoverIMG from "../../assets/images/cover.png";
import Avatar from "../../assets/images/avatar.png";

import Scamber from '../../services/Scamber'
import EventEmitter from '../../services/Events';

import { setScamber } from './../../redux/actions/scamberActions';

class Header extends Component {

  constructor(){
    super()
    
    this.scamber = new Scamber();
    this.state = {
      'minhaLoja':false
    }
  }

  componentWillReceiveProps(){
    if(window.location.pathname.indexOf('/loja') !== -1) this.setState({'minhaLoja':true})
  }

  loadScamberData = () => {
    Swal.fire('Aguarde...');
    Swal.showLoading();
    this.scamber.findScamber().then(( scamberData ) =>{  
      Swal.close();
      if( scamberData.status === 200) this.props.setScamber(scamberData.data.payload);

    }).catch((error)=> this.scamber.formatErrorMessage(error.response, 'FAHSCA'));
  }

  changeImage = (e, image) => {
    const file = e.target.files[0];
    
    const formDataAvatar = new FormData();
    formDataAvatar.append('imageToChange', file);
    
    Swal.fire('Alterando Imagem... Aguarde');
    Swal.showLoading();
    this.scamber.changeImage(formDataAvatar, image).then(( response ) =>{  
      Swal.close();
      
      if( response.status === 200 ) {

        Swal.fire({
          icon: "success",
          title: "Imagem alterada!",
          timer: 4000,
          timerProgressBar: true,
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonText: "fechar",
        }).then((its_ok) => {
          this.loadScamberData();
        });

      }

    }).catch((error)=> this.scamber.formatErrorMessage(error.response, 'CHISCA'));
  
  }
  
  render() {
    return (
      <div id="view_my-account-profile--header">
        <section className="_hero">
          <div
            className="_profile-cover"
            //style={{ backgroundImage: this.props.scamber? this.props.scamber.imagens?.banner? "url(" + this.scamber.loadImage(this.props.scamber.imagens?.banner) + ")":"url(" + CoverIMG  + ")":"" }}
          >
            {this.props.images? 
             <img src={this.props.images?.banner? this.scamber.loadImage(this.props.images?.banner) + "" :"" + CoverIMG  + "" } alt="" className="_img_profile-cover" /> 
            :<img src={this.props.scamber? this.props.scamber.imagens?.banner? "" + this.scamber.loadImage(this.props.scamber.imagens?.banner) + "":"" + CoverIMG  + "":"" + CoverIMG  + "" } alt="" className="_img_profile-cover" />}
            
            {!this.state.minhaLoja? 
              <React.Fragment>
                <input
                  type="file"
                  accept="image/x-png, image/jpeg"
                  name="profilePhotoCover"
                  id="profile-photo-cover"
                  className="_hidden"
                  onChange={(e) => this.changeImage(e, 'banner')}
                  />
                <label
                  htmlFor="profile-photo-cover"
                  className="btn btn-transparent _color _black"
                >
                  <FontAwesomeIcon icon={faCameraAlt}></FontAwesomeIcon>
                </label>
              </React.Fragment>
            : <></>}
          </div>
          <div className="_profile-bottom">
            <div className="_wrap-user">
              <div className="_wrap-user-image">
                <label htmlFor="profile-photo">
                <span className="user-image _rounded-image__with-notification-icon _3x">
                  {this.props.images?
                    this.props.images?.avatar? (<img src={this.scamber.loadImage(this.props.images?.avatar)} alt="Avatar" />):(<img src={Avatar} alt="Avatar" />) 
                    :this.props.scamber? this.props.scamber.imagens?.avatar? (<img src={this.scamber.loadImage(this.props.scamber.imagens?.avatar)} alt="Avatar" />):(<img src={Avatar} alt="Avatar" />) : <></>
                  }
                  
                </span>
                </label>

                {!this.state.minhaLoja? 
                  <React.Fragment>
                    <input
                      type="file"
                      accept="image/x-png, image/jpeg"
                      name="profilePhoto"
                      id="profile-photo"
                      className="_hidden"
                      onChange={(e) => this.changeImage(e, 'avatar')}
                    />
                    <label
                      htmlFor="profile-photo"
                      className="btn btn-transparent _color _black _label-icon"
                    >
                      <FontAwesomeIcon icon={faCameraAlt}></FontAwesomeIcon>
                    </label>
                  </React.Fragment>
                : <></>}
              </div>

              <div className="_user-info">
                <h1 style={{marginTop:"12px"}} className="_user-name">
                    {/* IF SCAMBER TEM  NOME DA LOJA, VAI APARECER O NOME DA LOJA, ELSE APARECE O DELE*/}
                    {!this.state.minhaLoja? 
                      this.props.scamber? this.props.scamber.minha_loja?.titulo? this.props.scamber.minha_loja.titulo: this.props.scamber?.nome:<></>
                      :this.props.store &&  this.props.store.titulo
                    }

                    {/* BIO */}
                    {this.state.minhaLoja && (<div className="_user-bio">{this.props.store?.bio}</div>)}
                    
                    {!this.state.minhaLoja? 
                        <span className="_user-location">{this.props.scamber? this.props.scamber.endereco?.length > 0? this.props.scamber.endereco[0].cidade?.toUpperCase() : <></>: <></>} - {this.props.scamber? this.props.scamber.endereco?.length > 0? this.props.scamber.endereco[0].estado?.toUpperCase(): <></>:<></>}</span>
                      : <span className="_user-location">{this.props.address && this.props.address[0]?.cidade} - {this.props.address && this.props.address[0]?.estado}</span>
                    }
                    
                  <div className="rating-stars">

                    {/* 
                        Usar {faStarSolid} para estrela completa
                        Usar {faStar} para estrela incompleta
                        -----------------

                        Usar className="star-0" ou className="1" para setar a cor da estrela (amarela ou cinza)

                    */}
                    <span className="star-0">
                      <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                    </span>
                    <span className="star-0">
                      <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                    </span>
                    <span className="star-0">
                      <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                    </span>
                    <span className="star-0">
                      <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                    </span>
                    <span className="star-0">
                      <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                    </span>
                  </div>
                </h1>
              </div>
            </div>

            {/*<div className="_profile-button-follow">
              <button type="button" className="btn btn-follow">
                Seguir
              </button>
            </div>*/}
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    scamber: state.scamberReducer.scamber,

  }
}

export default connect(mapStateToProps, { setScamber })(Header);
