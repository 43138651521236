import React, {Component, useState} from 'react';

import {Button, Modal} from 'react-bootstrap';


class ModalBase extends Component {

    constructor() {
        super();
        this.state = {show: false};
    }

    setShow = (show) => {
        this.setState({show: show})
    }
     
    handleClose = () => {this.setShow(false)};
    handleShow = () => {this.setShow(true)};

    render () {
    
      return (
        <>
            <Modal size={this.props.size} show={this.state.show} fullWidth={"full-width"} onHide={this.handleClose} centered>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                <div className={"_title _fw700 " +this.props.className}>
                    {this.props.modalTitle}
                    <span className="_subtitle _fw700">
						{this.props.modalSubTitle}
                        <br/>
						{this.props.modalSubTitleLine2}
                    </span>
                </div>
                    {this.props.children}
                </Modal.Body>
            </Modal>
        </>
      );
    
}
}

export default ModalBase;