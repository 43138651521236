import React, { useEffect, useState } from "react";
import {withRouter} from "react-router-dom";

import categories from "../data/categories/data.json";

import EventEmitter from "../services/Events";

//===============================================================================================================
// #4 - Import * Font Awesome Icons
//===============================================================================================================
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from "@fortawesome/pro-regular-svg-icons";


const HistoryCategory = ({ children }) => {

    return (
      <div className="wrap-back-list">
        <div className="back-list">
          {children}
        </div>
      </div>
    )
  }
  
  const HistoryItem = ({ text, onClick }) => {
  
    return (
      <button type="button" className="back-list-item" onClick={onClick}>
        <div className="icon">
          <Icon icon={faChevronLeft} />
        </div>
        <div className="text">
          {text}
        </div>
      </button>
    )
  }

//===============================================================================================================
// #3 - Import * Account Layout Base
//===============================================================================================================
const MenuCategory = ({ children }) => {

    return (
        <ul className="menu-list">
            {children}
        </ul>
    )
}

//===============================================================================================================
// #3 - Import * Account Layout Base
//===============================================================================================================
const MenuCategoryItem = ({ text, inputType, inputName, onClick }) => {

    return (
        <li>
            <label className="select-item" onClick={onClick}>
                <input type={inputType ? inputType : "radio"} name={inputName} />
                <div className="text">
                    {text}
                </div>
                <div className="icon">
                    <Icon icon={faChevronRight} />
                </div>
            </label>
        </li>
    )
}

function CategoryMenu(props) {

    // * States
    const [filter, setFilter] = useState();

    //Lista Original de categorizações
    const [listCategorization, setListCategorization] = useState([]);

    //Lista ativa e visivel para o usuario no momento
    const [listActiveCategorization, setListActiveCategorization] = useState([]);

    //Parametros selecionados pelo usuario
    const [listSelectedCategorization, setListSelectedCategorization] = useState(
        []
    );

    //Label final concatenato de categorizações
    const [allCategorizationLabel, setAllCategorizationLabel] = useState("");

    //Label categorização selecionada
    const [category, setCategory] = useState("");

    useEffect(() => {
        setListCategorization(categories);
        setListActiveCategorization(categories);
    }, []);

    useEffect(() => {
        setAllCategorizationLabel(
            listSelectedCategorization.reduce((label, selected, index) => {
                return (label += ` ${index > 0 ? ">" : ""} ${selected.categorization.nome
                    }`);
            }, "")
        );
    }, [
        listSelectedCategorization.length,
        listSelectedCategorization[listSelectedCategorization.length - 1]
    ]);

    const handleCategorizationBack = (oldCategorizationList, index) => {
        const listSelected = listSelectedCategorization.slice(0, index);
        setListSelectedCategorization(listSelected);

        setListActiveCategorization(oldCategorizationList);

        saveSelectedCategorizations(listSelected);
    };

    const handleCategorization = (categorization) => {
        setCategory(categorization.nome);

        let listSelected;
        if (categorization.categorias && categorization.categorias.length > 0) {
            setListActiveCategorization(categorization.categorias);

            listSelected = listSelectedCategorization;
            // listSelected = categorization.filtros_dependencias ? categorization.filtros_dependencias.concat(listSelectedCategorization) : listSelectedCategorization;

            listSelected.push({
                oldCategorizationList: listActiveCategorization,
                categorization: categorization
            });

            setListSelectedCategorization(listSelected);
            saveSelectedCategorizations(listSelected, categorization);
        } else if (
            categorization.tipos_produtos &&
            categorization.tipos_produtos.length > 0
        ) {
            setListActiveCategorization(categorization.tipos_produtos);

            listSelected = listSelectedCategorization;
            // listSelected = categorization.filtros_dependencias ? categorization.filtros_dependencias.concat(listSelectedCategorization) : listSelectedCategorization;

            listSelected.push({
                oldCategorizationList: listActiveCategorization,
                categorization: categorization
            });
            setListSelectedCategorization(listSelected);
            saveSelectedCategorizations(listSelected, categorization);
        } else if (categorization.produtos && categorization.produtos.length > 0) {
            setListActiveCategorization(categorization.produtos);

            listSelected = listSelectedCategorization;
            // listSelected = categorization.filtros_dependencias ? categorization.filtros_dependencias.concat(listSelectedCategorization) : listSelectedCategorization;

            listSelected.push({
                oldCategorizationList: listActiveCategorization,
                categorization: categorization
            });
            setListSelectedCategorization(listSelected);
            saveSelectedCategorizations(listSelected, categorization);
        } else {
            listSelected = listSelectedCategorization;
            // listSelected = categorization.filtros_dependencias ? categorization.filtros_dependencias.concat(listSelectedCategorization) : listSelectedCategorization;

            const lastIndex = listSelected.length > 0 ? listSelected.length - 1 : 0;

            if (listSelected[lastIndex] && listSelected[lastIndex].lastLevel) {
                listSelected[lastIndex] = {
                    oldCategorizationList: listActiveCategorization,
                    categorization: categorization,
                    lastLevel: true
                };
                setListActiveCategorization([]);
                setListSelectedCategorization(listSelected);

                setFilter(undefined);
                saveSelectedCategorizations(listSelected, categorization);

                historyPushCat(
                    listSelected[listSelected.length - 1].categorization._id
                );
                // Close Menu Category Modal
                props.onCloseModal();

                // Clear Categories
                handleCategorizationBack(listSelected[0].oldCategorizationList, listSelected[listSelected.length]);

                // Clear Selected Categories
                setListSelectedCategorization([]);

            } else {
                listSelected.push({
                    oldCategorizationList: listActiveCategorization,
                    categorization: categorization,
                    lastLevel: true
                });

                setListActiveCategorization([]);
                setListSelectedCategorization(listSelected);

                setFilter(undefined);
                saveSelectedCategorizations(listSelected, categorization);

                historyPushCat(
                    listSelected[listSelected.length - 1].categorization._id
                );
                
                // Close Menu Category Modal
                props.onCloseModal();

                // Clear Categories
                handleCategorizationBack(listSelected[0].oldCategorizationList, listSelected[listSelected.length]);

                // Clear Selected Categories
                setListSelectedCategorization([]);
            }
        }
    };

    const historyPushCat = (lastId) => {
        props.history.push({
          pathname: "/resultados",
          search: `?category=${lastId}&limit=8`
        });

        // setTimeout(() => {

        //     setListActiveCategorization(listCategorization);
    
        //     // Clear Selected Categories
        //     setListSelectedCategorization([]);
        // }, 1000)
    };

    const saveSelectedCategorizations = (categorizations, category) => {
        
        let listCat = JSON.parse(JSON.stringify(categorizations));
        
        if(category && category.filtros_dependencias ) {
            listCat = category.filtros_dependencias.concat(listCat);
            setFilter(category.filtros_dependencias);

        } else if(filter) {
            listCat = filter.concat(listCat);
        }

        localStorage.setItem(
            "categorizations",
            JSON.stringify(
                listCat.map((cat) => {
                    delete cat.oldCategorizationList;
                    delete cat.categorias;
                    delete cat.tipos_produtos;
                    delete cat.produtos;

                    if(cat.categorization) {
                        return cat.categorization;
                    } else {
                        return cat;
                    }
                })
            )
        );
    };

    const redirectSeeAll = () => {

        if(listSelectedCategorization && listSelectedCategorization.length > 0) {
            historyPushCat(
                listSelectedCategorization[listSelectedCategorization.length - 1].categorization._id
            );
        } else {
            props.history.push({
                pathname: "/resultados",
                search: `?limit=8`
            });
        }

        props.onCloseModal();
        
        // handleCategorizationBack(listCategorization, listSelectedCategorization[listSelectedCategorization.length]);
        
    } 

    React.useEffect(() => {
        EventEmitter.subscribe("close-dialog-cat", () => {
            setTimeout(() => {

                setListActiveCategorization(listCategorization);
        
                // Clear Selected Categories
                setListSelectedCategorization([]);
            }, 1000)
        });
    }, [listCategorization])

    return (
        <div className="App">

            <HistoryCategory>
                {listSelectedCategorization.map((selectedCategorization, index) => {
                    if (index === 0) {
                        return (<HistoryItem key={index} text={selectedCategorization.categorization.nome} onClick={() => handleCategorizationBack(selectedCategorization.oldCategorizationList, index) } />);
                    } else {
                        return (<HistoryItem text={selectedCategorization.categorization.nome} onClick={() =>handleCategorizationBack( selectedCategorization.oldCategorizationList, index )} />);
                    }
                })}

            </HistoryCategory>

            <MenuCategory>
                <MenuCategoryItem text="Ver Todos" inputType="radio" inputName="category_select" onClick={redirectSeeAll} />
                {listActiveCategorization?.map((category) => (
                    <MenuCategoryItem key={category._id} text={category.nome} inputType="radio" inputName="category_select" onClick={() => handleCategorization(category)} />
                ))}

            </MenuCategory>

        </div>
    );
}

export default withRouter(CategoryMenu);
