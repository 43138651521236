import React from "react";
import { Link } from "react-router-dom";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp } from "@fortawesome/pro-light-svg-icons";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";

function MessageSuccess (props) {

  return (
    <div className="wrap-messages success">
      <div className={"message-item " + props.direction}>
        <div className="message">
          <Link to={props.link} className="message-head">
            <div className="_left">
              <div className="icon">
                <FontAwesomeIcon icon={faThumbsUp}></FontAwesomeIcon>
              </div>
              <div className="text"> {props.title} </div>
            </div>

          </Link>

          <div className="message-content">
            {props.children}
          </div>

          <div className="message-link">
            <Link to={props.link}> {props.linkText} 
            <div className="_right">
              <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
            </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageSuccess;
