import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useHistory, withRouter } from "react-router-dom";

import Endereco from "../../services/Endereco";
import CreateIuguAccount from "../../services/CreateIuguAccount";
import { setScamber } from "../../redux/actions/scamberActions";
import { banks, accountType } from "../../data/bank-account";
import { connect } from "react-redux";

import { useForm } from "react-hook-form";

// * utils
import $ from "jquery";
import "jquery-mask-plugin";
import Swal from "sweetalert2";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";

import { Grid, TextField, useMediaQuery } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';

import { Wizard } from '../../components/design/navigation/wizard';
import { Headline } from "../../components/design/foundation/headline";
import Button from "../../components/design/form/button";
import AccountStatus from './components/AccountStatus';

import { Wrapper, WrapHeadline, WrapWizard, WizardActions, WizardContent, HDLine, Title, ButtonSubmit } from './styles';

const steps = ['Completar conta', 'Dados pessoais', 'Conta bancária'];

const endereco = new Endereco();
const account = new CreateIuguAccount();

const CompleteAccount = ({ scamber, header = true, className, children }) => {

    const matches = useMediaQuery("(min-width:600px)");
    const history = useHistory();

    // * React Hook Form
    const { register, handleSubmit, errors, reset } = useForm({ mode: "onChange" });
    const { register: registerForm, handleSubmit: handleSubmitForm, errors: errorsForm, reset: resetForm } = useForm({ mode: "onChange" });

    const [isLoading, setIsLoading] = useState(false);
    const [scamberAccount, setScamberAccount] = useState("");
    const [cep, setCep] = useState("");
    const [userAddress, setUserAddress] = useState({});
    const [selectedBank, setSelectedBank] = React.useState({ ag: "", cc: "" });

    // * Functions - React Mask
    const handleCepChange = (e) => setCep(e.target.value);

    const findAddressByCEP = () => {
        if (cep.length === 9) {
            endereco
                .findCep(cep)
                .then((address) => {
                    const response_address = {
                        cep: address.data.cep,
                        state: address.data.uf,
                        city: address.data.localidade,
                        address: address.data.logradouro,
                        bairro: address.data.bairro,
                        complemento: address.data.complemento,
                    };

                    if (!address.data?.erro) {
                        reset(response_address);
                    } else {
                        Swal.fire("", "CEP inválido", "info");
                    }
                })
                .catch((error) => {
                    Swal.fire("info", "Não foi possível consultar o endereço", "")
                });
        }
    };

    const handleFilterBank = (bankName) => {

        const bankList = [...banks];

        const filterBankName = bankList.find((bank) => bankName === bank.title);

        return setSelectedBank((bankPrev) => bankPrev = filterBankName);

    }

    const checkIuguAccount = async () => {

        setIsLoading(false)

        try {
            const response = await account.checkAccountStatus();

            if (response.data.payload !== null) {
                const isValidated = response.data.payload.isValidated;

                if (isValidated) {
                    setIsLoading(true);
                    setScamberAccount("validated");
                } else {
                    setIsLoading(true);
                    setScamberAccount("waiting");
                }

                return;
            } else {
                setIsLoading(true);
                setScamberAccount("notvalidated");
            }

        } catch (err) {
            Swal.fire("", "Erro ao checar conta iugu", "warning");
        }

    };

    const checkScamberAddress = useCallback((scamber) => {
        if (scamber && scamber.endereco && scamber.endereco.length > 0) {

            const { cpf, nome } = scamber;
            const obj = { cpf: cpf, titular_conta: nome };

            let endereco = scamber.endereco[0];

            const phone = `(${scamber.telefone[0].ddd}) ${scamber.telefone[0].numero.substring(0, 5)}-${scamber.telefone[0].numero.substring(5, 9)}`
            const response_address = {
                cep: endereco.cep,
                state: endereco.estado,
                city: endereco.cidade,
                address: endereco.logradouro,
                bairro: endereco.bairro,
                complemento: endereco.complemento,
                numero: endereco.numero,
                telephone: `${phone}`
            };

            reset(response_address);
            resetForm(obj);

        }
    }, []);

    useEffect(() => {

        checkScamberAddress(scamber);
    }, [checkScamberAddress, scamber]);


    useEffect(() => {

        checkIuguAccount();

        // * Field Masks
        $(".cep").mask("00000-000");
        // $(".bank-ag").mask(selectedBank?.ag || "0000-0");
        // $(".bank-cc").mask(selectedBank?.cc || "00000000-0");
        $(".house_number").mask("00000");
        $(".cpf").mask("000.000.000-00", { reverse: false });
        $(".phone").mask("(00) 00000-0000");

    }, []);

    const submitAddress = async (data) => {
        if (data) {

            try {
                const { cep, state, city, address, numero, bairro, telephone, complemento } = await data;

                setUserAddress((prevAddress) => {
                    return {
                        cep: cep,
                        state: state,
                        city: city,
                        address: address,
                        numero: numero,
                        bairro: bairro,
                        telephone: telephone,
                        complemento: complemento,
                    }
                })
                wizardNavigation("next")

            } catch (error) {
                Swal.fire("", "Preencha o formulário corretamente.", "info");
            }
        }
    };

    const submitBankAccount = async (data) => {

        Swal.fire({
            title: "Aguarde...",
            allowEscapeKey: true,
            allowOutsideClick: false,
            showCloseButton: false,
            showConfirmButton: false,
            showLoaderOnConfirm: true,
            didOpen: () => {
                Swal.showLoading()
            }
        });

        const { cpf, bank, account_type, bank_ag, bank_dig_ag, bank_cc, bank_dig_cc } = await data;
        const { telephone, cep, address, city, state, numero } = userAddress;

        const bankAccount = {
            cpf: cpf,
            telephone: telephone,
            address: {
                cep: cep,
                address: `${address}, ${numero}`,
                city: city,
                state: state
            },
            bankAccountInfo: {
                bank: bank,
                account_type: account_type,
                bank_ag: `${bank_ag}${bank_dig_ag ? '-' + bank_dig_ag : ''}`,
                bank_cc: `${bank_cc}${bank_dig_cc ? '-' + bank_dig_cc : ''}`,
            }
        }

        try {

            const response = await account.create(bankAccount);

            if (response.status === 200) {

                Swal.fire({
                    title: "Dados alterados!",
                    html: `a aprovação da conta bancária leva até 24 horas para ser efetivada. <br /><br />`+
                    `você pode acompanhar o status clicando em “Meu Perfil” no menu. <br /><br />`+
                    `até que a sua conta bancária seja aprovada, você pode cadastrar produtos mas eles não ficam disponíveis para venda.<br /> 
                    Depois da aprovação, eles são automaticamente liberados.`,
                    icon: "success",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    dangerMode: true
                }).then((isConfirm) => {
                    if (isConfirm) {
                        history.push("/meu-perfil");
                    }
                });

            }

        } catch (error) {
            const response = error.response.data.payload;

            if (response && response.errors) {
                Swal.fire("", response.errors[0].msg, "info");
            } else {
                Swal.fire("", error.response.data.message, "info");
            }
        }

    }

    // Account Status
    let contentType;

    switch (scamberAccount) {
        case "validated":
            contentType = <AccountStatus
                title="Conta validada!"
                link="#!!" text="Agora voce ja e um scamber!."
                icon={<Icon icon={["far", "check"]} />} iconColor="green"
                showArrowIcon={false}
            />;
            break;

        case "waiting":
            contentType = <AccountStatus
                title="Aguardando validação"
                link="#!!" text="Clique aqui para completar os dados da sua conta scamb."
                icon={<Icon icon={["far", "clock"]} />} iconColor="#e7b78a"
                showArrowIcon={false}
            />;
            break;

        case "notvalidated":
            contentType = "";
            break;

        default: contentType = "";
    }

    // Wizard
    const wizardRef = useRef();

    const [activeStep, setActiveStep] = useState(0);

    const handleNextStep = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);
    const handlePrevStep = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

    const wizardNavigation = (action) => {

        switch (action) {
            case "next":
                wizardRef.current.nextStep(handleNextStep())
                break;

            case "prev":
                wizardRef.current.prevStep(handlePrevStep())
                break;

            default:
                setActiveStep(0);
                break;
        }
    }

    return (
        <Wrapper>

            {header && (
                <WrapHeadline>
                    <Headline
                        type="big"
                        title="COMPLETE DADOS DE SUA CONTA"
                        titleColor="#515157"
                        subTitleColor="#515157"
                        subTitle="para que você possa receber o valor de suas próximas vendas!"
                        withLink={false}
                        linkText="Ver mais.."
                        textAlign="center"
                        className="x21line"
                    />
                </WrapHeadline>
            )}

            <WrapWizard className={className ? className : ""}>
                {contentType}
                <Wizard steps={steps} activeStep={0} ref={wizardRef}>

                    <WizardContent>
                        <div style={{ display: `${activeStep === 0 ? 'block' : 'none'}` }}>
                            <HDLine>
                                <Title>
                                    A partir de Dezembro temos uma mudança importante no Scamb!<br />
                                    Vendedores passam a receber em Reais pela venda de seus produtos.
                                </Title>
                                <br />
                                <Title>
                                    Por isso, precisamos que todos os usuários que têm produtos à
                                    venda no Scamb atualizem seus cadastros, incluindo conta corrente
                                    para receber o valor de vendas futuras.
                                </Title>
                                <br />
                                <Title>
                                    Clique aqui para atualizar seus dados e não perder
                                    nenhuma oportunidade de venda!
                                </Title>
                            </HDLine>

                            <WizardActions>
                                <Button
                                    type="default"
                                    buttonSize="medium"
                                    buttonColor="#E9E9E9"
                                    textColor="#777"
                                    buttonText="Voltar"
                                    onClick={() => wizardNavigation("prev")}
                                    disabled={activeStep === 0}
                                    maxWidth={140}
                                />

                                <Button
                                    type="submit"
                                    buttonSize="medium"
                                    buttonColor="#14233E"
                                    textColor="#FFFFFF"
                                    buttonText="Próximo"
                                    onClick={() => wizardNavigation("next")}
                                    maxWidth={140}
                                />
                            </WizardActions>
                        </div>

                        <div style={{ display: `${activeStep === 1 ? 'block' : 'none'}` }}>

                            <HDLine>
                                <Title>
                                    Preencha seus dados
                                    <span>
                                        Endereço e conta devem ser do CPF cadastrado no Scamb.
                                    </span>
                                </Title>
                            </HDLine>
                            <form onSubmit={handleSubmit(submitAddress)}>

                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            onChange={handleCepChange}
                                            onBlur={findAddressByCEP}
                                            id="outlined-cc-number"
                                            label="Cep *"
                                            type="text"
                                            variant="outlined"
                                            name="cep"
                                            size={matches ? "small" : "small"}
                                            error={!!errors.cep}
                                            inputProps={{ className: "cep" }}
                                            fullWidth
                                            inputRef={register({
                                                required: true,
                                                minLength: 8,
                                            })}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            id="outlined-state"
                                            label="Estado *"
                                            variant="outlined"
                                            size={matches ? "small" : "small"}
                                            name="state"
                                            error={!!errors.state}
                                            fullWidth
                                            inputProps={{ maxLength: 2 }}
                                            inputRef={register({
                                                required: true,
                                            })}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            id="outlined-city"
                                            label="Cidade *"
                                            variant="outlined"
                                            size={matches ? "small" : "small"}
                                            name="city"
                                            error={!!errors.city}
                                            fullWidth
                                            inputRef={register({
                                                required: true,
                                            })}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField
                                            id="outlined-address"
                                            label="Endereço *"
                                            variant="outlined"
                                            size={matches ? "small" : "small"}
                                            name="address"
                                            error={!!errors.address}
                                            fullWidth
                                            inputRef={register({
                                                required: true,
                                            })}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            id="outlined-address-number"
                                            label="Número *"
                                            type="number"
                                            variant="outlined"
                                            inputProps={{ className: "house_number" }}
                                            size={matches ? "small" : "small"}
                                            name="numero"
                                            error={!!errors.numero}
                                            fullWidth
                                            inputRef={register({
                                                required: true,
                                            })}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="outlined-neighborhood"
                                            label="Bairro *"
                                            variant="outlined"
                                            size={matches ? "small" : "small"}
                                            name="bairro"
                                            error={!!errors.bairro}
                                            fullWidth
                                            inputRef={register({
                                                required: true,
                                            })}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="outlined-telephone"
                                            label="Telefone *"
                                            variant="outlined"
                                            size={matches ? "small" : "small"}
                                            name="telephone"
                                            error={!!errors.telephone}
                                            fullWidth
                                            inputProps={{ className: "phone" }}
                                            inputRef={register({
                                                required: true,
                                            })}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="outlined-complement"
                                            label="Complemento"
                                            variant="outlined"
                                            name="complemento"
                                            size={matches ? "small" : "small"}
                                            fullWidth
                                            inputRef={register()}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <WizardActions>
                                    <Button
                                        type="default"
                                        buttonSize="medium"
                                        buttonColor="#E9E9E9"
                                        textColor="#777"
                                        buttonText="Voltar"
                                        onClick={() => wizardNavigation("prev")}
                                        disabled={activeStep === 0}
                                        maxWidth={140}
                                    />

                                    <ButtonSubmit
                                        type="submit"
                                        buttonSize="medium"
                                        buttonColor="#14233E"
                                        textColor="#FFFFFF"
                                        maxWidth={140}
                                    >Próximo</ButtonSubmit>
                                </WizardActions>


                            </form>
                        </div>

                        <div style={{ display: `${activeStep === 2 ? 'block' : 'none'}` }}>

                            <HDLine>
                                <Title>
                                    Dados bancários
                                    <span>
                                        Preencha seus dados bancários para receber em reais.
                                    </span>
                                </Title>
                            </HDLine>

                            <form onSubmit={handleSubmitForm(submitBankAccount)}>

                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="outlined-owner-account-name"
                                            label="Nome titular da conta *"
                                            variant="outlined"
                                            size={matches ? "small" : "small"}
                                            name="titular_conta"
                                            error={!!errorsForm.titular_conta}
                                            fullWidth
                                            inputRef={registerForm({
                                                required: true,
                                            })}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            onChange={handleCepChange}
                                            onBlur={findAddressByCEP}
                                            id="outlined-cpf"
                                            label="CPF *"
                                            type="text"
                                            variant="outlined"
                                            name="cpf"
                                            size={matches ? "small" : "small"}
                                            error={!!errorsForm.cpf}
                                            inputProps={{ className: "cpf" }}
                                            fullWidth
                                            inputRef={registerForm({
                                                required: true,
                                                minLength: 8,
                                            })}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>

                                    <Grid item md={12} xs={12} >
                                        <Autocomplete
                                            fullWidth
                                            size="small"
                                            id="bank-list"
                                            options={banks}
                                            getOptionLabel={(option) => option.title}
                                            renderInput={(params) => <TextField {...params} label="Banco" name="bank" variant="outlined" error={!!errorsForm.bank} inputRef={registerForm({
                                                required: true,
                                            })} />}
                                            onInputChange={(_, newInputValue) => handleFilterBank(newInputValue)}
                                        />
                                    </Grid>

                                    <Grid item md={12} xs={12} >
                                        <Autocomplete
                                            fullWidth
                                            size="small"
                                            id="bank-account-type"
                                            options={accountType}
                                            getOptionLabel={(option) => option.title}
                                            renderInput={(params) => <TextField {...params} label="Tipo de conta" name="account_type" variant="outlined" error={!!errorsForm.account_type} inputRef={registerForm({
                                                required: true,
                                            })} />}
                                        />
                                    </Grid>

                                    <Grid item xs={selectedBank?.dig_ag ? 8 : 12}>
                                        <TextField
                                            id="outlined-bank-ag"
                                            label="Agência *"
                                            variant="outlined"
                                            size={matches ? "small" : "small"}
                                            name="bank_ag"
                                            error={!!errorsForm.bank_ag}
                                            fullWidth
                                            inputProps={{ className: "bank-ag" }}
                                            inputRef={registerForm({
                                                required: true,
                                            })}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>

                                    {selectedBank?.dig_ag && (
                                        <Grid item xs={4}>
                                            <TextField
                                                id="outlined-bank-dig-ag"
                                                label="Digito Verificador *"
                                                variant="outlined"
                                                size={matches ? "small" : "small"}
                                                name="bank_dig_ag"
                                                error={!!errorsForm.bank_dig_ag}
                                                fullWidth
                                                // inputProps={{ className: "bank-ag" }}
                                                inputRef={registerForm({
                                                    required: true,
                                                })}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                    )}

                                    <Grid item xs={selectedBank?.dig_cc ? 8 : 12}>
                                        <TextField
                                            id="outlined-bank-cc"
                                            label="Número da conta *"
                                            variant="outlined"
                                            size={matches ? "small" : "small"}
                                            name="bank_cc"
                                            error={!!errorsForm.bank_cc}
                                            fullWidth
                                            inputProps={{ className: "bank-cc" }}
                                            inputRef={registerForm({
                                                required: true,
                                            })}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>

                                    {selectedBank?.dig_cc && (
                                        <Grid item xs={4}>
                                            <TextField
                                                id="outlined-bank-dig-cc"
                                                label="Digito Verificador *"
                                                variant="outlined"
                                                size={matches ? "small" : "small"}
                                                name="bank_dig_cc"
                                                error={!!errorsForm.bank_dig_cc}
                                                fullWidth
                                                // inputProps={{ className: "bank-ag" }}
                                                inputRef={registerForm({
                                                    required: true,
                                                })}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                    )}

                                </Grid>
                                <WizardActions>
                                    <Button
                                        type="default"
                                        buttonSize="medium"
                                        buttonColor="#E9E9E9"
                                        textColor="#777"
                                        buttonText="Voltar"
                                        onClick={() => wizardNavigation("prev")}
                                        disabled={activeStep === 0}
                                        maxWidth={140}
                                    />

                                    {activeStep === steps.length - 1 ? (

                                        !children ?
                                            <ButtonSubmit
                                                type="submit"
                                                buttonSize="medium"
                                                buttonColor="#14233E"
                                                textColor="#FFFFFF"
                                                maxWidth={140}
                                            >Finalizar</ButtonSubmit>
                                            : children
                                    ) : (

                                        <ButtonSubmit
                                            type="submit"
                                            buttonSize="medium"
                                            buttonColor="#14233E"
                                            textColor="#FFFFFF"
                                            maxWidth={140}
                                        >Próximo</ButtonSubmit>
                                    )}


                                </WizardActions>
                            </form>
                        </div>

                    </WizardContent>

                </Wizard>
            </WrapWizard>
        </Wrapper >
    )
}

const mapStateToProps = (state) => {
    return {
        scamber: state.scamberReducer.scamber,
    };
};

export default withRouter(connect(mapStateToProps, { setScamber })(CompleteAccount));