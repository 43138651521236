import Service from './Service';

class Showcase extends Service {

  constructor() {
    super();
  }

  getAvailableShowcase(website) {
    return this.axiosInstance.get(`/showcase${website? '?website=' + website: ''}`);
  }
}
export default Showcase;