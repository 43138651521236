import styled from "styled-components";

export const Wrapper = styled.div`

    ${({ theme }) => theme.media("xs", `
        background-color: #F1F1F1;
    `)}

    ${({ theme }) => theme.media("md", `
        background-color: #FFFFFF;
    `)}
`;

export const ShippingBlock = styled.div`
    padding: 0.3125rem 0.9375rem 1.25rem 0.9375rem;
`;

export const CupomBlock = styled.div`
    display: flex;
    width: 100%;
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
    padding-bottom: 1.25rem;
`;

export const FieldWrapper = styled.div`
    display: flex;
    width: 100%;

    .MuiFormControl-fullWidth {
        width: 100%;
    }

    .btn-add-payment.btn.btn-primary {
        width: 80px;
        margin-left: 10px;
    }
`;

export const PaymentTabs = styled.div`
    padding-bottom: 1.25rem;

    .react-tabs {

        padding-left: 0.9375rem;
        padding-right: 0.9375rem;

        .react-tabs__tab-list {
            border-bottom: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .react-tabs__tab {
                border: 0;
                padding: 0;
                width: 100%;
                margin-left: 10px;

                &:first-child {
                    margin-left: 0;
                }

            }

            .react-tabs__tab.react-tabs__tab--selected {
                &::after {
                    display: none;
                }

                &:focus {
                    box-shadow: none !important;
                }
            }
        }

        .react-tabs__tab-panel {

            .select-box {

                margin-bottom: 10px;
                
                &:last-child {
                    margin-bottom: 0;
                    label {
                        border-style: dashed;
                    }
                }

                ._cols {

                    label {

                        ._col {

                            .icon {

                                img {
                                    width: auto !important;
                                }

                                svg {
                                    path {
                                        opacity: 0.7;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const SelectParcels = styled.div`
    margin-top: 20px;
`;

export const SelectParcelsTitle = styled.h1`
    font-family: ${({ theme }) => theme.font.nunito} !important;
    font-weight: ${({ theme }) => theme.fontWeight.fw700};
    color: ${({ theme }) => theme.color.black};
    font-size: 1rem;
    margin-bottom: 0.9375rem;
`;

export const CheckoutActions = styled.div`

    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
    padding-bottom: 1.25rem;

    button {
        width: 100%;
        max-width: none;

        &:first-child {
            background-color: ${({ theme }) => theme.color.red};
            margin-bottom: 10px;
        }

        &:last-child {
            background-color: #f1f1f1 !important;
            color: #777 !important;
        }
    }
`;