import React, { useState } from "react";
import { connect } from 'react-redux';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faCalendarCheck, faCreditCard, faHandHoldingBox, faPiggyBank, faShippingFast, faStore, faWallet, faCameraRetro, faCommentEdit, faTruck, faHeart, faReceipt, faMobile, faThumbsUp, faEnvelope, faBoxes } from '@fortawesome/pro-light-svg-icons';

// * Material UI Components
import { useMediaQuery } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

// * UI Components
import { Container } from "../components/design/layout/container";
import { Headline } from "../components/design/foundation/headline";

// * Styled Components
import { LayoutBox, Description, Image, Title, Text, WrapHeroText, HeroText } from "./styles";


// Manifest Images
import people_1 from "../assets/images/institucional/pessoas-que-circulam-01.png";
import people_2 from "../assets/images/institucional/pessoas-que-circulam-02.png";
import people_3 from "../assets/images/institucional/pessoas-que-circulam-03.png";
import brands_1 from "../assets/images/institucional/marcas-que-circulam-01.png";
import brands_2 from "../assets/images/institucional/marcas-que-circulam-02.png";
import brands_3 from "../assets/images/institucional/marcas-que-circulam-03.png";

import Helmet from 'react-helmet';

const WrapBlocks = ({ title, blockColor, children, className }) => {

    return (
        <div className={`_wrap-block-grid-columns ${className ? className : ""} ${blockColor ? blockColor : ""}`}>

            <Headline
                type="big"
                title={title}
                titleColor="#515157"
                withLink={false}
            />

            <div className="_block-grid-columns">
                {children}
            </div>
        </div>
    )
}

const Block = ({ icon, title, subtitle, innerSubtitle }) => {

    return (
        <div className="_column-item">
            <div className="icon">
                {icon}
            </div>
            <div className="info">
                <h1 className="title">{title}</h1>
                <h2 className="subtitle">{subtitle} <br />{innerSubtitle}</h2>
            </div>
        </div>
    )
}

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <>{children}</>}
        </div>
    );
};

function Institutional(props) {

    // * Media Query
    const matches = useMediaQuery("(min-width:768px)");

    // * States
    const [tabValue, setTabValue] = useState(0);

    // * Tabs
    // * Material UI Tabs
    // * Props Tab
    function tabProps(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    }

    // * Material UI Tabs
    // * On Change Tab
    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <main role="main" id="view_how-it-works">
            <Helmet title={`${props.site_properties?.title} - Como funciona`} />

            <Container className="main-container" withContainer={false}>
                <Headline
                    type="big"
                    title="Como funciona"
                    titleColor="#515157"
                    subTitleColor="#515157"
                    subTitle="Existem duas áreas para circular no Scamb."
                    withLink={false}
                    linkText="Ver mais.."
                    textAlign="center"
                    className="x21line"
                />
            </Container>

            <Container className="tabs-container" withContainer={false}>
                <Tabs
                    value={tabValue}
                    onChange={handleChange}
                    aria-label="Tabs Help"
                    className="_material-tabs"
                >
                    <Tab label="Pessoas que circulam" {...tabProps(0)} />
                    <Tab label="Marcas que circulam" {...tabProps(1)} />

                </Tabs>

            </Container>

            <TabPanel value={tabValue} index={0} className="tab-panel">
                <WrapHeroText>
                    <HeroText color="#14233e">
                        Área de lojinhas de pessoas que querem economizar e consumir
                        mais conscientemente.<br /> Aqui os produtos são comprados e
                        vendidos em pontos!
                    </HeroText>
                </WrapHeroText>

                <Container className="main-container" withContainer={true}>


                    <LayoutBox marginBottom={matches ? 60 : 20} className="_Box">
                        <Grid container spacing={matches ? 6 : 2}>
                            <Grid item sm={5} xs={5}>
                                <Image src={people_1} />
                            </Grid>
                            <Grid item sm={6} xs={7}>
                                <Description>
                                    <Title textAlign={matches ? "left" : "left"}>
                                        Junte pontos
                                    </Title>
                                    <Text textAlign={matches ? "left" : "left"}>
                                        Acumule pontos vendendo suas coisas, participando das promos ou comprando no Marcas que Circulam
                                    </Text>
                                </Description>
                            </Grid>
                        </Grid>
                    </LayoutBox>

                    <LayoutBox marginBottom={matches ? 60 : 20} className="_Box">
                        <Grid container spacing={matches ? 6 : 2}>

                            <Grid item sm={5} xs={7}>
                                <Description>
                                    <Title textAlign={matches ? "right" : "right"}>
                                        Use seus pontos
                                    </Title>
                                    <Text textAlign={matches ? "right" : "right"}>
                                        Compre as coisas de segunda mão pagando com pontos na plataforma.
                                    </Text>
                                </Description>
                            </Grid>

                            <Grid item sm={5} xs={5}>
                                <Image src={people_2} />
                            </Grid>
                            <Grid item sm={1} xs={0}></Grid>
                        </Grid>
                    </LayoutBox>

                    <LayoutBox marginBottom={matches ? 40 : 20} className="_Box">
                        <Grid container spacing={matches ? 6 : 2}>
                            <Grid item sm={5} xs={5}>
                                <Image src={people_3} />
                            </Grid>
                            <Grid item sm={6} xs={7}>
                                <Description>
                                    <Title textAlign={matches ? "left" : "left"}>
                                        Repita
                                    </Title>
                                    <Text textAlign={matches ? "left" : "left"}>
                                        Faça parte da real circularidade! Aqui você economiza e ainda consome de forma mais sustentável. Faz o bem e se dá bem!
                                    </Text>
                                </Description>
                            </Grid>
                        </Grid>
                    </LayoutBox>

                    <Grid container spacing={2} className="GridBlockLinks">
                        <Grid item sm={6} xs={6}>
                            <a href="#BlockSell" className="block-link">Vender</a>
                        </Grid>
                        <Grid item sm={6} xs={6}>
                            <a href="#BlockBuy" className="block-link">Comprar</a>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>

                        <Grid item sm={12} xs={12} id="BlockSell">
                            <WrapBlocks title="Vender" blockColor="white">
                                <Block
                                    icon={<FontAwesomeIcon icon={faBoxes} />}
                                    title="Separe os produtos"
                                    subtitle="que não têm mais utilidade para você mas que ainda têm valor para outra pessoa."
                                />
                                <Block
                                    icon={<FontAwesomeIcon icon={faCameraRetro} />}
                                    title="Tire fotos atrativas"
                                    subtitle="escolha um lugar iluminado, mostre à frente, verso e detalhes da peça."
                                />
                                <Block
                                    icon={<FontAwesomeIcon icon={faStore} />}
                                    title="Crie sua loja"
                                    subtitle="e cadastre seus produtos pelo celular ou pelo computador. É totalmente free!"
                                />
                                <Block
                                    icon={<FontAwesomeIcon icon={faCommentEdit} />}
                                    title="Descreva seu produto"
                                    subtitle="com clareza, contando o estado de conservação e para quem será útil."
                                />
                                <Block
                                    icon={<div className="pts">R$<span className="smallcase">pts</span></div>}
                                    title="Defina o valor da sua peça"
                                    subtitle="em reais - e a plataforma converte em pontos automaticamente."
                                />
                                <Block
                                    icon={<FontAwesomeIcon icon={faEnvelope} />}
                                    title="Quando vender seu produto"
                                    subtitle="você receberá um email informando da venda."
                                />
                                <Block
                                    icon={<FontAwesomeIcon icon={faHandHoldingBox} />}
                                    title="Entrega em mãos"
                                    subtitle="o Scamb ajuda a combinar com o comprador como ela será feita."
                                />
                                <Block
                                    icon={<FontAwesomeIcon icon={faTruck} />}
                                    title="Entrega por Correios"
                                    subtitle="você receberá um email com a etiqueta e as instruções para postar, é super fácil!"
                                />
                                <Block
                                    icon={<FontAwesomeIcon icon={faPiggyBank} />}
                                    title="Receba seus pontos"
                                    subtitle="quando o comprador receber o produto e der ok, os pontos da venda vão para sua carteira!"
                                />
                                <Block
                                    icon={<FontAwesomeIcon icon={faShoppingCart} />}
                                    title="Use seus pontos"
                                    subtitle="você pode usar seus pontos para comprar o que quiser no Scamb."
                                />
                            </WrapBlocks>

                        </Grid>

                        <Grid item sm={12} xs={12} id="BlockBuy">

                            <WrapBlocks title="Comprar" blockColor="white">
                                <Block
                                    icon={<FontAwesomeIcon icon={faHeart} />}
                                    title="Comprar no Scamb é muito fácil"
                                    subtitle="se você achou algo que queira, pode comprar NA HORA em poucos cliques!"
                                />
                                <Block
                                    icon={<div className="pts">pts<span>r$</span></div>}
                                    title="Para ficar bem transparente"
                                    subtitle="os preços dos produtos aparecem sempre em pontos e o seu equivalente em reais."
                                />
                                <Block
                                    icon={<FontAwesomeIcon icon={faShoppingCart} />}
                                    title="Compre sem pontos na carteira"
                                    subtitle="basta adicionar o produto que você quer na sua sacola e ir para o checkout!"
                                />
                                <Block
                                    icon={<><FontAwesomeIcon icon={faWallet} /><div className="pts">pts</div></>}
                                    title="Se você tem pontos"
                                    subtitle="você vai pagar usando seus pontos."
                                />
                                <Block
                                    icon={<><FontAwesomeIcon icon={faWallet} /><div className="pts">R$</div></>}
                                    title="Se você não tem pontos"
                                    subtitle="no checkout vc vai automaticamente comprar pontos e pagar sua compra em reais."
                                />
                                <Block
                                    icon={<FontAwesomeIcon icon={faReceipt} />}
                                    title="Taxa Scamb"
                                    subtitle="é o valor que cobramos para a manutenção da plataforma, paga pelo comprador."
                                />
                                <Block
                                    icon={<FontAwesomeIcon icon={faShippingFast} />}
                                    title="Frete"
                                    subtitle="pago pelo comprador. Entrega para qualquer lugar do Brasil."
                                />
                                <Block
                                    icon={<FontAwesomeIcon icon={faCreditCard} />}
                                    title="Cartão, pix ou boleto"
                                    subtitle="pague com uma das três opções. Parcelamos em até 6X sem juros nas compras acima de R$50."
                                />
                            </WrapBlocks>
                        </Grid>

                    </Grid>

                </Container>

            </TabPanel>

            <TabPanel value={tabValue} index={1} className="tab-panel">

                <WrapHeroText>
                    <HeroText color="#681e36">
                        Área dedicada a Marcas que colocam para circular peças bacanas que estavam paradas mas que ainda têm muito valor: amostras, coleções passadas etc
                    </HeroText>
                </WrapHeroText>

                <Container className="main-container" withContainer={true}>

                    <LayoutBox marginBottom={matches ? 60 : 20} className="_Box">
                        <Grid container spacing={matches ? 6 : 2}>
                            <Grid item sm={5} xs={5}>
                                <Image src={brands_1} />
                            </Grid>
                            <Grid item sm={6} xs={7}>
                                <Description>
                                    <Title textAlign={matches ? "left" : "left"}>
                                        Conheça marcas bacanas
                                    </Title>
                                    <Text textAlign={matches ? "left" : "left"}>
                                        Aqui você encontra marcas que buscam criar um negócio mais sustentável, promovem a circularidade e acreditam em novas formas de consumo.
                                    </Text>
                                </Description>
                            </Grid>
                        </Grid>
                    </LayoutBox>

                    <LayoutBox marginBottom={matches ? 60 : 20} className="_Box">
                        <Grid container spacing={matches ? 6 : 2}>

                            <Grid item sm={5} xs={7}>
                                <Description>
                                    <Title textAlign={matches ? "right" : "right"}>
                                        Encontre peças incríveis
                                    </Title>
                                    <Text textAlign={matches ? "right" : "right"}>
                                        Aqui você tem acesso a peças únicas que estavam paradas, por
                                        um valor incrível.<br /> Afinal, uma peça de outra coleção ou com um
                                        pequeno defeito pode e deve ter vida muito longa.
                                    </Text>
                                </Description>
                            </Grid>

                            <Grid item sm={5} xs={5}>
                                <Image src={brands_2} />
                            </Grid>

                            <Grid item sm={1} xs={0}></Grid>
                        </Grid>
                    </LayoutBox>

                    <LayoutBox marginBottom={matches ? 40 : 20} className="_Box">
                        <Grid container spacing={matches ? 6 : 2}>
                            <Grid item sm={5} xs={5}>
                                <Image src={brands_3} />
                            </Grid>
                            <Grid item sm={6} xs={7}>
                                <Description>
                                    <Title textAlign={matches ? "left" : "left"}>
                                        Pague em reais e ganhe pontos
                                    </Title>
                                    <Text textAlign={matches ? "left" : "left"}>
                                        Os produtos no Marcas são vendidos em Reais.<br/> Em cada compra,
                                        você ganha pontos para usar nas lojinhas das pessoas e
                                        continuar renovando no Scamb!
                                    </Text>
                                </Description>
                            </Grid>
                        </Grid>
                    </LayoutBox>

                    <Grid container spacing={2} className="GridBlockLinks Brands">
                        <Grid item sm={6} xs={6}>
                            <a href="#BlockBuyB" className="block-link">Comprar</a>
                        </Grid>
                        <Grid item sm={6} xs={6}>
                            <a href="#PointsBack" className="block-link">Pointsback</a>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>

                        <Grid item sm={12} xs={12} id="BlockBuyB">

                            <WrapBlocks title="Comprar" blockColor="white" className="brands">
                                <Block
                                    icon={<FontAwesomeIcon icon={faMobile} />}
                                    title="Visite as lojas de marcas parceiras"
                                    subtitle="navegando pelo Marcas que Circulam."
                                />
                                <Block
                                    icon={<FontAwesomeIcon icon={faHeart} />}
                                    title="Encontre “achados” das marcas"
                                    subtitle="como peças de coleções passadas, com pequenos defeitos ou amostras. Surpreenda-se!"
                                />
                                <Block
                                    icon={<FontAwesomeIcon icon={faShoppingCart} />}
                                    title="Coloque seus achados no carrinho"
                                    subtitle="em um único clique, super fácil e rápido."
                                />
                                <Block
                                    icon={<><FontAwesomeIcon icon={faWallet} /><div className="pts">R$</div></>}
                                    title="Pague em reais"
                                    subtitle="apenas o valor da compra, sem nenhuma taxa adicional."
                                />
                                <Block
                                    icon={<FontAwesomeIcon icon={faCreditCard} />}
                                    title="Cartão, pix ou boleto"
                                    subtitle="aceitamos as três opções. Parcelamos em até 6X sem juros nas compras acima de R$50."
                                />
                                <Block
                                    icon={<FontAwesomeIcon icon={faShippingFast} />}
                                    title="Frete"
                                    subtitle="pago pelo comprador. Entrega para qualquer lugar do Brasil."
                                />
                                <Block
                                    icon={<><FontAwesomeIcon icon={faWallet} /><div className="pts">pts</div></>}
                                    title="Ganhe pontos"
                                    subtitle="você ainda é premiado com pontos para continuar circulando no Scamb."
                                />
                            </WrapBlocks>
                        </Grid>

                        <Grid item sm={12} xs={12} id="PointsBack">

                            <WrapBlocks title="Pointsback" blockColor="white" className="brands">
                                <Block
                                    icon={<div className="pts">R$<span className="smallcase">pts</span></div>}
                                    title="Pague em reais e ganhe pontos"
                                    subtitle="a cada produto comprado no Marcas que Circulam."
                                />
                                <Block
                                    icon={<FontAwesomeIcon icon={faMobile} />}
                                    title="No anúncio dos produtos"
                                    subtitle="você pode ver quantos pontos você vai ganhar quando fizer uma compra."
                                />
                                <Block
                                    icon={<><FontAwesomeIcon icon={faWallet} /><div className="pts">R$</div></>}
                                    title="Pontos disponíveis na carteira"
                                    subtitle="assim que o recebimento do produto for confirmado e a compra finalizada."
                                />
                                <Block
                                    icon={<FontAwesomeIcon icon={faShoppingCart} />}
                                    title="Use seus pontos bônus"
                                    subtitle="para comprar nas lojinhas dentro da área Pessoas que Circulam."
                                />
                                <Block
                                    icon={<FontAwesomeIcon icon={faCalendarCheck} />}
                                    title="Os pontos bônus expiram"
                                    subtitle="em 24 meses a partir do momento do crédito na carteira."
                                />
                                <Block
                                    icon={<FontAwesomeIcon icon={faThumbsUp} />}
                                    title="Faz o bem e se dá bem!"
                                    subtitle="Aqui você encontra achados incríveis e participa da real circularidade."
                                />
                            </WrapBlocks>
                        </Grid>

                    </Grid>
                </Container>
            </TabPanel>

        </main>
    )

}

const mapStateToProps = (state) => ({
    site_properties: state.websiteReducer.site_properties
})

export default connect(mapStateToProps, {})(Institutional);