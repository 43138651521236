import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faEdit, faCheck, faTimes, faInfoCircle } from "@fortawesome/pro-light-svg-icons";


// Material - UI
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  Slide,
  makeStyles,
  Typography,
  Grid,
  TextField
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import Store from './../../../services/Store';

// Styles
const useStyles = makeStyles({
  center: {
    margin: "auto",
    display: "block",
  },
  resize: {
    fontSize: "10px",
  },
  inputSize: {
    height: 50,
  },
  svgSize: {
    width: "18px !important",
    height: "auto !important",
  },
});

// Modal Dialog Base
const ModalDialog = forwardRef((props, ref) => {
  // Styles
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:600px)");

  // Material ui Modal
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useImperativeHandle(ref, () => ({
    openModal: () => handleClickOpen(),
    closeModal: () => handleClose(),
  }));

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={true}
        open={open}
        maxWidth={"sm"}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle className="dialog-title" id="responsive-dialog-title">
          <div className="_view-head-content">
            <button type="button" className="btn" onClick={handleClose}>
              <FontAwesomeIcon
                icon={matches ? faTimes : faChevronLeft}
              ></FontAwesomeIcon>
            </button>
            <div className="_title-view">{props.dialogTitle}</div>
          </div>
        </DialogTitle>
        <DialogContent className={props.className}>
          <Typography component={"span"} variant={"body2"}>
            {props.children}
          </Typography>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </>
  );
});

// Material ui Dialog Slide
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function StoreForm(props) {

    // Ref
    const refInfoBio = React.createRef();

    // Styles
    const classes = useStyles();
    const matches = useMediaQuery("(min-width:600px)");
  
    // Form
    const { register, handleSubmit, errors, formState, watch, reset} = useForm({
      mode: "onChnage",
    });
  
    const [editForm, showForm] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [disableField, setDisableField] = useState(true);
    const [haveCnpj, setHaveCnpj] = useState(false);
    //new
    const [titulo, setTitulo] = useState("");
  
    const [values, setValues] = useState({
      url: "",
    });
  
    useEffect(() => {
      initFormValues(props.store);
      if(props.cnpj) setHaveCnpj(true)
    }, [props.store]);
  
    const initFormValues = (store) => {
      if(store) {
        //REMOVING ALL EMPTY SPACES ON STRING
        setTitulo(store.url)
        values.url = store.url;
        reset(store); 
      }
    }
  
    const onSubmit = (data) => {
      const store = new Store();
  
      store.saveStore(data).then((response) => {
        
        if( response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Os dados da sua loja foram alterados!",
            timer: 4000,
            timerProgressBar: true,
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: "fechar",
          }).then((its_ok) => {
            props.reloadScamber();
            if (its_ok) {
              setDisableField(!disableField);
            }
          });
          
          showForm(false);
          setDisabled(!disabled);
        }
        
        
      }).catch (( error ) => store.formatErrorMessage(error.response, 'SFSVST'));
  
    };
  
    const toggleIcon = () => {
      if(props.store) {
        values.url = props.store.url;
      }
      
      showForm(!editForm);
      setDisabled(!disabled);
      setDisableField(!disableField);
    };
  
    const handleChange = (prop) => (event) => {
      setValues({
        ...values,
        [prop]: event.target.value,
      });
    };
  
    return (
      <>
        <ModalDialog dialogTitle="Bio" ref={refInfoBio}>
          Texto da bio
        </ModalDialog>

        <div className="_wrap-form-panel">
          <form
            className={
              editForm ? "_form _validate" : "_form _inline-disable _validate"
            }
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="_wrap-form-header">
              <h1 className="_title-box">{props.title}</h1>
              <div className="actions">
                <button
                  type="button"
                  className="btn btn-transparent _edit-cancel"
                  onClick={toggleIcon}
                >
                  <FontAwesomeIcon
                    icon={editForm ? faTimes : faEdit}
                  ></FontAwesomeIcon>
                </button>
                {editForm ? (
                  <button type="submit" className="btn btn-transparent _confirm">
                    <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                    <span>Salvar</span>
                  </button>
                ) : null}
              </div>
            </div>
  
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className="form-group">
                  {disableField ? (
                    <label className="_label">
                      Título <span>*</span>
                    </label>
                  ) : null}
                  <TextField
                    id="outlined-title"
                    label={disableField ? null : "Titulo *"} 
                    type="text"
                    variant={disableField ? "filled" : "outlined"}
                    size={matches ? "small" : "small"}
                    name="titulo"
                    onChange={(e)=>setTitulo(e.target.value)}
                    helperText={errors.titulo?.type === "minLength" && "é muito curto (minimo 5 caracteres)" || !!errors.titulo && "não pode ficar em branco"}
                    error={!!errors.titulo}
                    fullWidth
                    disabled={disableField}
                    inputRef={register({
                      required: true,
                      minLength: 5
                    })}
                    inputProps={{
                      maxLength: 25
                    }}
                  />
                </div>
              </Grid>
  
              <Grid item xs={12}>
                <div className="form-group">
                  {disableField ? (
                    <label className="_label">
                      Bio
                    </label>
                  ) : null}
                  <TextField
                    id="outlined-bio"
                    placeholder="Essa é a sua vitrine! Se quiser, escreva aqui um pouco sobre vc e sua loja. Assim, outros Scambers podem te conhecer melhor!"
                    label={disableField ? null : "Bio "}
                    type="text"
                    variant={disableField ? "filled" : "outlined"}
                    multiline
                    size={matches ? "small" : "small"}
                    name="bio"
                    helperText={errors.bio?.type === "minLength" && "é muito curto (minimo 15 caracteres)" || errors.bio?.type === "maxLength" && "é muito longo (máximo 140 caracteres)"}
                    error={!!errors.bio}
                    fullWidth
                    disabled={disableField}
                    inputRef={register({
                      required: false,
                      minLength: 15
                    })}
                    inputProps={{
                      maxLength: 140
                    }}
                  />
                </div>
              </Grid>
  
              <Grid item xs={12}>
                <div className="form-group">
                  {disableField ? (
                    <label className="_label">
                      Url <span>*</span>
                    </label>
                  ) : null}
                  <TextField
                    id="outlined-url"
                    label={disableField ? null : "Url Personalizada *"}
                    type="text"
                    onChange={handleChange("url")}
                    variant={disableField ? "filled" : "outlined"}
                    size={matches ? "small" : "small"}
                    name="url"
                    error={!!errors.url}
                    fullWidth
                    value={titulo.normalize('NFD').replace(/[^a-zA-Zs]/g, '').toLowerCase()}
                    disabled={true}
                    inputRef={register({
                      required: true,
                    })}
                    helperText={!haveCnpj 
                      ? "https://scamb.com.vc/loja/" + titulo.normalize('NFD').replace(/[^a-zA-Zs]/g, '').toLowerCase()
                      : "https://marcas.scamb.com.vc/loja/" + titulo.normalize('NFD').replace(/[^a-zA-Zs]/g, '').toLowerCase()}
                  />
                </div>
              </Grid>
            </Grid>
          </form>
        </div>
      </>
    ); 
}

export default StoreForm;