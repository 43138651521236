import { SET_SCAMBER } from '../actionTypes';

const initialState = { }

export default function(state = initialState, action) {
    switch(action.type) {
        case SET_SCAMBER:
            const { scamber } = action.payload;
            return { ...state, scamber: scamber }
        default:
            return state;
    }
}