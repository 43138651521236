import React, { Component } from "react";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCheck, faTrashAlt } from "@fortawesome/pro-light-svg-icons";

// Material ui
import { useMediaQuery } from "@material-ui/core";

function text(val, max) {
  return val.substring(0, max) + "...";
}

function Messages(props) {
  let action_status;

  const message_type = props.type;
  const message_date = props.message_date;
  const message_username = props.username;
  const message_product_title = props.product_title;

  const matches = useMediaQuery("(min-width:600px)");

  function markup(action_text, message_text, classTag) {
    return (
      <div className="_content">
        <div className="_message-status">
          <span className={"_tag " + classTag}>{action_text}</span>
        </div>
        <div className="_message-date">{message_date}</div>

        <div className="_message-text">
          {message_type == "doubt" || message_type == "purchase" ? (
            ""
          ) : (
            <span className="_message-username"> {message_username} </span>
          )}
          <span className="_the-message">{message_text}</span>
          <span className="_message-product-title">
            {matches ? message_product_title : text(message_product_title, 20)}
          </span>
        </div>
      </div>
    );
  }

  switch (message_type) {
    case "proposal":
      action_status = markup("Proposta", "fez uma proposta em", "proposal");
      break;
    case "doubt":
      action_status = markup("Conversas", "Você recebeu uma mensagem", "doubt");
      break;
    case "purchase":
      action_status = markup("Compra e Venda", "Você vendeu", "purchase");
      break;
  }

  return (
    <div className="_wrap-message-item">
      {/*  Switch wrapper <DIV> to <LINK>  */}
      <div className={"_message-item " + props.status} onClick={props.onClick}>
        <div className="_message-image">
          <span className="user-image _rounded-image__with-notification-icon _2x">
            <div className="_wrap-image">
              <img src={props.image} alt="Avatar" />
            </div>
            <span className="_notification-icon-mark _red"></span>
          </span>
        </div>

        <div className="_message-content">{action_status}</div>
      </div>

      <div className="_actions">
        {props.children}
      </div>
    </div>
  );
}

export default Messages;
