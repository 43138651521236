import Service from './Service';

class RecoveryPassword extends Service {

    constructor() {
        super()
    }

    requestRecoveryPassword( email ) {
        return this.axiosInstance.post(`/recoveryPassword`, email);
    }

    updatePassword( emailEToken ) {
        return this.axiosInstance.put(`/recoveryPassword`, emailEToken);
    }

}

export default RecoveryPassword;