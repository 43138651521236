import Service from './Service';

class ListOrders extends Service {

    constructor() {
        super()
    }

    listSalesOrders(){
        return this.axiosInstance.get(`/listorder/sales`);
    }

    listShoppingOrders(){
        return this.axiosInstance.get(`/listorder/shopping`);
    }

}

export default ListOrders;