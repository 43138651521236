//===========================================================
// #1 - Base Imports
//===========================================================
import React from "react";
import { Link } from "react-router-dom";

//===========================================================
// #2 - Import * UI Components
//===========================================================
import { ListContent, ListItem } from "../list-content";

//===========================================================
// #3 - Import * FontAwesome Icons
//===========================================================
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faTruck, faHandHoldingBox } from "@fortawesome/pro-light-svg-icons";

import formatNumberToBRL from './../../../utils/FormatNumberToBRL'


export const ProductThumbnailItems = (props) => {

    return (
        <>
            <div className={"_product-thumbnail _items"}>
                <div className="_col">
                    <div className="_products-thumbnail">
                        {props?.images && props?.images.map((image) => (
                            <div className="_image">
                                <img src={image} alt="product image" />
                            </div>
                        ))}
                    </div>
                </div>

                <div className="_col">
                    <div className="_product-info">
                        <div className="title">
                            {props.title}
                        </div>
                        <div className="date">
                            <div className="title">
                                {props.dateTitle}
                            </div>
                            {props.date}
                        </div>

                        {props.printLabel && (
                            <div className="print-label">
                                <div className="title">
                                    {props.printLabel !== null && "Ações"}

                                </div>
                                {props.printLabel}
                            </div>
                        )}

                        {props.viewInvoice && (
                            <div className="print-label">
                                <div className="title">
                                    {props.viewInvoice !== null && "Fatura"}
                                </div>
                                {props.viewInvoice}
                            </div>
                        )}

                        {props.viewLabel && (
                            <div className="print-label">
                                {/* <div className="title">
                                    {props.viewLabel !== null && "Etiqueta"}

                                </div> */}
                                {props.viewLabel}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export const ProductThumbnail = (props) => {

    return (

        <div className={"_product-thumbnail " + props.className} style={{marginBottom: '10px'}}>
            <Link className="_col" to={props.productUrl && props.productUrl || undefined} onClick={props.productUrl && props.onClick || null} onClick={props.onClick}>

                <div className="_image">
                    <img src={props.image} alt="" />
                </div>
                <div className="_product-info">

                    <div className="title">
                        {props.title}
                    </div>
                    <div className="date">
                        <div className="title">
                            {props.dateTitle}
                        </div>

                        {!props.type || props.type === 'scamb'?
                            
                            props.date || props.pricePoints && (
                                <div className={"_product-price _1x points "}>
                                    <div className="ui-item__price">
                                        <span className="price-tag-fraction">{props.pricePoints}</span>
                                        <span className="price-tag-symbol">pts</span>
                                    </div>
                                </div> )
                            :
                            
                            props.date && props.cashPrice && (
                                <div className={"_product-price _1x points "}>
                                    <div className="ui-item__price">
                                        <span className="price-tag-symbol">R$&nbsp;</span>
                                        <span className="price-tag-fraction">{formatNumberToBRL(props.cashPrice)}</span>
                                    </div>
                                </div> )
                        }

                        
                    </div>

                    {!props.type || props.type === 'scamb'?

                        (
                            <div className="_product-price _1x">
                            {props.oldPrice && (
                                <div className="ui-item__discount-price">
                                    <span className="price-tag-fraction"> {props.oldPrice} </span>
                                    <span className="price-tag-symbol">pts</span>
                                </div>
                            )}
    
                            {props.price && (
                                <div className="ui-item__price">
                                    <span className="price-tag-fraction"> {props.price} </span>
                                    <span className="price-tag-symbol">pts</span>
                                </div>
                            )}
    
                            </div>
                        ):
                        (

                            <div className="_product-price _1x">
                            {props.cashDiscount && (
                                <div className="ui-item__discount-price">
                                    <span className="price-tag-symbol">R$&nbsp;</span>
                                    <span className="price-tag-fraction"> {formatNumberToBRL(props.cashDiscount)} </span>
                                </div>
                            )}
    
                            {props.cashPrice && (
                                <div className="ui-item__price">
                                    <span className="price-tag-symbol">R$&nbsp;</span>
                                    <span className="price-tag-fraction"> {formatNumberToBRL(props.cashPrice)} </span>
                                </div>
                            )}
    
                            </div>

                        )
                    }

                   

                    {props.freightType == "correios" && (
                        <ListContent bgColor="#FFFFFF">

                            <ListItem title="" className="flex">
                                <div className="_frete">
                                    <Icon icon={faTruck} />
                                Correios
                                </div>
                            </ListItem>

                        </ListContent>
                    ) || props.freightType === "entrega_maos" && (
                        <ListContent bgColor="#FFFFFF">

                            <ListItem title="" className="flex">
                                <div className="_frete">
                                    <Icon icon={faHandHoldingBox} />
                                    Entrega em mãos
                                </div>
                            </ListItem>

                        </ListContent>
                    )}

                </div>

            </Link>

            {props.children && (
                <div className="_col">
                    {props.children && (props.children)}
                </div>
            )}
        </div>
    )
}