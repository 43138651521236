import React, { Component } from "react";

class ChatAction extends Component {

  render() {

    return (
      <div className={"_wrap-actions "+ this.props.className}>
        <div className="_proposal-value">
          {this.props.textInfo} {this.props.status}
          <div className="sub-text">
            {this.props.subText}
          </div>
          <div className="_product-price _1x">
            <div className="ui-item__price">
              { this.props.proposalValue !== "" && (
                <>
              <span className="price-tag-fraction"> {this.props.proposalValue} </span>
              <span className="price-tag-symbol">pts</span>
              </>
              ) }
            </div>
          </div>
        </div>

        <div className="_actions">
          
            { this.props.children }

        </div>
      </div>
    );
  }
}

export default ChatAction;
