import React from 'react';

import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";

import { Wrapper, Label, InputRadio, WrapIcon, Title, Mark, Overlay } from "./styles";


function PaymentTab({ inputName, icon, title, defaultChecked, onClick, inputId }) {
    return (
        <Wrapper>
            <Label>
                <InputRadio type="radio" name={inputName} defaultChecked={defaultChecked} onClick={onClick} id={inputId} />

                <Overlay className="overlay" />
                <WrapIcon>
                    {icon}
                </WrapIcon>

                <Title>
                    {title}
                </Title>

                <Mark className="mark">
                    <Icon icon={["far", "check"]} />
                </Mark>
            </Label>
        </Wrapper>
    )
}

export default PaymentTab;
