import React from 'react';
import { Link } from 'react-router-dom';

function StoreCard(props) {

  return (
      <>
        <div className="_ui-item-wrapper">
          <div className="_ui-item">

            <Link to={props.link} style={{ backgroundImage: `url(${props.image})` }} className="_ui-item-image" />

            <div className="_ui-item-bottom">
              <div className="_col-left">
                <Link to={props.link} className="_product-title">
                    {props.title}
                </Link>
              </div>

            </div>
          </div>
        </div>
      </>
  );
};


export default StoreCard;