
import Service from './Service';

class Store extends Service {

    saveStore(store) {
        return this.axiosInstance.post(`/store`, store);
    }

}

export default Store;