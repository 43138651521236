import { SET_AD } from '../actionTypes';

const initialState = { }

export default function(state = initialState, action) {
    switch(action.type) {
        case SET_AD:
            const { ad } = action.payload;
            return { ...state, ad: ad }
        default:
            return state;
    }
}