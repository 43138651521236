import styled from "styled-components";

export const Wrapper = styled.div`
position: relative;
`;

export const Label = styled.label`
    margin-bottom: 0;
    position: relative;
    cursor: pointer;
    border: 1px solid ${({ theme }) => theme.color.border};
    padding: 10px 10px;
    border-radius: 3px;
    width: 100%;

    ${({ theme }) => theme.media("xs", `
        min-height: 84px;
    `)}

    input {
        display: none;
        visibility: hidden;
        opacity: 0;
    }

    input:checked ~ .mark {
        background-color: ${({ theme }) => theme.color.blue};
        border: 0.0625rem solid ${({ theme }) => theme.color.blue};
    }

    input:checked ~ .overlay {
        border: 0.0625rem solid rgba(43, 57, 81, 0.5);
    }
`;

export const Overlay = styled.span`
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: ${({ theme }) => theme.color.transparent};
    border: 0;
    border-radius: 3px;
`;

export const InputRadio = styled.input``;

export const WrapIcon = styled.div`
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    svg {
        height: 20px;
        width: auto !important;

        path {
            fill: ${({ theme }) => theme.color.grayBold};
        }
    }

    img {
        height: auto;
        width: 26px;
    }
`;

export const Title = styled.div`
    font-weight: ${({ theme }) => theme.fontWeight.fw700};
    color: ${({ theme }) => theme.color.grayBold};
    font-size: 0.625rem;
    text-align: center;
`;

export const Mark = styled.div`
    height: 20px;
    width: 20px;
    min-width: 20px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.color.border};
    border: 0.0625rem solid ${({ theme }) => theme.color.border};
    position: absolute;
    top: 5px;
    right: 5px;

    svg {
        height: 12px;
        width: auto !important;

        path {
            fill: ${({ theme }) => theme.color.light};
        }
    }
`;
