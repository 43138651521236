//=====================================================================================
// #1 - Set Breakpoints
//=====================================================================================
const breakpoints = {
    xs: '240px',
    sm: '768px',
    md: '992px',
    lg: '1200px'
}

//=====================================================================================
// #2 - Define Media Query
//=====================================================================================
export const media = (label = breakpoints["xs"], content) => {

    return `@media(min-width: ${breakpoints[label]}) {
        ${content}
    }`
}