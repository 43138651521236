import React, { forwardRef, useImperativeHandle, useEffect } from "react";
import { useHistory } from "react-router-dom";

//=======================================
// I * React Hook Form
//=======================================
import { useForm } from "react-hook-form";

//=======================================
// I * Sweet Alert
//=======================================
import Swal from "sweetalert2";


// Material - UI
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  Slide,
  Typography,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faChevronLeft,
  faCheck,
  faTimes,
} from "@fortawesome/pro-light-svg-icons";

// Bootstrap
import { Button } from "react-bootstrap";

// Modal Dialog Base
const ModalDialog = forwardRef((props, ref) => {
  // Form
  const { register, handleSubmit, errors, formState } = useForm({
    mode: "onChnage",
  });

  // Styles
  const matches = useMediaQuery("(min-width:600px)");

  const history = useHistory();

  // Material ui Modal
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickOpen = () => {
    document.querySelector("#gmb").click();
    setOpen(true);
  };

  const handleClose = () => {
    document.querySelector("#gmb").click();
    setOpen(false);
  };

  useImperativeHandle(ref, () => ({
    openModal: () => handleClickOpen(),
    closeModal: () => handleClose(),
  }));

  const onSubmit = (data) => {
    console.log(data);
    setOpen(false);

    Swal.fire({
      icon: "success",
      title:
        "Proposta enviada!",
        text: `Sua proposta foi enviada e, assim que o vendedor responder, mandaremos uma notificação para você!`,
      timer: 6000,
      timerProgressBar: true,
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonText: "fechar",
    }).then((eComplete) => {
      if (eComplete) {
        console.log("ok");
      }
    });
  };

    const redirectCheckout = (e) => {
        e.preventDefault();
        history.push({pathname: `${props.checkoutUrl}`});
    }

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={true}
        open={open}
        maxWidth={"sm"}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle className="dialog-title" id="responsive-dialog-title">
          <div className="_view-head-content">
            <button type="button" className="btn" onClick={handleClose}>
              <FontAwesomeIcon
                icon={faTimes}
              ></FontAwesomeIcon>
            </button>
            <div className="_title-view">{props.dialogTitle}</div>
          </div>
        </DialogTitle>

          <DialogContent className={props.className}>
            <Typography component={"div"} variant={"div"}>
                {props.children}
            </Typography>
          </DialogContent>
          <DialogActions className="dialog-actions">  
            <Button
                className="accept"
                onClick={redirectCheckout}
                color="primary"
                autoFocus
                style={{ height: matches ? 35 : 40 }}
                type="submit"
            >
              Finalizar compra
              <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
            </Button>

            <Button
                className="cancel"
                onClick={handleClose}
                color="primary"
                autoFocus
                style={{ height: matches ? 35 : 40 }}
                type="submit"
            >
              Continuar comprando
            </Button>
            <a hidden id="gmb" href="#!"> gmb </a>
          </DialogActions>
      </Dialog>
    </>
  );
});

// Material ui Dialog Slide
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default ModalDialog;
