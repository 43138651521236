import Service from './Service';

class ScamberFormasPagamento extends Service {

    constructor() {
        super()
    }

    createPaymentForm(cardInfos) {
        return this.axiosInstance.post(`/scamberPaymentMethod`, cardInfos);
    }

    find() {
        return this.axiosInstance.get(`/scamberPaymentMethod`);
    }

    deleteCardPaymentForm(cardId) {
        return this.axiosInstance.delete(`/scamberPaymentMethod`, {data:{cardId}});
    }

    updateCardPaymentForm(cardInfos, creditCardId) {
        return this.axiosInstance.put(`/scamberPaymentMethod`, {cardInfos, creditCardId});
    }

    createDirectCharge(paymentInfos) {
        return this.axiosInstance.post(`/scamberPaymentMethod/directPayment`, paymentInfos);
    }

}

export default ScamberFormasPagamento;