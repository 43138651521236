export const banks = [
    { title: 'Banco do Brasil', ag: '0000-0', cc: '00000000-0', dig_ag: true, dig_cc: true },
    { title: 'Santander', ag: '0000', cc: '00000000-0', dig_ag: false, dig_cc: true },
    { title: 'Caixa Econômica', ag: '0000', cc: '00000000000-0', dig_ag: false, dig_cc: true },
    { title: 'Caixa Econômica', ag: '0000', cc: '0000000000000-0', dig_ag: false, dig_cc: true  },
    { title: 'Bradesco', ag: '0000-0', cc: '0000000-0', dig_ag: true, dig_cc: true  },
    { title: 'Next', ag: '0000-0', cc: '0000000-0', dig_ag: true, dig_cc: true  },
    { title: 'Itaú', ag: '0000', cc: '00000-0', dig_ag: false, dig_cc: true  },
    { title: 'Agibank', ag: '0000', cc: '0000000000', dig_ag: false, dig_cc: false  },
    { title: 'Banpará', ag: '0000', cc: '000000000-0', dig_ag: false, dig_cc: true  },
    { title: 'Banrisul', ag: '0000', cc: '000000000-0', dig_ag: false, dig_cc: true  },
    { title: 'Sicredi', ag: '0000', cc: '0000000', dig_ag: false, dig_cc: false  },
    { title: 'Sicoob (Bancoob)', ag: '0000', cc: '000000000-0', dig_ag: false, dig_cc: true  },
    { title: 'Inter', ag: '0000', cc: '000000000-0', dig_ag: false, dig_cc: true  },
    { title: 'BRB', ag: '0000', cc: '000000000-0', dig_ag: false, dig_cc: true  },
    { title: 'Via Credi', ag: '0000', cc: '00000000000-0', dig_ag: false, dig_cc: true  },
    { title: 'Neon', ag: '0000', cc: '000000000-0', dig_ag: false, dig_cc: true  },
    { title: 'Votorantim', ag: '0000', cc: '000000000-0', dig_ag: false, dig_cc: true  },
    { title: 'Nubank', ag: '0000', cc: '0000000000-0', dig_ag: false, dig_cc: true  },
    { title: 'Pagseguro', ag: '0000', cc: '00000000-0', dig_ag: false, dig_cc: true  },
    { title: 'Banco Original', ag: '0000', cc: '0000000-0', dig_ag: false, dig_cc: true  },
    { title: 'Safra', ag: '0000', cc: '00000000-0', dig_ag: false, dig_cc: true  },
    { title: 'Modal', ag: '0000', cc: '000000000-0', dig_ag: false, dig_cc: true  },
    { title: 'Banestes', ag: '0000', cc: '00000000-0', dig_ag: false, dig_cc: true  },
    { title: 'Unicred', ag: '0000', cc: '00000000-0', dig_ag: false, dig_cc: true  },
    { title: 'Money Plus', ag: '0', cc: '00000000-0', dig_ag: false, dig_cc: true  },
    { title: 'Mercantil do Brasil', ag: '0000', cc: '00000000-0', dig_ag: false, dig_cc: true },
    { title: 'JP Morgan', ag: '0000', cc: '00000000000-0', dig_ag: false, dig_cc: true },
    { title: 'Gerencianet Pagamentos do Brasil', ag: '0000', cc: '00000000-0', dig_ag: false, dig_cc: true  },
    { title: 'Banco C6', ag: '0000', cc: '00000000-0', dig_ag: false, dig_cc: true  },
    { title: 'BS2', ag: '0000', cc: '000000-0', dig_ag: false, dig_cc: true  },
    { title: 'Banco Topazio', ag: '0000', cc: '00000-0', dig_ag: false, dig_cc: true  },
    { title: 'Uniprime', ag: '0000', cc: '00000-0', dig_ag: false, dig_cc: true  },
    { title: 'Stone', ag: '0000', cc: '0000000-0', dig_ag: false, dig_cc: true  },
    { title: 'Banco Daycoval', ag: '0000', cc: '000000-0', dig_ag: false, dig_cc: true },
    { title: 'Rendimento', ag: '0000-0', cc: '0000000000', dig_ag: true, dig_cc: false  },
    { title: 'Banco do Nordeste', ag: '000', cc: '000000-0', dig_ag: false, dig_cc: true  },
    { title: 'Citibank', ag: '0000', cc: '00000000', dig_ag: false, dig_cc: false  },
    { title: 'PJBank', ag: '0000', cc: '0000000000-0', dig_ag: false, dig_cc: true  },
    { title: 'Cooperativa Central de Credito Noroeste Brasileiro', ag: '0000', cc: '0000000-0', dig_ag: false, dig_cc: true  },
    { title: 'Uniprime Norte do Paraná', ag: '0000', cc: '000000-0', dig_ag: false, dig_cc: true  },
    { title: 'Global SCM', ag: '0000', cc: '00000000000', dig_ag: false, dig_cc: false  },
    { title: 'Cora', ag: '0000', cc: '0000000-0', dig_ag: false, dig_cc: true  },
    { title: 'Mercado Pago', ag: '0000', cc: '0000000000000-0', dig_ag: false, dig_cc: true  },
    { title: 'Banco da Amazonia', ag: '0000', cc: '000000-0', dig_ag: false, dig_cc: true  },
    { title: 'BNP Paribas Brasil', ag: '000', cc: '000000-000', dig_ag: false, dig_cc: true },
    { title: 'Juno', ag: '0000', cc: '0000000000-0', dig_ag: false, dig_cc: true },
    { title: 'Cresol', ag: '0000-0', cc: '00000-0', dig_ag: true, dig_cc: true },
    { title: 'BRL Trust DTVM', ag: '000', cc: '000000-0', dig_ag: false, dig_cc: true },
    { title: 'Banco Banese', ag: '000', cc: '00000000-0', dig_ag: false, dig_cc: true },
    { title: 'Banco BTG Pactual', ag: '0000', cc: '00000-0', dig_ag: false, dig_cc: true },
    { title: 'Banco Omni', ag: '0000', cc: '000000-0', dig_ag: false, dig_cc: true },
    { title: 'Acesso Soluções de pagamento', ag: '0000', cc: '00000000', dig_ag: false, dig_cc: false },
    { title: 'CCR de São Miguel do Oeste', ag: '0000', cc: '00000', dig_ag: false, dig_cc: false },
    { title: 'Polocred', ag: '0000', cc: '000000-0', dig_ag: false, dig_cc: true },
    { title: 'Ótimo', ag: '0000', cc: '00000-0', dig_ag: false, dig_cc: true },
]

export const accountType = [
    { id: 0, title: "Corrente" },
    { id: 1, title: "Poupança" }
]