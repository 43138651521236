//===============================================================================================================
// #1 - Base Imports
//===============================================================================================================
import React, { Component, forwardRef, useImperativeHandle } from 'react';
import { Link } from 'react-router-dom';

//===============================================================================================================
// #2 - Import * Redux
//===============================================================================================================
import { connect } from 'react-redux';

//===============================================================================================================
// #3 - Import * Utils
//===============================================================================================================
import $ from 'jquery';

//===============================================================================================================
// #4 - Import * UI Components
//===============================================================================================================
import { Menu, MenuItem as Item } from './ui/menu';
import Avatar from './ui/avatar';
import ScamberPoints from './ui/scamb-points';
import { ButtonAnchor, ButtonLink } from "./ui/form/button";

//===============================================================================================================
// #4 - Import * Font Awesome Icons
//===============================================================================================================
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import {
	faHomeAlt,
	faCommentAltLines,
	faPlusSquare,
	faShoppingBag,
	faUser,
	faTimes
} from '@fortawesome/pro-light-svg-icons';

import {
	faQuestionCircle,
	faEye,
	faHeart,
	faStore,
	faShoppingCart,
	faCog,
	faUserFriends,
	faCreditCardBlank,
	faIdCard,
	faBullhorn,
	faSignOut,
	faWallet,
} from '@fortawesome/pro-regular-svg-icons';


//===============================================================================================================
// #5 - Import * Services
//===============================================================================================================
import EventEmitter from './../services/Events';
import Scamber from './../services/Scamber';
import { setScamber } from "../redux/actions/scamberActions";
import { setBalance } from "../redux/actions/balanceActions";
import Loyalty from "../services/Loyalty";
import Authentication from "./../services/Authentication";

//===============================================================================================================
// #8 - Import * Material UI Components
//===============================================================================================================
import {
	Dialog,
	DialogContent,
	DialogTitle,
	useMediaQuery,
	Slide,
	Typography,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

//===============================================================================================================
// #6 - Import * Images
//===============================================================================================================
import AvatarIMG from '../assets/images/avatar.png';
import CoverIMG from "../assets/images/cover.png";

import Cookie from './../utils/Cookie';

//===========================================================
// #10 - Create Material UI Modal Dialog
//===========================================================
const ModalDialog = forwardRef((props, ref) => {
	// * Material UI Styles - useMediaQuery
	const matches = useMediaQuery("(min-width:600px)");

	// # Define States
	const [open, setOpen] = React.useState(false);

	// * Material UI Styles - useTheme
	const theme = useTheme();

	// # Define Material UI Modal Dialog Fullscreen on mobile
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

	// # Define Functions

	// * Set Material UI Modal Dialog Open
	const handleClickOpen = () => {
		setOpen(true);
	};

	// * Set Material UI Modal Dialog Close
	const handleClose = () => {
		setOpen(false);
	};

	// * Set Function Using useImperativeHandle();
	useImperativeHandle(ref, () => ({
		openModal: () => handleClickOpen(),
		closeModal: () => handleClose(),
	}));

	return (
		<>
			<Dialog
				fullScreen={fullScreen}
				fullWidth={true}
				open={open}
				maxWidth={"sm"}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleClose}
				aria-labelledby="responsive-dialog-title"
				className={`UserMenuDialog ${props.className}`}
			>
				<DialogTitle className="DialogTitle" id="responsive-dialog-title">
					<IconLabel onClick={handleClose} />

				</DialogTitle>
				<DialogContent className="ModalContent">
					<Typography component={"span"} variant={"body2"}>
						{props.children}
					</Typography>
				</DialogContent>
			</Dialog>
		</>
	);
});

// * Material UI Dialog Slide Transition Effect
const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="left" ref={ref} {...props} />;
});

//===============================================================================================================
// #7 - Menu Item
//===============================================================================================================
const MenuItem = ({ icon, text, className, onClick, link, alert }) => {

	return (
		<li className={className ? className : ""} onClick={onClick}>
			<Link to={link ? link : "#!"} className="wrap">
				<div className="icon">
					{alert ? (
						<span className="alert-icon-box">
							{icon}
							<span className="_notification-icon-mark _red"></span>
						</span>
					) :
						<>
							{icon}
						</>
					}
				</div>
				<div className="text">
					{text}
				</div>
			</Link>
		</li>
	)
}

//===============================================================================================================
// #7 - Menu Item
//===============================================================================================================
const IconLabel = ({ onClick }) => {

	return (
		<button type="button" className="icon-with-label" onClick={onClick}>
			<div className="icon">
				<Icon icon={faTimes} />
			</div>
			<div className="text-label">
				Fechar
			</div>
		</button>
	)
}

//===============================================================================================================
// #7 - Menu Item
//===============================================================================================================
const UserAvatar = ({ src }) => {

	return (
		<button type="button" className="avatar-small">
			<div className="image">
				<img src={src} alt="Avatar scamb" />
			</div>
		</button>
	)
}

class FixedMenu extends Component {

	constructor() {
		super();

		this.state = {};
		this.scamber = new Scamber();
		this.loyalty = new Loyalty();
		this.dialogUser = React.createRef();
	}

	loadScamberData = () => {
		// Swal.fire('Aguardeee...');
		// Swal.showLoading();
		this.scamber
			.findScamber()
			.then((scamberData) => {
				// Swal.close();
				if (scamberData.status === 200) {
					this.props.setScamber(scamberData.data.payload);

					const loyalty = new Loyalty();
					loyalty.balance().then((balanceData) => {
						if (balanceData.status === 200)
							this.props.setBalance(balanceData.data.payload);
					});
				}
			})
			.catch((error) => this.scamber.formatErrorMessage(error.response, 'FIHSCA'));
	};

	logoutUser = () => {
		const auth = new Authentication();

		auth
			.doLogout()
			.then(() => {
				Cookie.setCookie('token', "", -1);
				this.setState({ isAuthenticated: false });
				EventEmitter.dispatch("logoutSucess");
				this.props.setScamber(undefined);
				this.props.setBalance(undefined);

				this.props.history.push("/");
			})
			.catch(() => { });

		setTimeout(() => this.dialogUser.current.closeModal(), 500);

	};

	componentDidMount() {

		this.setState({ isAuthenticated: Cookie.getCookie('token') ? true : false });

		EventEmitter.subscribe('loginSucess', () => {
			this.setState({ isAuthenticated: true });
			this.loadScamberData();
		});

		EventEmitter.subscribe('logoutSucess', () => {
			this.setState({ isAuthenticated: false });
		});

		EventEmitter.subscribe("balanceChange", () => {
			const loyalty = new Loyalty();
			loyalty.balance().then((balanceData) => {
				if (balanceData.status === 200)
					this.props.setBalance(balanceData.data.payload);
			});
		});

		$(".fmenu").on('click', 'li', function () {

			$(this).parents(".fmenu")
				.find('.active')
				.removeClass('active')
				.end()
				.end()
				.addClass('active');

		});

	}


	toggleTawkToChat = (e) => {
		e.preventDefault();
		window.tawkto.showWidget();
		window.tawkto.toggle();

		//SET ATTRIBUTES
		window.tawkto.setAttributes({
			'name': this.props.scamber ? this.props.scamber.nome : '',
			'email': this.props.scamber ? this.props.scamber.email : '',
			'hash': window.hashInBase64,
		});

	}

	// * Open Modal User Menu
	openUserDialog = () => {
		this.dialogUser.current.openModal();
	}

	// * Close Modal User Menu
	closeUserDialog = () => {
		this.dialogUser.current.closeModal();
	}


	render() {

		return (
			<>
				<div id="fixed-menu" className={`fixed-menu-${this.props.site}`}>
					<ul className="fmenu">
						<MenuItem className="active" link="/" icon={<Icon icon={faHomeAlt} />} text="Home" />
						<MenuItem icon={<Icon icon={faCommentAltLines} />} text="Chat" onClick={(e) => this.toggleTawkToChat(e)} />
						<MenuItem link="/criar-novo" icon={<Icon icon={faPlusSquare} />} text="Vender" />
						<MenuItem link="/minha-sacola" icon={<Icon icon={faShoppingBag} />} text="Comprar" alert={this.props.cart?.itens?.length > 0 ? true : false} />

						{!this.state.isAuthenticated
							? <MenuItem link="/prosseguir" icon={<Icon icon={faUser} />} text="Entrar" />

							: <MenuItem
								onClick={this.openUserDialog}
								icon={
									<>
										{
											this.props.scamber ? this.props.scamber.imagens?.avatar
												? <UserAvatar src={this.scamber.loadImage(this.props.scamber.imagens?.avatar)} />
												: <UserAvatar src={AvatarIMG} /> : null
										}
									</>
								} text={this.props.scamber?.nome?.split(" ")[0]} />

						}

					</ul>
				</div>

				<ModalDialog ref={this.dialogUser} className={`user-menu-${this.props.site}`}>

					<div className="user-cover">
						{/* <img src={!this.props.imagens?.banner ? this.scamber.loadImage(this.props.scamber?.imagens?.banner) : CoverIMG} alt="Scamber imagem banner" /> */}

						<img src={
							this.props.scamber?.imagens?.banner ? this.scamber.loadImage(this.props.scamber?.imagens?.banner) : CoverIMG
						} alt="Scamber imagem banner" />
					</div>

					<div className="scamb-user">
						{/* <Avatar image={!this.props.imagens?.avatar ? this.scamber.loadImage(this.props.scamber?.imagens?.avatar) : AvatarIMG} size="large" /> */}
						<Avatar image={
							this.props.scamber ? this.props.scamber.imagens?.avatar ? this.scamber.loadImage(this.props.scamber.imagens?.avatar) : AvatarIMG : null
						} size="large" />

						<div className="username">
							{this.props.scamber?.nome}
						</div>

						<ScamberPoints title="Meus pontos" points={this.props.balance?.currentBalance || 0} />
					</div>


					<div className="menu-content">
						<div className="actions">

							{this.props.site === "shopping" && this.props.scamber?.cnpj !== undefined && (
								<ButtonAnchor link="/criar-novo" text="Quero vender" bordered={false} color="red" onClick={() => this.closeUserDialog()} />
							)}

							{this.props.site === "scamb" && this.props.scamber?.cnpj === undefined && (
								<ButtonAnchor link="/criar-novo" text="Quero vender" bordered={false} color="red" onClick={() => this.closeUserDialog()} />
							)}

							{this.props.site === "scamb" && this.props.scamber?.cnpj === undefined && (
								<ButtonLink link="/comprar-pontos" text="Comprar pontos" bordered={true} color="red" onClick={() => this.closeUserDialog()} />
							)}

						</div>
					</div>

					<div className="menu-title">
						Menu
					</div>

					<Menu>

						<Item link={"/loja/" + this.props.scamber?.minha_loja?.url} icon={<Icon icon={faEye} />} title="Loja" subtitle="Ver minha loja" onClick={() => this.closeUserDialog()} />
						<Item link="/meus-anuncios" icon={<Icon icon={faBullhorn} />} title="Anúncios" subtitle="Meus anúncios" onClick={() => this.closeUserDialog()} />
						<Item link="/minha-carteira" icon={<Icon icon={faWallet} />} title="Carteira" subtitle="Minha carteira" onClick={() => this.closeUserDialog()} />
						
						{this.props.scamber?.cnpj === undefined && (
							<Item link="/metodos-de-pagamento" icon={<Icon icon={faCreditCardBlank} />} title="Formas de pagamento" subtitle="Minhas formas de pagamento" onClick={() => this.closeUserDialog()} />
						)}

						{this.props.scamber?.cnpj === undefined && (
							<Item link="/minhas-compras" icon={<Icon icon={faShoppingCart} />} title="Compras" subtitle="Minhas compras" onClick={() => this.closeUserDialog()} />
						)}


						<Item link="/minhas-vendas" icon={<Icon icon={faStore} />} title="Vendas" subtitle="Minhas vendas" onClick={() => this.closeUserDialog()} />

						{this.props.scamber?.cnpj === undefined && (
							<Item link="/meus-favoritos" icon={<Icon icon={faHeart} />} title="Favoritos" subtitle="Meus Favoritos" onClick={() => this.closeUserDialog()} />
						)}
						
						<Item link="/meu-perfil" icon={<Icon icon={faCog} />} title="Perfil" subtitle="Meu perfil" onClick={() => this.closeUserDialog()} />
						<Item link="/meus-enderecos" icon={<Icon icon={faIdCard} />} title="Endereços" subtitle="Meus endereços" onClick={() => this.closeUserDialog()} />

						{/* {this.props.scamber?.cnpj === undefined && (
							<Item link="/convidar-amigos" icon={<Icon icon={faUserFriends} />} title="Convide amigos" subtitle="Convide amigos e ganhe pontos" onClick={() => this.closeUserDialog()} />
						)} */}
						
						<Item link="/ajuda" icon={<Icon icon={faQuestionCircle} />} title="Ajuda" subtitle="Central de ajuda" onClick={() => this.closeUserDialog()} />
						<Item link="#!" icon={<Icon icon={faSignOut} />} title="Sair" subtitle="" onClick={(e) => { e.preventDefault(); this.logoutUser() }} />

					</Menu>

				</ModalDialog>
			</>
		);

	};

};

const mapStateToProps = state => {
	return {
		scamber: state.scamberReducer.scamber,
		balance: state.balanceReducer.balance,
		cart: state.cartReducer.cart,
	}
}

export default connect(mapStateToProps, { setScamber, setBalance })(FixedMenu);