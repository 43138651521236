//=====================================================================================
// #1 - Import * Sweet Alert
//=====================================================================================
import Swal from "sweetalert2";

export default function errorMessage(title, errorCode, errorMsg, defaultErrorMessage, closedFn) {
  Swal.fire({
    title: title,
    html: `<p>${errorMsg? errorMsg: defaultErrorMessage}</p> <small style="font-size: 9px;">[${errorCode?.toUpperCase()}]</small>`,
    icon: "info",
    dangerMode: true,
    className: "swall-error",
  }).then(() => {  
    if(closedFn) closedFn();
  });

}
