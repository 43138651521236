import Service from './Service';

export default class Shipment extends Service {

  constructor(){
    super();
  }

  calculateShipment(website) {
    return this.axiosInstance.get(`/shipment/calculate${website? '?website=' + website: ''}`);
  }

  generateCompleteTag(orderID) {
    return this.axiosInstance.post(`/shipment/generate/complete/tag/order/${orderID}`);
  }

  printTag(orderID) {
    return this.axiosInstance.get(`/shipment/print/tag/order/${orderID}`);
  }

  updateTrackingStatus(orderID) {
    return this.axiosInstance.get(`/shipment/update/tracking/order/${orderID}`);
  }

  calculateReturnOrderShipment(orderID) {
    return this.axiosInstance.get(`/shipment/calculate/reverse-logistc/order/${orderID}`);
  }

  generateCompleteReturnOrderTag(orderID) {
    return this.axiosInstance.post(`/corder/generate/reversal/tag/order/${orderID}`);
  }

  updateReturnOrderTrackingStatus(orderID) {
    return this.axiosInstance.get(`/corder/update/reversal/tracking/order/${orderID}`);
  }

  printReturnOrderTag(orderID) {
    return this.axiosInstance.get(`/corder/print/tag/order/${orderID}`);
  }

  generateCompleteReturnOrderTagShopping(orderID) {
    return this.axiosInstance.post(`/cordershopping/generate/reversal/tag/order/${orderID}`);
  }

  updateReturnOrderTrackingStatusShopping(orderID) {
    return this.axiosInstance.get(`/cordershopping/update/reversal/tracking/order/${orderID}`);
  }

  printReturnOrderTagShopping(orderID) {
    return this.axiosInstance.get(`/cordershopping/print/tag/order/${orderID}`);
  }

}