import React, { Component } from "react";
import { Link } from "react-router-dom";

class ChatHeader extends Component {

    render () {

        return (
            <section className="_chat-header">
                <div className="_cols">
                  <div className="_col _col-left">
                    <div className="_back">
                      {this.props.children}
                    </div>

                    <Link to={this.props.link} className="_current-user">
                      <span className="user-image _rounded-image__with-notification-icon _1x">
                        <img src={this.props.userAvatar} alt="Avatar" />
                      </span>
                      <span className="username"> {this.props.username} </span>
                    </Link>
                  </div>
                </div>
              </section>
        );

    };

};

export default ChatHeader;