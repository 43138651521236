//===============================================================================================================
// #1 - Base Imports
//===============================================================================================================
import React, { useState, useRef, forwardRef, useImperativeHandle } from "react";
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet'; 
import { connect } from 'react-redux';

//===============================================================================================================
// #4 - Import * Material UI Components
//===============================================================================================================
import Grid from "@material-ui/core/Grid";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    useMediaQuery,
    Slide,
    Typography,
    Tabs,
    Tab
  } from "@material-ui/core";
  import { useTheme } from "@material-ui/core/styles";

//===============================================================================================================
// #3 - Import * UI Components
//===============================================================================================================
import Header from '../../components/account/Header';
import { AdvertiserCard } from "../../components/ui/advertiser-card";

//===============================================================================================================
// #2 - Import * FontAwesome Icons
//===============================================================================================================
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faTimes } from "@fortawesome/pro-light-svg-icons";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { faWhatsapp, faFacebookF,  faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons';

//===============================================================================================================
// #5 - Import * { Button } Bootstrap
//===============================================================================================================
import { Button } from "react-bootstrap";


//===========================================================
// #9 - Create Material UI Modal Dialog
//===========================================================
const ModalDialog = forwardRef((props, ref) => {

    // * Material UI Styles - useMediaQuery
    const matches = useMediaQuery("(min-width:600px)");
  
    // # Define States
    const [open, setOpen] = React.useState(false);
  
    // * Material UI Styles - useTheme
    const theme = useTheme();
  
    // # Define Material UI Modal Dialog Fullscreen on mobile 
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  
    
    // # Define Functions
  
    // * Set Material UI Modal Dialog Open
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    // * Set Material UI Modal Dialog Close
    const handleClose = () => {
      setOpen(false);
    };
  
    // * Set Function Using useImperativeHandle();
    useImperativeHandle(ref, () => ({
      openModal: () => handleClickOpen(),
      closeModal: () => handleClose(),
    }));
  
    return (
      <>
        <Dialog
          fullScreen={fullScreen}
          fullWidth={true}
          open={open}
          maxWidth={"sm"}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle className="dialog-title _rm-margin" id="responsive-dialog-title">
            <div className="_view-head-content">
              <button type="button" className="btn" onClick={handleClose}>
                <Icon
                  icon={matches ? faTimes : faChevronLeft}
                ></Icon>
              </button>
              <div className="_title-view">{props.dialogTitle}</div>
            </div>
          </DialogTitle>
            <DialogContent className={props.className}>
              <Typography component={"span"} variant={"body2"}>
  
                {props.children}
                
              </Typography>
            </DialogContent>
        </Dialog>
      </>
    );
  });
  
// * Material UI Dialog Slide Transition Effect
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

//===============================================================================================================
// #5 - Import * { Button } Bootstrap
//===============================================================================================================
const DiscountBox = (props) => {

    return (
        <div className="discount-box">
            <div className="top">
                <div className="_cols">
                    <div className="_col">
                        <div className="percent-off">
                            {props.percentOff} <span>off</span>
                        </div>
                    </div>
                    <div className="_col">
                        <div className="infos">
                            <p className="text">
                                {props.text}
                            </p>
                            <p className="small"> Cupons restantes: {props.remainingCoupons} </p>
                        </div>

                        <div className="button-action">
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

//===============================================================================================================
// #11 - Material UI Set Panel
//===============================================================================================================
const TabPanel = (props) => {

  const { children, value, index, ...other } = props;

  return (

    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
          <>
            {children}
          </>
      )}
    </div>

  )
}

function PromotionPage (props) {

    // * Refs
    const refDialogModal = useRef();
    const refField = useRef();

    // * States
    const [tabValue, setTabValue] = useState(0);
    const [couponBlur, setCouponBlur] = useState(false);
    const [copySuccess, setCopySuccess] = useState(false);

    // * Functions
    const handleCouponBlur = () => setCouponBlur(!couponBlur);
    const handleCopyCoupon = () => setCopySuccess(!copySuccess);

    const copyToClipboard = (e) => {
        refField.current.select();
        document.execCommand('copy');
        e.target.focus();

        // Change copy text
        handleCopyCoupon();

        setTimeout(() => {
            setCopySuccess(false)
        }, 2000)
    };

    // * Material UI Tabs
    // * Props Tab
    function tabProps(index) {
      return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
      };
    }

    // * Material UI Tabs
    // * On Change Tab
    const handleChange = (event, newValue) => {
      setTabValue(newValue);
    };
    
    return (
        <>

        <ModalDialog ref={refDialogModal} dialogTitle="Pegar cupom" className="_dialog-cupom">
            <AdvertiserCard 
                storeLogo="https://i.ibb.co/9YcqrdT/download.png"
                storeLink={`/loja/`}
                storeTitle="Mariza"
                storeLocation="São paulo"
                button={true}
            />
            <DiscountBox percentOff="25%" remainingCoupons="15" text="Desconto de 10% em compras acima a de R$20,00 reais do app." />
            <div className="_generate-coupon-box">
                <div className="wrap-field">
                    <div className={couponBlur ? "_field" : "_blur _field"}>
                        {!couponBlur ? (
                          "SCAMBCUPOM"
                        ) : (<input defaultValue='444s5' ref={refField} className={copySuccess ? "field-coupon" : ""} readOnly />)}
                    </div>

                    {!couponBlur ? (
                    <Button type="button" color="primary" autoFocus className="see-offer" onClick={handleCouponBlur} >
                        Gerar cupom
                    </Button>
                    ) : (
                    <Button type="button" color="primary" autoFocus onClick={copyToClipboard}>
                        {!copySuccess ? "Copiar" : "Copiado!"}
                    </Button>
                    )}
              </div>
            </div>
            
            <div className="_tabs">

              <Tabs value={tabValue} onChange={handleChange} aria-label="Tabs My Sales" className="_material-tabs">
                <Tab label="Regras do cupom" {...tabProps(0)} />
                <Tab label="Compartilhar" {...tabProps(1)} />
              </Tabs>

              <TabPanel value={tabValue} index={0} className="tabpanel">
                <div className="_section">
                  <h1 className="title">
                    Regras do cupom
                  </h1>

                  <ul className="list">
                    <li>
                      <div className="icon">
                        <Icon icon={faCheck} />
                      </div>
                      <div className="text">
                        Válido para usar apenas na loja <span className="partner-name">Marisa</span>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <Icon icon={faCheck} />
                      </div>
                      <div className="text">
                      Válido até 01/12/2020
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <Icon icon={faCheck} />
                      </div>
                      <div className="text">
                        Válido apenas para uma compra
                      </div>
                    </li>
                  </ul>
                </div>
              </TabPanel>

              <TabPanel value={tabValue} index={1} className="tabpanel">
              <div className="_section">
                <h1 className="title">
                  Compartilhar nas redes sociais
                </h1>
                
                <nav className="_list-socials">
                  <ul>
                      <li>
                          <Button type="button">
                              <Icon icon={faWhatsapp} />
                          </Button>
                      </li>
                      <li>
                          <Button type="button">
                            <Icon icon={faFacebookF} />
                        </Button>
                      </li>
                      <li>
                          <Button type="button">
                              <Icon icon={faTwitter} />
                          </Button>
                      </li>

                      <li>
                        <Button type="button">
                            <Icon icon={faLinkedinIn} />
                        </Button>
                      </li>
                  </ul>
                </nav>
              </div>
              </TabPanel>

            </div>
        </ModalDialog>

        <section id="view_promotion-discount">
        <Helmet title={`${props.site_properties?.title} - Desconto"`} />
            <Header address="addres" images="ad" store="ad"></Header>

        <section className="_content _med-container">
        <div className="_title">
            <h1> Cupom de desconto NomeDaLoja de Dezembro 2020 </h1>
        </div>

            <Grid container spacing={2}>
                <Grid item md={12} xs={12}>
                    <DiscountBox percentOff="25%" remainingCoupons="15" text="Desconto de 10% em compras acima a de R$20,00 reais do app.">
                        <Button type="button" color="primary" autoFocus className="see-offer" onClick={() => refDialogModal.current.openModal()} >
                            Resgatar cupom
                        </Button>
                    </DiscountBox>
                </Grid>
            </Grid>
        </section>

        </section>
        </>
    )
}

const mapStateToProps = (state) => ({
  site_properties: state.websiteReducer.site_properties
})

export default connect(mapStateToProps, {})(PromotionPage);

