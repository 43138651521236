import React from "react";

import Cookies from "universal-cookie";

import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";

import { Popup, WrapIcon, Content, Title, Subtitle, Button } from "./styles";

const cookies = new Cookies();

function PopupMobile({ title, subTitle, onClick }) {

    const handleCookie = () => {

        // * Cookie Expires
        const date = new Date();
        const minutes = 1200;
        date.setTime(date.getTime() + (minutes * 60 * 1000));

        cookies.set("_popup_mobile_xs521", "true", { path: "/", expires: date });

        setTimeout(() => redirectWebSite("marcas"), 500)
    }

    const redirectWebSite = (websiteToGo) => {

        const url = window.location.href;
        const urlToGO = websiteToGo === 'marcas' ? 'marcas.' : '';

        if (url.indexOf('localhost') >= 0) {
            localStorage.setItem('website', websiteToGo);
            window.location.href = `http://localhost:3001`;
            return false;
        } else if (url.indexOf('d1') >= 0) {
            window.location.href = `https://${urlToGO}d1.scamb.com.vc`;
        } else if (url.indexOf('d2') >= 0) {
            window.location.href = `https://${urlToGO}d2.scamb.com.vc`;
        } else if (url.indexOf('h1') >= 0) {
            window.location.href = `https://${urlToGO}h1.scamb.com.vc`;
        } else if (url.indexOf('h2') >= 0) {
            window.location.href = `https://${urlToGO}h2.scamb.com.vc`;
        } else {
            window.location.href = `https://${urlToGO}scamb.com.vc`;
        }

    }

    return (
        <>
            <Popup>
                <WrapIcon>
                    <Icon icon={["far", "engine-warning"]} />
                </WrapIcon>

                <Content onClick={handleCookie}>
                    <Title>
                        {title && title}
                    </Title>
                    <Subtitle>
                        {subTitle && subTitle}
                    </Subtitle>
                </Content>
                <Button type="button" onClick={onClick}>
                    <Icon icon={["far", "times"]} />
                </Button>
            </Popup>
        </>
    )
}

export default PopupMobile;