//=====================================================================================
// #1 - Import * Styled Components
//=====================================================================================
import styled from 'styled-components';

export const BadgeIcon = styled.span `
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    height: 12px;
    width: 12px;
    background-color: ${props => props.theme.color.white};
    position: absolute;
    border-radius: 100px;
    top: -1px;
    right: -5px;

    &::after {
        display: block;
        content: "";
        height: 8px;
        width: 8px;
        background-color: ${props => props.theme.color.red};
        border-radius: 100px;
    }
`;