//=====================================================================================
// #1 - Base Imports
//=====================================================================================
import React from "react";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

//=====================================================================================
// #2 - Import * Services
//=====================================================================================
import ShowCase from "../../../../services/Showcase";

//=====================================================================================
// #2 - Import * UI Components
//=====================================================================================
import { Container } from "../../../../components/design/layout/container";
import { HighlightBox } from "../../../../components/ui/highlight-box";

//=====================================================================================
// #4 - Import * Styled Components
//=====================================================================================
import { WrapperContent } from "./styles";

//=====================================================================================
// #5 - Instantiate Services
//=====================================================================================
const showcase = new ShowCase();

function ShowCases(props) {

    // * States
    const [showCaseList, setShowCaseList] = React.useState([]);

    // * Effect
    React.useEffect(() => {

        const findShowcases = async () => {

            try {

                const response = await showcase.getAvailableShowcase(props.website);
                const data = await response.data?.payload;

                setShowCaseList(responsePayload => [...responsePayload, data]);

            } catch (error) {
                showcase.formatErrorMessage(error.response, "HFIAS");
            }

        }

        // * Calls To Functions
        findShowcases();

    }, [])

    return (
        <>

            <Container className="highlight-boxes" withContainer={true} >
                <WrapperContent id={props.id} className="_xs522">

                    {showCaseList[0]?.map(({ ordination, tipo, title, subtitle, filters, banners, filter_type, _id }) => (
                        <HighlightBox
                            type={ordination}
                            device={tipo}
                            title={title}
                            subtitile={subtitle}
                            filters={filters}
                            images={banners}
                            filterType={filter_type}
                            showcaseId={_id}
                        />
                    ))}

                </WrapperContent>
            </Container>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
      website: state.websiteReducer.website
    };
};

export default withRouter(connect(mapStateToProps)(ShowCases));