import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Tabs, Tab } from "react-bootstrap";
import Layout from "../../components/account/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from 'sweetalert2';
import { connect } from 'react-redux';

// Icons
import { faEdit, faMegaphone, faBan, faCheck, faEye } from "@fortawesome/pro-light-svg-icons";

//Images
import AvatarIMG from "../../assets/images/avatar.png";

import Ad from './../../services/Ad';

import Helmet from 'react-helmet';
import { CurrentPage } from "../../components/ui/current-page";

import formatNumberToBRL from './../../utils/FormatNumberToBRL';

class MyAds extends Component {

  constructor() {
    super();

    this.state = {
      activeAds: [],
      inactiveAds: []
    };

    this.ad = new Ad();
  }

  componentDidMount() {
    this.listAllAds();
  }

  listAllAds = () => {
    Swal.fire('Carregando anúncios... Aguarde');
    Swal.showLoading();

    this.ad.listActive(this.props.website).then((response) => {
      Swal.close();
      this.setState({ activeAds: response.data.payload })
    }).catch(error => {
      if (error.response.status !== 404) {
        this.ad.formatErrorMessage(error.response, 'MALIAAD')
      } else {
        Swal.close();
        this.setState({ activeAds: [] });
      }
    })

    this.ad.listInactive(this.props.website).then((response) => {
      this.setState({ inactiveAds: response.data.payload })
    }).catch(error => {
      if (error.response.status !== 404) {
        this.ad.formatErrorMessage(error.response, 'MALIIAD')
      } else {
        Swal.close();
        this.setState({ inactiveAds: [] });
      }

    })
  }

  setAdState = (state, idAd) => {
    const adInfo = {
      idAd: idAd,
      state: state
    }

    this.ad.setState(adInfo).then(() => {
      Swal.fire();

      Swal.fire({
        icon: "success",
        title: `Anúncio ${state ? 'ativado' : 'desativado'} com sucesso!`,
        timer: 4000,
        timerProgressBar: true,
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText: "fechar",
      }).then((its_ok) => {
        this.listAllAds();
        if (its_ok) { }

      });

    }).catch(error => this.ad.formatErrorMessage(error.response, 'MASTSAD'))
  }

  render() {
    return (
      <Layout>
        <div id="myads" className="_content">

          <CurrentPage icon={<FontAwesomeIcon icon={faMegaphone} />} title="Meus Anúncios" />

          <Helmet title={`${this.props.site_properties?.title} - Meus Anuncios`} />
          <Tabs defaultActiveKey="publicados">
            <Tab eventKey="publicados" title="Publicados">
              {this.state.activeAds.map((ad) => (
                <div className="_wrap-my-shopping-item">
                  <section className="_my-shopping-item">
                    <div className="_col _col-1">
                      <div className="product">
                        <div style={{ cursor: "pointer" }} onClick={() => this.props.history.push(`/produto?id=${ad._id}`)} className="product-image">
                          <img src={this.ad.loadImage(ad.fotos[0])} alt="" />
                        </div>
                        <div className="product-info">
                          <div style={{ cursor: "pointer" }} onClick={() => this.props.history.push(`/produto?id=${ad._id}`)} className="product-info-title _14px _fw700 _color _black">
                            {ad.titulo}
                          </div>

                          <div className="_product-price _1x">
                            <div className="ui-item__price">
                              {
                                ad.tipo_anuncio === 'shopping'?
                                  (
                                    <>
                                      <span class="price-tag-symbol">R$&nbsp;</span>
                                      <span class="price-tag-fraction">{formatNumberToBRL(ad.valor_por)}</span>
                                    </>
                                  ):
                                  (
                                    <>
                                      <span class="price-tag-fraction">{ad.pontos_por?.$numberDecimal}</span>
                                      <span class="price-tag-symbol">pts</span>
                                    </>
                                  )
                              }
                            </div>
                          </div>

                          <div className="published-in">
                            Publicado em:{" "}
                            <span className="the-date">{new Date(ad.data_publicacao).toLocaleDateString()}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="_col _col-2">
                      <h1 className="proposals-received-title _14px _fw700 _color _black">
                        Propostas recebidas
                        <span>( 0 )</span>
                      </h1>
                    </div>

                    <div className="_col _col-3">
                      <div className="_action-buttons">
                        <button onClick={() => this.props.history.push('/editar-anuncio/' + ad._id)} type="button" className="btn">
                          <span>Editar</span>
                          <FontAwesomeIcon icon={faEdit}></FontAwesomeIcon>
                        </button>
                        <button onClick={() => this.setAdState(false, ad._id)} type="button" className="btn">
                          <span>Desativar</span>
                          <FontAwesomeIcon icon={faBan}></FontAwesomeIcon>
                        </button>
                      </div>
                    </div>
                  </section>

                  {/*<sections className="_recent_messages">
                    <Link to="/" className="_wrap-message">
                      <div className="contact-infos">
                        <span className="user-image _rounded-image__with-notification-icon _medium">
                          <img src={AvatarIMG} alt="Avatar" />
                        </span>

                        <div className="contact-informations">
                          <div className="contact-name">Fernanda Souza</div>
                          <div className="contact-message-preview">
                            Quanto tempo de uso ela tem?
                          </div>
                        </div>
                      </div>

                      <div className="message-date">17/02/2020</div>
                    </Link>
                    <Link to="/" className="_wrap-message">
                      <div className="contact-infos">
                        <span className="user-image _rounded-image__with-notification-icon _medium">
                          <img src={AvatarIMG} alt="Avatar" />
                        </span>

                        <div className="contact-informations">
                          <div className="contact-name">Fernanda Souza</div>
                          <div className="contact-message-preview">
                            Quanto tempo de uso ela tem?
                          </div>
                        </div>
                      </div>

                      <div className="message-date">17/02/2020</div>
                    </Link>
                  </sections>

                  <section className="_load-more-messages">
                    <Link to="/" className="_load-more">
                      Ver mais (7)
                    </Link>
                  </section>*/}
                </div>
              ))}
              {this.state.activeAds.length === 0 ? (<p style={{ textAlign: 'center' }}>Você não possui nenhum anúncio ativo</p>) : (<></>)}
            </Tab>
            <Tab eventKey="inativos" title="Inativos">
              {this.state.inactiveAds.map((ad) => (
                <div className="_wrap-my-shopping-item">
                  <section className="_my-shopping-item _inactive">
                    <div className="_col _col-1">
                      <div className="product">
                        <div className="product-image">
                          <img src={this.ad.loadImage(ad.fotos[0])} alt="" />
                        </div>
                        <div className="product-info">
                          <div className="product-info-title _14px _fw700 _color _black">
                            {ad.titulo}
                          </div>

                          <div className="_product-price _1x">
                            <div className="ui-item__price">
                              {
                                ad.tipo_anuncio === 'shopping'?
                                  (
                                    <>
                                      <span class="price-tag-symbol">R$&nbsp;</span>
                                      <span class="price-tag-fraction">{formatNumberToBRL(ad.valor_por)}</span>
                                    </>
                                  ):
                                  (
                                    <>
                                      <span class="price-tag-fraction">{ad.pontos_por?.$numberDecimal}</span>
                                      <span class="price-tag-symbol">pts</span>
                                    </>
                                  )
                              }
                            </div>
                          </div>

                          <div className="published-in">
                            Publicado em:{" "}
                            <span className="the-date">{new Date(ad.data_publicacao).toLocaleDateString()}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="_col _col-2">
                      <h1 className="proposals-received-title _14px _fw700 _color _black">
                        Propostas recebidas
                        <span>( 0 )</span>
                      </h1>
                    </div>

                    <div className="_action-inactive">
                      <div className="_action-buttons">
                        <button onClick={() => this.setAdState(true, ad._id)} type="button" className="btn">
                          Ativar
                          <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                        </button>
                        <button onClick={() => this.props.history.push('/editar-anuncio/' + ad._id)} type="button" className="btn">
                          Editar
                          <FontAwesomeIcon icon={faEdit}></FontAwesomeIcon>
                        </button>
                      </div>
                    </div>
                  </section>
                </div>
              ))}
              {this.state.inactiveAds.length === 0 ? (<p style={{ textAlign: 'center' }}>Você não possui nenhum anúncio inativo</p>) : (<></>)}
            </Tab>
          </Tabs>
        </div>
      </Layout>
    );
  }

}

const mapStateToProps = (state) => ({
  site_properties: state.websiteReducer.site_properties,
  website: state.websiteReducer.website
})

export default connect(mapStateToProps, {})(MyAds);
