import React, { useState } from "react";
import { Link } from "react-router-dom";

import ReactFullpage from '@fullpage/react-fullpage';

import { useMediaQuery } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import { Container } from "../../components/design/layout/container";
import { Logo } from "../../components/design/layout/logo";
import {
    Wrapper,
    LandingContent,
    FullPageSection,
    Content,
    MainHeader,
    WrapperLogo,
    FixedHeadline,
    Headline,
    ImageBox,
    ImageFull,
    ImageText,
    ImageTextTitle,
    WrapperButton,
    BannerWithText,
    WrapBanner,
    Banner,
    BannerTitle,
    BannerText,
    IconTextBlock,
    IconImage,
    Icone,
    Title,
    Subtitle,
} from "./styles";
import Button from "../../components/design/form/button";

// Images
import LogoImage from "../../assets/images/logo.png";
import Image01 from "../../assets/images/institucional/landing-page/image-01.jpg";
import Image02 from "../../assets/images/institucional/landing-page/signup.jpg";
import Banner01 from "../../assets/images/institucional/landing-page/banner-01.jpg";
import Banner02 from "../../assets/images/institucional/landing-page/banner-02.jpg";
import Icon01 from "../../assets/images/institucional/landing-page/icon-01.png";
import Icon02 from "../../assets/images/institucional/landing-page/icon-02.png";
import Icon03 from "../../assets/images/institucional/landing-page/icon-03.png";
import Icon04 from "../../assets/images/institucional/landing-page/icon-04.png";
import Icon05 from "../../assets/images/institucional/landing-page/icon-05.png";
import Icon06 from "../../assets/images/institucional/landing-page/icon-06.png";
import Icon07 from "../../assets/images/institucional/landing-page/icon-07.png";
import Icon08 from "../../assets/images/institucional/landing-page/icon-08.png";

const Section = ({ children }) => <FullPageSection className="section">{children}</FullPageSection>

const Header = ({ text }) => {

    return (
        <MainHeader>
            <WrapperLogo>
                <Logo link="#!" image={LogoImage} alt="Scamb" />
            </WrapperLogo>
            {!!text && (
                <FixedHeadline>
                    <Link to="/criar-conta">
                        {text}
                    </Link>
                </FixedHeadline>
            )}
        </MainHeader>
    )
}

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <>{children}</>}
        </div>
    );
};

const Fullpage = ({ children }) => (
    <ReactFullpage
        scrollingSpeed={500}
        // navigation={}

        render={({ state, fullpageApi }) => {
            return (
                <>
                    <ReactFullpage.Wrapper>
                        {children}
                    </ReactFullpage.Wrapper>
                </>
            );
        }}
    />
);


function Landing() {

    // * Media Query
    const matches = useMediaQuery("(min-width:360px)");

    // * States
    const [tabValue, setTabValue] = useState(0);

    // * Tabs
    // * Material UI Tabs
    // * Props Tab
    function tabProps(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    }

    // * Material UI Tabs
    // * On Change Tab
    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };


    return (
        <>
            <Wrapper>
                <LandingContent>
                    <Header text="FINALIZE SEU CADASTRO E GANHE 25PTS!" />

                    <Fullpage>

                        <Section>

                            <Content>

                                <Container withContainer={true}>
                                    <Headline>
                                        NO SCAMB, PESSOAS RENOVAM COISAS, MARCAS MOVIMENTAM PRODUTOS E NADA FICA PARADO!
                                    </Headline>

                                    <ImageBox>
                                        <ImageFull src={Image01} alt="image 01" />
                                        <ImageText>
                                            somos uma nova solução para quem
                                            quer consumir segunda mão de um jeito
                                            diferente, economizar e participar da
                                            real circularidade!

                                            <ImageTextTitle>
                                                AQUI VOCÊ FAZ O BEM E SE DÁ BEM!
                                            </ImageTextTitle>
                                        </ImageText>
                                    </ImageBox>

                                    <WrapperButton>
                                        <Button
                                            type="bordered"
                                            buttonSize="medium"
                                            buttonColor="#C2403F"
                                            textColor="#FFF"
                                            buttonText="Cadastre-se agora"
                                            buttonLink="/criar-conta"
                                            borderColor="#C2403F"
                                            maxWidth={200}
                                        />
                                    </WrapperButton>
                                </Container>
                            </Content>
                        </Section>

                        <Section>
                            <Content className="ContentWithTabs">
                                <Container withContainer={true}>
                                    <Headline className="HeadlineBlock2">
                                        existem 2 áreas para circular no scamb
                                    </Headline>
                                </Container>

                                <Container withContainer={true}>

                                    <BannerWithText marginBottom={matches ? 25 : 10}>
                                        <WrapBanner>
                                            <Banner src={Banner01} alt="Banner 01" />
                                        </WrapBanner>

                                        <BannerTitle>
                                            PESSOAS QUE CIRCULAM
                                        </BannerTitle>
                                        <BannerText>
                                            Aqui o movimento acontece entre pessoas e os produtos são comprados e vendidos em pontos!
                                        </BannerText>
                                    </BannerWithText>

                                    <BannerWithText>
                                        <WrapBanner>
                                            <Banner src={Banner02} alt="Banner 01" />
                                        </WrapBanner>

                                        <BannerTitle>
                                            MARCAS QUE CIRCULAM
                                        </BannerTitle>
                                        <BannerText>
                                            Aqui, elas colocam essas peças para circular, você compra em dinheiro e ainda ganha pontos!
                                        </BannerText>
                                    </BannerWithText>

                                    <WrapperButton>
                                        <Button
                                            type="bordered"
                                            buttonSize="medium"
                                            buttonColor="#C2403F"
                                            textColor="#FFF"
                                            buttonText="Cadastre-se agora"
                                            buttonLink="/criar-conta"
                                            borderColor="#C2403F"
                                            maxWidth={200}
                                        />
                                    </WrapperButton>
                                </Container>
                            </Content>
                        </Section>

                        <Section>
                            <Content>

                                <Container withContainer={true}>
                                    <IconTextBlock marginBottomBlock={20}>
                                        <IconImage>
                                            <Icone src={Icon01} alt="icone" />
                                        </IconImage>
                                        <Title>
                                            FRETE R$9,90 TODO BRASIL*
                                        </Title>
                                        <Subtitle>
                                            *até 0,5kg
                                        </Subtitle>
                                    </IconTextBlock>
                                    <IconTextBlock marginBottomBlock={20}>
                                        <IconImage>
                                            <Icone src={Icon02} alt="icone" />
                                        </IconImage>
                                        <Title>
                                            6X SEM JUROS*
                                        </Title>
                                        <Subtitle>
                                            *a partir R$50,00
                                        </Subtitle>
                                    </IconTextBlock>
                                    <IconTextBlock marginBottomBlock={20}>
                                        <IconImage>
                                            <Icone src={Icon03} alt="icone" />
                                        </IconImage>
                                        <Title>
                                            7 DIAS PARA DEVOLUÇÃO
                                        </Title>
                                    </IconTextBlock>
                                    <IconTextBlock marginBottomBlock={10}>
                                        <IconImage>
                                            <Icone src={Icon04} alt="icone" />
                                        </IconImage>
                                        <Title>
                                            SEGURANÇA
                                        </Title>
                                        <Subtitle>
                                            Processo de compra 100% seguro
                                        </Subtitle>
                                    </IconTextBlock>

                                    <WrapperButton>
                                        <Button
                                            type="bordered"
                                            buttonSize="medium"
                                            buttonColor="#C2403F"
                                            textColor="#FFF"
                                            buttonText="Cadastre-se agora"
                                            buttonLink="/criar-conta"
                                            borderColor="#C2403F"
                                            maxWidth={200}
                                        />
                                    </WrapperButton>
                                </Container>
                            </Content>
                        </Section>

                        <Section>
                            <Content>

                                <Container withContainer={true}>
                                    <IconTextBlock marginBottomBlock={20}>
                                        <IconImage>
                                            <Icone src={Icon05} alt="icone" />
                                        </IconImage>
                                        <Title>
                                            VOCÊ FAZ ÓTIMOS NEGÓCIOS
                                        </Title>
                                        <Subtitle>
                                            e economiza
                                        </Subtitle>
                                    </IconTextBlock>
                                    <IconTextBlock marginBottomBlock={20}>
                                        <IconImage>
                                            <Icone src={Icon06} alt="icone" />
                                        </IconImage>
                                        <Title>
                                            COMPRA COM OU SEM DINHEIRO
                                        </Title>
                                    </IconTextBlock>
                                    <IconTextBlock marginBottomBlock={20}>
                                        <IconImage>
                                            <Icone src={Icon07} alt="icone" />
                                        </IconImage>
                                        <Title>
                                            SEM COMISSÃO E FRETE PARA O VENDEDOR
                                        </Title>
                                    </IconTextBlock>
                                    <IconTextBlock marginBottomBlock={10}>
                                        <IconImage>
                                            <Icone src={Icon08} alt="icone" />
                                        </IconImage>
                                        <Title>
                                            PROMOVE A CIRCULARIDADE REAL
                                        </Title>
                                        <Subtitle>
                                            e o consumo consciente
                                        </Subtitle>
                                    </IconTextBlock>

                                    <WrapperButton>
                                        <Button
                                            type="bordered"
                                            buttonSize="medium"
                                            buttonColor="#C2403F"
                                            textColor="#FFF"
                                            buttonText="Cadastre-se agora"
                                            buttonLink="/criar-conta"
                                            borderColor="#C2403F"
                                            maxWidth={200}
                                        />
                                    </WrapperButton>
                                </Container>
                            </Content>
                        </Section>
                        <Section>
                            <Content>

                                <Container withContainer={true}>
                                    <ImageBox>
                                        <ImageFull src={Image02} alt="image 02" />
                                    </ImageBox>

                                    <WrapperButton>
                                        <Button
                                            type="bordered"
                                            buttonSize="medium"
                                            buttonColor="#C2403F"
                                            textColor="#FFF"
                                            buttonText="Cadastre-se agora"
                                            buttonLink="/criar-conta"
                                            borderColor="#C2403F"
                                            maxWidth={200}
                                        />
                                    </WrapperButton>
                                </Container>
                            </Content>
                        </Section>

                    </Fullpage>

                </LandingContent>
            </Wrapper>
        </>
    )
}

export default Landing;