//SCAMBER TYPES
export const SET_SCAMBER = "SET_SCAMBER";

//AD TYPES
export const SET_AD = 'SET_AD';

//CART ITENS
export const SET_CART = 'SET_CART';

//BALANCE TYPES
export const SET_BALANCE = "SET_BALANCE";

//WEBSITE OPENED TYPES
export const SET_WEBSITE = "SET_WEBSITE";

//WEBSITE PROPERTIES TYPES
export const SET_WEBISTE_PROPERTIES = "SET_WEBISTE_PROPERTIES";