import Service from './Service';

class ShortURL extends Service {

    constructor(){
        super();
    }

    shortAdURL( adInfo ) {
        return this.axiosInstance.post(`/short`, adInfo);
    }

}

export default ShortURL;
