import React, { Component } from 'react';
import { Link as LinkRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HashLink as Link } from 'react-router-hash-link';
import { connect } from 'react-redux';

// Icons
import {
    faFacebookF,
    faInstagram,
    faLinkedinIn
} from '@fortawesome/free-brands-svg-icons';

// Images
import LogoScamb from '../assets/images/logo.png';
import SSLSecureLogo from '../assets/images/sectigo_trust_seal_lg_140x54.png';

class Footer extends Component {

    render() {

        return (
            <>
                <div>
                    <div className="_wrap-footer">

                        <div className="_footer">

                            {/* Container */}
                            <div className="_med-container">

                                {/* Cols */}
                                <div className="cols">

                                    <div className="_col _col-1">
                                        <div className="inside-col">
                                            <Link to="/" className="logo">
                                                <img src={LogoScamb} alt="" />
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="_col _col-2 _institucional">
                                        <div className="inside-col">
                                            <h1 className="title _color _black _14px _fw700">
                                                Scamb
                                            </h1>

                                            <nav className="_list-nav">
                                                <ul>

                                                    <li>
                                                        <Link to="/sobre-o-scamb">
                                                            como funciona
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/sobre-o-scamb">
                                                            sobre o scamb
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/#!!">
                                                            manifesto
                                                        </Link>
                                                    </li>

                                                </ul>
                                            </nav>
                                        </div>

                                    </div>
                                    <div className="_col _col-2 _second-column">
                                        <div className="inside-col">
                                            <h1 className="title _color _black _14px _fw700">
                                                Links
                                            </h1>

                                            <nav className="_list-nav _first-list">
                                                <ul>
                                                    <li>
                                                        <Link to="/ajuda">
                                                            ajuda
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/termos-e-condicoes">
                                                            termos de uso
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/politica-de-privacidade">
                                                            políticas de privacidade
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/promocoes">
                                                            promoções
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>

                                    </div>

                                    <div className="_col _col-3">
                                        <div className="inside-col">
                                            <h1 className="title _color _black _14px _fw700">
                                                Perfil
                                            </h1>

                                            <nav className="_list-nav">
                                                <ul>
                                                    <li>
                                                        <Link to="/login">
                                                            entrar
                                                        </Link>
                                                    </li>

                                                    <li>
                                                        <Link to="/meu-perfil">
                                                            minha conta
                                                        </Link>
                                                    </li>
                                                    {/* <li>
                                            <li className="_is-hidden">
                                                <Link to="/minha-carteira">
                                                    minha carteira
                                                </Link>
                                            </li> */}
                                                    <li>
                                                        {this.props.scamber ?
                                                            this.props.scamber.minha_loja?.url ?
                                                                <Link to={`/loja/${this.props.scamber.minha_loja.url}`}>
                                                                    minha loja
                                                                </Link>
                                                                :
                                                                <Link to="/meu-perfil">
                                                                    minha loja
                                                                </Link>
                                                            :
                                                            <Link to="/login">
                                                                minha loja
                                                            </Link>
                                                        }

                                                    </li>
                                                    {/* <li>
                                                <Link to="/minhas-compras">
                                                    minhas compras
                                                </Link>
                                            </li> */}
                                                    {/* <li>
                                            <li className="_is-hidden">
                                                <Link to="/minhas-compras">
                                                    minhas compras
                                                </Link>
                                            </li>
                                            <li className="_is-hidden">
                                                <Link to="/minhas-vendas">
                                                    minhas vendas
                                                </Link>
                                            </li> */}
                                                </ul>
                                            </nav>
                                        </div>

                                    </div>

                                    <div className="_col _col-4">
                                        <div className="inside-col">
                                            <h1 className="title _color _black _14px _fw700">
                                                Siga o Scamb
                                            </h1>

                                            <nav className="_list-nav socials">
                                                <ul>
                                                    <li>
                                                        <a href="https://www.facebook.com/Scamb-100953004928723" target="_blank">
                                                            <FontAwesomeIcon icon={faFacebookF} />
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="https://instagram.com/scamb.com.vc?igshid=m4gvlpgsy97v" target="_blank">
                                                            <FontAwesomeIcon icon={faInstagram} />
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="https://www.linkedin.com/company/scamb/?viewAsMember=true" target="_blank">
                                                            <FontAwesomeIcon icon={faLinkedinIn} />
                                                        </a>
                                                    </li>
                                                </ul>
                                            </nav>

                                            <div className="_legals">
                                                <span className="_color _black _12px _fw700 d-block">
                                                    Scamb Atividades de Internet e Prestação de Serviços Ltda.
                                                </span>

                                                <span className="_color _black _12px _fw700 d-block">
                                                    <a href="mailto:contato@scamb.com.vc" className="d-block">contato@scamb.com.vc</a>
                                                </span>
                                            </div>

                                            <div className="secure-logo">
                                                <img src={SSLSecureLogo} alt="Kiwi standing on oval" />
                                            </div>

                                        </div>

                                    </div>

                                </div>{/* END ./ Cols */}

                            </div>{/* END ./ Container */}
                        </div>

                        <div className="copyrights">

                            <div className="_med-container">
                                <p className="_color _black _11px ">Scamb © 2020 - todos os direitos reservados.</p>
                                <p className="_color _black _11px ">Alameda Ministro Rocha Azevedo, nº 912 E37, Jardim Paulista, São Paulo/SP, CEP: 01410-002. CNPJ: 39.479.710/0001-81</p>
                            </div>

                        </div>

                    </div>

                </div>
            </>
        )

    }

}

const mapStateToProps = state => {
    return {
        scamber: state.scamberReducer.scamber
    }
}

export default connect(mapStateToProps)(Footer);
