//=====================================================================================
// #1 - Base Imports
//=====================================================================================
import React from "react";

//=====================================================================================
// #2 - Import * Styled Components
//=====================================================================================
import { WrapAvatar, Image } from "./styles";

//=====================================================================================
// #2 - Import * Images
//=====================================================================================
import UserAvatar from "../../../../assets/images/avatar.png";

export const Avatar = ({ size, image }) => {

    const avatarSize = {
        small: "small",
        medium: "medium",
        large: "large",
    }

    return (
        <>
            <WrapAvatar className="scamb-avatar" size={avatarSize[size]} >
                <Image
                    src={image ? image : UserAvatar}
                    alt="Picture of the author"
                />
            </WrapAvatar>

        </>
    )
}