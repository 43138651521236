//=====================================================================================
// #1 - Base Imports
//=====================================================================================
import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
  useEffect,
  useCallback,
} from "react";

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import replaceAll from "string.prototype.replaceall";

//===============================================================================================================
// #5 - Import * Utils
//===============================================================================================================
import formatTitle from "../../../utils/FormatTitle";

//=====================================================================================
// #2 - Import * Sweet Alert 2
//=====================================================================================
import SweetAlert from "sweetalert";
import Swal from "sweetalert2";

//=====================================================================================
// #3 - Import * Imput Mask
//=====================================================================================
import InputMask from "react-input-mask";

//=====================================================================================
// #4 - Import * React Hook Form
//=====================================================================================
import { useForm } from "react-hook-form";

//=====================================================================================
// #5 - Import * Countdown Timer
//=====================================================================================
import Timer from "react-compound-timer";
import { NothingMessage } from "../../../components/ui/nothing-message";
//=====================================================================================
// #6 - Import * Material UI Components
//=====================================================================================
import TextField from "@material-ui/core/TextField";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import {
  Grid,
  useMediaQuery,
  InputAdornment,
  Select,
  MenuItem,
} from "@material-ui/core";

import {
  ThemeProvider,
  withStyles,
  makeStyles,
  createTheme,
} from '@material-ui/core/styles';

import { useHistory, withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";

//=====================================================================================
// #7 - Import * Boostrap Modals
//=====================================================================================
import { Modal } from "react-bootstrap";
import ModalBase from "../../../components/modals/ModalBase";
import ModalShippingOptions from "../../../common_pages/modals/ModalShippingOptions";
import ModalTaxs from "../../../common_pages/modals/ModalTaxs";

//=====================================================================================
// #8 - Import * UI Components
//=====================================================================================
import { AdvertiserCard } from "../../../components/ui/advertiser-card";
import { Price, PricePoints } from "../../../components/ui/price";
import { ListContent, ListItem } from "../../../components/ui/list-content";
import { ProductThumbnail } from "../../../components/ui/product-thumbnail";
import { CurrentPage } from "../../../components/ui/current-page";
import { SelectBox, SelectBoxExpand } from "../../../components/ui/select-box";
import { BoxInfo } from "../../../components/ui/box-info";

//=====================================================================================
// #9 - Import * FontAwesome Icons
//=====================================================================================
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import {
  faCreditCardBlank as faCreditCardLt,
  faUser,
  faCalendarStar,
  faCalendarAlt,
  faLockAlt,
  faShoppingCart,
  faHandHoldingBox,
  faQuestionCircle,
  faChevronRight,
  faChevronLeft,
  faClock,
  faBarcodeAlt,
  faPlus,
  faUserLock,
  faEdit,
  faIdCard,
  faTrashAlt,
  faCreditCard,
} from "@fortawesome/pro-light-svg-icons";

import { faTruck, faCheck } from "@fortawesome/pro-regular-svg-icons";
import {
  faPlus as faPlusRegular,
  faInfo,
  faMapMarkerAlt,
  faCheck as faCheckSolid,
  faTag
} from "@fortawesome/pro-solid-svg-icons";

//===============================================================================================================
// #10 - Import * Redux Actions
//===============================================================================================================
import { setScamber } from "./../../../redux/actions/scamberActions";

//===============================================================================================================
// #10 - Import * Bootstrap Components
//===============================================================================================================
import Button from "react-bootstrap/Button";

//===============================================================================================================
// #11 - Credit Card Flags
//===============================================================================================================
import Visa from "../../../assets/images/credit-card/visa.png";
import Mastercard from "../../../assets/images/credit-card/mastercard.png";
import Elo from "../../../assets/images/credit-card/elo.png";
import AmericanExpress from "../../../assets/images/credit-card/americanExpress.png";
import DinersClub from "../../../assets/images/credit-card/DinersClub.png";
import Pix from "../../../assets/images/pix-logo.png";
import AddCC from "../../../assets/images/icon-add-cc@2x.png";

//===============================================================================================================
// #12 - Fake Data
//===============================================================================================================
import Scamber from "../../../services/Scamber";
import Cart from "../../../services/Cart";
import Anuncios from "../../../services/Ad";
import Favorito from "../../../services/Favorito";
import Endereco from "../../../services/Endereco";
import ScamberFormaPagamento from "../../../services/ScamberFormasPagamento";
import Pedido from "../../../services/Pedido";
import Shipment from '../../../services/Shipment';
import CouponService from "../../../services/Coupon";

// JQuery
import $ from "jquery";
import "jquery-mask-plugin";

import CoverIMG from "./../../../assets/images/cover.png";
import Avatar from "./../../../assets/images/avatar.png";

// Utils
import UseScript from "../../../utils/UseScript";
import ValidateIugu from "../../../utils/ValidateIugu";

import EventEmitter from "./../../../services/Events";
import { propTypes } from "react-bootstrap/esm/Image";
import Product from "./../../../common_pages/Product";
import formatNumberToBRL from "../../../utils/FormatNumberToBRL";
import CheckoutBlock from "./compoments/checkout-block";
import { Container } from "../../../components/design/layout/container";

import { Wrapper, ShippingBlock, CupomBlock, FieldWrapper, PaymentTabs, SelectParcels, SelectParcelsTitle, CheckoutActions } from "./styles";
import PedidoShopping from "../../../services/PedidoShopping";
import Shipping from "./compoments/shipping";
import OrderDetails, { OrderDetail, SkippedStitches } from "./compoments/order-details";
import PaymentTab from "./compoments/payment-tab";


let scamber = new Scamber();
let cart = new Cart();
let anuncios = new Anuncios();
let endereco = new Endereco();
let scamberFormaPagamento = new ScamberFormaPagamento();
// let pedido = new Pedido();
let pedidoShopping = new PedidoShopping();
let shipment = new Shipment();
let couponService = new CouponService();

function limitTitle(text = "", maxWords = 1) {
  let setText = "";
  const textArray = [];
  const textToArray = text.split(" ");

  if (textToArray.length > 6) {
    const sl = textToArray.slice(0, maxWords);
    textArray.push(sl);
    const joinToStr = textArray.join("|");
    const formatText = replaceAll(joinToStr, ",", " ");

    setText += formatText;

  } else {
    setText += text;
  }

  return setText || "...";
}

const CupomField = withStyles({
  root: {
    "& .MuiFormLabel-root.Mui-error": {
      color: "green !important"
    },

    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "green !important",
      color: "green !important"
    },

    "& .MuiInputBase-input": {
      color: "#14233e !important",
      fontWeight: "bold"
    },

    "& .MuiInputBase-input:disabled": {
      color: "#14233e !important",
      fontWeight: "bold"
    },
  },
})(TextField);


//===============================================================================================================
// #13 - Modal - Address
//===============================================================================================================
const ModalAddress = forwardRef((props, ref) => {
  // * States
  const [show, setShow] = useState(false);

  // * Function
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useImperativeHandle(ref, () => ({
    openModal: () => handleShow(),
    closeModal: () => handleClose(),
  }));

  return (
    <>
      <Modal show={show} onHide={handleClose} className="full-width" centered>
        <Modal.Header closeButton>
          <section className="_view-head">
            <div className="_view-head-content">
              <Button className="_back-history btn" onClick={handleClose}>
                <Icon icon={faChevronLeft} />
              </Button>
              <Modal.Title> {props.title} </Modal.Title>
            </div>
          </section>
        </Modal.Header>
        <Modal.Body className={props.className}>{props.children}</Modal.Body>
      </Modal>
    </>
  );
});

//===============================================================================================================
// #13 - Modal - Personal Data
//===============================================================================================================
const ModalPersonalData = forwardRef((props, ref) => {
  // * States
  const [show, setShow] = useState(false);
  const [cep, setCep] = useState("");
  const [checkedAddress, setCheckedAddress] = useState();
  const [customProfile, setCustomProfile] = useState({});

  // * React Hook Form
  const { register, handleSubmit, errors, formState, reset } = useForm({ mode: "onChange" });

  // Styles
  const matches = useMediaQuery("(min-width:600px)");
  const classes = useStyles();

  useEffect(() => {
    initFormValues(props.scamber);
  }, [props.scamber]);

  const initFormValues = (profile) => {
    let customProfile = {}

    if (profile) {
      customProfile.cpf = profile.cpf;

      if (profile.telefone && profile.telefone.length > 0) {
        customProfile.phone = `(${profile.telefone[0].ddd}) ${profile.telefone[0].numero.substring(0, 5)}-${profile.telefone[0].numero.substring(5, 9)}`;
      }

      setCustomProfile(customProfile)
      reset(customProfile);
    }
  }

  const loadScamberData = () => {
    Swal.fire("Aguarde...");
    Swal.showLoading();
    scamber
      .findScamber()
      .then((scamberData) => {
        Swal.close();
        if (scamberData.status === 200)
          props.setScamber(scamberData.data.payload);
      });
  };

  // * Functions - React Mask
  const handleCepChange = (e) => setCep(e.target.value);

  // * Function
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useImperativeHandle(ref, () => ({
    openModal: () => handleShow(),
    closeModal: () => handleClose(),
  }));

  useEffect(() => {
    $(".cep").mask("00000-000");
    $(".cpf").mask("000.000.000-00", { reverse: false });
    $(".celular").mask("(00) 00000-0000");
    $(".house_number").mask("00000");
  });

  const findAddressByCEP = () => {
    if (cep.length === 9) {
      endereco
        .findCep(cep)
        .then((address) => {
          let formatedAddress = {
            cep: address.data.cep,
            estado: address.data.estado,
            logradouro: address.data.logradouro,
            cidade: address.data.localidade,
            estado: address.data.uf,
            bairro: address.data.bairro,
            complemento: address.data.complemento,
            phone: customProfile.phone,
            cpf: customProfile.cpf
          };

          if (address.data) {
            reset(formatedAddress);
          }
        })
        .catch((error) =>
          Swal.fire("info", "Não foi possível consultar o endereço", "")
        );
    }
  };

  const setPersonalData = (data, callback) => {

    if (data.senha === 'not_specified') {
      delete data.senha;
    }

    scamber.updateScamber(data).then((response) => {
      if (response.status === 200) {
        callback()
      }

    }).catch((error) => scamber.formatErrorMessage(error.response, 'BPUPSC'));

  };


  // * Create New Address
  const onSubmitAddress = (data) => {

    let dadosCadastrais = { cpf: data.cpf, celular: data.phone };

    delete data.cpf;
    delete data.phone;

    data.principal = checkedAddress;

    setPersonalData(dadosCadastrais, () => {
      if (props.scamber?.endereco.length === 0) {

        endereco
          .create(data)
          .then((response) => {
            if (response.status === 200) {
              // * Close Modal
              handleClose();
              loadScamberData();
            }
          })
          .catch((error) =>
            endereco.formatErrorMessage(error.response, 'BPCREN')
          );

      } else {
        handleClose();
        loadScamberData();
      }


      setCheckedAddress(false);
    })

  };

  return (
    <>
      <Modal show={show} onHide={handleClose} className="full-width" centered>
        <Modal.Header closeButton>
          <section className="_view-head">
            <div className="_view-head-content">
              <Button className="_back-history btn" onClick={handleClose}>
                <Icon icon={faChevronLeft} />
              </Button>
              <Modal.Title> Quase lá! </Modal.Title>
            </div>
          </section>
        </Modal.Header>
        <Modal.Body>
          <p style={{ textAlign: 'center', marginTop: '10px' }}>Precisamos que complete algumas informações antes de prosseguir com a solicitação</p>

          <form onSubmit={handleSubmit(onSubmitAddress)}>
            <h1 className="_content-title" />
            {/*  Form Body */}
            <section className="_form-body">
              <Grid container spacing={2}>
                {props.scamber?.endereco.length === 0 ?
                  (
                    <>
                      <Grid item xs={12}>
                        <TextField
                          id="outlined-cc-number"
                          label="Cep *"
                          type="text"
                          onChange={handleCepChange}
                          onBlur={findAddressByCEP}
                          variant="outlined"
                          name="cep"
                          size={matches ? "small" : "small"}
                          error={!!errors.cep}
                          inputProps={{ className: "cep" }}
                          fullWidth
                          inputRef={register({
                            required: true,
                            minLength: 8,
                          })}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          id="outlined-neighborhood"
                          label="Estado *"
                          variant="outlined"
                          size={matches ? "small" : "small"}
                          name="estado"
                          error={!!errors.estado}
                          fullWidth
                          inputProps={{ maxLength: 2 }}
                          inputRef={register({
                            required: true,
                          })}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="outlined-neighborhood"
                          label="Cidade *"
                          variant="outlined"
                          size={matches ? "small" : "small"}
                          name="cidade"
                          error={!!errors.cidade}
                          fullWidth
                          inputRef={register({
                            required: true,
                          })}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>

                      <Grid item xs={8}>
                        <TextField
                          id="outlined-address"
                          label="Endereço *"
                          variant="outlined"
                          size={matches ? "small" : "small"}
                          name="logradouro"
                          error={!!errors.logradouro}
                          fullWidth
                          inputRef={register({
                            required: true,
                          })}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          id="outlined-address-number"
                          label="Número *"
                          type="number"
                          variant="outlined"
                          inputProps={{ className: "house_number" }}
                          size={matches ? "small" : "small"}
                          name="numero"
                          error={!!errors.numero}
                          fullWidth
                          inputRef={register({
                            required: true,
                          })}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="outlined-neighborhood"
                          label="Bairro *"
                          variant="outlined"
                          size={matches ? "small" : "small"}
                          name="bairro"
                          error={!!errors.bairro}
                          fullWidth
                          inputRef={register({
                            required: true,
                          })}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="outlined-complement"
                          label="Complemento"
                          variant="outlined"
                          name="complemento"
                          size={matches ? "small" : "small"}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="outlined-cc-number"
                          label="Apelido *"
                          type="text"
                          variant="outlined"
                          name="apelido"
                          placeholder="Exemplo: Casa, Trabalho.."
                          size={matches ? "small" : "small"}
                          error={!!errors.apelido}
                          fullWidth
                          inputRef={register({
                            required: true,
                            minLength: 2,
                          })}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>

                    </>
                  ) : (<></>)
                }

                <Grid item xs={12}>
                  <TextField
                    id="outlined-cc-number"
                    label="Telefone *"
                    type="text"
                    variant="outlined"
                    name="phone"
                    placeholder=""
                    size={matches ? "small" : "small"}
                    error={!!errors.phone}
                    fullWidth
                    inputRef={register({
                      required: true,
                      minLength: 2,
                    })}
                    inputProps={{
                      className: "celular"
                    }}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    id="outlined-cc-number"
                    label="CPF *"
                    type="text"
                    variant="outlined"
                    name="cpf"
                    placeholder=""
                    size={matches ? "small" : "small"}
                    error={!!errors.cpf}
                    fullWidth
                    inputRef={register({
                      required: true,
                      minLength: 2,
                    })}
                    inputProps={{
                      className: 'cpf'
                    }}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
              </Grid>

            </section>

            <Button
              type="submit"
              className={
                "btn btn-primary btn-fluid btn-submit-modal " + classes.center
              }
              color="primary"
              autoFocus
              style={{ height: matches ? 35 : 40 }}
              disabled={
                !formState.dirty || (formState.dirty && !formState.isValid)
              }
            >
              Confirmar
              <Icon icon={faCheck} />
            </Button>
            {/*  END ./ Form Body */}
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
});

//===============================================================================================================
// #14 - Modal - Add Credit Card
//===============================================================================================================
const ModalCreditCard = forwardRef((props, ref) => {
  // Bootstrap Modal
  const [show, setShow] = useState(false);

  // Handle Modal
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useImperativeHandle(ref, () => ({
    openModal: () => handleShow(),
    closeModal: () => handleClose(),
  }));

  return (
    <>
      <Modal show={show} onHide={props.onClick} className="full-width" centered>
        <Modal.Header closeButton>
          <section className="_view-head">
            <div className="_view-head-content">
              <Button className="_back-history btn" onClick={props.onClick}>
                <Icon icon={faChevronLeft} />
              </Button>
              <Modal.Title>Adicionar informações</Modal.Title>
            </div>
          </section>
        </Modal.Header>
        <Modal.Body>{props.children}</Modal.Body>
      </Modal>
    </>
  );
});

//===============================================================================================================
// #15 - Modal - Add Credit Card
//===============================================================================================================
const ModalPreviewProduct = forwardRef((props, ref) => {
  // Bootstrap Modal
  const [show, setShow] = useState(false);

  // Handle Modal
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useImperativeHandle(ref, () => ({
    openModal: () => handleShow(),
    closeModal: () => handleClose(),
  }));

  return (
    <>
      <Modal show={show} onHide={handleClose} className="full-width" centered>
        <Modal.Header closeButton>
          <section className="_view-head">
            <div className="_view-head-content">
              <Button className="_back-history btn" onClick={handleClose}>
                <Icon icon={faChevronLeft} />
              </Button>
              <Modal.Title> {props.title} </Modal.Title>
            </div>
          </section>
        </Modal.Header>
        <Modal.Body>{props.children}</Modal.Body>
      </Modal>
    </>
  );
});

//===============================================================================================================
// #16 - Default
//===============================================================================================================
const useStyles = makeStyles({
  center: {
    margin: "auto",
  },
  resize: {
    fontSize: "10px",
  },
  inputSize: {
    height: 50,
  },
});

const missingPointsModalStyles = {
  padding: "15px"
}

const missingPointsModalStylesParaf = {
  display: "block",
  fontSize: "13px",
  color: "#515157",
  fontWeight: "700",
  marginBottom: "10px",
  textAlign: "center"
}

//===============================================================================================================
// #16 - Address Card
//===============================================================================================================
const AddressCard = (props) => {
  return (
    <div className={"address-card " + props.className}>
      <label>
        {props.field}
        <div className="overlay"></div>
        <div className="mark">
          <Icon icon={faCheckSolid} />
        </div>
        <div className="_cols">
          <div className="_col">
            <div className="icon">
              <Icon icon={faMapMarkerAlt} />
            </div>
          </div>

          <div className="_col">
            <span className="tag"> {props.nickname} </span>
            <h1 className="_address-cep"> {props.cep} </h1>
            <div className="_full-address">{props.children}</div>
          </div>
        </div>
      </label>
    </div>
  );
};

const AddressCardActive = (props) => {
  return (
    <div className="address-card buy-page active">
      <label>
        {props.field}
        <div className="overlay"></div>
        <button
          type="button"
          className="btn action-address"
          onClick={props.edit}
        >
          <Icon icon={faEdit} />
        </button>
        <div className="_cols">
          <div className="_col">
            <div className="icon">
              <Icon icon={faMapMarkerAlt} />
            </div>
          </div>

          <div className="_col">
            <span className="tag"> {props.nickname} </span>
            <h1 className="_address-cep"> {props.cep} </h1>
            <div className="_full-address">{props.children}</div>
          </div>
        </div>
      </label>
    </div>
  );
};

function Buy(props) {
  // States
  const [shippingIcon, setShippingIcon] = useState();
  const [checkbox, setCheckbox] = useState(false);
  const [resumePanel, setResumePanel] = useState(false);
  const [cep, setCep] = useState("");
  const [creditCardType, setCreditCardType] = useState(null);
  const [valueField, setValue] = useState("");
  const [cartObject, setCartObject] = useState({});
  const [checked, setChecked] = useState();
  const [isBoleto, setIsBoleto] = useState();
  const [payMethod, setPayMethod] = useState();
  const [frete, setFrete] = useState();
  const [checkedAddress, setCheckedAddress] = useState();
  const [valueFieldCpf, setValueCpf] = useState("");
  const [check, setCheck] = useState(true);
  const [editing, setEditing] = useState(false);
  const [addressId, setAddressId] = useState("");
  const [addressChecked, setAddressChecked] = useState("");
  const [paymentMethod, setPaymentMethod] = useState([]);
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [cvv, setCvv] = useState("");
  const [dateTimer, setDateTimer] = useState();
  const [haveCorreios, setHaveCorreios] = useState(false);
  const [haveEntregaMaos, setHaveEntragaMaos] = useState(false);

  const [startShipmentRange, setStartShipmentRange] = useState('');
  const [endShipmentRange, setEndShipmentRange] = useState('');
  const [installmentValue, setInstallmentValue] = useState(1);
  const [missingValue, setMissingValue] = useState(0);
  const [missingPoints, setMissingPoints] = useState(0);
  const [coupon, setCoupon] = useState({ coupon_code: "", _id: "", show: false });
  const [couponApplied, setCouponApplied] = useState(false);

  const checkboxRef = useRef(false);


  const params = (price, callback) => {

    let paramsValue = [
      { value: 1, title: `1x de R$ ${formatNumberToBRL(parseFloat(price))} sem juros` },
      { value: 2, title: `2x de R$ ${formatNumberToBRL(parseFloat(price / 2))} sem juros` },
      { value: 3, title: `3x de R$ ${formatNumberToBRL(parseFloat(price / 3))} sem juros` },
      { value: 4, title: `4x de R$ ${formatNumberToBRL(parseFloat(price / 4))} sem juros` },
      { value: 5, title: `5x de R$ ${formatNumberToBRL(parseFloat(price / 5))} sem juros` },
      { value: 6, title: `6x de R$ ${formatNumberToBRL(parseFloat(price / 6))} sem juros` }
    ];

    if (callback) {
      return callback(paramsValue);
    } else {
      return paramsValue;
    }
  }

  const map_products = props?.cart?.itens.map((product) => {
    return {
      name: product.titulo,
      id: product._id,
      price: product.pontos_por?.$numberDecimal
    }
  })

  const products_price = map_products?.map((item) => +item.price)

  const products_total_price = products_price?.reduce((acc, curr) => acc + curr)

  const total_frete = parseFloat(props?.cart?.valor_frete);

  const price_total = formatNumberToBRL(parseFloat(props?.cart?.valor));

  const cart_id = props?.cart?._id;

  const remove_item = (id) => {

    const findIdx = map_products?.findIndex((product) => product.id === id)

    return map_products?.slice(findIdx, 1)
  }

  const handleCheckShipping = (e) => {
    setCheckbox(!checkbox);
    const id = e.target.id;
    setShippingIcon(id);
  };

  const handleCoupomCode = (couponsArray) => {
    couponService.findCoupons(couponsArray, 'scamb').then(response => {
      const data = response.data.payload;

      if (response.data.type === 'success' && data) {
        setCoupon({
          coupon_code: data.cupom_code,
          _id: data._id,
          show: true
        });

        setCouponApplied(couponApplied => couponApplied = true);
      };
    })

  }

  useEffect(() => {
    if (props.cart?.itens && !checkboxRef.current) {
      checkboxRef.current = true;
      // setCheckbox(
      //   props.cart.itens.filter((product) => product.operador_logistico)
      //     .length > 0
      //     ? false
      //     : true
      // );

      // handleCoupomCode(props.cart?.couponUtilizado);

      if (props.cart.itens.filter((product) => product.operador_logistico).length > 0) {
        /*loadMissingValue(() => {*/
        loadCalcShipment();
        /*})*/
      } /*else {
        loadMissingValue();
      }*/

      if (props.cart?.couponUtilizado) {
        handleCoupomCode(props.cart?.couponUtilizado);
      }

    }


  }, [props.cart]);

  useEffect(() => {

    EventEmitter.subscribe("cartLoaded", (data) => {
      const filter_itens = data.itens.filter((product) => product.operador_logistico === true)
      setCheckbox(
        filter_itens.length > 0
          ? false
          : true
      );

    });

    return function cleanup() {

      EventEmitter.unsubscribe("cartLoaded");
    }
  }, [])

  const deleteCartItem = (itemASerRemovido, index) => {
    SweetAlert({
      title: "Remover produto?",
      icon: "warning",
      buttons: ["Fechar", "Confirmar"],
      dangerMode: true,
    }).then((isConfirm) => {
      if (isConfirm) {
        remove_item(itemASerRemovido);

        cart
          .deleteCart(itemASerRemovido, index, props.website)
          .then((result) => {
            Swal.fire({
              title: "Produto removido!",
              icon: "success",
              buttons: "Ok",
              dangerMode: true,
            });
            EventEmitter.dispatch("cartChange");
            if (props.cart?.itens?.length > 1) /*loadMissingValue(() =>*/ loadCalcShipment()/*)*/;

          })
          .catch((error) => {
            cart.formatErrorMessage(error.response, 'BDECAR')
          });
      }
    });
  };

  // * Checkbox
  const handleCheck = (classe, addressId) => {
    document.querySelector(`.${classe}`).checked = true;
    setAddressChecked(addressId);
    // setCheck(!check)
  };

  // Styles
  const matches = useMediaQuery("(min-width:600px)");
  const classes = useStyles();

  // Refs
  const childShippingOptions = useRef();
  const childTaxs = useRef();
  const refMissingPoints = useRef();
  const refAddressModal = useRef();
  const refPersonalDataModal = useRef();
  const refChangeCurrentAddressModal = useRef();
  const refChangeAddressModal = useRef();
  const refNewAddressModal = useRef();
  const refCreditCardModal = useRef();
  const refPreviewProductModal = useRef();

  // * React Hook Form
  const { register, handleSubmit, errors, formState, reset } = useForm({
    mode: "onChange",
  });
  const {
    register: registerFormBuy,
    handleSubmit: handleSubmitFormBuy,
    errors: errorsFormBuy,
    formState: formStateFormBuy,
    reset: resetFormBuy,
  } = useForm({ mode: "onChange" });

  const onSubmitFormBuy = (data) => { };

  const loadScamberData = () => {
    Swal.fire("Aguarde...");
    Swal.showLoading();
    const scamber = new Scamber();
    scamber
      .findScamber()
      .then((scamberData) => {
        Swal.close();
        if (scamberData.status === 200)
          props.setScamber(scamberData.data.payload);
      })
      .catch((error) => {
        Swal.fire({
          type: "info",
          html: error.response.data.message,
          title: "",
        });
      });
  };

  // * Functions - Modals
  const openModalShippingOptions = () =>
    childShippingOptions.current.handleShow();
  const openModalTaxs = () => childTaxs.current.handleShow();
  const closeAddressModal = () => refAddressModal.current.closeModal();
  const closePersonalDataModal = () => refPersonalDataModal.current.closeModal();
  const closeChangeAddressModal = () =>
    refChangeAddressModal.current.closeModal();
  const closeModalCreditCard = () => refCreditCardModal.current.closeModal();

  // * React Hook Form - Submit ESSE É O UPDATE
  const onSubmitAddress = (data) => {
    data.addressId = addressId;
    data.principal = true;

    // * Close Address Modal
    closeAddressModal();
    endereco
      .update(data)
      .then((response) => {
        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Endereço alterado!",
            timer: 4000,
            timerProgressBar: true,
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: "Ok",
          }).then((its_ok) => {
            refChangeCurrentAddressModal.current.closeModal();
            reset({});
            loadScamberData();
            loadCalcShipment();
          });
        }
      })
      .catch((error) =>
        endereco.formatErrorMessage(error.response, 'BENUPD')
      );
  };

  // ESSE É O CREATE :)
  const onChangeSubmitAddress = (data) => {
    data.principal = true;
    // * Close Modal
    endereco
      .create(data)
      .then((response) => {
        if (response.status === 200) {
          Swal.fire({
            title: "Endereço adicionado!",
            icon: "success",
            timer: 4000,
            timerProgressBar: true,
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: "Ok",
          }).then((its_ok) => {
            // * Success Message
            refAddressModal.current.closeModal();
            loadScamberData();
            loadCalcShipment();
          });
        }
      })
      .catch((error) =>
        endereco.formatErrorMessage(error.response, 'BENCRE')
      );

    setCheckedAddress(false);
    // * Close Change Address Modal
    closeChangeAddressModal();
  };

  const onSubmitCreditCard = (creditCard) => {
    let formattedObject = {
      token_cartao: "",
      cardInfos: {
        bandeira: creditCardType ? creditCardType : "none",
        numero_cartao: creditCard.numero_cartao,
        nome: creditCard.nome,
        mes_expiracao: creditCard.mes_expiracao,
        ano_expiracao: creditCard.ano_expiracao,
        cvv: creditCard.cvv,
        id_endereco: props.scamber?.endereco[0]._id,
        cpf: creditCard.numero_cpf,
      },
    };

    scamberFormaPagamento
      .createPaymentForm(formattedObject)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Cartão de crédito adicionado!",
          timer: 4000,
          timerProgressBar: true,
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonText: "Ok",
        }).then((eComplete) => {
          if (eComplete) {
            // closeModalCreditCard();

            setTimeout(() => { loadPaymentForms(); }, 100)
            // loadPaymentForms();
            reset({});
            refCreditCardModal.current.closeModal()

          }
        });
      })
      .catch((error) => {
        endereco.formatErrorMessage(error.response, 'BPFCRE')
      });
  };

  let loadPaymentForms = () => {
    scamberFormaPagamento
      .find()
      .then((response) => {
        setPaymentMethod(response.data.payload);
      })
      .catch((error) => {
        endereco.formatErrorMessage(error.response, 'BPFLOA')
      });
  };

  useEffect(() => {
    if (paymentMethod.length > 0 && document.querySelector(`input[name="${0}check"]`) != null) {
      document.querySelector(`input[name="${0}check"]`).checked = true;
      setChecked(0);
    }
  }, [paymentMethod]);

  useEffect(() => {
    $(".cep").mask("00000-000");
    $(".house_number").mask("00000");
  });

  const loadCalcShipment = () => {

    Swal.fire('Calculando valor do frete... Aguarde');
    Swal.showLoading();

    shipment
      .calculateShipment(props.website)
      .then((response) => {
        Swal.close();
        if (response.data.payload) {
          setStartShipmentRange(response.data.payload.custom_delivery_range?.min);
          setEndShipmentRange(response.data.payload.custom_delivery_range?.max);

          EventEmitter.dispatch("cartChange");
        }
      })
      .catch((error) => {
        EventEmitter.dispatch("cartChange")
        Swal.close();
        shipment.formatErrorMessage(error.response, 'CALFRE')
      });
  }

  /*const loadMissingValue = (callback) => {
    cart
      .calculateCartMissingValue()
      .then((response) => {
        Swal.close();
        if (response.data.payload) {
          setMissingValue(response.data.payload.valor_faltante);
          setMissingPoints(response.data.payload.valor_pontos_faltante);
          //EventEmitter.dispatch("cartChange");
          if(callback) callback();
        }
      })
      .catch((error) => {
        if(callback) callback();
        Swal.close();
        shipment.formatErrorMessage(error.response, 'CALFRE')
      });
  }*/

  const eraseCart = useCallback(() => {
    changeStatusAdsOfCart(() => {
      cart
        .eraseCart(props.website)
        .then((result) => {
          //EventEmitter.emit('cartChange');
          window.location.href = "/";
        })
        .catch((error) => {
          cart.formatErrorMessage(error.response, 'BERCAR')
        });
    });
  }, []);

  const loadTimer = useCallback(() => {
    cart
      .getTimer(props.website)
      .then((response) => {
        if (response.data.payload !== 0) {
          setDateTimer(response.data.payload);
        } else {
          eraseCart();
        }
      })
      .catch((error) => {
        cart.formatErrorMessage(error.response, 'BLOTMR')
      });
  }, [eraseCart]);

  useEffect(() => {
    loadPaymentForms();
    loadTimer();
    //loadCalcShipment();
  }, [loadTimer]);

  // * Functions - React Mask
  const handleCepChange = (e) => setCep(e.target.value);

  // * Check Credit Card Flag Type
  let checkCreditCardType = (e) => {
    let cardnumber = e.target.value.replace(" ", "").replace(/[^0-9]+/g, "");
    let emptyFields = 16 - cardnumber.length;
    let zeros = "";
    for (let count = 0; count < emptyFields; count++) {
      zeros += "0";
    }

    var cards = {
      visa: /^4[0-9]{12}(?:[0-9]{3})/,
      mastercard: /^5[1-5][0-9]{14}/,
      DinersClub: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
      americanExpress: /^3[47][0-9]{13}/,
    };

    for (var flag in cards) {
      if (cards[flag].test(parseInt(cardnumber + zeros))) {
        return setCreditCardType(flag);
      }
    }

    return setCreditCardType(null);
  };

  let findAddressByCEP = () => {
    if (cep.length === 9) {
      endereco
        .findCep(cep)
        .then((address) => {
          let formatedAddress = {
            cep: address.data.cep,
            estado: address.data.estado,
            logradouro: address.data.logradouro,
            cidade: address.data.localidade,
            estado: address.data.uf,
            bairro: address.data.bairro,
            complemento: address.data.complemento,
          };

          if (address.data) {
            reset(formatedAddress);
          }
        })
        .catch((error) =>
          Swal.fire("info", "Não foi possível consultar o endereço", "")
        );
    }
  };

  let setCheckValue = (index, qtdCheckbox) => {

    if (index === "boleto") {
      let i = 0;

      while (i < qtdCheckbox) {
        if (document.querySelector(`input[name="${i}check"]`))
          document.querySelector(`input[name="${i}check"]`).checked = false;
        i++;
      }

      // document.querySelector(`input[name="pix"]`).checked = false;
      // document.querySelector(`input[name="boleto"]`).checked = true;
      setChecked("boleto");

    } else if (index === "pix") {
      let i = 0;

      while (i < qtdCheckbox) {
        if (document.querySelector(`input[name="${i}check"]`))
          document.querySelector(`input[name="${i}check"]`).checked = false;
        i++;
      }
      // document.querySelector(`input[name="boleto"]`).checked = false;
      // document.querySelector(`input[name="pix"]`).checked = true;
      setChecked("pix");


    } else if (index === "card") {
      let qtdCheckbox = 100;
      let i = 0;
      while (i < qtdCheckbox) {
        if (document.querySelector(`input[name="${i}check"]`)) {
          document.querySelector(`input[name="${i}check"]`).checked = false;
        }
        i++;
      }

      // document.querySelector(`input[name="boleto"]`).checked = false;
      // document.querySelector(`input[name="pix"]`).checked = false;
      setChecked(index);

    } else {
      let qtdCheckbox = 100;
      let i = 0;
      while (i < qtdCheckbox) {
        if (document.querySelector(`input[name="${i}check"]`)) {
          document.querySelector(`input[name="${i}check"]`).checked = false;
        }
        i++;
      }

      if (document.querySelector(`input[name="${index}check"]`) != null) {
        document.querySelector(`input[name="${index}check"]`).checked = true;
        setChecked(index);
      }
    }
  };

  let callDirectPayment = (e) => {
    if (e) e.preventDefault();

    let paymentMethod;
    let cardID;
    let parcelamento = installmentValue;
    let orderValue = checkbox ? props.cart.valor - props.cart.valor_frete : props.cart.valor

    if (checked === 'boleto') {
      paymentMethod = 'bank_slip';
      parcelamento = 1;
      setInstallmentValue(1);
    } else if (checked === 'pix') {
      paymentMethod = 'pix';
      parcelamento = 1;
      setInstallmentValue(1)
    } else if (checked !== undefined) {
      cardID = document.querySelector(`input[name="${checked}check"]`)?.id;
      paymentMethod = 'card';

      if (!installmentValue) {
        return Swal.fire({
          icon: "info",
          html: "Você precisa selecionar um parcelamento!",
          title: "",
        });
      }

      if (orderValue < 50) {
        parcelamento = 1;
        setInstallmentValue(1);
      }

    }

    if (!paymentMethod && orderValue !== 0)
      return Swal.fire({
        icon: "info",
        html: "Você precisa selecionar uma forma de pagamento!",
        title: "",
      });

    const freteType = checkbox ? "IN_HANDS" : "LOGISTICS";

    if (paymentMethod !== 'card' && (!props.scamber?.endereco.length || !props.scamber?.telefone.length || !props.scamber?.cpf)) {
      setPayMethod(paymentMethod);
      setFrete(freteType);
      setIsBoleto(true);

      refPersonalDataModal.current.openModal();
      return;
    }

    Swal.fire("Aguarde...");
    Swal.showLoading();

    Swal.fire({
      title: "Finalizar compra?",
      showConfirmButton: true,
      confirmButtonText: 'Finalizar compra',
      showCancelButton: true,
      cancelButtonText: 'Adicionar mais produtos',
      allowEscapeKey: true,
      allowOutsideClick: false,
      reverseButtons: true,
    }).then(result => {
      if (result.isConfirmed) {

        Swal.fire({
          title: "Efetuando pedido ... aguarde",
          allowEscapeKey: true,
          allowOutsideClick: false,
          showCloseButton: false,
          showConfirmButton: false,
          showLoaderOnConfirm: true,
          didOpen: () => {
            Swal.showLoading()
          }
        });

        Swal.showLoading();
        
        pedidoShopping.createOrderShopping({ cardId: cardID, paymentMethod, freteType, installmentValue: parcelamento })
          .then((response) => {

            Swal.close();

            EventEmitter.dispatch("balanceChange");
            EventEmitter.dispatch("cartChange");
            window.location = `/pedido-sucesso?order=${response.data.payload._id}`;

            // Swal.fire({
            //   html: "",
            //   icon: "success",
            //   title: "Pedido realizado com sucesso.",
            //   allowOutsideClick: false,
            //   customClass: {
            //     confirmButton: "modal-confirm_purchase_button",
            //   }
            // }).then(() => {
            //   EventEmitter.dispatch("balanceChange");
            //   EventEmitter.dispatch("cartChange");

            //   props.history.push(`/pedido-sucesso?order=${response.data.payload._id}`);

            //   if (paymentMethod !== 'card')
            //   window.open(response.data.payload.gateway_infos.url_fatura, "_blank");

            //  props.history.push("/");


            // });
          })
          .catch((error) => {
            if (
              error.response &&
              error.response.status === 400 &&
              error.response.data
            ) {
              if (error.response.data.payload && error.response.data.code === -1) {
                Swal.fire({
                  title:
                    `Faltam ${(+error.response.data.payload).toFixed()} pontos para finalizar a sua compra.
                    \n Deseja comprá-los?`,

                  text: `Você será direcionado para a página de compra de pontos.
                        \n Após o crédito dos pontos, será necessário retornar ao carrinho para finalizar esta compra.`,
                  showCancelButton: true,
                  confirmButtonText: "Sim",
                  cancelButtonText: "Cancelar",
                }).then((result) => {
                  /* Read more about isConfirmed, isDenied below */
                  if (result.isConfirmed) {
                    props.history.push({
                      pathname: "/comprar-pontos",
                      search:
                        "?points=" + error.response.data.payload + "&toOrder=S&origin=checkout",
                    });
                  }
                });

              } else if (error.response.data.payload && error.response.data.code === 55) {

                changeStatusAdsOfCart(() => {
                  cart
                    .eraseCart(props.website)
                    .then((result) => {
                      const returnView = error.response.data;

                      Swal.fire(returnView.title, returnView.message, returnView.type).then(() => {
                        EventEmitter.dispatch("cartChange");
                        props.history.push("/");
                      })

                    })
                    .catch((error) => {
                      cart.formatErrorMessage(error.response, 'BERCAR2')
                    });
                });

              } else {
                scamber.formatErrorMessage(error.response, 'BORCR1')
              }

            } else {
              scamber.formatErrorMessage(error.response, 'BORCR2')
            }
          });
      } else {
        Swal.close();
        props.history.push(`/loja/${props.cart?.scamber_vendedor?.minha_loja?.url}`)
      }
    })
  };

  const handleResumePanel = () => setResumePanel(!resumePanel);

  const onChangeSubmitAddressPrincipal = (data) => {
    data.principal = data.check_address === "on" ? true : false;
    data.addressId = addressChecked;

    Swal.fire("Aguarde...");
    Swal.showLoading();

    endereco
      .update(data)
      .then((response) => {
        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Endereço principal alterado!",
            timer: 4000,
            timerProgressBar: true,
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: "fechar",
          }).then((its_ok) => {
            refChangeAddressModal.current.closeModal();
            loadScamberData();
            loadCalcShipment();
          });
        }
      })
      .catch((error) =>
        endereco.formatErrorMessage(error.response, 'BENUP2')
      );
  };

  const openModalEditAddress = (address) => {
    setAddressId(address._id);

    let formatedAddress = {
      cep: address?.cep,
      estado: address?.estado,
      logradouro: address?.logradouro,
      cidade: address?.cidade,
      bairro: address?.bairro,
      apelido: address?.apelido,
      numero: address?.numero,
      complemento: address?.complemento,
    };

    reset(formatedAddress);

    refChangeCurrentAddressModal.current.openModal();
  };

  // * if exists address
  const checkAdress = () => {
    if (props.scamber?.endereco.length) {
      refCreditCardModal.current.openModal();
    } else {
      refAddressModal.current.openModal();
    }
  };

  const onSubmitCheckAddress = (e) => {
    if (props.cart && props.cart.itens && props.cart.itens.length > 0) {
      callDirectPayment(e);
    } else {
      Swal.fire("", "Você não possui itens no carrinho para comprar", "info");
    }
  };

  let changeStatusAdsOfCart = (callback) => {
    cart
      .changeStatusAdsOfCart({ status: "desbloqueado" }, props.website)
      .then((response) => {
        callback();
      })
      .catch(
        (error) => { }
        /*scamber.formatErrorMessage(error.response, 'BORSTA')*/
      );
  };

  const atualizarScamber = (scamber) => {
    /*loadMissingValue(() =>*/loadCalcShipment()/*)*/;
    props.setScamber(scamber);
  }

  const handleInsertCoupom = () => {
    Swal.fire('Incluindo Cupon... Aguarde!');
    Swal.showLoading();

    couponService.validateCouponCodeAndUpdataCart({
      couponCode: coupon.coupon_code,
      cartId: props.cart._id,
      website: props.website
    })
      .then((response) => {
        Swal.close();
        if (response.data.type === 'success') {
          EventEmitter.dispatch("cartChange");
          Swal.fire({
            title: "Cupom aplicado com sucesso!",
            icon: "success",
            buttons: 'Ok',
            dangerMode: true
          });
          setCouponApplied(couponApplied => couponApplied = true);

          setCoupon({ coupon_code: response.data.payload.cupom_code, _id: response.data.payload._id, show: true });
        }
      })
      .catch((error) => {
        Swal.close();
        scamber.formatErrorMessage(error.response, 'SCINC')
      });
  }

  const handleDeleteCoupon = () => {


    couponService.deleteCoupon(props.cart._id, coupon._id).then((response) => {

      if (response.data.type === 'success') {
        EventEmitter.dispatch("cartChange");

        Swal.fire({
          title: "Cupom deletado com sucesso!",
          icon: "success",
          buttons: 'Ok',
          dangerMode: true
        });

        setCouponApplied(couponApplied => couponApplied = false);
        setCoupon({ coupon_code: "", _id: "", show: false });
      }
    })
      .catch((error) => {
        Swal.close();
        scamber.formatErrorMessage(error.response, 'SCINC')
      })

  }

  return (
    <>
      <section id="view_buy">
        <Wrapper>
          <div className="_block countdown">
            <div className="_med-container">
              <h1 className="_title">Tempo restante para a compra</h1>

              <div className="_timer">
                <Icon icon={faClock} />

                <div className="_timer-clock">
                  {dateTimer ? (
                    <Timer
                      initialTime={dateTimer}
                      direction="backward"
                      checkpoints={[
                        {
                          time: 0,
                          callback: () => eraseCart(),
                        },
                      ]}
                    >
                      {({ start, stop }) => (
                        <React.Fragment>
                          <Timer.Minutes
                            formatValue={(text) =>
                              text.toString().length > 1 ? text : "0" + text
                            }
                          />
                          :
                          <Timer.Seconds
                            formatValue={(text) =>
                              text.toString().length > 1 ? text : "0" + text
                            }
                          />
                          <button hidden onClick={start}>
                            Start
                          </button>
                        </React.Fragment>
                      )}
                    </Timer>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>


          <div className="_block _title-with-price">
            <div className="_med-container">
              <div className="_cols">
                <div className="_col _with-values">
                  <div className="_column-item">
                    <CurrentPage
                      icon={<Icon icon={faShoppingCart} />}
                      title="Resumo do pedido"
                      onClick={handleResumePanel}
                    />
                  </div>

                  <div className="_column-item">
                    <PricePoints
                      value={props.cart ? props.cart.valor_pontos : "-"}
                    />
                    {checkbox ? (
                      <Price
                        value={
                          props.cart
                            ? formatNumberToBRL(parseFloat(props.cart.valor - props.cart.valor_frete))
                            : "-"
                        }
                      />
                    ) : (
                      <Price
                        value={
                          props.cart
                            ? formatNumberToBRL(
                              parseFloat(
                                props.cart.valor
                              )
                            )
                            : "-"
                        }
                      />
                    )}
                  </div>
                </div>
                <div className="_col">
                  <div className="_timer">
                    <Icon icon={faClock} />

                    <div className="_timer-clock">
                      {dateTimer ? (
                        <Timer
                          initialTime={dateTimer}
                          direction="backward"
                          checkpoints={[
                            {
                              time: 0,
                              callback: () => eraseCart(),
                            },
                          ]}
                        >
                          {({ start, stop }) => (
                            <React.Fragment>
                              <Timer.Minutes
                                formatValue={(text) =>
                                  text.toString().length > 1 ? text : "0" + text
                                }
                              />
                              :
                              <Timer.Seconds
                                formatValue={(text) =>
                                  text.toString().length > 1 ? text : "0" + text
                                }
                              />
                              <button hidden onClick={start}>
                                Start
                              </button>
                            </React.Fragment>
                          )}
                        </Timer>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <form onSubmit={handleSubmitFormBuy(onSubmitFormBuy)} className="CheckoutForm">
            <div className="_med-container">
              <div className="_columns">
                {/* Col #1 */}
                <div className={resumePanel ? "_column" : "_column _visiblity-column"} >

                  <CheckoutBlock title="Vendedor" marginBottom={5}>
                    {props.cart?.scamber_vendedor && (
                      <AdvertiserCard
                        storeLogo={
                          props.cart?.scamber_vendedor?.imagens?.avatar
                            ? scamber.loadImage(props.cart?.scamber_vendedor?.imagens?.avatar)
                            : Avatar
                        }
                        storeLink={`/loja/${props.cart.scamber_vendedor?.minha_loja?.url}`}
                        storeTitle={props.cart.scamber_vendedor?.minha_loja?.titulo}
                        storeLocation={`${props.cart.scamber_vendedor?.endereco[0].cidade} - ${props.cart.scamber_vendedor?.endereco[0].estado}`}
                      />
                    )}
                  </CheckoutBlock>

                  <CheckoutBlock title="Produtos" footerLinkText="Adicionar mais produtos" footerLink={`/loja/${props.cart?.scamber_vendedor?.minha_loja?.url}`} marginBottom={5}>
                    {props.cart?.itens && (
                      props.cart.itens.map((product, index) => (
                        <ProductThumbnail
                          key={index}
                          image={scamber.loadImage(product.fotos[0] ? product.fotos[0] : CoverIMG)}
                          title={limitTitle(product.titulo, 3)}
                          price={product.pontos_por?.$numberDecimal}
                          productUrl={`/produto?id=${product._id}`}
                          className="small"
                        >

                          <Button
                            className="trash-item btn-shipping"
                            color="primary"
                            autoFocus
                            type="button"
                          >
                            {checkbox && faTruck ? ("Em mãos") : ("Correios")}
                            <Icon icon={checkbox ? faHandHoldingBox : faTruck} />
                          </Button>

                          <Button
                            className="trash-item"
                            color="primary"
                            autoFocus
                            type="button"
                            onClick={() => deleteCartItem(product._id, index)}
                          >
                            <Icon icon={faTrashAlt} />
                          </Button>
                        </ProductThumbnail>
                      ))
                    )}
                  </CheckoutBlock>
                </div>
                {/* END ./ Col #1 */}

                {/* Col #2 */}
                <div className={resumePanel ? "_column" : "_column _visiblity-column"}>


                  <CheckoutBlock title="Frete" marginBottom={5}>
                    <ShippingBlock>
                      {props.cart?.itens &&
                        props.cart.itens.filter(
                          (product) => product.operador_logistico
                        ).length > 0 ? (
                        <Shipping
                          inputName="select_shipping"
                          shippingType="Correios"
                          shippingValue={props.cart ? formatNumberToBRL(parseFloat(props.cart.valor_frete)) : "-"}
                          footerText={`De ${startShipmentRange} a ${endShipmentRange} dias úteis após a postagem`}
                          inputChecked={!checkbox}
                          onChange={handleCheckShipping}
                        >

                          {props.cart?.itens && (
                            props.cart.itens.map((product, index) => (
                              <React.Fragment key={index}>
                                {product.operador_logistico && (
                                  <ProductThumbnail
                                    image={scamber.loadImage(product.fotos[0] ? product.fotos[0] : CoverIMG)}
                                    title={product.titulo}
                                    className="small"
                                  />
                                )}
                              </React.Fragment>
                            ))
                          )}

                        </Shipping>
                      ) : (null)}

                      {props.cart?.itens &&
                        props.cart.itens.filter((product) => product.entrega_maos)
                          .length > 0 ? (
                        <Shipping
                          inputName="select_shipping"
                          shippingType="Entrega em mãos"
                          shippingValue="0,00"
                          footerText="A combinar com o vendedor"
                          inputChecked={checkbox}
                          onChange={handleCheckShipping}
                        >
                          <>
                            {props.cart?.itens && (
                              props.cart.itens.map((product, index) => {
                                if (!haveEntregaMaos) setHaveEntragaMaos(true);

                                return (
                                  <React.Fragment key={index}>
                                    {product.entrega_maos && (
                                      <ProductThumbnail
                                        image={scamber.loadImage(
                                          product.fotos[0]
                                            ? product.fotos[0]
                                            : CoverIMG
                                        )}
                                        title={product.titulo}
                                        className="small"
                                      />
                                    )}
                                  </React.Fragment>
                                );
                              })
                            )}
                          </>
                          <BoxInfo
                            icon={<Icon icon={faInfo} />}
                            title="importante"
                            text="Assim que sua compra for finalizada, a equipe Scamb entrará em contato com comprador e vendedor para coordenar a entrega do produto."
                          />
                        </Shipping>
                      ) : (null)}
                    </ShippingBlock>
                  </CheckoutBlock>


                  <CheckoutBlock
                    title="Endereço de entrega"
                    buttonActionText={
                      props.scamber?.endereco?.length
                        ? ("Alterar")
                        : ("Adicionar")
                    }
                    buttonAction={
                      props.scamber?.endereco?.length
                        ? () => { reset({}); refChangeAddressModal.current.openModal(); }
                        : () => { reset({}); refAddressModal.current.openModal(); }
                    }
                    marginBottom={5}
                  >
                    {props.scamber?.endereco?.length ? (
                      props.scamber.endereco.map((item, index) => (
                        <React.Fragment key={index}>
                          {item.principal ? (
                            <AddressCardActive
                              edit={() => {
                                openModalEditAddress(item);
                              }}
                              nickname={formatTitle(String(item?.apelido))}
                              cep={item?.cep}
                              className={errors.check_address && "error"}
                            >
                              {formatTitle(String(item?.logradouro))} , Nº
                              {item.numero} - Bairro
                              {formatTitle(String(item?.bairro))},
                              {item.complemento === ""
                                ? ""
                                : item.complemento &&
                                " - Complemento: " +
                                formatTitle(String(item?.complemento))}{" "}
                              -{formatTitle(String(item?.cidade))} -{" "}
                              {item.estado?.toUpperCase()}
                            </AddressCardActive>
                          ) : (
                            ""
                          )}
                        </React.Fragment>
                      ))
                    ) : (
                      <NothingMessage>
                        Você ainda não cadastrou nenhum endereço.
                        <br /> para cadastrar um novo endereço clique em Adicionar.
                      </NothingMessage>
                    )}
                  </CheckoutBlock>
                </div>
                {/* END ./ Col #2 */}

                {/* Col #3 */}
                <div className="_column">

                  <CheckoutBlock title="Meu pedido" marginBottom={5}>
                    <OrderDetails>
            
                      <OrderDetail title="Total dos produtos" valuePrice={props.cart ? formatNumberToBRL(parseFloat(props.cart.valor_total_anuncios)) : "-"} />
                      <OrderDetail title="Pointsback" valuePoints={ props.cart ? props.cart.valor_total_cashback : "-"} />
                      <OrderDetail title="Frete" valuePrice={checkbox ? "0,00" : props.cart ? formatNumberToBRL(parseFloat(props.cart.valor_frete)) : "-"} iconAction={openModalShippingOptions} />
                      <OrderDetail
                        title="Total em reais"
                        valuePrice={checkbox ?
                          props.cart ? props.cart.valor_frete ? formatNumberToBRL(parseFloat(props.cart.valor - props.cart.valor_frete)) : formatNumberToBRL(parseFloat(props.cart.valor)) : null
                          :
                          props.cart ? formatNumberToBRL(parseFloat(props.cart.valor)) : "-"
                        }
                        bgColor="#F6F6F9"
                      />
                    </OrderDetails>
                  </CheckoutBlock>

                  {(Number(props?.cart?.valor ? props?.cart?.valor : 0) + Number(props?.cart?.valor_frete)) !== 0 && (
                    <CheckoutBlock title="Forma de pagamento" marginBottom={5}>
                      <PaymentTabs>
                        <Tabs>
                          <TabList>
                            <Tab>
                              <PaymentTab title="Cartão de crédito" inputId="credit_card" inputName="payment_tp" icon={<Icon icon={["fal", "credit-card-front"]} />} defaultChecked={true} onClick={() => setCheckValue("card", paymentMethod[0]?.cartoes.length)} />
                            </Tab>
                            <Tab>
                              <PaymentTab title="Pix" inputId="pix" inputName="payment_tp" icon={<img src={Pix} />} onClick={() => setCheckValue("pix", paymentMethod[0]?.cartoes.length)} />
                            </Tab>
                            <Tab>
                              <PaymentTab title="Boleto" inputId="boleto" inputName="payment_tp" icon={<Icon icon={["fal", "barcode-alt"]} />} onClick={() => setCheckValue("boleto", paymentMethod[0]?.cartoes.length)} />
                            </Tab>
                          </TabList>

                          <TabPanel>
                            {paymentMethod[0]?.cartoes?.length > 0 ? (
                              paymentMethod[0]?.cartoes.map((item, index) => (
                                <SelectBox
                                  className="active"
                                  key={index}
                                  icon={<Icon icon={["fal", "credit-card-front"]} />}
                                  input={
                                    <input
                                      id={item._id}
                                      type="radio"
                                      name={index + "check"}
                                      onClick={(e) =>
                                        setCheckValue(
                                          index,
                                          paymentMethod[0]?.cartoes.length
                                        )
                                      }
                                    />
                                  }
                                  titleContent={
                                    <ListContent>
                                      <ListItem
                                        title={
                                          item.bandeira === "visa"
                                            ? "Visa"
                                            : item.bandeira === "mastercard"
                                              ? "Mastercard"
                                              : item.bandeira === "diners" ||
                                                item.bandeira === "DinersClub"
                                                ? "DinersClub"
                                                : item.bandeira === "amex" ||
                                                  item.bandeira === "American Express"
                                                  ? "americanexpress"
                                                  : "visa"
                                        }
                                      >
                                        {item.numero_cartao}
                                      </ListItem>
                                    </ListContent>
                                  }
                                />
                              ))
                            ) : (
                              <>
                                <SelectBox
                                  onClick={checkAdress}
                                  className="active _new-cc"
                                  icon={
                                    <>
                                      <Icon icon={faCreditCard} />
                                      <div className="plus-add">
                                        <Icon icon={faPlusRegular} />
                                      </div>
                                    </>
                                  }
                                  input={
                                    <input
                                      type="radio"
                                      name={"check"}
                                    />
                                  }
                                  titleContent={"Cartão de crédito"}
                                />
                              </>
                            )}

                            <SelectBox
                              className="active"
                              onClick={checkAdress}
                              icon={<img src={AddCC} />}
                              titleContent={
                                <ListContent>
                                  <ListItem>
                                    Adicionar cartão
                                  </ListItem>
                                </ListContent>
                              }
                            />

                            {props.cart &&
                              props.cart.valor != undefined &&
                              props.cart.valor >= 50 &&
                              props.cart.valor != null &&
                              checked !== 'boleto' && checked !== 'pix' && checked != null && (
                                <SelectParcels>
                                  <SelectParcelsTitle>Opções de parcelamento</SelectParcelsTitle>
                                  <FormControl variant="outlined" size="small" fullWidth={true}>
                                    <InputLabel id="select-outlined-label">Selecione</InputLabel>
                                    <Select
                                      labelId="select-outlined-label"
                                      id="combo-box-demo"
                                      label="Selecione"
                                      defaultValue={(params(checkbox ? props.cart.valor - props.cart.valor_frete : props.cart.valor)[0]).value}
                                      onChange={(e, option) => setInstallmentValue(e.target.value)}
                                    >

                                      {params(checkbox ? props.cart.valor - props.cart.valor_frete : props.cart.valor, (listParcelamento) => {
                                        return listParcelamento.map((itemParcelamento) => (
                                          <MenuItem value={itemParcelamento.value}> {itemParcelamento.title} </MenuItem>
                                        ))
                                      })}

                                    </Select>
                                  </FormControl>
                                </SelectParcels>
                              )}
                          </TabPanel>
                          <TabPanel />
                          <TabPanel />
                        </Tabs>
                      </PaymentTabs>
                      <CheckoutActions>
                        <Button
                          className="accept"
                          color="primary"
                          style={{ height: matches ? 35 : 40 }}
                          type="submit"
                          onClick={(e) => onSubmitCheckAddress(e)}
                        >
                          Comprar
                        </Button>

                        <Button
                          className="cancel"
                          color="primary"
                          style={{ height: matches ? 35 : 40 }}
                          type="submit"
                        >
                          Cancelar
                        </Button>
                      </CheckoutActions>
                    </CheckoutBlock>
                  )}

                </div>
                {/* END ./ Col #3 */}
              </div>
            </div>
          </form>
        </Wrapper>
      </section>

      <ModalBase
        ref={childShippingOptions}
        modalTitle="Opções de entrega"
        modalSubTitle="Qual a melhor opção para mim?"
      >
        <ModalShippingOptions></ModalShippingOptions>
      </ModalBase>

      <ModalBase
        ref={childTaxs}
        modalTitle="Taxa Scamb"
        modalSubTitle={`
          Você não paga nada para listar produtos no Scamb e ter sua loja ativa.
          Porém, a cada transação efetivada, o Scamb cobra uma taxa de administração para apoiar a manutenção da plataforma.
          A taxa é sempre paga pelo COMPRADOR. Não há taxas para o vendedor.
          O valor da taxa Scamb varia de acordo com o valor de pontos da transação.
          Veja aqui a tabela de valores da taxa Scamb.
				`}
      >
        <ModalTaxs></ModalTaxs>
      </ModalBase>

      <ModalBase
        ref={refMissingPoints}
        modalTitle="Pontos Scamb"
        className="missingPointsModal"
      >
        <div style={missingPointsModalStyles}>

          <p style={missingPointsModalStylesParaf}>Se você quer comprar um produto no Scamb mas não tem pontos suficientes em sua carteira, nós te ajudamos com isso!</p>
          <p style={missingPointsModalStylesParaf}>Aqui no checkout, você pode comprar os pontos faltantes para completar o valor que precisa! Tudo de forma simples e em uma única transação.</p>
          <p style={missingPointsModalStylesParaf}>Nós calculamos o valor faltante em pontos e já te mostramos quanto isso vale em reais. Somamos este valor à taxa Scamb e ao frete, e te passamos o valor total para pagamento em reais! <br /> Legal, né?</p>
        </div>
      </ModalBase>

      <ModalAddress
        ref={refChangeCurrentAddressModal}
        title="Alterar endereço de entrega"
      >
        <form onSubmit={handleSubmit(onSubmitAddress)}>
          <h1 className="_content-title" />
          {/*  Form Body */}
          <section className="_form-body">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  onChange={handleCepChange}
                  onBlur={findAddressByCEP}
                  id="outlined-cc-number"
                  label="Cep *"
                  type="text"
                  variant="outlined"
                  name="cep"
                  size={matches ? "small" : "small"}
                  error={!!errors.cep}
                  inputProps={{ className: "cep" }}
                  fullWidth
                  inputRef={register({
                    required: true,
                    minLength: 8,
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="outlined-neighborhood"
                  label="Estado *"
                  variant="outlined"
                  size={matches ? "small" : "small"}
                  name="estado"
                  error={!!errors.estado}
                  fullWidth
                  inputProps={{ maxLength: 2 }}
                  inputRef={register({
                    required: true,
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="outlined-neighborhood"
                  label="Cidade *"
                  variant="outlined"
                  size={matches ? "small" : "small"}
                  name="cidade"
                  error={!!errors.cidade}
                  fullWidth
                  inputRef={register({
                    required: true,
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  id="outlined-address"
                  label="Endereço *"
                  variant="outlined"
                  size={matches ? "small" : "small"}
                  name="logradouro"
                  error={!!errors.logradouro}
                  fullWidth
                  inputRef={register({
                    required: true,
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="outlined-address-number"
                  label="Número *"
                  type="number"
                  variant="outlined"
                  inputProps={{ className: "house_number" }}
                  size={matches ? "small" : "small"}
                  name="numero"
                  error={!!errors.numero}
                  fullWidth
                  inputRef={register({
                    required: true,
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-neighborhood"
                  label="Bairro *"
                  variant="outlined"
                  size={matches ? "small" : "small"}
                  name="bairro"
                  error={!!errors.bairro}
                  fullWidth
                  inputRef={register({
                    required: true,
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-complement"
                  label="Complemento"
                  variant="outlined"
                  name="complemento"
                  size={matches ? "small" : "small"}
                  fullWidth
                  inputRef={register()}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-cc-number"
                    label="Apelido *"
                    type="text"
                    variant="outlined"
                    name="apelido"
                    placeholder="Exemplo: Casa, Trabalho.."
                    size={matches ? "small" : "small"}
                    error={!!errors.apelido}
                    fullWidth
                    inputRef={register({
                      required: true,
                      minLength: 2,
                    })}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </section>

          <Button
            type="submit"
            className={
              "btn btn-primary btn-fluid btn-submit-modal " + classes.center
            }
            color="primary"
            autoFocus
            style={{ height: matches ? 35 : 40 }}
          >
            Confirmar
            <Icon icon={faCheck} />
          </Button>
          {/*  END ./ Form Body */}
        </form>
      </ModalAddress>

      <ModalAddress
        ref={refChangeAddressModal}
        title="Selecionar endereço de entrega"
        className="modal-change-address"
      >
        <div className="_block-title">
          <CurrentPage icon={<Icon icon={faIdCard} />} title="Meus Endereços" />

          <div className="_action">
            <Button
              className="btn-add-payment"
              onClick={() => refAddressModal.current.openModal()}
            >
              Adicionar
              <Icon icon={faPlus} />
            </Button>
          </div>
        </div>
        <form onSubmit={handleSubmit(onChangeSubmitAddressPrincipal)}>
          <h1 className="_content-title" />
          {/*  Form Body */}
          <section className="_form-body">
            {props.scamber?.endereco ? (
              props.scamber.endereco.map((item, index) => (
                <div key={index}>
                  <AddressCard
                    field={
                      <React.Fragment>
                        <input
                          type="radio"
                          name="check_address"
                          className={"checkbox" + " " + "check" + index}
                          id={item._id}
                          onChange={() =>
                            handleCheck("check" + index, item._id)
                          }
                          defaultChecked={editing ? check : item.principal}
                          ref={register({
                            required: true,
                          })}
                        />
                      </React.Fragment>
                    }
                    nickname={formatTitle(String(item?.apelido))}
                    cep={item?.cep}
                    className={errors.check_address && "error"}
                  >
                    {formatTitle(String(item?.logradouro))} , Nº
                    {item.numero} - Bairro
                    {formatTitle(String(item?.bairro))},
                    {item.complemento === ""
                      ? ""
                      : item.complemento &&
                      " - Complemento: " +
                      formatTitle(String(item?.complemento))}{" "}
                    -{formatTitle(String(item?.cidade))} -{" "}
                    {item.estado?.toUpperCase()}
                  </AddressCard>
                </div>
              ))
            ) : (
              <NothingMessage>
                Você não tem mais endereços cadastrados
                <br /> para cadastrar um novo endereço{" "}
                <Link to="/meus-enderecos">clique aqui</Link>
              </NothingMessage>
            )}
          </section>

          <Button
            type="submit"
            className={
              "btn btn-primary btn-fluid btn-submit-modal " + classes.center
            }
            color="primary"
            autoFocus
            style={{ height: matches ? 35 : 40 }}
          >
            Confirmar
            <Icon icon={faCheck} />
          </Button>
          {/*  END ./ Form Body */}
        </form>
      </ModalAddress>

      <ModalAddress ref={refAddressModal} title="Adicionar endereço de entrega">
        <form onSubmit={handleSubmit(onChangeSubmitAddress)}>
          <h1 className="_content-title" />
          {/*  Form Body */}
          <section className="_form-body">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  onChange={handleCepChange}
                  onBlur={findAddressByCEP}
                  id="outlined-cc-number"
                  label="Cep *"
                  type="text"
                  variant="outlined"
                  name="cep"
                  size={matches ? "small" : "small"}
                  error={!!errors.cep}
                  inputProps={{ className: "cep" }}
                  fullWidth
                  inputRef={register({
                    required: true,
                    minLength: 8,
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="outlined-neighborhood"
                  label="Estado *"
                  variant="outlined"
                  size={matches ? "small" : "small"}
                  name="estado"
                  error={!!errors.estado}
                  fullWidth
                  inputProps={{ maxLength: 2 }}
                  inputRef={register({
                    required: true,
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="outlined-neighborhood"
                  label="Cidade *"
                  variant="outlined"
                  size={matches ? "small" : "small"}
                  name="cidade"
                  error={!!errors.cidade}
                  fullWidth
                  inputRef={register({
                    required: true,
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  id="outlined-address"
                  label="Endereço *"
                  variant="outlined"
                  size={matches ? "small" : "small"}
                  name="logradouro"
                  error={!!errors.logradouro}
                  fullWidth
                  inputRef={register({
                    required: true,
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="outlined-address-number"
                  label="Número *"
                  type="number"
                  variant="outlined"
                  inputProps={{ className: "house_number" }}
                  size={matches ? "small" : "small"}
                  name="numero"
                  error={!!errors.numero}
                  fullWidth
                  inputRef={register({
                    required: true,
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-neighborhood"
                  label="Bairro *"
                  variant="outlined"
                  size={matches ? "small" : "small"}
                  name="bairro"
                  error={!!errors.bairro}
                  fullWidth
                  inputRef={register({
                    required: true,
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-complement"
                  label="Complemento"
                  variant="outlined"
                  name="complemento"
                  size={matches ? "small" : "small"}
                  fullWidth
                  inputRef={register()}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-cc-number"
                  label="Apelido *"
                  type="text"
                  variant="outlined"
                  name="apelido"
                  placeholder="Exemplo: Casa, Trabalho.."
                  size={matches ? "small" : "small"}
                  error={!!errors.apelido}
                  fullWidth
                  inputRef={register({
                    required: true,
                    minLength: 2,
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>

            <div className="mark-default-address">
              <label>
                <input
                  onChange={() => setCheckedAddress(!checkedAddress)}
                  type="checkbox"
                  name="mark_default_address"
                />

                <div className="text">Marcar como meu novo endereço padrão</div>

                <div className="check">
                  <Icon icon={faCheck} />
                </div>
              </label>
            </div>
          </section>

          <Button
            type="submit"
            className={
              "btn btn-primary btn-fluid btn-submit-modal " + classes.center
            }
            color="primary"
            autoFocus
            style={{ height: matches ? 35 : 40 }}
            disabled={
              !formState.dirty || (formState.dirty && !formState.isValid)
            }
          >
            Confirmar
            <Icon icon={faCheck} />
          </Button>
          {/*  END ./ Form Body */}
        </form>
      </ModalAddress>

      <ModalCreditCard ref={refCreditCardModal} onClick={() => { reset({}); refCreditCardModal.current.closeModal() }}>
        <form onSubmit={handleSubmit(onSubmitCreditCard)}>
          <h1 className="_content-title">
            Não faremos nenhuma cobrança automática em seu cartão. Ele só será
            utilizado com sua autorização.
          </h1>
          <ul className="_payment-methods-list">
            <li>
              <div className="creadit-card--flag">
                <img src={Visa} alt="" />
              </div>
            </li>
            <li>
              <div className="creadit-card--flag">
                <img src={Mastercard} alt="" />
              </div>
            </li>
            <li>
              <div className="creadit-card--flag">
                <img src={Elo} alt="" />
              </div>
            </li>
            <li>
              <div className="creadit-card--flag">
                <img src={AmericanExpress} alt="" />
              </div>
            </li>
            <li>
              <div className="creadit-card--flag">
                <img src={DinersClub} alt="" />
              </div>
            </li>
          </ul>

          {/*  Form Body */}
          <section className="_form-body _credit-card-form">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputMask
                  mask="9999 9999 9999 9999"
                  defaultValue={valueField}
                  onChange={(e) => setValue(e.target.value)}
                >
                  <TextField
                    id="outlined-cc-number"
                    label="Número do cartão *"
                    type="text"
                    defaultValue={valueField}
                    variant="outlined"
                    name="numero_cartao"
                    onInput={checkCreditCardType}
                    onPaste={checkCreditCardType}
                    size={matches ? "small" : "small"}
                    error={!!errors.numero_cartao}
                    fullWidth
                    inputRef={register({
                      required: true,
                      minLength: 8,
                    })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {creditCardType ? (
                            <div className="creadit-card--flag">
                              <img
                                style={{ width: "15.75px" }}
                                src={require(`./../../../assets/images/credit-card/${creditCardType}.png`)}
                                alt="Flag credit card"
                              />
                            </div>
                          ) : (
                            <Icon icon={faCreditCardLt} />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </InputMask>
              </Grid>

              <Grid item xs={4}>
                <InputMask
                  mask="99"
                  defaultValue={month}
                  onChange={(e) => setMonth(e.target.value)}
                >
                  <TextField
                    id="outlined-cc-month"
                    label="Mês *"
                    type="text"
                    variant="outlined"
                    name="mes_expiracao"
                    size={matches ? "small" : "small"}
                    error={!!errors.mes_expiracao}
                    fullWidth
                    inputRef={register({
                      required: true,
                    })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Icon icon={faCalendarStar} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </InputMask>
              </Grid>
              <Grid item xs={4}>
                <InputMask
                  mask="9999"
                  defaultValue={year}
                  onChange={(e) => setYear(e.target.value)}
                >
                  <TextField
                    id="outlined-cc-year"
                    label="Ano *"
                    type="text"
                    variant="outlined"
                    name="ano_expiracao"
                    size={matches ? "small" : "small"}
                    error={!!errors.ano_expiracao}
                    fullWidth
                    inputRef={register({
                      required: true,
                    })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Icon icon={faCalendarAlt} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </InputMask>
              </Grid>
              <Grid item xs={4}>
                <InputMask
                  mask="999"
                  defaultValue={cvv}
                  onChange={(e) => setCvv(e.target.value)}
                >
                  <TextField
                    id="outlined-cc-number"
                    label="Cvv *"
                    type="text"
                    variant="outlined"
                    name="cvv"
                    size={matches ? "small" : "small"}
                    error={!!errors.cvv}
                    fullWidth
                    inputRef={register({
                      required: true,
                      minLength: 3,
                      maxLength: 4,
                    })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Icon icon={faLockAlt} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </InputMask>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-cc-name"
                  label="Nome do titular cartão *"
                  type="text"
                  variant="outlined"
                  name="nome"
                  size={matches ? "small" : "small"}
                  error={!!errors.nome}
                  fullWidth
                  inputRef={register({
                    required: true,
                    minLength: 4,
                  })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon icon={faUser} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <InputMask
                  mask="999.999.999-99"
                  defaultValue={valueFieldCpf}
                  onChange={(e) => setValueCpf(e.target.value)}
                >
                  <TextField
                    id="outlined-cc-number"
                    label="CPF *"
                    type="text"
                    defaultValue={valueFieldCpf}
                    variant="outlined"
                    name="numero_cpf"
                    size={matches ? "small" : "small"}
                    error={!!errors.numero_cpf}
                    fullWidth
                    inputRef={register({
                      required: true,
                      minLength: 8,
                    })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Icon icon={faUserLock} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </InputMask>
              </Grid>
            </Grid>

            <div className="_set-user-location">
              <div className="_wrap-location">
                <div className="_icon">
                  <Icon icon={faMapMarkerAlt} />
                </div>

                <div className="_wrap-current-location">
                  <div className="_location-title">
                    {props.scamber?.endereco ? (
                      props.scamber.endereco.map((item, index) => (
                        <div key={index} className="_location">
                          {item.principal &&
                            "CEP: " +
                            item.cep +
                            " " +
                            item.logradouro +
                            ", n" +
                            item.numero +
                            " - " +
                            item.bairro +
                            ", " +
                            item.cidade +
                            " - " +
                            item.estado}
                        </div>
                      ))
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*  END ./ Form Body */}

          <Button
            type="submit"
            className={
              "btn btn-primary btn-fluid btn-submit-modal " + classes.center
            }
            color="primary"
            autoFocus
            style={{ height: matches ? 35 : 40 }}
            disabled={
              !formState.dirty || (formState.dirty && !formState.isValid)
            }
          >
            Confirmar
            <Icon icon={faCheck} />
          </Button>
        </form>
      </ModalCreditCard>

      <ModalPersonalData scamber={props.scamber} freteType={frete} isBoleto={isBoleto} history={props.history}
        setScamber={atualizarScamber} ref={refPersonalDataModal} paymentMethod={payMethod} title="dados pessoais" />

    </>
  );
}

const mapStateToProps = (state) => {
  return {
    scamber: state.scamberReducer.scamber,
    cart: state.cartReducer.cart,
    website: state.websiteReducer.website
  };
};

export default withRouter(connect(mapStateToProps, { setScamber })(Buy));
