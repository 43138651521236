import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// JQuery
import $ from "jquery";
import "jquery-mask-plugin";

import { faEdit, faCheck, faTimes } from "@fortawesome/pro-light-svg-icons";

// Material - UI
import { useMediaQuery, Grid, TextField } from "@material-ui/core";

import Scamber from '../../../services/Scamber';

function PersonalForm(props) {
    const matches = useMediaQuery("(min-width:600px)");
  
    const { register, handleSubmit, errors, reset, watch } = useForm();
    const [editForm, showForm] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [disableField, setDisableField] = useState(true);
    const [haveCnpj, setHaveCnpj] = useState(false);
  
    useEffect(() => {
      initFormValues(props.profile);
    }, [props.profile]);
  
    const initFormValues = (profile) => {
      if(profile) {
        if(profile.telefone && profile.telefone.length > 0) {
          profile.celular = `(${profile.telefone[0].ddd}) ${profile.telefone[0].numero.substring(0, 5)}-${profile.telefone[0].numero.substring(5, 9)}`;
        }

        if(profile.cnpj) setHaveCnpj(true)

        reset(profile); 
      }
    }
  
    const onSubmit = (data) => {
      const scamber = new Scamber();

      if(data.senha === 'not_specified') {
        delete data.senha;
      } 

      scamber.updateScamber(data).then((response) => {
        if( response.status === 200) {

          Swal.fire({
            icon: "success",
            title: "Dados pessoais alterados!",
            timer: 4000,
            timerProgressBar: true,
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: "fechar",
          }).then((its_ok) => {
            props.reloadScamber();
            if (its_ok) {
              setDisableField(!disableField);
            }
          });

          showForm(false);
          setDisabled(!disabled);
        }
        
      }).catch (( error ) => scamber.formatErrorMessage(error.response, 'PFUPSC'));

    };
  
    const toggleIcon = () => {
      showForm(!editForm);
      setDisabled(!disabled);
      setDisableField(!disableField);
    };

    useEffect(() => {
        $(".cpf").mask("000.000.000-00", { reverse: false });
        $(".celular").mask("(00) 00000-0000");
    });
  
    return (
      <>
        <div className="_wrap-form-panel">
          <form
            className={
              editForm ? "_form _validate" : "_form _inline-disable _validate"
            }
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="_wrap-form-header">
              <h1 className="_title-box">{props.title}</h1>
              <div className="actions">
                <button
                  type="button"
                  className="btn btn-transparent _edit-cancel"
                  onClick={toggleIcon}
                >
                  <FontAwesomeIcon
                    icon={editForm ? faTimes : faEdit}
                  ></FontAwesomeIcon>
                </button>
                {editForm ? (
                  <button type="submit" className="btn btn-transparent _confirm">
                    <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                    <span>Salvar</span>
                  </button>
                ) : null}
              </div>
            </div>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className="form-group">
                  {disableField ? (
                    <label className="_label">
                      Nome <span>*</span>
                    </label>
                  ) : null}
                  <TextField
                    id="outlined-name"
                    label={disableField ? null : "Nome completo *"}
                    type="text"
                    variant={disableField ? "filled" : "outlined"}
                    size={matches ? "small" : "small"}
                    name="nome"
                    error={!!errors.nome}
                    fullWidth
                    disabled={disableField}
                    inputRef={register({
                      required: true,
                    })}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="form-group">
                  {disableField ? (
                    <label className="_label">
                      Email <span>*</span>
                    </label>
                  ) : null}
                  <TextField
                    id="outlined-name"
                    label={disableField ? null : "Email *"}
                    type="text"
                    variant={disableField ? "filled" : "outlined"}
                    size={matches ? "small" : "small"}
                    name="email"
                    error={!!errors.email}
                    fullWidth
                    disabled={true}
                    inputRef={register({
                      required: true,
                    })}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="form-group">
                  {disableField ? (
                    <label className="_label">
                      Senha <span>*</span>
                    </label>
                  ) : null}
                  <TextField
                    id="outlined-name"
                    label={disableField ? null : "Senha *"}
                    type="password"
                    defaultValue="not_specified"
                    variant={disableField ? "filled" : "outlined"}
                    size={matches ? "small" : "small"}
                    name="senha"
                    error={!!errors.senha}
                    fullWidth
                    disabled={disableField}
                    inputRef={register({
                      required: true
                    })}
                  />
                </div>
              </Grid>
              { 
                !disableField?
                (<Grid item xs={12}>
                  <div className="form-group">
                    {disableField ? (
                      <label className="_label">
                        Confirmar Senha <span>*</span>
                      </label>
                    ) : null}
                    <TextField
                      id="outlined-name"
                      label={disableField ? null : "Confirmar Senha *"}
                      type="password"
                      defaultValue="not_specified"
                      variant={disableField ? "filled" : "outlined"}
                      size={matches ? "small" : "small"}
                      name="confirmacao_senha"
                      error={!!errors.confirmacao_senha}
                      fullWidth
                      disabled={disableField}
                      inputRef={register({
                        required: true,
                        validate: (value) =>
                        value === watch("senha")
                      })}
                    />
                  </div>
                </Grid>):''
              }
              {!haveCnpj ? (
                <Grid item xs={12}>
                  <div className="form-group">
                    {disableField ? (
                      <label className="_label">
                        CPF <span>*</span>
                      </label>
                    ) : null}
                      <TextField
                          id="outlined-name"
                          label={disableField ? null : "Cpf *"}
                          type="text"
                          variant={disableField ? "filled" : "outlined"}
                          size={matches ? "small" : "small"}
                          name="cpf"
                          error={!!errors.cpf}
                          fullWidth
                          disabled={disableField}
                          inputProps={{ className: "cpf" }}
                          inputRef={register({
                              required: true,
                          })}
                      />
                  </div>
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <div className="form-group">
                    {disableField ? (
                      <label className="_label">
                        CNPJ <span>*</span>
                      </label>
                    ) : null}
                      <TextField
                          id="outlined-name"
                          label={disableField ? null : "Cnpj *"}
                          type="text"
                          variant={disableField ? "filled" : "outlined"}
                          size={matches ? "small" : "small"}
                          name="cnpj"
                          error={!!errors.cnpj}
                          fullWidth
                          disabled
                          inputProps={{ className: "cnpj" }}
                          inputRef={register({
                              required: true,
                          })}
                      />
                  </div>
                </Grid>
              )}
              <Grid item xs={12}>
                <div className="form-group">
                  {disableField ? (
                    <label className="_label">
                      Celular <span>*</span>
                    </label>
                  ) : null}
                  <TextField
                    id="outlined-name"
                    label={disableField ? null : "Celular *"}
                    type="text"
                    variant={disableField ? "filled" : "outlined"}
                    size={matches ? "small" : "small"}
                    name="celular"
                    error={!!errors.celular}
                    fullWidth
                    disabled={disableField}
                    inputProps={{ className: "celular" }}
                    inputRef={register({
                      required: true,
                    })}
                  />
                </div>
              </Grid>
            </Grid>

          </form>
        </div>
      </>
    );
}

export default PersonalForm;