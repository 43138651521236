import Service from './Service';

class Domain extends Service {

    constructor() {
        super()
    }

    listBrands(brand) {
        if(brand) return this.axiosInstance.get('/domain/brands?brand=' + brand);
        return this.axiosInstance.get('/domain/brands');
    }

    listWeights() {
        return this.axiosInstance.get('/domain/weights');
    }

    listConditions() {
        return this.axiosInstance.get('/domain/conditions');
    }

    findSizeGroup(categorizationIdsSelected, website) {
        return this.axiosInstance.post(`/domain/sizeGroup${website? '?website=' + website: ''}`, categorizationIdsSelected);
    }

    listSizeGroups(website) {
        return this.axiosInstance.get(`/domain/list/sizeGroup${website? '?website=' + website: ''}`);
    }
    

}

export default Domain;