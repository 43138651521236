//===========================================================
// #1 - Base Imports
//===========================================================
import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
  useCallback,
  useEffect,
} from "react";

import { format } from "date-fns";
import { connect } from 'react-redux';

import ListOrders from "../../services/ListOrders";
import Shipment from './../../services/Shipment';
import Pedido from './../../services/Pedido';

//===========================================================
// #2 - Import * Account Layout Base
//===========================================================
import Layout from "../../components/account/Layout";

//===========================================================
// #3 - Import * Sweet Alert
//===========================================================
import Swal2 from "sweetalert2";
import Swal from "sweetalert";

//===========================================================
// #4 - Import * React Hook Form
//===========================================================
import { useForm } from "react-hook-form";

//===========================================================
// #5 - Import * { Button } Bootstrap
//===========================================================
import { Button } from "react-bootstrap";

//===========================================================
// #6 - Import * UI Components
//===========================================================
import { Price, PricePoints } from "../../components/ui/price";
import {
  Accordion,
  AccordionCard,
  AccordionInside,
  AccordionContent,
} from "../../components/ui/accordion";
import { ListContent, ListItem } from "../../components/ui/list-content";
import { ProductThumbnail } from "../../components/ui/product-thumbnail";
import { ProductThumbnailItems } from "../../components/ui/product-thumbnail";
import { Timeline, Status } from "../../components/ui/order-timeline";
import { Checkbox } from "../../components/ui/checkbox";
import { StatusMessage } from "../../components/ui/status-message";
import { BoxPrint } from "../../components/ui/box-print";
import { CurrentPage } from "../../components/ui/current-page";
import { AdvertiserCard } from "../../components/ui/advertiser-card";
import { NothingMessage } from "../../components/ui/nothing-message";

import formatNumberToBRL from "../../utils/FormatNumberToBRL";

//===========================================================
// #7 - Import * FontAwesome Icons
//===========================================================
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import {
  faShoppingCart,
  faTruck,
  faHandHoldingBox,
  faChevronRight,
  faChevronLeft,
  faTimes,
  faBox,
  faFileAlt,
  faThumbsUp,
  faShippingFast,
  faClock,
  faCheck,
  faHourglass,
  faFileInvoice
} from "@fortawesome/pro-light-svg-icons";
import {
  faBoxCheck, faEye, faPrint,
} from "@fortawesome/pro-solid-svg-icons";

//===========================================================
// #8 - Import * Material UI Components
//===========================================================
import {
  Dialog,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  Slide,
  Typography,
} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { useTheme } from "@material-ui/core/styles";

import Helmet from "react-helmet";

//===========================================================
// #9 - Import * Images
//===========================================================
import Avatar from "./../../assets/images/avatar.png";
import CoverIMG from "../../assets/images/cover.png";



//===========================================================
// #10 - Create Material UI Modal Dialog
//===========================================================
const ModalDialog = forwardRef((props, ref) => {
  // * Material UI Styles - useMediaQuery
  const matches = useMediaQuery("(min-width:600px)");

  // # Define States
  const [open, setOpen] = React.useState(false);

  // * Material UI Styles - useTheme
  const theme = useTheme();

  // # Define Material UI Modal Dialog Fullscreen on mobile
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // # Define Functions

  // * Set Material UI Modal Dialog Open
  const handleClickOpen = () => {
    setOpen(true);
  };

  // * Set Material UI Modal Dialog Close
  const handleClose = () => {
    setOpen(false);
  };

  // * Set Function Using useImperativeHandle();
  useImperativeHandle(ref, () => ({
    openModal: () => handleClickOpen(),
    closeModal: () => handleClose(),
  }));

  // * Capture Event onSubmit Form with
  // * React Hook Forms
  const onSubmit = (data) => {
    // * Close Material UI Modal Dialog After Submit
    setOpen(false);

    // * If Submit Success, Show Sweet Alert Dialog
    Swal.fire({
      icon: "success",
      title: "Proposta enviada!",
      text: `Sua proposta foi enviada e, assim que o vendedor responder, mandaremos uma notificação para você!`,
      timer: 6000,
      timerProgressBar: true,
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonText: "fechar",
    }).then((eComplete) => {
      if (eComplete) {
        console.log("ok");
      }
    });
  };

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={true}
        open={open}
        maxWidth={"sm"}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle className="dialog-title" id="responsive-dialog-title">
          <div className="_view-head-content">
            <button type="button" className="btn" onClick={handleClose}>
              <Icon icon={matches ? faTimes : faChevronLeft}></Icon>
            </button>
            <div className="_title-view">{props.dialogTitle}</div>
          </div>
        </DialogTitle>
        <DialogContent className={props.className}>
          <Typography component={"span"} variant={"body2"}>
            <div className="subtitle">{props.subTitle}</div>

            {props.children}
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
});

// * Material UI Dialog Slide Transition Effect
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

//===========================================================
// #11 - Material UI Set Panel
//===========================================================
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
};

function MySales(props) {
  // * States
  const [tabValue, setTabValue] = useState(0);
  const [sales, setSales] = useState([]);
  const [selectedSale, setSelectedSale] = useState();
  const [tagUrls, setTagUrls] = useState([]);
  const [selectedReturnOrderTracking, setSelectedReturnOrderTracking] = useState();

  const [buttonState, setButtonState] = useState({
    accordion: true,
    buttonConfirm: true,
    buttonVitrine: false,
    buttonCancelSale: true,
  });
  const [show] = useState(false);

  const listofOrders = new ListOrders();
  const shipment = new Shipment();

  // * Material UI Styles - useMediaQuery
  const matches = useMediaQuery("(min-width:600px)");

  // * Create React UI Dialog Refs
  const refModalPrintLabel = useRef();
  const refModalNotaFiscal = useRef();
  const refModalPrintLabelSuccess = useRef();
  const refModalCancelProduct = useRef();

  //* React Hook Forms
  const { register, handleSubmit, errors, formState } = useForm({
    mode: "onChange",
  });

  const openDialogModal = (refModal) => refModal.current.openModal();
  const closeDialogModal = (refModal) => refModal.current.closeModal();

  // * Capture Event onSubmit Form with
  // * React Hook Forms
  // * Form - Submit Cancel Product Form
  const onSubmitCancelSale = (data) => {
    closeDialogModal(refModalCancelProduct);

    Swal({
      title: "Confirmar o cancelamento?",
      text: `Ao clicar em confirmar, a sua venda será
        cancelada e seu produto voltará ativo
        com o preço normal para sua loja.`,
      icon: "warning",
      buttons: ["Cancelar", "Confirmar"],
      dangerMode: true,
    }).then(function (isConfirm) {
      if (isConfirm) {
        Swal({
          title: "Tudo pronto!",
          text: `Fique atendo a sua caixa de email.
            Em até 12 horas entraremos em
            contato com o status do cancelamento.`,
          icon: "success",
          buttons: ["Ok", false],
          dangerMode: true,
        });

        setButtonState({ buttonCancelSale: false });
      } else {
        Swal.close();
      }
    });
  };

  // * UI Modal Dialog Close Return Product
  const handleClosePrintLabel = () => {
    closeDialogModal(refModalPrintLabel);
    closeDialogModal(refModalPrintLabelSuccess);
  };

  // * Swal Confirm Product Receipt
  const handleProductReceipt = (orderID, type) => {
    Swal({
      title: "Confirmar recebimento?",
      text: `Ao clicar em confirmar, você afirma que
        recebeu a devolução do produto.`,
      icon: "warning",
      buttons: ["Cancelar", "Confirmar"],
      dangerMode: true,
    }).then(function (isConfirm) {
      if (isConfirm) {
        finishOrderReturn(orderID, type);
        /*
            - Estado inicial de {buttonState.buttonConfirm} é {true}.
            - Caso o usuário confirme que recebeu o produto o estado de
            - {buttonState.buttonConfirm} é marcado como {false} e esconde o
            - componente <ListItem />
          */
        setButtonState({ buttonConfirm: false });
      } else {
        Swal.close();
      }
    });
  };

  const finishOrderReturn = (orderID, type) => {
    const pedido = new Pedido();

    Swal2.fire('Finalizando o pedido... Aguarde');
    Swal2.showLoading()

    if(!type || type === 'scamb') {

      pedido.finishOrderReturn(orderID).then(() => {

        getSales();
        Swal2.close();
  
        Swal({
          title: "Tudo pronto!",
          text: `Você confirmou que recebeu a devolução do
            produto.`,
          icon: "success",
          buttons: ["Ok", false],
          dangerMode: true,
        });
  
      }).catch((error) => {
        pedido.formatErrorMessage(error.response, 'MPFIOR')
      })

    } else if(type === 'shopping') {

      pedido.finishOrderReturnShopping(orderID).then(() => {

        getSales();
        Swal2.close();
  
        Swal({
          title: "Tudo pronto!",
          text: `Você confirmou que recebeu a devolução do
            produto.`,
          icon: "success",
          buttons: ["Ok", false],
          dangerMode: true,
        });
  
      }).catch((error) => {
        pedido.formatErrorMessage(error.response, 'MPFIORS')
      })

    }

  }

  // * Swall Publish Product
  const handleButtonVitrine = () => {
    Swal({
      title: "Publicar produto?",
      text: `Ao clicar em confirmar seu produto irá ficar disponível novamento para negociação. Seu produto voltará para a sua loja com o valor original.`,
      icon: "warning",
      buttons: ["Cancelar", "Confirmar"],
      dangerMode: true,
    }).then(function (isConfirm) {
      if (isConfirm) {
        Swal({
          title: "Confirmado!",
          text: `Seu produto foi publicado novamente um sua loja.`,
          icon: "success",
          buttons: ["Ok", false],
          dangerMode: true,
        });

        /*
            - Estado inicial de {buttonState.accordion} é {true}.
            - Caso o usuário confirme que recebeu o produto o estado de
            - {buttonState.accordion} é marcado como {false} e esconde o
            - componente <AccordionInside />
            *
            - Clicando em confirmar o estado do "Botão: Vitrine" -> {buttonState.buttonVitrine} é alterado para {false}
            - e esconde o botão do accortion de ações.
          */
        setButtonState({ accordion: false, buttonVitrine: false });
      } else {
        /*
            - Caso o usuário clique em cancelar o estado do "Botão: Sim! recebi o produto!" -> {buttonState.buttonConfirm}
            -  é marcado como {false} e ele é escondido do accordion.
            - logo após o estado do "Botão: Vitrine" -> {buttonState.buttonVitrine} é alterado para {true}
            - e ele é exibido na tela até o usuário clicar e optar por colocar o produto novamente na vitrine.
          */
        Swal.close();
      }
    });
  };

  // * Material UI Tabs
  // * Props Tab
  function tabProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  // * Material UI Tabs
  // * On Change Tab
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleImagens = (itens) => {
    const arrayImages = [];

    itens.map((item) => {
      return arrayImages.push(listofOrders.loadImage(item.fotos[0]));
    });

    return arrayImages;
  };

  const handleTitles = (itens) => {
    const allTitles = [];

    itens.map(iten => {
      allTitles.push(iten.titulo);
    });

    return allTitles.join(' / ');
  }

  const handleAdress = (adress) => {
    const adrress = `${adress.logradouro}, Nº ${adress.numero}, Bairro: 
    ${adress.bairro}, 
    ${adress.complemento === null || adress.complemento === undefined
        ? ""
        : `Complemento: ${adress?.complemento}, `
      }  
    ${adress.cidade}/${adress.estado}
    , CEP: ${adress.cep}`;

    return adrress;
  };

  const getSales = useCallback(async () => {
    console.log('chamou get sales')
    await listofOrders.listSalesOrders().then((response) => {
      setSales(response.data.payload);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getSales();
  }, [getSales]);

  const generateCompleteTag = (orderID) => {

    Swal2.fire('Gerando etiqueta... Aguarde');
    Swal2.showLoading()
    shipment.generateCompleteTag(orderID).then((result) => {

      Swal2.close();
      let listTagUrls = [result.data.payload];
      setTagUrls(listTagUrls);

      listofOrders.listSalesOrders().then((response) => {
        setSales(response.data.payload);
      });

      refModalPrintLabelSuccess.current.openModal();

    }).catch((error) => {
      shipment.formatErrorMessage(error.response, 'MSGETA')
    });
  }

  const updateTrackingStatus = (orderID, index) => {

    Swal2.fire('Verificando status do pedido... Aguarde');
    Swal2.showLoading()
    shipment.updateTrackingStatus(orderID).then((result) => {
      Swal2.close();

      let ordersUpdated = [...sales];
      let orderChanged = { ...sales[index] };

      orderChanged.historico_status_pedido = result.data.payload.historico_status_pedido;
      orderChanged.status_pedido = result.data.payload.status_pedido;

      ordersUpdated[index] = orderChanged;

      setSales(ordersUpdated);

    }).catch((error) => {
      shipment.formatErrorMessage(error.response, 'MPTRUP')
    });

  }

  const printTag = (orderID) => {
    Swal2.fire('Localizando etiqueta... Aguarde');
    Swal2.showLoading()
    shipment.printTag(orderID).then((result) => {

      Swal2.close();
      let listTagUrls = [result.data.payload];
      setTagUrls(listTagUrls);

      refModalPrintLabelSuccess.current.openModal();

    }).catch((error) => {

      listofOrders.listSalesOrders().then((response) => {
        setSales(response.data.payload);
      });

      shipment.formatErrorMessage(error.response, 'MSPETA')
    });

  }

  const checkStatus = (historico_status_pedido, status_pedido, data_alteracao, callback) => {

    const status = {}

    if (historico_status_pedido) {
      for (let count = 0; count < historico_status_pedido.length; count++) {
        const order_status = historico_status_pedido[count];

        if (order_status.status_pedido) {
          if (!status[order_status.status_pedido.toLowerCase()]) {
            status[order_status.status_pedido.toLowerCase()] = { data: getFormattedDate(order_status.data_inclusao), hora: getFormattedHour(order_status.data_inclusao) }
          }
        }

      }
    } else {
      if (status_pedido) {
        status[status_pedido.toLowerCase()] = { data: getFormattedDate(data_alteracao), hora: getFormattedHour(data_alteracao) }
      }
    }

    return callback(status);
  }

  const getFormattedDate = (dateTime) => {
    const data = dateTime.split('T')[0];

    var dia = data.split("-")[2];
    var mes = data.split("-")[1];
    var ano = data.split("-")[0];

    return ("0" + dia).slice(-2) + '/' + ("0" + mes).slice(-2) + '/' + ano;
  }

  const getFormattedHour = (dateTime) => {
    return dateTime.split('T')[1].split('.')[0];
  }

  return (
    <>
      <Helmet title={`${props.site_properties?.title} - Minhas vendas`} />

      <ModalDialog
        ref={refModalCancelProduct}
        dialogTitle="Cancelar a venda"
        subTitle="Selecione o motivo do cancelamento da venda abaixo."
        className="_modal-action-product"
      >
        <form
          className="_form-modal-product"
          onSubmit={handleSubmit(onSubmitCancelSale)}
        >
          <Checkbox title="Me arrependi da venda" alignCenter={true}>
            <input
              type="radio"
              id="reason_1"
              name="reason"
              ref={register({ required: true })}
            />
          </Checkbox>

          <Checkbox title="Não tenho o produto disponível" alignCenter={true}>
            <input
              type="radio"
              id="reason_2"
              name="reason"
              ref={register({ required: true })}
            />
          </Checkbox>

          <Checkbox title="O comprador se arrependeu" alignCenter={true}>
            <input
              type="radio"
              id="reason_3"
              name="reason"
              ref={register({ required: true })}
            />
          </Checkbox>

          <Checkbox title="Tive problemas com o envio" alignCenter={true}>
            <input
              type="radio"
              id="reason_4"
              name="reason"
              ref={register({ required: true })}
            />
          </Checkbox>

          <Checkbox
            title="Tive problemas com a transportadora"
            alignCenter={true}
          >
            <input
              type="radio"
              id="reason_4"
              name="reason"
              ref={register({ required: true })}
            />
          </Checkbox>

          {errors.reason && (
            <StatusMessage
              type="error"
              messageText="Você deve selecionar um dos tópicos acima antes de prosseguir."
            />
          )}

          <Button
            className="btn-submit"
            color="primary"
            autoFocus
            style={{ height: matches ? 35 : 40, justifyContent: `center` }}
            type="submit"
            disabled={
              !formState.dirty || (formState.dirty && !formState.isValid)
            }
          >
            Continuar
            <Icon icon={faChevronRight}></Icon>
          </Button>
        </form>
      </ModalDialog>

      <ModalDialog
        ref={refModalNotaFiscal}
        dialogTitle="Informações para emissão da nota fiscal"
        subTitle="Informações do comprador para emissão da nota fiscal"
        className="_modal-action-product"
      >
        

            <div className="content-nf">

              <div className="nf-user-info">
                <h6 style={{ paddingTop: '10px', paddingBottom: '10px' }}><strong>Informações do comprador</strong></h6>
                
                <p style={{ padding: '2px' }}><strong>Nome completo: </strong> {selectedSale?.scamber_comprador[0]?.nome} </p>
                <p style={{ padding: '2px' }}> <strong>{selectedSale?.scamber_comprador[0]?.cnpj? 'CNPJ:': 'CPF:'} </strong> {
                  selectedSale?.scamber_comprador[0]?.cnpj? selectedSale?.scamber_comprador[0]?.cnpj: selectedSale?.scamber_comprador[0]?.cpf } </p>
                <p style={{ padding: '2px' }}><strong>Telefone: </strong> {selectedSale?.scamber_comprador[0]?.telefone[0]? 
                `(${selectedSale.scamber_comprador[0].telefone[0].ddd}) ${selectedSale?.scamber_comprador[0].telefone[0].numero}`:''} </p>
              
              </div>

              <div className="nf-user-address">
                <h6 style={{ paddingBottom: '10px', paddingTop: '20px' }}><strong>Endereço do comprador</strong></h6>
               
                <p style={{ padding: '2px' }}><strong>CEP: </strong> {selectedSale?.endereco_entrega?.cep} </p>
                <p style={{ padding: '2px' }}><strong>Logradouro: </strong> {selectedSale?.endereco_entrega?.logradouro} </p>
                <p style={{ padding: '2px' }}><strong>Número: </strong> {selectedSale?.endereco_entrega?.numero} </p>
                <p style={{ padding: '2px' }}><strong>Bairro: </strong> {selectedSale?.endereco_entrega?.bairro} </p>
                <p style={{ padding: '2px' }}><strong>Cidade: </strong> {selectedSale?.endereco_entrega?.cidade} </p>
                <p style={{ padding: '2px' }}><strong>Estado: </strong> {selectedSale?.endereco_entrega?.estado} </p>
                <p style={{ padding: '2px' }}><strong>Complemento: </strong> {selectedSale?.endereco_entrega?.complemento} </p>

              </div>

            </div>

            <Button
              className="btn-submit"
              color="primary"
              autoFocus
              style={{ height: matches ? 45 : 55 }}
              type="button"
              onClick={() => { closeDialogModal(refModalNotaFiscal); }}
            >
              Fechar
            </Button>
          
      </ModalDialog>

      <ModalDialog
        ref={refModalPrintLabel}
        dialogTitle="Gerar etiqueta"
        subTitle="Faça a emissão da etiqueta"
        className="_modal-action-product"
      >
        <div className="_body-modal">
          <BoxPrint
            type="info"
            icon={<Icon icon={faBox} />}
            title="Clique no botão abaixo para realizar
                    a emissão da etiqueta."
          >
            <Button
              className="btn-submit"
              color="primary"
              autoFocus
              style={{ height: matches ? 45 : 55 }}
              type="button"
              onClick={() => generateCompleteTag(selectedSale._id)}
            >
              Emitir etiqueta
              <Icon icon={faFileAlt}></Icon>
            </Button>
          </BoxPrint>
        </div>

        {/*<Button
          className="btn-submit"
          color="primary"
          autoFocus
          style={{ height: matches ? 35 : 40, justifyContent: `center` }}
          type="submit"
          onClick={handleClosePrintLabel}
          disabled
        >
          Finalizar
          <Icon icon={faChevronRight}></Icon>
        </Button>*/}
      </ModalDialog>

      <ModalDialog
        ref={refModalPrintLabelSuccess}
        dialogTitle="Aqui está sua etiqueta do pedido!"
        subTitle="Agora basta imprimir e enviar :)."
        className="_modal-action-product"
      >
        <div className="_body-modal">
          <BoxPrint
            type="success"
            icon={<Icon icon={faBoxCheck} />}
            title="Você pode visualizar e imprimir sua etiqueta clicando no botão imprimir logo abaixo."
          >
          </BoxPrint>
        </div>

        {tagUrls && tagUrls.length > 0 ? tagUrls.map(url =>
          <>
            <p style={{ textAlign: 'center', paddingTop: '20px' }}>Caso você não consiga visualizar a etiqueta abaixo <a target="_blank" href={url}>clique aqui.</a></p>
            <div key={url} className="print-preview-iframe">
              <iframe src={url} frameBorder="0"></iframe>
            </div>
          </>
        ) : <></>
        }


        <Button
          className="btn-submit"
          color="primary"
          autoFocus
          style={{ height: matches ? 35 : 40, justifyContent: `center` }}
          type="submit"
          onClick={handleClosePrintLabel}
        >
          Fechar
          {/*<Icon icon={faChevronRight}></Icon>*/}
        </Button>
      </ModalDialog>

      <Layout>
        <div id="my-sales" className="_content">
          <div className="_wrap-title-and-tabs">
            <CurrentPage
              icon={<Icon icon={faShoppingCart} />}
              title="Minhas Vendas"
            />

            <Tabs
              value={tabValue}
              onChange={handleChange}
              aria-label="Tabs My Sales"
              className="_material-tabs"
            >
              <Tab label="Pendentes" {...tabProps(0)} />
              <Tab label="Devoluções" {...tabProps(1)} />
              <Tab label="Concluídas" {...tabProps(2)} />

            </Tabs>
          </div>

          {/* 
           /* Material UI Tab Panel - Em Aberto
          */}

          <TabPanel value={tabValue} index={0}>
            {sales.length > 0 ? sales.map((sale, index) => (sale.status_pedido.indexOf('_DEVOLUCAO') === -1 && (sale.vendedor_creditado === false || sale.status_pedido !== 'FINALIZADO') && sale.status_pedido !== 'PAGAMENTO_NAO_APROVADO' && sale.status_pedido !== 'EXPIRADO' && sale.status_pedido !== 'CANCELADO' && sale.status_pedido !== 'REEMBOLSADO') && (
                <Accordion
                  origin={sale?.tipo_pedido ? sale?.tipo_pedido : "scamb" }
                  key={sale._id}
                  productStatus={sale.status_pedido.toLowerCase()}
                  productStatusSeller={false}
                  className="_subitems"
                  onClick={(opened) => opened && (sale.status_pedido === 'ETIQUETA_GERADA' || sale.status_pedido === 'POSTADO') ? updateTrackingStatus(sale._id, index) : ''}
                  child={
                    <ProductThumbnailItems
                      images={handleImagens(sale.itens)}
                      title={handleTitles(sale.itens)}
                      dateTitle="Data da compra"
                      date={format(new Date(sale.data_inclusao), 'dd/MM/yyyy')}
                      printLabel={
                        <>
                          {sale.tipo_entrega == 'LOGISTICS' && !sale.url_etiquetas && sale.status_pedido === 'PAGO'?
                            <button type="button" className="small-button" onClick={() => { setSelectedSale(sale); openDialogModal(refModalPrintLabel); }}>
                              Gerar etiqueta
                            <Icon icon={faPrint} />
                            </button>
                            : null}

                            {props.scamber?.cnpj?
                              <button type="button" style={{ marginTop: '5px' }} className="small-button" onClick={() => { setSelectedSale(sale); openDialogModal(refModalNotaFiscal) }}>
                                Informações para Nota Fiscal
                              <Icon icon={faFileInvoice} />
                              </button>
                            : null}
                        </>
                      }
                      viewLabel={
                        sale.tipo_entrega == 'LOGISTICS' && sale.url_etiquetas ?
                          <ListContent bgColor="#FFFFFF">
                            <ListItem title="Etiqueta">
                              <button type="button" className="small-button" onClick={() => printTag(sale._id)} disabled={false}>
                                Visualizar etiqueta
                          <Icon icon={faEye} />
                              </button>
                            </ListItem>
                          </ListContent>
                          : null
                      }
                    />
                  }
                >
                  <ListContent bgColor="#FFFFFF">
                    {sale.scamber_comprador.map(comprador => (
                      <ListItem title="Comprador" key={comprador._id}>
                        <div className="_wrap-user">
                          <span className="user-image _rounded-image__with-notification-icon _2x">
                            <img
                              src={comprador?.imagens?.avatar ? listofOrders.loadImage(comprador?.imagens?.avatar) : Avatar}
                              alt="Avatar"
                            />
                          </span>
                          <div>
                            <div className="_username">{comprador?.nome.split(" ")[0]}</div>
                          </div>
                        </div>
                      </ListItem>
                    ))}


                    <ListItem title="Número do pedido">
                      {sale._id}
                    </ListItem>

                    {false && (
                      <ListItem title="Você recebeu o seu pedido?">
                        <button type="button" className="btn-received" onClick={handleProductReceipt}>
                          Sim! recebi o produto
                        <span>
                            <Icon icon={faThumbsUp} />
                          </span>
                        </button>
                      </ListItem>
                    )}
                      
                    {sale.tipo_pedido !== 'shopping' ? (
                        <>
                          <ListItem title="Total em pontos">
                            <PricePoints value={sale.valor_pontos} />
                          </ListItem>

                          <ListItem title="Taxa em Scamb">
                            <Price value={parseFloat((sale.valor_taxa !== undefined ? sale.valor_taxa : sale.taxa_valor)).toFixed(2)} />
                          </ListItem>
                        </>
                      ) : (
                        <>
                          <ListItem title="Total dos produtos">
                            <Price
                              value={formatNumberToBRL(parseFloat(sale.valor_total_anuncios))}
                            />
                          </ListItem>
                          

                          <ListItem title="Pointsback">
                            <PricePoints value={sale.valor_total_cashback} />
                          </ListItem>
                        </>
                      )
                    }   

                    {/* <ListItem title="Taxa em reais">
                      <Price value={sale.valor_taxa} />
                    </ListItem> */}
                    {/*<ListItem title="Frete" className="flex">
                      <Price value={parseFloat(sale.valor_frete? sale.valor_frete:0).toFixed(2)} />
                      <div className="_frete">
                        <Icon icon={sale.tipo_entrega ? (sale.tipo_entrega === 'IN_HANDS' ? faHandHoldingBox : faTruck) : undefined} />
                        {sale.tipo_entrega ? (sale.tipo_entrega === 'IN_HANDS' ? 'Em mãos' : 'Correios') : ' - '}
                      </div>
                    </ListItem>*/}

                    {/*{sale.valor_faltante? 
                      (<><ListItem title="Valor de pontos comprados no checkout">
                        <Price value={parseFloat(sale.valor_faltante).toFixed(2)} />
                        equivalente à <strong>{sale.valor_pontos_faltante} pontos</strong>
                      </ListItem></>):(<></>)
                    }*/}

                    {/*<ListItem title="Total em reais">
                      <Price
                        value={parseFloat(sale.valor).toFixed(2)}
                      />
                    </ListItem>*/}

                    {/* <ListItem title="Forma de pagamento">
                    Cartão de crédito
                  </ListItem> */}

                    {/*<ListItem title="Endereço da entrega">
                      {handleAdress(sale.endereco_entrega)}
                    </ListItem>*/}

                    <ListItem title="Produtos">
                      {sale.itens && sale.itens.map((item) => (
                        <ProductThumbnail
                          key={item._id}
                          image={item.fotos[0] ? listofOrders.loadImage(item.fotos[0]) : CoverIMG}
                          title={item.titulo}
                          dateTitle="Valor"
                          pricePoints={item.pontos_por?.$numberDecimal}
                          productUrl={`/produto?id=${item._id}`}
                          type={item.tipo_anuncio}
                          cashback={item.cashback}
                          cashDiscount={item.valor_de}
                          cashPrice={item.valor_por} 
                        />
                      ))}
                    </ListItem>

                    {/* {sale.tipo_entrega == 'LOGISTICS' && !sale.url_etiquetas ?
                      <ListItem title="">
                        <button type="button" className="small-button" onClick={() => { setSelectedSale(sale); openDialogModal(refModalPrintLabel); }}>
                          Gerar etiqueta
                      <Icon icon={faChevronRight} />
                        </button>
                      </ListItem>
                      : <></>} */}

                    {/* {sale.tipo_entrega == 'LOGISTICS' && sale.url_etiquetas ?
                      <ListItem title="">
                        <button type="button" className="small-button" onClick={() => printTag(sale._id)} disabled={false}>
                          Visualizar etiqueta
                      <Icon icon={faChevronRight} />
                        </button>
                      </ListItem>
                      : <></>} */}

                  </ListContent>

                </Accordion>
              )) : (
                <section className="_block">
                  <NothingMessage>
                    Você não possui nenhuma venda pendente
                  </NothingMessage>
                </section>
              )}
          </TabPanel>

          {/* 
           /* Material UI Tab Panel - Aguardando envio
          */}
          <TabPanel value={tabValue} index={1}>
            {sales.length > 0 ? sales.map((sale, index) => (sale.status_pedido.indexOf('_DEVOLUCAO') !== -1) && (
              <Accordion
                origin={sale?.tipo_pedido ? sale?.tipo_pedido : "scamb" }
                key={sale._id}
                productStatus={sale.status_pedido.toLowerCase()}
                className="_subitems"
                onClick={(opened) => opened && (sale.status_pedido === 'ETIQUETA_GERADA' || sale.status_pedido === 'POSTADO') ? updateTrackingStatus(sale._id, index) : ''}
                child={
                  <ProductThumbnailItems
                    images={handleImagens(sale.itens)}
                    title={handleTitles(sale.itens)}
                    dateTitle="Data da compra"
                    date={format(new Date(sale.data_inclusao), 'dd/MM/yyyy')}
                  />
                }
              >
                <ListContent bgColor="#FFFFFF">
                  {sale.scamber_comprador.map(comprador => (
                    <ListItem title="Comprador" key={comprador._id}>
                      <div className="_wrap-user">
                        <span className="user-image _rounded-image__with-notification-icon _2x">
                          <img
                            src={comprador?.imagens?.avatar ? listofOrders.loadImage(comprador?.imagens?.avatar) : Avatar}
                            alt="Avatar"
                          />
                        </span>
                        <div>
                          <div className="_username">{comprador?.nome.split(" ")[0]}</div>
                        </div>
                      </div>
                    </ListItem>
                  ))}


                  <ListItem title="Número do pedido">
                    {sale._id}
                  </ListItem>

                  {(sale.status_pedido == 'POSTADO_DEVOLUCAO' || sale.status_pedido == 'ETIQUETA_GERADA_DEVOLUCAO') && (
                    <ListItem title="Confirmar recebimento">
                      <button type="button" className="btn-received" onClick={() => handleProductReceipt(sale._id, sale.tipo_pedido)}>
                        Sim! recebi o produto
                      <span>
                          <Icon icon={faThumbsUp} />
                        </span>
                      </button>
                    </ListItem>
                  )}


                  <ListItem title="">
                    Status do pedido
                  </ListItem>

                  <ListItem title="" bgColor="#F9F9F9">
                    <Timeline>

                      {checkStatus(sale.devolucao.historico_status_devolucao, sale.status_pedido, sale.data_alteracao, (status) =>
                        <>
                          <Status icon={<Icon icon={faCheck} />} title="Finalizado"
                            className={status.finalizado_devolucao ? '_active' : 'no-active'}
                            date={status.finalizado_devolucao?.data}
                            hour={status.finalizado_devolucao?.hora}>Link</Status>

                          <Status icon={<Icon icon={faShippingFast} />} title="Enviado"
                            className={status.postado_devolucao || status.finalizado_devolucao ? '_active' : 'no-active'}
                            subtitle={
                              status.postado_devolucao && sale.devolucao.urls_rastreio && sale.devolucao.urls_rastreio.length > 0 ?
                                sale.devolucao.urls_rastreio.map((infoRastreio) => <a href={infoRastreio.url} target="_new"> Rastrear meu pedido ({infoRastreio.url.split('/')[4]}) </a>) : <></>
                            }
                            date={status.postado_devolucao?.data}
                            hour={status.postado_devolucao?.hora}></Status>

                          <Status icon={<Icon icon={faClock} />} title="Aguardando Envio"
                            className={status.etiqueta_gerada_devolucao || status.postado_devolucao || status.finalizado_devolucao ? '_active' : 'no-active'}
                            date={status.etiqueta_gerada_devolucao?.data}
                            hour={status.etiqueta_gerada_devolucao?.hora}>Link</Status>

                          <Status icon={<Icon icon={faCheck} />} title="Frete Pago"
                            className={status.pago_devolucao || status.etiqueta_gerada_devolucao || status.postado_devolucao || status.finalizado_devolucao ? '_active' : 'no-active'}
                            date={status.pago_devolucao?.data}
                            hour={status.pago_devolucao?.hora}>Link</Status>

                          <Status icon={<Icon icon={faHourglass} />} title="Frete Aguardando Pagamento"
                            className={status.pagamento_pendente_devolucao || status.pago_devolucao || status.etiqueta_gerada_devolucao || status.postado_devolucao || status.finalizado_devolucao ? '_active' : 'no-active'}
                            date={status.pagamento_pendente_devolucao?.data}
                            hour={status.pagamento_pendente_devolucao?.hora}>Link</Status>
                        </>
                      )}

                    </Timeline>
                  </ListItem>

                  {false && (
                    <ListItem title="Você recebeu o seu pedido?">
                      <button type="button" className="btn-received" onClick={handleProductReceipt}>
                        Sim! recebi o produto
                        <span>
                          <Icon icon={faThumbsUp} />
                        </span>
                      </button>
                    </ListItem>
                  )}

                  {sale.tipo_pedido !== 'shopping' ? (
                      <>
                        <ListItem title="Total em pontos">
                          <PricePoints value={sale.valor_pontos} />
                        </ListItem>

                        <ListItem title="Taxa em Scamb">
                          <Price value={parseFloat((sale.valor_taxa !== undefined ? sale.valor_taxa : sale.taxa_valor)).toFixed(2)} />
                        </ListItem>
                      </>
                      ) : (
                        <>
                          <ListItem title="Total dos produtos">
                            <Price
                              value={formatNumberToBRL(parseFloat(sale.valor_total_anuncios))}
                            />
                          </ListItem>
                          

                          <ListItem title="Pointsback">
                            <PricePoints value={sale.valor_total_cashback} />
                          </ListItem>
                        </>
                      )
                    }   

                  {/* <ListItem title="Taxa em reais">
                    <Price value={sale.valor_taxa} />
                  </ListItem> */}
                  {/*<ListItem title="Frete" className="flex">
                    <Price value={parseFloat(sale.valor_frete? sale.valor_frete:0).toFixed(2)} />
                    <div className="_frete">
                      <Icon icon={sale.tipo_entrega ? (sale.tipo_entrega === 'IN_HANDS' ? faHandHoldingBox : faTruck) : undefined} />
                      {sale.tipo_entrega ? (sale.tipo_entrega === 'IN_HANDS' ? 'Em mãos' : 'Correios') : ' - '}
                    </div>
                  </ListItem>*/}

                  {/*{sale.valor_faltante? 
                    (<><ListItem title="Valor de pontos comprados no checkout">
                      <Price value={parseFloat(sale.valor_faltante).toFixed(2)} />
                      equivalente à <strong>{sale.valor_pontos_faltante} pontos</strong>
                    </ListItem></>):(<></>)
                  }*/}

                  {/*<ListItem title="Total em reais">
                    <Price
                      value={parseFloat(sale.valor).toFixed(2)}
                    />
                </ListItem>*/}

                  {/* <ListItem title="Forma de pagamento">
                    Cartão de crédito
                  </ListItem> */}

                  {/*<ListItem title="Endereço da entrega">
                    {handleAdress(sale.endereco_entrega)}
                  </ListItem>*/}

                  <ListItem title="Produtos">
                    {sale.itens && sale.itens.map((item) => (
                      <ProductThumbnail
                        key={item._id}
                        image={item.fotos[0] ? listofOrders.loadImage(item.fotos[0]) : CoverIMG}
                        title={item.titulo}
                        dateTitle="Valor em pontos"
                        pricePoints={item.pontos_por?.$numberDecimal}
                        productUrl={`/produto?id=${item._id}`}
                        type={item.tipo_anuncio}
                        cashback={item.cashback}
                        cashDiscount={item.valor_de}
                        cashPrice={item.valor_por} 
                      />
                    ))}
                  </ListItem>

                  {/* {sale.tipo_entrega == 'LOGISTICS' && !sale.url_etiquetas ?
                      <ListItem title="">
                        <button type="button" className="small-button" onClick={() => { setSelectedSale(sale); openDialogModal(refModalPrintLabel); }}>
                          Gerar etiqueta
                      <Icon icon={faChevronRight} />
                        </button>
                      </ListItem>
                      : <></>} */}

                  {/* {sale.tipo_entrega == 'LOGISTICS' && sale.url_etiquetas ?
                      <ListItem title="">
                        <button type="button" className="small-button" onClick={() => printTag(sale._id)} disabled={false}>
                          Visualizar etiqueta
                      <Icon icon={faChevronRight} />
                        </button>
                      </ListItem>
                      : <></>} */}

                </ListContent>

              </Accordion>
            )) : (
                <section className="_block">
                  <NothingMessage>
                    Você não possui nenhuma venda pendente
                  </NothingMessage>
                </section>
              )}

          </TabPanel>
          {/* ACCORDION */}
          {/* </TabPanel> */}

          {/* 
           /* Material UI Tab Panel - Concluídas
          */}
          <TabPanel value={tabValue} index={2}>
            {/* 
              /* productStatus possui 3 estados:
              /* productStatus="delivered"
              /* productStatus="canceled"
              /* productStatus="returned"
              /* productStatus="paid"
              /* productStatus="waiting"
              */}

            {sales.length > 0 ? (
              sales.map(
                (sale) =>
                  (sale.status_pedido.indexOf('_DEVOLUCAO') === -1 && ((sale.status_pedido === 'FINALIZADO' && sale.vendedor_creditado !== false) || sale.status_pedido === 'EXPIRADO' || sale.status_pedido === 'CANCELADO' || sale.status_pedido === 'REEMBOLSADO' || sale.status_pedido === 'PAGAMENTO_NAO_APROVADO' )) && (
                    <Accordion
                      origin={sale?.tipo_pedido ? sale?.tipo_pedido : "scamb" }
                      key={sale._id}
                      productStatus={sale.status_pedido.toLowerCase()}
                      productStatusSeller={true}
                      className="_subitems"
                      child={
                        <ProductThumbnailItems
                          images={handleImagens(sale.itens)}
                          title={sale.itens[0].titulo}
                          dateTitle="Data da compra"
                          date={format(
                            new Date(sale.data_inclusao),
                            "dd/MM/yyyy"
                          )}
                        />
                      }
                    >
                      <ListContent bgColor="#FFFFFF">
                        {sale.scamber_comprador.map((comprador) => (
                          <ListItem title="Comprador" key={comprador._id}>
                            <div className="_wrap-user">
                              <span className="user-image _rounded-image__with-notification-icon _2x">
                                <img
                                  src={
                                    comprador?.imagens?.avatar
                                      ? listofOrders.loadImage(
                                        comprador?.imagens?.avatar
                                      )
                                      : Avatar
                                  }
                                  alt="Avatar"
                                />
                              </span>
                              <div>
                                <div className="_username">
                                  {comprador?.nome.split(" ")[0]}
                                </div>
                              </div>
                            </div>
                          </ListItem>
                        ))}

                        <ListItem title="Número do pedido">{sale._id}</ListItem>

                        {sale.tipo_pedido !== 'shopping' ? (
                            <>
                              <ListItem title="Total em pontos">
                                <PricePoints value={sale.valor_pontos} />
                              </ListItem>

                              <ListItem title="Taxa em Scamb">
                                <Price value={parseFloat((sale.valor_taxa !== undefined ? sale.valor_taxa : sale.taxa_valor)).toFixed(2)} />
                              </ListItem>
                            </>
                          ) : (
                            <>
                              <ListItem title="Total dos produtos">
                                <Price
                                  value={formatNumberToBRL(parseFloat(sale.valor_total_anuncios))}
                                />
                              </ListItem>
                              

                              <ListItem title="Pointsback">
                                <PricePoints value={sale.valor_total_cashback} />
                              </ListItem>
                            </>
                          )
                        }   

                        {/* <ListItem title="Taxa em reais">
                          <Price value={sale.valor_taxa} />
                        </ListItem> */}
                        {/*<ListItem title="Frete" className="flex">
                          <Price value={parseFloat(sale.valor_frete? sale.valor_frete:0).toFixed(2)} />
                          <div className="_frete">
                            <Icon icon={sale.tipo_entrega ? (sale.tipo_entrega === 'IN_HANDS' ? faHandHoldingBox : faTruck) : undefined} />
                            {sale.tipo_entrega ? (sale.tipo_entrega === 'IN_HANDS' ? 'Em mãos' : 'Correios') : ' - '}
                          </div>
                        </ListItem>*/}

                        {/*{sale.valor_faltante? 
                          (<><ListItem title="Valor de pontos comprados no checkout">
                            <Price value={parseFloat(sale.valor_faltante).toFixed(2)} />
                            equivalente à <strong>{sale.valor_pontos_faltante} pontos</strong>
                          </ListItem></>):(<></>)
                        }*/}

                        {/*<ListItem title="Total em reais">
                          <Price value={parseFloat(sale.valor).toFixed(2)} />
                        </ListItem>*/}

                        {/*<ListItem title="Endereço da entrega">
                          {handleAdress(sale.endereco_entrega)}
                        </ListItem>*/}

                        <ListItem title="Produtos">
                          {sale.itens && sale.itens.map((item) => (
                            <ProductThumbnail
                              key={item._id}
                              image={item.fotos[0] ? listofOrders.loadImage(item.fotos[0]) : CoverIMG}
                              title={item.titulo}
                              dateTitle="Valor em pontos"
                              pricePoints={item.pontos_por?.$numberDecimal}
                              productUrl={`/produto?id=${item._id}`}
                              type={item.tipo_anuncio}
                              cashback={item.cashback}
                              cashDiscount={item.valor_de}
                              cashPrice={item.valor_por} 
                            />
                          ))}
                        </ListItem>
                      </ListContent>
                    </Accordion>
                  ))) : (
                <section className="_block">
                  <NothingMessage>
                    Você não possui nenhuma venda concluída
                  </NothingMessage>
                </section>
              )}
          </TabPanel>
        </div>
      </Layout>
    </>
  );
}

const mapStateToProps = (state) => ({
  scamber: state.scamberReducer.scamber,
  site_properties: state.websiteReducer.site_properties
})

export default connect(mapStateToProps, {})(MySales);
