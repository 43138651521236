//===========================================================
// #1 - Base Imports
//===========================================================
import React, { useState } from "react";
//===========================================================
// #2 - Import * FontAwesome Icons
//===========================================================
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp, faPlus, faMinus } from "@fortawesome/pro-light-svg-icons";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
export const AccordionCard = (props) => {
    return (
        <div className="_visible-content">
            {props.children}
        </div>
    )
}
export const Accordion = (props) => {
    const [accordion, setAccordion] = useState(props.opened ? true : false);
    const handleAccordion = () => setAccordion(props.awaysOpened ? true : !accordion);
    const accordion_class = props.className !== undefined ? props.className : "";
    return (
        <div className={"_accordion " + accordion_class}>
            <div className={`_accordion-head ${props.origin}`} onClick={() => { handleAccordion(); if (props.onClick) props.onClick(!accordion); }}>

                

                {props.origin === "shopping" ? (
                    <div className="_tag __cf shopping-scamb shopping">
                    Marcas que circulam
                </div>
                ) : (
                    <div className="_tag __cf shopping-scamb scamb">
                    Pessoas que circulam
                </div>
                )}

                <div className="_visible-content">
                    {props.child}
                </div>
                <div className="_chevron-icon">
                    <Icon icon={accordion ? faChevronUp : faChevronDown} />
                </div>

                <div className="__wrap-tags">

                    {/* {props.origin === "shopping" ? (
                        <div className="_tag __cf shopping">
                            Shopping
                        </div>
                    ) : (
                        <div className="_tag __cf scamb">
                            Lojinhas
                        </div>
                    )} */}

                    {((props.productStatus === "finalizado" && props.productStatusSeller === false) || props.productStatus === 'finalizado_devolucao') && (
                        <div className="_tag delivered">
                            <Icon icon={faCircle} />
                        Entregue
                        </div>
                    ) || ((props.productStatus === "finalizado" && props.productStatusSeller === true) || props.productStatus === 'finalizado_devolucao') && (
                        <div className="_tag delivered">
                            <Icon icon={faCircle} />
                        Finalizado
                        </div>
                    ) ||
                        (props.productStatus === "cancelado" || props.productStatus === 'cancelado_devolucao' || props.productStatus === 'reembolsado') && (
                            <div className="_tag canceled">
                                <Icon icon={faCircle} />
                        Cancelado
                            </div>
                        ) || props.productStatus === "returned" && (
                            <div className="_tag returned">
                                <Icon icon={faCircle} />
                        Devolvido
                            </div>
                        ) || (props.productStatus === "pago" || props.productStatus === 'pago_devolucao') && (
                            <div className="_tag delivered">
                                <Icon icon={faCircle} />
                        Pago
                            </div>
                        ) || (props.productStatus === "pagamento_pendente" || props.productStatus === 'pagamento_pendente_devolucao') && (
                            <div className="_tag returned">
                                <Icon icon={faCircle} />
                        Aguardando Pagamento
                            </div>
                        ) || (props.productStatus === "etiqueta_gerada" || props.productStatus === 'etiqueta_gerada_devolucao') && (
                            <div className="_tag delivered">
                                <Icon icon={faCircle} />
                        Aguardando Envio
                            </div>
                        ) || (props.productStatus === "postado" || props.productStatus === 'postado_devolucao') && (
                            <div className="_tag delivered">
                                <Icon icon={faCircle} />
                        Postado
                            </div>
                        ) || (props.productStatus === "aguardando_estorno_boleto" || props.productStatus === 'aguardando_estorno_pix') && (
                            <div className="_tag returned">
                                <Icon icon={faCircle} />
                        Aguardando Reembolso
                            </div>
                        ) || (props.productStatus === "pagamento_nao_aprovado" || props.productStatus === "expirado") && (
                            <div className="_tag canceled">
                                <Icon icon={faCircle} />
                        Pagamento não realizado
                            </div>
                        )}
                </div>
            </div>
            {accordion && (
                <div className="_accordion-content">
                    {props.children}
                </div>
            )}
        </div>
    )
}
export const AccordionInside = (props) => {
    const [accordion, setAccordion] = useState(false);
    const handleAccordion = () => setAccordion(!accordion);
    return (
        <div className="_accordion _inside">
            <div className="_accordion-head" onClick={handleAccordion}>
                <div className="_visible-content">
                    {props.title}
                </div>
                <div className="_chevron-icon">
                    <Icon icon={accordion ? faMinus : faPlus} />
                </div>
            </div>
            {!props.open === accordion && (
                <div className="_accordion-content">
                    {props.children}
                </div>
            )}
        </div>
    )
}
export const AccordionContent = (props) => {
    return (
        <div className="_accordion-content _inside" style={{ backgroundColor: `${props.bgColor}` }}>
            {props.children}
        </div>
    )
}