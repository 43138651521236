//===========================================================
// #1 - Base Imports
//===========================================================
import React from "react";

//===========================================================
// #2 - Import * FontAwesome Icons
//===========================================================
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-light-svg-icons";

export const GreenList = (props) => {

    return (

        <ul className="green-list">
            {props.children}
        </ul>
    )
}

export const GreenListItem = (props) => {

    return (
        <li className="green-list-item">
            <div className="icon mark">
                <Icon icon={faCheck} />
            </div>
            <div className="text">
                {props.title}
            </div>
        </li>
    )
}