import Service from './Service';

class Extract extends Service {

    constructor() {
        super();
    }

    loadPointExtract(limit, startDate, endDate, filter) {
        return this.axiosInstance.get(`/extract/history${limit? '?limit=' + limit: ''}${startDate?'&startDate=' + startDate:''}${endDate?'&endDate=' + endDate:''}${filter?'&filter=' + filter:''}`);
    }

    
    loadLoyaltyExtract(startDate, endDate, productCode) {
        return this.axiosInstance.get(`/extract/loyalty${productCode?'?productCode=' + productCode:''}${startDate?'&startDate=' + startDate:''}${endDate?'&endDate=' + endDate:''}`);
    }

    loadLoyaltyRedemptionExtract(startDate, endDate) {
        return this.axiosInstance.get(`/extract/loyalty/redemption${startDate?'?startDate=' + startDate:''}${endDate?'&endDate=' + endDate:''}`);
    }

    loadLoyaltyExtractExpiration(startDate, endDate, filter) {
        console.log('passou aqui', `/extract/loyalty/expiration?a=a${startDate?'&startDate=' + startDate:''}${endDate? '&endDate=' + endDate:''}${filter?'&filter=' + filter:''}`)
        return this.axiosInstance.get(`/extract/loyalty/expiration?a=a${startDate?'&startDate=' + startDate:''}${endDate? '&endDate=' + endDate:''}${filter?'&filter=' + filter:''}`);
    }

    loadPointsAwaitingApproval() {
        return this.axiosInstance.get(`/extract/loyalty/pointsAwaitingApproval`);
    }

    loadExtractAwaitingApproval(startDate, endDate) {
        return this.axiosInstance.get(`/extract/loyalty/extractAwaitingApproval?a=a${startDate?'&startDate=' + startDate:''}${endDate? '&endDate=' + endDate:''}`);  
    }

    loadCashBalance() {
        return this.axiosInstance.get('/iugu/balances');
    }

    loadBankTransfers() {
        return this.axiosInstance.get('/iugu/list/bank_transfers');
    }

    loadReceivables() {
        return this.axiosInstance.get('/iugu/list/receivables');
    }

    requestWithdrawal(amount) {
        return this.axiosInstance.post('/iugu/request/withdrawal', amount);
    }

}

export default Extract;