import React from 'react';
import { Link } from "react-router-dom";

import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";

import { Popup, WrapIcon, Content, Title, Subtitle, Button, Column } from "./styles";

const AccountStatus = ({ title, text, link, icon, showArrowIcon, iconColor }) => {

    return (
        <Popup>
            <Link to={link}>
                <Column>
                    <WrapIcon iconColor={iconColor}>{icon}</WrapIcon>

                    <Content>
                        <Title>
                            {title}
                        </Title>
                        <Subtitle>
                            {text}
                        </Subtitle>
                    </Content>
                </Column>
                {showArrowIcon && (
                    <Column>
                        <Button type="button">
                            <Icon icon={["far", "chevron-right"]} />
                        </Button>
                    </Column>
                )}
            </Link>
        </Popup>
    )
}

export default AccountStatus;