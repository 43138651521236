import React from 'react';
import {Switch, Route} from 'react-router-dom';

import LayoutRoute from './../utils/LayoutRoute';
import PrivateRoute from './../utils/PrivateRoute';

import Layout from './Layout';

import Home from './pages/home/index.jsx';

// import Home from './pages/Home';
import Buy from './pages/checkout';
import NotFound404 from './../common_pages/NotFound404';

const ShoppingRoutes = (props) => (
    <Switch>
        <LayoutRoute layout={Layout} exact path="/" component={Home} />
        <LayoutRoute layout={Layout} exact path="/comprar" component={Buy} />

        <Route path="*">
            <NotFound404 />
        </Route>        
    </Switch>
)
    

export default ShoppingRoutes;