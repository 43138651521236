import React, { Component } from "react";
import Header from "./Header";
import Menu from "./Menu";
import { faClone, faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from 'react-redux';
import config from '../../configs/config.json';

class Layout extends Component {

	constructor(props) {
		super(props);
		this.state = {
      invite_banner: true,
      inviteUrl:''
		}
	}
 
	closeInviteBanner = () => {
		this.setState({
			invite_banner: false
		});
  }

  componentWillMount(){
    if(!this.state.inviteUrl && this.props.scamber){
      if(config.nodejs.server.indexOf('localhost') !== -1){
        this.setState({inviteUrl: `http://localhost:3001/in/${this.props?.scamber._id}`})
      }else if(config.nodejs.server.indexOf('/dev') !== -1 && config.nodejs.server.indexOf('/dev2') === -1){
        this.setState({inviteUrl: `https://d1.scamb.com.vc/in/${this.props?.scamber._id}`})
      } else if(config.nodejs.server.indexOf('/dev2') !== -1){
        this.setState({inviteUrl: `https://d2.scamb.com.vc/in/${this.props?.scamber._id}`})
      } else if(config.nodejs.server.indexOf('apihml') !== -1 && config.nodejs.server.indexOf('apihml2') === -1){
        this.setState({inviteUrl: `https://h1.scamb.com.vc/in/${this.props?.scamber._id}`})
      } else if(config.nodejs.server.indexOf('apihml2') !== -1){
        this.setState({inviteUrl: `https://h2.scamb.com.vc/in/${this.props?.scamber._id}`})
      } else{
        this.setState({inviteUrl: `https://scamb.com.vc/in/${this.props?.scamber._id}`})
      }
    }
  }

  componentDidUpdate(nextProps) {
    const { scamber } = this.props
    if (nextProps.scamber !== scamber) {
      if (scamber) {
        if(!this.state.inviteUrl && this.props.scamber){
          if(config.nodejs.server.indexOf('localhost') !== -1){
            this.setState({inviteUrl: `http://localhost:3001/in/${this.props?.scamber._id}`})
          }else if(config.nodejs.server.indexOf('/dev') !== -1 && config.nodejs.server.indexOf('/dev2') === -1){
            this.setState({inviteUrl: `https://d1.scamb.com.vc/in/${this.props?.scamber._id}`})
          } else if(config.nodejs.server.indexOf('/dev2') !== -1){
            this.setState({inviteUrl: `https://d2.scamb.com.vc/in/${this.props?.scamber._id}`})
          } else if(config.nodejs.server.indexOf('apihml') !== -1 && config.nodejs.server.indexOf('apihml2') === -1){
            this.setState({inviteUrl: `https://h1.scamb.com.vc/in/${this.props?.scamber._id}`})
          } else if(config.nodejs.server.indexOf('apihml2') !== -1){
            this.setState({inviteUrl: `https://h2.scamb.com.vc/in/${this.props?.scamber._id}`})
          } else{
            this.setState({inviteUrl: `https://scamb.com.vc/in/${this.props?.scamber._id}`})
          }
        }
      }
    }
  }

  copyText = () =>{
    let textArea = document.createElement("textarea");
    let inviteLink = document.getElementById("inviteLink");
    let oldValue = inviteLink.value;

    textArea.value = inviteLink.value;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy')
    textArea.remove();
  }
 
  render() {
    return (
      <div id="view_my-account-layout" className={this.props.className}>
        <Header></Header>

        <div className="_wrap-content">
          <div className="_cols">
            <div className="_col _col-left">
              <Menu></Menu>
            </div>
            <div className="_col _col-right">
				{this.state.invite_banner ? (
              <section className="_wrap-banner-fixed _is-hidden">
                <button type="button" className="btn _close-banner" onClick={this.closeInviteBanner}>
                  <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
                </button>
                <section className="_banner-fixed">
                  <div className="_texts-banner">
                    <h1 className="_the-title _color _white _fw700">
                      Convide amigos e ganhe pontos
                    </h1>
                    <span className="_subtitle _color _white _fw700">
                      Copie seu link e envie para seus amigos
                    </span>
                  </div>

                  <div className="_invite-label">
                    <form className="_form">
                      <div className="form-group">
                        <input
                          id="inviteLink"
                          type="text"
                          className="form-control"
                          placeholder={this.state.inviteUrl}
                          value={this.state.inviteUrl}
                          disabled="disabled"
                        />
                        <button onClick={this.copyText} type="button" className="btn">
                          <FontAwesomeIcon icon={faClone}></FontAwesomeIcon>
                        </button>
                      </div>
                    </form>
                  </div>
                </section>
			  </section>
				) : null}
              <div className="_wrap-col-content">{this.props.children}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = state => {
  return {
      scamber: state.scamberReducer.scamber
  }
}

export default connect(mapStateToProps)(Layout);
