//=====================================================================================
// #1 - Import * Banners Images
//=====================================================================================

import Brands01 from "../../../../../assets/images/banners/brands/marcas_novo_01.jpg";
import Brands01Mob from "../../../../../assets/images/banners/brands/marcas_novo_mob_01.jpg";

import Brands02 from "../../../../../assets/images/banners/brands/marcas_novo_02.jpg";
import Brands02Mob from "../../../../../assets/images/banners/brands/marcas_novo_mob_02.jpg";

import Brands03 from "../../../../../assets/images/banners/brands/marcas_novo_03.jpg";
import Brands03Mob from "../../../../../assets/images/banners/brands/marcas_novo_mob_03.jpg";

// Banner #1 Mob & Desk
import TFSeven from "../../../../../assets/images/banners/novembro/tfseven.jpg";
import TFSevenMobile from "../../../../../assets/images/banners/novembro/tfseven-mobile.jpg";


//=====================================================================================
// #2 - Banners Json
//=====================================================================================
export const banners = [
  {
    image_desk: TFSeven,
    image_mob: TFSevenMobile,
    link: "https://marcas.scamb.com.vc/loja/twentyfourseven#/",
  },
  {
    image_desk: Brands01,
    image_mob: Brands01Mob,
    link: "/sobre-o-scamb",
  },
  {
    image_desk: Brands02,
    image_mob: Brands02Mob,
    link: "/sobre-o-scamb",
  },
  {
    image_desk: Brands03,
    image_mob: Brands03Mob,
    link: "/resultados?tag=top20&limit=8&aleatory=54",
  },
];