//=====================================================================================
// #1 - Base Imports
//=====================================================================================
import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
  useEffect,
} from "react";

//=====================================================================================
// #2 - Import * JQuery
//=====================================================================================
import $, { Callbacks } from "jquery";
import "jquery-mask-plugin";

//=====================================================================================
// #2 - Import * Sweet Alert 2
//=====================================================================================
import Swal from "sweetalert2";
import SweetAlert from "sweetalert";

//=====================================================================================
// #3 - Import * Imput Mask
//=====================================================================================
import InputMask from "react-input-mask";

//=====================================================================================
// #4 - Import * React Hook Form
//=====================================================================================
import { useForm } from "react-hook-form";

//=====================================================================================
// #5 - Import * Countdown Timer
//=====================================================================================
import Timer from "react-compound-timer";

//=====================================================================================
// #6 - Import * Material UI Components
//=====================================================================================
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Grid,
  useMediaQuery,
  makeStyles,
  InputAdornment,
} from "@material-ui/core";

//=====================================================================================
// #7 - Import * Boostrap Modals
//=====================================================================================
import { Modal } from "react-bootstrap";
import ModalBase from "../components/modals/ModalBase";
import ModalShippingOptions from "../common_pages/modals/ModalShippingOptions";
import ModalTaxs from "../common_pages/modals/ModalTaxs";

//=====================================================================================
// #8 - Import * UI Components
//=====================================================================================
import { AdvertiserCard } from "../components/ui/advertiser-card";
import { Price, PricePoints } from "../components/ui/price";
import { ListContent, ListItem } from "../components/ui/list-content";
import { ProductThumbnail } from "../components/ui/product-thumbnail";
import { CurrentPage } from "../components/ui/current-page";
import { SelectBox, SelectBoxExpand } from "../components/ui/select-box";
import { BoxInfo } from "../components/ui/box-info";

//=====================================================================================
// #9 - Import * FontAwesome Icons
//=====================================================================================
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import {
  faCreditCardBlank as faCreditCardLt,
  faUser,
  faCalendarStar,
  faCalendarAlt,
  faLockAlt,
  faShoppingCart,
  faCheck,
  faChevronLeft,
  faBarcodeAlt,
  faPlus,
  faArrowRight,
  faUserLock,
  faInfoCircle,
  faCreditCard
} from "@fortawesome/pro-light-svg-icons";

import { faPlus as faPlusRegular, } from "@fortawesome/pro-regular-svg-icons";
import { faInfo, faMapMarkerAlt } from "@fortawesome/pro-solid-svg-icons";

//===============================================================================================================
// #10 - Import * Bootstrap Components
//===============================================================================================================
import Button from "react-bootstrap/Button";

//===============================================================================================================
// #11 - Credit Card Flags
//===============================================================================================================
import Visa from "../assets/images/credit-card/visa.png";
import Mastercard from "../assets/images/credit-card/mastercard.png";
import AmericanExpress from "../assets/images/credit-card/americanExpress.png";
import DinersClub from "../assets/images/credit-card/DinersClub.png";
import Pix from "../assets/images/pix-logo.png";

import ScamberFormaPagamento from "../services/ScamberFormasPagamento";
import Endereco from "../services/Endereco";
import Pedido from "../services/Pedido";
import Scamber from '../services/Scamber';
import { setScamber } from '../redux/actions/scamberActions';

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import EventEmitter from './../services/Events';

import { addYears, format, lastDayOfMonth } from 'date-fns';

//===============================================================================================================
// #15 - Import - Utils
//===============================================================================================================
import formatNumberToBRL from "../utils/FormatNumberToBRL";

//===============================================================================================================
// #15 - Import - Services
//===============================================================================================================
let scamberFormaPagamento = new ScamberFormaPagamento();
let endereco = new Endereco();
let pedido = new Pedido();

//===============================================================================================================
// #12 - Instances
//===============================================================================================================

const scamber = new Scamber();

//===============================================================================================================
// #13 - Modal - Address
//===============================================================================================================
const ModalPersonalData = forwardRef((props, ref) => {
  // * States
  const [show, setShow] = useState(false);
  const [cep, setCep] = useState("");
  const [checkedAddress, setCheckedAddress] = useState();
  const [customProfile, setCustomProfile] = useState({});

  // * React Hook Form
  const { register, handleSubmit, errors, formState, reset } = useForm({ mode: "onChange" });

  // Styles
  const matches = useMediaQuery("(min-width:600px)");
  const classes = useStyles();

  useEffect(() => {
    initFormValues(props.scamber);
  }, [props.scamber]);

  const initFormValues = (profile) => {
    let customProfile = {}

    if (profile) {
      customProfile.cpf = profile.cpf;

      if (profile.telefone && profile.telefone.length > 0) {
        customProfile.phone = `(${profile.telefone[0].ddd}) ${profile.telefone[0].numero.substring(0, 5)}-${profile.telefone[0].numero.substring(5, 9)}`;
      }

      setCustomProfile(customProfile)
      reset(customProfile);
    }
  }

  const loadScamberData = () => {
    Swal.fire("Aguarde...");
    Swal.showLoading();
    scamber
      .findScamber()
      .then((scamberData) => {
        Swal.close();
        if (scamberData.status === 200)
          props.setScamber(scamberData.data.payload);

        Swal.fire("Aguarde...");
        Swal.showLoading();
        pedido
          .doOrderPoints({
            paymentMethod: props.paymentMethod,
            points: props.points.qtd_points
          })
          .then((response) => {
            Swal.fire({
              html: "",
              icon: "success",
              title: "Pedido de pontos realizado com sucesso.",
            }).then(() => {
              if (props.isBoleto)
                window.open(
                  response.data.payload.gateway_infos.url_fatura,
                  "_blank"
                );
              props.history.push("/minha-carteira");
            });
          })
          .catch((error) => {
            if(error.response.data && error.response.data.code === 55) {
              const returnView = error.response.data;

              Swal.fire(returnView.title, returnView.message, returnView.type).then(() => {
                props.history.push("/minha-carteira");
              })
            } else {
              pedido.formatErrorMessage(error.response, 'BPOINS');
            }
            
          });


      })
      .catch((error) => {
        Swal.fire({
          type: "info",
          html: error,
          title: "",
        });
      });
  };

  // * Functions - React Mask
  const handleCepChange = (e) => setCep(e.target.value);

  // * Function
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useImperativeHandle(ref, () => ({
    openModal: () => handleShow(),
    closeModal: () => handleClose(),
  }));

  useEffect(() => {
    $(".cep").mask("00000-000");
    $(".cpf").mask("000.000.000-00", { reverse: false });
    $(".celular").mask("(00) 00000-0000");
    $(".house_number").mask("00000");
  });

  const findAddressByCEP = () => {
    if (cep.length === 9) {
      endereco
        .findCep(cep)
        .then((address) => {
          let formatedAddress = {
            cep: address.data.cep,
            estado: address.data.estado,
            logradouro: address.data.logradouro,
            cidade: address.data.localidade,
            estado: address.data.uf,
            bairro: address.data.bairro,
            complemento: address.data.complemento,
            phone: customProfile.phone,
            cpf: customProfile.cpf
          };

          if (address.data) {
            reset(formatedAddress);
          }
        })
        .catch((error) =>
          Swal.fire("info", "Não foi possível consultar o endereço", "")
        );
    }
  };

  const setPersonalData = (data, callback) => {

    if (data.senha === 'not_specified') {
      delete data.senha;
    }

    scamber.updateScamber(data).then((response) => {
      if (response.status === 200) {
        callback()
      }

    }).catch((error) => scamber.formatErrorMessage(error.response, 'BPUPSC'));

  };


  // * Create New Address
  const onSubmitAddress = (data) => {

    let dadosCadastrais = { cpf: data.cpf, celular: data.phone };

    delete data.cpf;
    delete data.phone;

    data.principal = checkedAddress;

    setPersonalData(dadosCadastrais, () => {
      if (props.scamber?.endereco.length === 0) {

        endereco
          .create(data)
          .then((response) => {
            if (response.status === 200) {
              // * Close Modal
              handleClose();
              loadScamberData();
            }
          })
          .catch((error) =>
            endereco.formatErrorMessage(error.response, 'BPCREN')
          );

      } else {
        handleClose();
        loadScamberData();
      }


      setCheckedAddress(false);
    })

  };

  return (
    <>
      <Modal show={show} onHide={handleClose} className="full-width" centered>
        <Modal.Header closeButton>
          <section className="_view-head">
            <div className="_view-head-content">
              <Button className="_back-history btn" onClick={handleClose}>
                <Icon icon={faChevronLeft} />
              </Button>
              <Modal.Title> Quase lá! </Modal.Title>
            </div>
          </section>
        </Modal.Header>
        <Modal.Body>
          <p style={{ textAlign: 'center', marginTop: '10px' }}>Precisamos que complete algumas informações antes de prosseguir com a solicitação</p>

          <form onSubmit={handleSubmit(onSubmitAddress)}>
            <h1 className="_content-title" />
            {/*  Form Body */}
            <section className="_form-body">
              <Grid container spacing={2}>
                {props.scamber?.endereco.length === 0 ?
                  (
                    <>
                      <Grid item xs={12}>
                        <TextField
                          id="outlined-cc-number"
                          label="Cep *"
                          type="text"
                          onChange={handleCepChange}
                          onBlur={findAddressByCEP}
                          variant="outlined"
                          name="cep"
                          size={matches ? "small" : "small"}
                          error={!!errors.cep}
                          inputProps={{ className: "cep" }}
                          fullWidth
                          inputRef={register({
                            required: true,
                            minLength: 8,
                          })}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          id="outlined-neighborhood"
                          label="Estado *"
                          variant="outlined"
                          size={matches ? "small" : "small"}
                          name="estado"
                          error={!!errors.estado}
                          fullWidth
                          inputProps={{ maxLength: 2 }}
                          inputRef={register({
                            required: true,
                          })}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="outlined-neighborhood"
                          label="Cidade *"
                          variant="outlined"
                          size={matches ? "small" : "small"}
                          name="cidade"
                          error={!!errors.cidade}
                          fullWidth
                          inputRef={register({
                            required: true,
                          })}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>

                      <Grid item xs={8}>
                        <TextField
                          id="outlined-address"
                          label="Endereço *"
                          variant="outlined"
                          size={matches ? "small" : "small"}
                          name="logradouro"
                          error={!!errors.logradouro}
                          fullWidth
                          inputRef={register({
                            required: true,
                          })}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          id="outlined-address-number"
                          label="Número *"
                          type="number"
                          variant="outlined"
                          inputProps={{ className: "house_number" }}
                          size={matches ? "small" : "small"}
                          name="numero"
                          error={!!errors.numero}
                          fullWidth
                          inputRef={register({
                            required: true,
                          })}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="outlined-neighborhood"
                          label="Bairro *"
                          variant="outlined"
                          size={matches ? "small" : "small"}
                          name="bairro"
                          error={!!errors.bairro}
                          fullWidth
                          inputRef={register({
                            required: true,
                          })}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="outlined-complement"
                          label="Complemento"
                          variant="outlined"
                          name="complemento"
                          size={matches ? "small" : "small"}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="outlined-cc-number"
                          label="Apelido *"
                          type="text"
                          variant="outlined"
                          name="apelido"
                          placeholder="Exemplo: Casa, Trabalho.."
                          size={matches ? "small" : "small"}
                          error={!!errors.apelido}
                          fullWidth
                          inputRef={register({
                            required: true,
                            minLength: 2,
                          })}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>

                    </>
                  ) : (<></>)
                }

                <Grid item xs={12}>
                  <TextField
                    id="outlined-cc-number"
                    label="Telefone *"
                    type="text"
                    variant="outlined"
                    name="phone"
                    placeholder=""
                    size={matches ? "small" : "small"}
                    error={!!errors.phone}
                    fullWidth
                    inputRef={register({
                      required: true,
                      minLength: 2,
                    })}
                    inputProps={{
                      className: "celular"
                    }}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    id="outlined-cc-number"
                    label="CPF *"
                    type="text"
                    variant="outlined"
                    name="cpf"
                    placeholder=""
                    size={matches ? "small" : "small"}
                    error={!!errors.cpf}
                    fullWidth
                    inputRef={register({
                      required: true,
                      minLength: 2,
                    })}
                    inputProps={{
                      className: 'cpf'
                    }}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
              </Grid>

            </section>

            <Button
              type="submit"
              className={
                "btn btn-primary btn-fluid btn-submit-modal " + classes.center
              }
              color="primary"
              autoFocus
              style={{ height: matches ? 35 : 40 }}
              disabled={
                !formState.dirty || (formState.dirty && !formState.isValid)
              }
            >
              Confirmar
            <Icon icon={faCheck} />
            </Button>
            {/*  END ./ Form Body */}
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
});

//===============================================================================================================
// #13 - Modal - Address
//===============================================================================================================
const ModalAddress = forwardRef((props, ref) => {
  // * States
  const [show, setShow] = useState(false);
  const [cep, setCep] = useState("");
  const [checkedAddress, setCheckedAddress] = useState();

  // * React Hook Form
  const { register, handleSubmit, errors, formState, reset } = useForm({ mode: "onChange" });

  // Styles
  const matches = useMediaQuery("(min-width:600px)");
  const classes = useStyles();

  const scamber = new Scamber();

  const loadScamberData = () => {
    Swal.fire("Aguarde...");
    Swal.showLoading();
    scamber
      .findScamber()
      .then((scamberData) => {
        Swal.close();
        if (scamberData.status === 200)
          props.setScamber(scamberData.data.payload);
        props.modalCreditCard.openModal();
      })
      .catch((error) => {
        Swal.fire({
          type: "info",
          html: error,
          title: "",
        });
      });
  };

  // * Functions - React Mask
  const handleCepChange = (e) => setCep(e.target.value);

  // * Function
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useImperativeHandle(ref, () => ({
    openModal: () => handleShow(),
    closeModal: () => handleClose(),
  }));

  useEffect(() => {
    $(".cep").mask("00000-000");
    $(".house_number").mask("00000");
  });

  const findAddressByCEP = () => {
    if (cep.length === 9) {
      endereco
        .findCep(cep)
        .then((address) => {
          let formatedAddress = {
            cep: address.data.cep,
            estado: address.data.estado,
            logradouro: address.data.logradouro,
            cidade: address.data.localidade,
            estado: address.data.uf,
            bairro: address.data.bairro,
            complemento: address.data.complemento,
          };

          if (address.data) {
            reset(formatedAddress);
          }
        })
        .catch((error) =>
          Swal.fire("info", "Não foi possível consultar o endereço", "")
        );
    }
  };

  // * Create New Address
  const onSubmitAddress = (data) => {

    data.principal = checkedAddress;
    // * Close Modal
    handleClose();

    endereco
      .create(data)
      .then((response) => {
        if (response.status === 200) {
          // * Success Message
          loadScamberData();
        }
      })
      .catch((error) =>
        endereco.formatErrorMessage(error.response, 'BPEN2C')
      );

    setCheckedAddress(false);
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} className="full-width" centered>
        <Modal.Header closeButton>
          <section className="_view-head">
            <div className="_view-head-content">
              <Button className="_back-history btn" onClick={handleClose}>
                <Icon icon={faChevronLeft} />
              </Button>
              <Modal.Title> {props.title} </Modal.Title>
            </div>
          </section>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmitAddress)}>
            <h1 className="_content-title">
              Antes de adicionar um metodo de pagamento é necessário adicionar um endereço a sua conta.
          </h1>
            {/*  Form Body */}
            <section className="_form-body">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-cc-number"
                    label="Cep *"
                    type="text"
                    onChange={handleCepChange}
                    onBlur={findAddressByCEP}
                    variant="outlined"
                    name="cep"
                    size={matches ? "small" : "small"}
                    error={!!errors.cep}
                    inputProps={{ className: "cep" }}
                    fullWidth
                    inputRef={register({
                      required: true,
                      minLength: 8,
                    })}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="outlined-neighborhood"
                    label="Estado *"
                    variant="outlined"
                    size={matches ? "small" : "small"}
                    name="estado"
                    error={!!errors.estado}
                    fullWidth
                    inputProps={{ maxLength: 2 }}
                    inputRef={register({
                      required: true,
                    })}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="outlined-neighborhood"
                    label="Cidade *"
                    variant="outlined"
                    size={matches ? "small" : "small"}
                    name="cidade"
                    error={!!errors.cidade}
                    fullWidth
                    inputRef={register({
                      required: true,
                    })}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    id="outlined-address"
                    label="Endereço *"
                    variant="outlined"
                    size={matches ? "small" : "small"}
                    name="logradouro"
                    error={!!errors.logradouro}
                    fullWidth
                    inputRef={register({
                      required: true,
                    })}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="outlined-address-number"
                    label="Número *"
                    type="number"
                    variant="outlined"
                    inputProps={{ className: "house_number" }}
                    size={matches ? "small" : "small"}
                    name="numero"
                    error={!!errors.numero}
                    fullWidth
                    inputRef={register({
                      required: true,
                    })}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-neighborhood"
                    label="Bairro *"
                    variant="outlined"
                    size={matches ? "small" : "small"}
                    name="bairro"
                    error={!!errors.bairro}
                    fullWidth
                    inputRef={register({
                      required: true,
                    })}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-complement"
                    label="Complemento"
                    variant="outlined"
                    name="complemento"
                    size={matches ? "small" : "small"}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-cc-number"
                    label="Apelido *"
                    type="text"
                    variant="outlined"
                    name="apelido"
                    placeholder="Exemplo: Casa, Trabalho.."
                    size={matches ? "small" : "small"}
                    error={!!errors.apelido}
                    fullWidth
                    inputRef={register({
                      required: true,
                      minLength: 2,
                    })}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>

              <div className="mark-default-address">
                <label>
                  <input
                    onChange={() => setCheckedAddress(!checkedAddress)}
                    type="checkbox"
                    name="mark_default_address"
                  />

                  <div className="text">Marcar como meu novo endereço padrão</div>

                  <div className="check">
                    <Icon icon={faCheck} />
                  </div>
                </label>
              </div>
            </section>

            <Button
              type="submit"
              className={
                "btn btn-primary btn-fluid btn-submit-modal " + classes.center
              }
              color="primary"
              autoFocus
              style={{ height: matches ? 35 : 40 }}
              disabled={
                !formState.dirty || (formState.dirty && !formState.isValid)
              }
            >
              Confirmar
            <Icon icon={faCheck} />
            </Button>
            {/*  END ./ Form Body */}
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
});

//===============================================================================================================
// #14 - Modal - Add Credit Card
//===============================================================================================================
const ModalCreditCard = forwardRef((props, ref) => {
  // Bootstrap Modal
  const [show, setShow] = useState(false);

  // Handle Modal
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useImperativeHandle(ref, () => ({
    openModal: () => handleShow(),
    closeModal: () => handleClose(),
  }));

  return (
    <>
      <Modal show={show} onHide={handleClose} className="full-width" centered>
        <Modal.Header closeButton>
          <section className="_view-head">
            <div className="_view-head-content">
              <Button className="_back-history btn" onClick={handleClose}>
                <Icon icon={faChevronLeft} />
              </Button>
              <Modal.Title>Adicionar informações</Modal.Title>
            </div>
          </section>
        </Modal.Header>
        <Modal.Body>{props.children}</Modal.Body>
      </Modal>
    </>
  );
});

//===============================================================================================================
// #16 - Default
//===============================================================================================================
const useStyles = makeStyles({
  center: {
    margin: "auto",
  },
  resize: {
    fontSize: "10px",
  },
  inputSize: {
    height: 50,
  },
});

function BuyPoints(props) {
  // States
  const [valueFieldCredt, setValueCredt] = useState("");
  const [cep, setCep] = useState("");
  const [creditCardType, setCreditCardType] = useState(null);
  const [valueField, setValue] = useState("");
  const [pricePoints, setPricePoints] = useState(0);
  const [valueFieldCpf, setValueCpf] = useState("");
  const [originalPoints, setOriginalPoints] = useState(0);
  const [checked, setChecked] = useState();
  const [paymentMethod, setPaymentMethod] = useState([]);
  const [payMethod, setPayMethod] = useState();
  const [points, setPoints] = useState();
  const [isBoleto, setIsBoleto] = useState(false);
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [cvv, setCvv] = useState("");
  const [toOrder, setToOrder] = useState("N");
  const [installmentValue, setInstallmentValue] = useState(1);

  // Styles
  const matches = useMediaQuery("(min-width:600px)");
  const classes = useStyles();

  // Refs
  const childShippingOptions = useRef();
  const childTaxs = useRef();
  const refAddressModal = useRef();
  const refCreditCardModal = useRef();
  const refPreviewProductModal = useRef();
  const refPersonalDataModal = useRef();

  const { refCreditNumber } = useRef();

  // * Consulta a URL e pega o parametro ?origin=checkout
  const queryString = window.location.search;
  const url_params = new URLSearchParams(queryString);
  const checkParam = url_params.get("origin");

  // * React Hook Form
  const { register, handleSubmit, errors, formState, reset, setValue: setFormFieldValue } = useForm({
    mode: "onChange",
  });

  const { register: registerCard, handleSubmit: handleSubmitCard, errors: errorsCard, formState: formStateCard, reset: resetCard } = useForm({
    mode: "onChange",
  });

  const params = (price) => [
    { value: 1, title: `1x de R$ ${formatNumberToBRL(parseFloat(price))} sem juros` },
    { value: 2, title: `2x de R$ ${formatNumberToBRL(parseFloat(price / 2))} sem juros` },
    { value: 3, title: `3x de R$ ${formatNumberToBRL(parseFloat(price / 3))} sem juros` },
    { value: 4, title: `4x de R$ ${formatNumberToBRL(parseFloat(price / 4))} sem juros` },
    { value: 5, title: `5x de R$ ${formatNumberToBRL(parseFloat(price / 5))} sem juros` },
    { value: 6, title: `6x de R$ ${formatNumberToBRL(parseFloat(price / 6))} sem juros` }
  ];

  const onSubmitPoints = (values) => {
    let paymentMethod;
    let cardID;
    let parcelamento = installmentValue;

    if (checked === 'boleto') {
      paymentMethod = 'bank_slip';
    } else if (checked === 'pix') {
      paymentMethod = 'pix';
    } else if(checked !== undefined) {
      cardID = document.querySelector(`input[name="${checked}check"]`)?.id;
      paymentMethod = 'card';

      if(!installmentValue) {
        return Swal.fire({
          icon: "info",
          html: "Você precisa selecionar um parcelamento!",
          title: "",
        });
      }

      let orderValue = pricePoints;
      if(orderValue < 50) {
        parcelamento = 1;
        setInstallmentValue(1);
      }

    }

    if (!paymentMethod)
      return Swal.fire({
        icon: "info",
        html: "Você precisa selecionar uma forma de pagamento!",
        title: "",
      });

    if (paymentMethod !== 'card' && (!props.scamber?.endereco.length || !props.scamber?.telefone.length || !props.scamber?.cpf)) {
      setPayMethod(paymentMethod)
      setPoints(values);
      setIsBoleto(true);

      refPersonalDataModal.current.openModal();
      return;
    }

    // Swal.fire("Aguarde...");
    // Swal.showLoading();

    SweetAlert({
      title: "Confirmar compra ",
      text: `Deseja confirmar a compra de pontos?`,
      icon: "info",
      buttons: ["Cancelar", "Comprar"],
      dangerMode: true,
      className: "confirm-buy-points"
    }).then(function (isConfirm) {
      if (isConfirm) {

        Swal.fire("Aguarde...");
        Swal.showLoading();

        pedido
          .doOrderPoints({
            cardId: cardID,
            paymentMethod: paymentMethod,
            points: values.qtd_points,
            installmentValue: parcelamento
          })
          .then((response) => {

            // * Fecha o loading ("Aguarde...")
            Swal.close();

            if (paymentMethod !== 'card') {

              // * Pedido por boleto
              SweetAlert({
                icon: "success",
                title: "Compra de pontos realizada com sucesso.",
                text: `Os pontos serão creditados na carteira após a compensação do ${paymentMethod === 'boleto' ? "boleto bancário" : "pix"}.`
              }).then((isConfirm) => {

                if (isConfirm) {

                  // * Abrea página de impressão do boleto em nova janela
                  window.open(response.data.payload.gateway_infos.url_fatura, "_blank");

                  if (checkParam === "checkout") {
                    props.history.push("/comprar");
                  } else {
                    props.history.push("/minha-carteira");
                  }

                }
              })


            } else {

              // * Pedido por cartão de crédito
              SweetAlert({
                icon: "success",
                title: "Compra de pontos realizada com sucesso.",
                text: "Os pontos serão creditados na carteira, mas isso pode levar alguns instantes. Caso não esteja visualizando os seus pontos, atualize a página do site."
              }).then(() => {

                if (checkParam === "checkout") {
                  props.history.push("/comprar");
                } else {
                  props.history.push("/minha-carteira");
                }

              })

            } // * END if (boleto)

            // * Atualiza o saldo de pontos
            EventEmitter.dispatch('balanceChange');
          })
          .catch((error) => {
            if(error.response.data && error.response.data.code === 55) {
              const returnView = error.response.data;

              Swal.fire(returnView.title, returnView.message, returnView.type).then(() => {
                props.history.push("/minha-carteira");
              })
            } else {
              pedido.formatErrorMessage(error.response, 'BPORPT');
            }
          });

      }
    });

    // pedido
    //   .doOrderPoints({
    //     cardId: boleto ? null : checkboxClicada.id,
    //     points: values.qtd_points,
    //   })
    //   .then((response) => {
    //     Swal.fire({
    //       html: "",
    //       icon: "success",
    //       title: "Pedido de pontos realizado com sucesso.",
    //     }).then(() => {
    //       if (boleto)
    //         window.open(
    //           response.data.payload.gateway_infos.url_fatura,
    //           "_blank"
    //         );

    //         EventEmitter.dispatch('balanceChange');
    //         if(toOrder === 'N') {
    //           props.history.push("/minha-carteira");
    //         } else if(toOrder === 'S') {
    //           props.history.push("/comprar");
    //         }

    //     });
    //   })
    //   .catch((error) => {
    //     pedido.formatErrorMessage(error.response, 'BPORPT');
    //   });
  };

  const onSubmitCreditCard = (creditCard, e) => {
    let formattedObject = {
      token_cartao: "",
      cardInfos: {
        bandeira: creditCardType ? creditCardType : "none",
        numero_cartao: creditCard.numero_cartao,
        nome: creditCard.nome,
        mes_expiracao: creditCard.mes_expiracao,
        ano_expiracao: creditCard.ano_expiracao,
        cvv: creditCard.cvv,
        id_endereco: props.scamber?.endereco[0]._id,
        cpf: creditCard.numero_cpf,
      },
    };

    scamberFormaPagamento
      .createPaymentForm(formattedObject)
      .then((response) => {

        // * Clear Form
        e.target.reset();
        setValue("");
        setMonth("");
        setYear("");
        setCvv("");
        setValueCpf("");

        Swal.fire({
          icon: "success",
          title: "Cartão de crédito adicionado!",
          timer: 4000,
          timerProgressBar: true,
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonText: "Ok",
        }).then((eComplete) => {
          if (eComplete) {
            closeModalCreditCard();
            loadPaymentForms();

          }
        });
      })
      .catch((error) => {
        scamberFormaPagamento.formatErrorMessage(error.response, 'BPPFCR');
      });



  };

  // * Functions - Modals
  const openModalShippingOptions = () =>
    childShippingOptions.current.handleShow();
  const openModalTaxs = () => childTaxs.current.handleShow();
  const closeModalAddress = () => refAddressModal.current.closeModal();
  const closeModalCreditCard = () => refCreditCardModal.current.closeModal();

  // * Functions - React Mask
  const handleCepChange = (e) => setCep(e.target.value);

  // * Check Credit Card Flag Type
  let checkCreditCardType = (e) => {
    let cardnumber = e.target.value.replace(" ", "").replace(/[^0-9]+/g, "");
    let emptyFields = 16 - cardnumber.length;
    let zeros = "";
    for (let count = 0; count < emptyFields; count++) {
      zeros += "0";
    }

    var cards = {
      visa: /^4[0-9]{12}(?:[0-9]{3})/,
      mastercard: /^5[1-5][0-9]{14}/,
      DinersClub: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
      americanExpress: /^3[47][0-9]{13}/,
    };

    for (var flag in cards) {
      if (cards[flag].test(parseInt(cardnumber + zeros))) {
        return setCreditCardType(flag);
      }
    }

    return setCreditCardType(null);
  };

  let convertPricePoints = (e) => {
    e.preventDefault();
    let value = e.target.value ? e.target.value : 0;
    if (value > 0) {
      setOriginalPoints(value);
      setPricePoints((parseFloat(value) / 1.818).toFixed(2));
    } else {
      setOriginalPoints('');
      setPricePoints('');
    }
  }

  let loadPaymentForms = () => {
    scamberFormaPagamento
      .find()
      .then((response) => {
        setPaymentMethod(response.data.payload);
      })
      .catch((error) => {
        scamberFormaPagamento.formatErrorMessage(error.response, 'BPPFLO')
      });
  };

  useEffect(() => {

    loadPaymentForms();

    let urlParams = new URLSearchParams(props.location.search);
    let points = urlParams.get('points');
    let toOrder = urlParams.get('toOrder');

    if (toOrder) {
      setToOrder(toOrder);
    }

    if (points) {
      //reset({ qtd_points: Number(points) });
      setFormFieldValue('qtd_points', Number(points), { shouldDirty: true })

      if (points > 0) {
        setOriginalPoints(Number(points));
        setPricePoints((parseFloat(points) / 1.818).toFixed(2));
      } else {
        setOriginalPoints('');
        setPricePoints('');
      }

    }


  }, []);

  let setCheckValue = (index, qtdCheckbox) => {
    if (index === "boleto") {
      let i = 0;

      while (i < qtdCheckbox) {
        document.querySelector(`input[name="${i}check"]`).checked = false;
        i++;
      }

      document.querySelector(`input[name="pix"]`).checked = false;
      document.querySelector(`input[name="boleto"]`).checked = true;
      setChecked("boleto");

    } else if (index === "pix") {
      let i = 0;

      while (i < qtdCheckbox) {
        document.querySelector(`input[name="${i}check"]`).checked = false;
        i++;
      }

      document.querySelector(`input[name="boleto"]`).checked = false;
      document.querySelector(`input[name="pix"]`).checked = true;
      setChecked("pix");


    } else {
      let qtdCheckbox = 100;
      let i = 0;
      while (i < qtdCheckbox) {
        if (document.querySelector(`input[name="${i}check"]`)) {
          document.querySelector(`input[name="${i}check"]`).checked = false;
        }
        i++;
      }

      if (document.querySelector(`input[name="${index}check"]`) != null) {
        document.querySelector(`input[name="${index}check"]`).checked = true;
        setChecked(index);
      }

      document.querySelector(`input[name="boleto"]`).checked = false;
      document.querySelector(`input[name="pix"]`).checked = false;

    }
  };

  // * if exists address
  const checkAdress = () => {
    if (props.scamber?.endereco.length) {
      refCreditCardModal.current.openModal();
    } else {
      refAddressModal.current.openModal();
    }

  };

  const atualizarScamber = (scamber) => {
    props.setScamber(scamber);
  }

  const info_points = () => {

    SweetAlert({
      icon: "info",
      text: `Você pode comprar pontos Scamb sempre que quiser, seja para completar pontos para finalizar uma compra de produto ou apenas para ter os pontos em sua carteira.
      Após a finalização da compra e confirmação do pagamento, os pontos serão creditados automaticamente em sua carteira.\n\n
      O tempo de confirmação do pagamento depende do método escolhido. Geralmente, pagamentos com cartão de crédito e PIX são confirmados automaticamente.
      Pagamentos via boleto bancário podem levar até 2 dias para ser confirmados.
      `
    })
  }

  const calculateExpirationDate = () => {
    const date = new Date();
    const expirationDate = lastDayOfMonth(addYears(date, 2));

    return format(expirationDate, 'dd/MM/yyyy'); 
  }

  return (
    <>
      <section id="view_buy-points">
        <div className="_block _title-with-timer">
          <div className="_med-container">
            <CurrentPage
              icon={<Icon icon={faShoppingCart} />}
              title={`Comprar pontos Scamb`}
            />
            <button type="button" className="btn info-points" onClick={info_points}><Icon icon={faInfoCircle} /></button>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmitPoints)}>
          <div className="_med-container">
            <div className="_columns">
              {/* Col #1 */}
              <div className="_column">
                <section className="_block">
                  <h6 className="_block-title"> Pontos </h6>
                  <Grid container spacing={2}>
                    <Grid item md={7} xs={7}>
                      <TextField
                        id="outlined-add-description"
                        label="Quantidade de pontos *"
                        variant="outlined"
                        onChange={(e) => convertPricePoints(e)}
                        size={matches ? "small" : "small"}
                        name="qtd_points"
                        fullWidth
                        error={!!errors.qtd_points}
                        inputRef={register({
                          required: true,
                          minLength: 1,
                          validate: (input) => input > 0
                        })}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid item md={1} xs={1}>
                      <div className="icon">
                        <Icon icon={faArrowRight} />
                      </div>
                    </Grid>
                    <Grid item md={4} xs={4}>
                      <ListContent bgColor="#FFFFFF">
                        <ListItem title="Valor a pagar em reais">
                          <Price value={pricePoints ? formatNumberToBRL(Number(pricePoints)) : formatNumberToBRL(Number(0))} />
                        </ListItem>
                      </ListContent>
                    </Grid>
                  </Grid>
                </section>
              </div>
              {/* END ./ Col #1 */}

              {/* Col #3 */}
              <div className="_column">
                <section className="_block">
                  <h6 className="_block-title"> Meu pedido</h6>

                  <ListContent bgColor="#FFFFFF">
                    <ListItem title="Total de pontos a comprar">
                      <PricePoints value={originalPoints} />
                    </ListItem>

                    <ListItem title="Valor a pagar em reais">
                      <Price value={formatNumberToBRL(Number(pricePoints))} />
                    </ListItem>

                    <ListItem title="Data de expiração">{calculateExpirationDate()}</ListItem>
                  </ListContent>
                </section>

                <section className="_block">
                  <h6 className="_block-title">
                    Formas de pagamento
                    <Button className="btn-add-payment" onClick={checkAdress}>
                      Adicionar
                      <Icon icon={faPlus} />
                    </Button>
                  </h6>

                  {paymentMethod[0]?.cartoes?.length > 0 ? (
                    paymentMethod[0]?.cartoes.map((item, index) => (
                      <SelectBox
                        key={index}
                        icon={
                          <img
                            src={
                              item.bandeira === "visa"
                                ? Visa
                                : item.bandeira === "mastercard"
                                  ? Mastercard
                                  : item.bandeira === "diners" ||
                                    item.bandeira === "DinersClub"
                                    ? DinersClub
                                    : item.bandeira === "amex" ||
                                      item.bandeira === "americanExpress"
                                      ? AmericanExpress
                                      : Visa
                            }
                            alt={item.bandeira}
                          />
                        }
                        input={
                          <input
                            id={item._id}
                            type="radio"
                            name={index + "check"}
                            onClick={(e) =>
                              setCheckValue(
                                index,
                                paymentMethod[0]?.cartoes.length
                              )
                            }
                          />
                        }
                        titleContent={
                          <ListContent>
                            <ListItem
                              title={
                                item.bandeira === "visa"
                                  ? "Visa"
                                  : item.bandeira === "mastercard"
                                    ? "Mastercard"
                                    : item.bandeira === "diners" ||
                                      item.bandeira === "DinersClub"
                                      ? "DinersClub"
                                      : item.bandeira === "amex" ||
                                        item.bandeira === "American Express"
                                        ? "americanexpress"
                                        : "visa"
                              }
                            >
                              {item.numero_cartao}
                            </ListItem>
                          </ListContent>
                        }
                      />
                    ))
                  ) : (
                    <>
                      <SelectBox
                        onClick={checkAdress}
                        className="active _new-cc"
                        icon={
                          <>
                            <Icon icon={faCreditCard} />
                            <div className="plus-add">
                              <Icon icon={faPlusRegular} />
                            </div>
                          </>
                        }
                        input={
                          <input
                            type="radio"
                            name={"check"}
                          />
                        }
                        titleContent={"Cartão de crédito"}
                      />
                    </>
                  )}

                  <SelectBox
                    icon={<img src={Pix} />}
                    input={
                      <input
                        id="pix"
                        type="radio"
                        onClick={(e) => setCheckValue("pix", paymentMethod[0]?.cartoes.length)}
                        name="pix"
                      />
                    }
                    titleContent={
                      <ListContent>
                        <ListItem> Pix </ListItem>
                      </ListContent>
                    }
                  />

                  <SelectBox
                    icon={<Icon icon={faBarcodeAlt} />}
                    input={
                      <input
                        id="boleto"
                        type="radio"
                        onClick={(e) => setCheckValue("boleto", paymentMethod[0]?.cartoes.length)}
                        name="boleto"
                      />
                    }
                    titleContent={
                      <ListContent>
                        <ListItem> Boleto bancário </ListItem>
                      </ListContent>
                    }
                  />

                  {
                    pricePoints != undefined &&
                    pricePoints >= 50 &&
                    pricePoints != null && 
                    checked !== 'boleto' && checked !== 'pix' && checked != null && (
                      <Autocomplete
                        id="combo-box-demo"
                        options={params(pricePoints)}
                        size="small"
                        fullWidth
                        getOptionLabel={(option) => option.title}
                        defaultValue={params(pricePoints)[0]}
                        onChange={(e, option) => setInstallmentValue(option.value)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Opções de parcelamento"
                            variant="outlined"
                          />
                        )}
                      />
                    )
                  }
                </section>

                <section className="checkout-actions">
                  <Button
                    className="accept"
                    color="primary"
                    autoFocus
                    style={{ height: matches ? 35 : 40 }}
                    type="submit"
                    disabled={!formState.dirty || (formState.dirty && !formState.isValid)}
                  >
                    Comprar
                  </Button>

                  <Button
                    className="cancel"
                    color="primary"
                    autoFocus
                    style={{ height: matches ? 35 : 40 }}
                    type="button"
                    onClick={() => props.history.push("/minha-carteira")}
                  >
                    Cancelar
                  </Button>
                </section>
              </div>
              {/* END ./ Col #3 */}
            </div>
          </div>
        </form>
      </section>

      <ModalBase
        ref={childShippingOptions}
        modalTitle="Opções de entrega"
        modalSubTitle="Qual a melhor opção para mim?"
      >
        <ModalShippingOptions></ModalShippingOptions>
      </ModalBase>

      <ModalBase
        ref={childTaxs}
        modalTitle="Taxa Scamb"
        modalSubTitle={`
            Você não paga nada para listar produtos no Scamb e ter sua loja ativa.
            Porém, a cada transação efetivada, o Scamb cobra uma taxa de administração para apoiar a manutenção da plataforma.
            A taxa é sempre paga pelo COMPRADOR. Não há taxas para o vendedor.
            O valor da taxa Scamb varia de acordo com o valor de pontos da transação.
            Veja aqui a tabela de valores da taxa Scamb.
                  `}
      >
        <ModalTaxs></ModalTaxs>
      </ModalBase>

      <ModalAddress modalCreditCard={refCreditCardModal.current} setScamber={atualizarScamber} ref={refAddressModal} title="Adicionar endereço" />

      <ModalPersonalData scamber={props.scamber} points={points} isBoleto={isBoleto} history={props.history}
        setScamber={atualizarScamber} ref={refPersonalDataModal} paymentMethod={payMethod} installmentValue={installmentValue} title="dados pessoais" />

      <ModalCreditCard ref={refCreditCardModal}>
        <form onSubmit={handleSubmitCard(onSubmitCreditCard)}>
          <h1 className="_content-title">
            Não faremos nenhuma cobrança automática em seu cartão. Ele só será utilizado com sua autorização.
          </h1>
          <ul className="_payment-methods-list">
            <li>
              <div className="creadit-card--flag">
                <img src={Visa} alt="" />
              </div>
            </li>
            <li>
              <div className="creadit-card--flag">
                <img src={Mastercard} alt="" />
              </div>
            </li>
            <li>
              <div className="creadit-card--flag">
                <img src={AmericanExpress} alt="" />
              </div>
            </li>
            <li>
              <div className="creadit-card--flag">
                <img src={DinersClub} alt="" />
              </div>
            </li>
          </ul>

          {/*  Form Body */}
          <section className="_form-body _credit-card-form">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputMask
                  mask="9999 9999 9999 9999"
                  defaultValue={valueField}
                  onChange={(e) => setValue(e.target.value)}
                >
                  <TextField
                    id="outlined-cc-number"
                    label="Número do cartão ***"
                    type="text"
                    defaultValue={valueField}
                    variant="outlined"
                    name="numero_cartao"
                    onInput={checkCreditCardType}
                    onPaste={checkCreditCardType}
                    size={matches ? "small" : "medium"}
                    error={!!errors.numero_cartao}
                    fullWidth
                    inputRef={registerCard({
                      required: true,
                      minLength: 8,
                    })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {creditCardType ? (
                            <div className="creadit-card--flag">
                              <img
                                style={{ width: "15.75px" }}
                                src={require(`../assets/images/credit-card/${creditCardType}.png`)}
                                alt="Flag credit card"
                              />
                            </div>
                          ) : (
                            <Icon icon={faCreditCardLt} />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </InputMask>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-cc-name"
                  label="Nome do titular cartão *"
                  variant="outlined"
                  name="nome"
                  size={matches ? "small" : "medium"}
                  error={!!errors.nome}
                  fullWidth
                  inputRef={registerCard({
                    required: true,
                    minLength: 4,
                  })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon icon={faUser} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <InputMask
                  mask="99"
                  defaultValue={month}
                  onChange={(e) => setMonth(e.target.value)}
                >
                  <TextField
                    id="outlined-cc-month"
                    label="Mês *"
                    type="text"
                    variant="outlined"
                    name="mes_expiracao"
                    size={matches ? "small" : "medium"}
                    error={!!errors.mes_expiracao}
                    fullWidth
                    inputRef={registerCard({
                      required: true,
                    })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Icon icon={faCalendarStar} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </InputMask>
              </Grid>
              <Grid item xs={4}>
                <InputMask
                  mask="9999"
                  defaultValue={year}
                  onChange={(e) => setYear(e.target.value)}
                >
                  <TextField
                    id="outlined-cc-year"
                    label="Ano *"
                    type="text"
                    variant="outlined"
                    name="ano_expiracao"
                    size={matches ? "small" : "medium"}
                    error={!!errors.ano_expiracao}
                    fullWidth
                    inputRef={registerCard({
                      required: true,
                    })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Icon icon={faCalendarAlt} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </InputMask>
              </Grid>
              <Grid item xs={4}>
                <InputMask
                  mask="999"
                  defaultValue={cvv}
                  onChange={(e) => setCvv(e.target.value)}
                >
                  <TextField
                    id="outlined-cc-number"
                    label="Cvv *"
                    type="text"
                    variant="outlined"
                    name="cvv"
                    size={matches ? "small" : "medium"}
                    error={!!errors.cvv}
                    fullWidth
                    inputRef={registerCard({
                      required: true,
                    })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Icon icon={faLockAlt} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </InputMask>
              </Grid>
              <Grid item xs={12}>
                <InputMask
                  mask="999.999.999-99"
                  defaultValue={valueFieldCpf}
                  onChange={(e) => setValueCpf(e.target.value)}
                >
                  <TextField
                    id="outlined-cc-number"
                    label="CPF *"
                    type="text"
                    defaultValue={valueFieldCpf}
                    defaultValue={""}
                    variant="outlined"
                    name="numero_cpf"
                    size={matches ? "small" : "medium"}
                    error={!!errors.numero_cpf}
                    fullWidth
                    inputRef={registerCard({
                      required: true,
                      minLength: 8,
                    })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Icon icon={faUserLock} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </InputMask>
              </Grid>
            </Grid>

            <div className="_set-user-location">
              <div className="_wrap-location">
                <div className="_icon">
                  <Icon icon={faMapMarkerAlt} />
                </div>

                <div className="_wrap-current-location">
                  <div className="_location-title">
                    {props.scamber?.endereco ? (
                      props.scamber.endereco.map((item, index) => (
                        <div key={index} className="_location">
                          {item.principal &&
                            "CEP: " +
                            item.cep +
                            " " +
                            item.logradouro +
                            ", n" +
                            item.numero +
                            " - " +
                            item.bairro +
                            ", " +
                            item.cidade +
                            " - " +
                            item.estado}
                        </div>
                      ))
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*  END ./ Form Body */}
          <Button
            type="submit"
            className={
              "btn btn-primary btn-fluid btn-submit-modal " + classes.center
            }
            color="primary"
            autoFocus
            style={{ height: matches ? 35 : 40 }}
            disabled={
              !formStateCard.dirty || (formStateCard.dirty && !formStateCard.isValid)
            }
          >
            Confirmar
            <Icon icon={faCheck} />
          </Button>

        </form>
      </ModalCreditCard>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    scamber: state.scamberReducer.scamber,
    cart: state.cartReducer.cart,
  };
};

export default withRouter(connect(mapStateToProps, { setScamber })(BuyPoints));
