import styled from "styled-components";

export const Wrapper = styled.div`
    background-color: ${({ theme }) => theme.color.white};
    margin-bottom: ${props => props.marginBottom+"px" || 0+"px"};

    ${({ theme }) => theme.media("md", `
        border: 1px solid #F1F1F1;                
    `)}
`;

export const WrapperTitle = styled.div`
    padding: 0.375rem 0.9375rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const Title = styled.div`
    font-family: ${({ theme }) => theme.font.nunito} !important;
    font-weight: ${({ theme }) => theme.fontWeight.fw700};
    color: ${({ theme }) => theme.color.black};
    font-size: 1rem;
`;

export const Button = styled.button`
    background-color: ${({ theme }) => theme.color.blue};
    font-weight: ${({ theme }) => theme.fontWeight.fw700};
    color: ${({ theme }) => theme.color.white};
    font-size: 0.75rem;
    border: 0;
    border-radius: 0.1875rem;
    padding: 5px 1.125rem;
    width: auto;
    display: inline-block;
`;

export const Content = styled.div`

    ._advertiser-card {
        padding: 0.3125rem 0.9375rem 1.25rem 0.9375rem;

        ._col {

            &:first-child {

                ._advertiser-card-logo {
                    
                    ${({ theme }) => theme.media("xs", `
                        height: 38px;
                        width: 38px;                    
                        min-width: 38px;                    
                        max-width: 38px;                    
                    `)}
                    
                    ${({ theme }) => theme.media("md", `
                        height: 46px;
                        width: 46px;                    
                        min-width: 46px;                    
                        max-width: 46px;                    
                    `)}
                }

                ._advertiser-card-title--location {
                    font-size: 0.75rem;

                    span {
                        font-size: 0.5625rem;
                    }
                }
            }

            &:last-child {
                width: 35px;
            }
        }
    }

    ._product-thumbnail.small {
        margin-bottom: 0 !important;
        border-bottom: 0.0625rem dashed ${({ theme }) => theme.color.iceBold};
        padding: 0.625rem 0.9375rem;
        
        &:last-child {
            border-bottom: 0;
        }

        ._col {

            &:first-child {

                padding-right: 10px;
                
                ._product-info {

                    .title {
                        text-transform: lowercase !important;
                    }
                }
            }

            &:last-child {
                width: 55%;

                .btn-shipping {
                    font-family: ${({ theme }) => theme.font.nunito} !important;
                    font-weight: ${({ theme }) => theme.fontWeight.fw700};
                    background-color: ${({ theme }) => theme.color.ice};
                    color: ${({ theme }) => theme.color.black};
                    font-size: 0.75rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    max-width: 94px;
                    min-width: 94px;
                    width: 100%;
                    padding: 0.3125rem 0.625rem;

                    svg {
                        height: 14px;
                        margin-left: 0.3125rem;

                        path {
                            fill: ${({ theme }) => theme.color.black};
                        }
                    }
                }
            }
        }
    }

    .shipping {
        
    }

`;

export const FooterAction = styled.div`
    padding: 0.5938rem 0.9375rem;
    border-top: 0.0375rem solid ${({ theme }) => theme.color.iceBold};
`;

export const ButtonAction = styled.button`
    border: 0;
    background-color: ${({ theme }) => theme.color.transparent};
    width: 100%;
    
    a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        svg {

            path {
                fill: ${({ theme }) => theme.color.blue};
                opacity: 0.6;
            }
        }
    }
`;

export const Text = styled.div`
    font-weight: ${({ theme }) => theme.fontWeight.fw700};
    color: ${({ theme }) => theme.color.black};
    font-size: 0.75rem;
`;
