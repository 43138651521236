import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";

import { useForm } from "react-hook-form";
import Swal from "sweetalert2";

import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";

//===============================================================================================================
// #5 - Import * Utils
//===============================================================================================================
import formatTitle from "../../../utils/FormatTitle";

//===============================================================================================================
// #6 - Import * UI Components
//===============================================================================================================
import { NothingMessage } from "../../../components/ui/nothing-message";
import {connect} from 'react-redux';
// JQuery
import $ from "jquery";
import "jquery-mask-plugin";

import { faEdit, faCheck, faTimes, faChevronRight, faPlus } from "@fortawesome/pro-light-svg-icons";
import { faCheck as faCheckSolid } from "@fortawesome/pro-regular-svg-icons";
import { faMapMarkerAlt } from "@fortawesome/pro-solid-svg-icons";

// Material - UI
import { useMediaQuery } from "@material-ui/core";

import Endereco from '../../../services/Endereco';
import { CheckBox } from "@material-ui/icons";

const AddressCard = (props) => {

  return (
    <div className={"address-card " + props.className}>
      <label>
        {props.field}
        <div className="overlay"></div>
        <div className="mark">
          <Icon icon={faCheckSolid} />
        </div>
        <div className="_cols">
          <div className="_col">
            <div className="icon">
              <Icon icon={faMapMarkerAlt} />
            </div>
          </div>

          <div className="_col">
            <span className="tag"> {props.nickname} </span>
            <h1 className="_address-cep"> {props.cep} </h1>
            <div className="_full-address">
              {props.children}
            </div>
          </div>
        </div>
      </label>
    </div>
  )
}

function AddressForm(props) {
    const matches = useMediaQuery("(min-width:600px)");

    // * React Hook Forms
    const { register, handleSubmit, errors, reset } = useForm();

    // * States
    const [editForm, showForm] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [disableField, setDisableField] = useState(true);
    const [ address, setAddress ] = useState({});
    const [check, setCheck] = useState(true);
    const [editing, setEditing] = useState(false);
    const [addressChecked, setAddressChecked] = useState("")
    let [ cep, setCep ] = useState({});

    let [ exists_address, set_exists_address ] = useState(false);

    // * Check Main Address
    const main_address = props.scamber?.endereco?.filter(main_address => main_address.principal === true);
    
  // * Checkbox
  const handleCheck = (classe, addressId) => { 
    document.querySelector(`.${classe}`).checked = true;
    setAddressChecked(addressId);
    // setCheck(!check) 
  };

    const initFormValues = useCallback((address) => {
      if(address && address.length > 0) {
        set_exists_address(true);
        reset(address[0]); 
        setAddress(address[0]);
      }
    },[reset])

    useEffect(() => {
      initFormValues(props.address);
    }, [initFormValues, props.address]);
  
    let endereco = new Endereco();

    const onSubmit = (data) => {
      
      data.principal = data.check_address === "on"? true : false;
      data.addressId = addressChecked;

      Swal.fire('Aguarde...');
      Swal.showLoading();

      console.log(data)
      endereco.update(data).then(( response )=>{

        if( response.status === 200 ){

          Swal.fire({
            icon: "success",
            title: "Endereço principal alterado!",
            timer: 4000,
            timerProgressBar: true,
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: "fechar",
          }).then((its_ok) => {
            props.reloadScamber();
            if (its_ok) {
              setDisableField(!disableField);
            }
          });

        }
       
      }).catch((error)=> endereco.formatErrorMessage(error.response, 'AFUPEN'))
  
      setEditing(false);
      showForm(false);
      setDisabled(!disabled);
    };
  
    const toggleIcon = () => {
      showForm(!editForm);
      setDisabled(!disabled);
      setDisableField(!disableField);
      // setEditing(!editing)
    };
  
    useEffect(() => {
      $(".cep").mask("00000-000");
      $(".house_number").mask("00000");
    });

    let handleChange = (event) => {
      setCep(event.target.value);
    }

    let findAddressByCEP = () => {
      
      if(cep.length === 9){
        endereco.findCep(cep).then(( address ) => {

          let formatedAddress = {
            cep:        address.data.cep,
            estado:     address.data.estado,
            logradouro: address.data.logradouro,
            cidade:     address.data.localidade,
            estado:     address.data.uf,
            bairro:     address.data.bairro,
            complemento:     address.data.complemento,
          }
  
          if(address.data){
            reset(formatedAddress); 
          }
        }).catch((error)=> Swal.fire('info','Não foi possível consultar o endereço',''));
      }
    }

    // console.log("main address: ", main_address);

    return (
      <>
        <div className="_wrap-form-panel">
          <form
            className={
              editForm ? "_form _validate" : "_form _inline-disable _validate"
            }
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="_wrap-form-header">
              <h1 className="_title-box _link"> {props.title} </h1>
              {props.scamber?.endereco?.length ? (
              <div className="actions">
                <button
                  type="button"
                  className="btn btn-transparent _edit-cancel"
                  onClick={toggleIcon}
                >
                  <Icon icon={editForm ? faTimes : faEdit} />
                </button>
                {editForm && (
                <button
                  type="button"
                  className="btn btn-transparent _edit-cancel"
                >
                  <Link to="/meus-enderecos">
                    <Icon icon={faPlus} />
                  </Link>
                </button>
                )}
                {editForm ? (
                  <button type="submit" className="btn btn-transparent _confirm">
                    <Icon icon={faCheck} />
                    <span>Salvar</span>
                  </button>
                ) : null}
              </div>
              ) : null }
            </div>

            {!editForm && (
            <>
                  
              {main_address?.length ? main_address?.map((address, index) => (
                <AddressCard field={
                  <React.Fragment>
                    <input
                      type="radio"
                      name="main_address"
                      className={"checkbox" + " " + "check" + index}
                      id={address._id}
                      onChange={()=>handleCheck("check" + index, address._id)}
                      defaultChecked={editing? check : address.principal}
                      ref={register({
                        required: true,
                      })}
                    />
                  </React.Fragment>
                }
                    nickname={formatTitle(String(address?.apelido))}
                    cep={address?.cep}
                    className={errors.check_address && "error"}
                    >
    
                    {formatTitle(String(address?.logradouro))} , Nº 
                    {address.numero} - Bairro 
                    {formatTitle(String(address?.bairro))},
                    {address.complemento === "" ? "" : address.complemento && (" - Complemento: "+formatTitle(String(address?.complemento)))} - 
                    {formatTitle(String(address?.cidade))} - {address.estado?.toUpperCase()}
                  </AddressCard>  
              )) : <NothingMessage>
              Você não possui nenhum endereço cadastrado.<br/> para cadastrar um novo endereço <Link to="/meus-enderecos">clique aqui</Link>
            </NothingMessage>}

            </>)}
                    
            {editForm ? (
            <>        
            {props.scamber?.endereco?.length?
              props.scamber.endereco.map((item,index)=>
              <>
                <AddressCard field={
                  <React.Fragment>
                    <input
                      type="radio"
                      name="check_address"
                      className={"checkbox" + " " + "check" + index}
                      id={item._id}
                      onChange={()=>handleCheck("check" + index, item._id)}
                      defaultChecked={editing? check : item.principal}
                      ref={register({
                        required: true,
                      })}
                    />
                  </React.Fragment>
                }
                    nickname={formatTitle(String(item?.apelido))}
                    cep={item?.cep}
                    className={errors.check_address && "error"}
                    >
    
                    {formatTitle(String(item?.logradouro))} , Nº 
                    {item.numero} - Bairro 
                    {formatTitle(String(item?.bairro))},
                    {item.complemento === "" ? "" : item.complemento && (" - Complemento: "+formatTitle(String(item?.complemento)))} - 
                    {formatTitle(String(item?.cidade))} - {item.estado?.toUpperCase()}
                  </AddressCard>  

                </>
              )
            :
              <NothingMessage>
                Você não tem mais endereços cadastrados<br/> para cadastrar um novo endereço <Link to="/meus-enderecos">clique aqui</Link>
              </NothingMessage>}
            </>
            ) : null} 

          </form>
        </div>
      </>
    );
  }

const mapStateToProps = state => {
  return {
    scamber: state.scamberReducer.scamber,

  }
}

export default connect(mapStateToProps)(AddressForm);