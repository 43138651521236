import Service from './Service';

class Categorization extends Service {

  constructor(){
    super();
  }

  list( ) {
    return this.axiosInstance.get(`/categorization`);
  }

}

export default Categorization;