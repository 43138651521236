import styled from 'styled-components';

export const WrapTerms = styled.div`

    padding-bottom: 2.5rem;

    #view_common-page {

        ._med-container {

            padding: 0;
        }
    }
`;

export const DialogActions = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 999;
  padding-bottom: 15px;
  padding-top: 15px;
  opacity: 1;
  background: rgb(255, 255, 255);
  background: -moz-linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.742734593837535) 54%,
    rgba(255, 255, 255, 0) 100%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.742734593837535) 54%,
    rgba(255, 255, 255, 0) 100%
  );
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.742734593837535) 54%,
    rgba(255, 255, 255, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);

  &::before {
    content: "";
    display: block;
    height: 60px;
    width: 100%;
    background-color: ${({ theme }) => theme.color.blue};
    background: rgb(255, 255, 255);
    background: -moz-linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0.742734593837535) 54%,
      rgba(255, 255, 255, 0) 100%
    );
    background: -webkit-linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0.742734593837535) 54%,
      rgba(255, 255, 255, 0) 100%
    );
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0.742734593837535) 54%,
      rgba(255, 255, 255, 0) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
  }
`;

export const MarginContent = styled.div`
  margin-left: 15px;
  margin-right: 15px;
`;

export const WrapButtons = styled(MarginContent)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    border: 0;
    &:last-child {
      margin-left: 15px;
    }
  }
`;