import React, { Component } from "react";
import { Link } from "react-router-dom";

// Messages
import ChatHeader from "../../../components/account/messenger/ChatHeader";
import ChatProduct from "../../../components/account/messenger/ChatProduct";
import Message from "../../../components/account/messenger/Message";
import MessageSuccess from "../../../components/account/messenger/MessageSuccess";
import ProductThumbnail from "../../../components/modals/Proposal/ProductThumbnail";
import ChatAction from "../../../components/account/messenger/ChatAction";

// Modal
import ModalBase from "../../../components/modals/ModalBase";
import ModalDialog from "../../../components/modals/Proposal/ModalProposal";

// Sweet Alert
import Swal from "sweetalert";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faCog,
  faPaperPlane,
  faImage,
  faPaperclip,
  faThumbsUp,
  faThumbsDown,
  faCheck,
  faEdit,
} from "@fortawesome/pro-light-svg-icons";

// Icons
import {
  faThumbsUp as faUp,
  faThumbsDown as faDown,
} from "@fortawesome/pro-solid-svg-icons";

// Images
import Avatar from "../../../assets/images/user-proposal.png";

class Chat extends Component {
  constructor(props) {
    super(props);

    this.childProposal = React.createRef();
    this.refModalDialogSize = React.createRef();

    this.state = {
      chatMessenger: false,
      dropdown: false,
      thumbsup: false,
      thumbsdown: false,
      proposal: true,
      proposal_accept: false,
      proposal_rejected: false,
      showString: false,
      showSwall: true,
    };
  }

  openModalProposal = () => {
    this.childProposal.current.handleShow();
  };

  closeModalProposal = () => {
    this.childProposal.current.handleClose();
  };

  closeMessenger = () => {
    this.setState({
      chatMessenger: false,
    });
  };

  showMessenger = () => {
    this.setState({
      chatMessenger: true,
    });
  };

  handleDDown = () => {
    this.setState({
      dropdown: !this.state.dropdown,
    });
  };

  render() {
    const acceptProposal = () => {
      this.setState({
        proposal_accept: true,
      });

      this.setState({
        proposal: false,
      });

      this.setState({
        showString: true,
      });
    };

    const rejectProposal = () => {
      this.setState({
        proposal_rejected: true,
      });

      this.setState({
        proposal: true,
      });

      this.setState({
        showString: true,
      });
    };

    const swallOnlyOnce = () => {
      this.setState({
        showSwall: false,
      });
    };

    const setFaUp = () => {
      this.setState({
        thumbsup: !this.state.thumbsup,
        thumbsdown: false,
      });
    };

    const setFaDown = () => {
      this.setState({
        thumbsdown: !this.state.thumbsdown,
        thumbsup: false,
      });
    };

    const confirmProposal = () => {
      if (this.state.showSwall === true) {
        Swal({
          title: "Aceitar proposta?",
          text:
            `Ao clicar em confirmar, o interessado pelo seu produto tem até 24 horas para realizar a
            compra nas condições acordadas. \n Durante este período, o produto segue disponível na sua
            loja para outros usuários, no valor original.`,
          icon: "info",
          buttons: ["Cancelar", "Confirmar"],
          dangerMode: true,
        }).then(function (isConfirm) {
          if (isConfirm) {
            swallOnlyOnce();
            acceptProposal();
            setFaUp();

            Swal({
              title: "Proposta aceita!",
              text: `O interessado pelo seu produto tem até 24 horas para realizar a compra nas condições acordadas com você. \n Essas condições são únicas para este interessado, mas durante este período o produto continua disponível em sua loja para outros Scambers, no valor original.`,
              icon: "success",
              button: "Ok",
              dangerMode: true,
            });
          }
        });
      }
    };

    const proposalReject = () => {
      if (this.state.showSwall === true) {
        Swal({
          title: "Recusar proposta?",
          icon: "info",
          buttons: ["Cancelar", "Confirmar"],
          dangerMode: true,
        }).then(function (isConfirm) {
          if (isConfirm) {
            swallOnlyOnce();
            rejectProposal();
            setFaDown();

            Swal({
              title: "Proposta recusada!",
              text: "Você recusou a proposa de Marina Hertz.",
              icon: "error",
              button: "Ok",
              dangerMode: true,
            });
          }
        });
      }
    };

    return (
      <>
        {this.state.chatMessenger ? (
          <section className={this.props.className}>
            <form className="_chat-form">
              <ChatHeader
                username="Loja Emilia"
                userAvatar="https://picsum.photos/600/600"
                link="#!"
              >
                <button
                  type="button"
                  className="btn btn-transparent _disabled"
                  onClick={this.closeMessenger}
                >
                  <FontAwesomeIcon icon={faChevronLeft}></FontAwesomeIcon>
                </button>
              </ChatHeader>

              <ChatProduct
                productImage="https://picsum.photos/600/600"
                messageType="purchase"
                buyerName="Marina Hertz"
                productTitle="Bolsa morena rosa clutch"
                productPrice="68"
              >
                <ChatAction
                  className="_is-hidden"
                  textInfo="proposta"
                  status={
                    this.state.showString &&
                    (this.state.proposal ? " recusada" : " aceita")
                  }
                  proposalValue="61"
                >
                  {!this.state.proposal_accept && (
                    <button
                      type="button"
                      className="btn decline"
                      onClick={proposalReject}
                    >
                      <FontAwesomeIcon
                        icon={this.state.thumbsdown ? faDown : faThumbsDown}
                      ></FontAwesomeIcon>
                    </button>
                  )}

                  {!this.state.proposal_rejected && (
                    <button
                      type="button"
                      className="btn accept"
                      onClick={confirmProposal}
                    >
                      <FontAwesomeIcon
                        icon={this.state.thumbsup ? faUp : faThumbsUp}
                      ></FontAwesomeIcon>
                    </button>
                  )}
                </ChatAction>

                <ChatAction
                  className="_is-hidden"
                  textInfo="Minha proposta"
                  proposalValue="61"
                >
                  <button
                    type="button"
                    className="btn edit"
                    onClick={() => this.refModalDialogSize.current.openModal()}
                  >
                    <FontAwesomeIcon icon={faEdit}></FontAwesomeIcon>
                  </button>
                </ChatAction>

                <ChatAction
                  className="_link-to-buy"
                  textInfo="Sua proposta foi aceita"
                  subText="clique aqui para comprar"
                  proposalValue=""
                >
                  <button type="button" className="btn accept">
                    <FontAwesomeIcon icon={faUp}></FontAwesomeIcon>
                  </button>
                </ChatAction>
              </ChatProduct>

              {/* Conversas do chat */}
              <section className="_chat-conversation">
                <Message direction="left">
                  Oi, tudo bem? aceita 450 pontos na bolsa?
                </Message>
                <Message direction="right">Oi, tudo sim.</Message>
                <Message direction="right">
                  Pode ser, eu aceito sim. Vou Aceitar aqui, ta?
                </Message>
                <Message direction="left">Maravilha! Muito obrigada.</Message>

                <MessageSuccess
                  direction="left"
                  link="@link"
                  linkText="Ver detalhes da venda"
                  title="Você vendeu!"
                >
                  <ProductThumbnail
                    productTitle="Bolsa morena rosa clutch"
                    productImage="https://picsum.photos/600/600"
                    storeTitle="Loja Emilia"
                    price="67"
                  ></ProductThumbnail>
                </MessageSuccess>

                <MessageSuccess
                  direction="right"
                  link="@link"
                  linkText="Clique aqui para comprar"
                  title="Sua proposta foi aceita!"
                >
                  <ProductThumbnail
                    productTitle="Clique aqui para comprar"
                    productImage="https://picsum.photos/600/600"
                    storeTitle="Loja Emilia"
                    price="67"
                  ></ProductThumbnail>
                </MessageSuccess>
              </section>

              <section className="_chat-footer">
                <section className="_chat-send">
                  <div className="_wrap-send">
                    <div className="send-text">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Digite uma mensagem..."
                      />
                    </div>
                    <div className="send-message">
                      <button
                        type="button"
                        className="btn btn-transparent _disabled"
                      >
                        <FontAwesomeIcon icon={faPaperPlane}></FontAwesomeIcon>
                      </button>
                    </div>
                  </div>
                </section>

              </section>
            </form>
          </section>
        ) : null}

        {/* Modal Proposta Produto */}
        <ModalBase
          ref={this.childProposal}
          modalTitle="Fazer oferta"
          modalSubTitle="seja justo e faça a sua melhor oferta. se o vendedor topar, você leva!"
        >
          <form className="_form _form-proposal">
            <ProductThumbnail
              productTitle="Clique aqui para comprar"
              productImage="https://picsum.photos/600/600"
              storeTitle="Loja Emilia"
              price="67"
            ></ProductThumbnail>

            <div className="form-group">
              <label htmlFor="price" className="_label">
                Minha oferta *
              </label>
              <input
                type="text"
                id="price"
                className="form-control"
                placeholder="Digite o preço"
              />
            </div>
            <div className="form-group">
              <label htmlFor="message" className="_label">
                Mensagem *
              </label>
              <textarea
                id="message"
                className="form-control"
                placeholder="Escreva uma mensagem"
                rows="2"
              />
            </div>
            <div className="_form-footer">
              <Link to="/mensagens" className="btn btn-red">
                Confirmar
              </Link>
              <button
                type="button"
                className="btn btn-cancel"
                onClick={this.closeModalProposal}
              >
                Cancelar
              </button>
            </div>
          </form>
        </ModalBase>

        <ModalDialog
          dialogTitle="Fazer oferta"
          btnTitle="Aplicar"
          className="_modal-weight"
          ref={this.refModalDialogSize}
        ></ModalDialog>
      </>
    );
  }
}

export default Chat;
