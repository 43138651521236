import Service from './Service';

class CreditCard extends Service {

    constructor() {
        super()
    }

    save( creditCard ) {
        return this.axiosInstance.post(`/creditCard`, creditCard);
    }

    find() {
        return this.axiosInstance.get('/creditCard');
    }

    update( creditCard, creditCardId ) {
        return this.axiosInstance.put(`/creditCard?creditCardId=${creditCardId}`, creditCard);
    }

    delete( creditCardId ) {
        return this.axiosInstance.delete(`/creditCard`, {data:{creditCardId}});
    }

}

export default CreditCard;