//===============================================================================================================
// #1 - Base Imports
//===============================================================================================================
import React from "react";

export default function IconLabel ({icon, text, onClick}) {

	return (
		<button type="button" className="icon-with-label" onClick={onClick}>
			<div className="icon">
				{icon}
			</div>
			<div className="text-label">
				{text}
			</div>
		</button>
	)
}
