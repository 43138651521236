import Service from './Service';

class Pedido extends Service {

    constructor() {
        super()
    }

    doOrderAndDirectCharge(paymentInfos){
        return this.axiosInstance.post(`/order`, paymentInfos);
    }

    doOrderPoints(orderInfo) {
        return this.axiosInstance.post(`/order/points`, orderInfo);
    }

    findOrder(orderId) {
        return this.axiosInstance.get(`/order?orderId=${orderId}`);
    }

    finishOrder(orderID) {
        return this.axiosInstance.post(`/order/finish/${orderID}`, {});
    }

    finishOrderReturn(orderID) {
        return this.axiosInstance.post(`/corder/reversal/finish/${orderID}`, {});
    }

    doOrderReturn(orderID, paymentInfos){
        return this.axiosInstance.post(`/corder/reversal/${orderID}`, paymentInfos);
    }

    finishOrderReturnShopping(orderID) {
        return this.axiosInstance.post(`/cordershopping/reversal/finish/${orderID}`, {});
    }

    doOrderReturnShopping(orderID, paymentInfos){
        return this.axiosInstance.post(`/cordershopping/reversal/${orderID}`, paymentInfos);
    }

    consultOrderDataLayer(orderID){
        return this.axiosInstance.get(`/order/datalayer/${orderID}`,);
    }

}

export default Pedido;