import formatNumberToBRL from "./FormatNumberToBRL";

class Convert {

    constructor() { }

    convert(tax) {
        return function (points) {
            return (points / tax)
        };
    }

    setConversion(points) {

        const taxConversion = this.convert(1.818);

        return formatNumberToBRL(taxConversion(points));

    }
}

export default Convert;