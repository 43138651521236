import { SET_BALANCE } from '../actionTypes';

const initialState = { }

export default function(state = initialState, action) {
    switch(action.type) {
        case SET_BALANCE:
            const { balance } = action.payload;
            return { ...state, balance: balance }
        default:
            return state;
    }
}