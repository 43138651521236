import Service from './Service';

class Scamber extends Service {

    constructor() {
        super()
    }

    saveScamber(scamber) {
        return this.axiosInstance.post(`/scamber`, scamber);
    }

    updateScamber(scamber) {
        return this.axiosInstance.put(`/scamber`, scamber);
    }

    updateScamberCPF(cpf) {
        return this.axiosInstance.put(`/scamber/update/cpf`, {cpf: cpf});
    }

    validate(validationCode, inviteCode) {
        return this.axiosInstance.put(`/scamber/validate${inviteCode ? '?inviteCode=' + inviteCode:''}`, { validationCode: validationCode } );
    }

    createSimpleScamber(email, password, name, phone, cpf) {
        return {
            email: email,
            senha: password,
            nome: name,
            celular: phone
            //cpf: cpf
        }
    }

    findScamber() {
        return this.axiosInstance.get(`/scamber`);
    }

    changeImage(formDataAvatar, image) {
        return this.axiosInstance.put(`/scamber/change/${image}`, formDataAvatar,  { headers: {'Content-Type': 'multipart/form-data'} });
    }

    resendEmailValidation(email) {
        if(email){
            return this.axiosInstance.get('/scamber/resendEmailValidation?email=' + email);    
        }
        return this.axiosInstance.get('/scamber/resendEmailValidation');
    }

    findScamberByAdId(idAd) {
        return this.axiosInstance.get('/scamber/idAd/' + idAd);
    }

    findScamberByScamberId(scamberId) {
        return this.axiosInstance.get('/scamber/scamberId/' + scamberId);
    }

    findScamberStoreBySearch(limit, search, website) {
        return this.axiosInstance.get(`/scamber/findStores?limit=${limit}&search=${search}${website? '&website=' + website:''}`);
    }

}

export default Scamber;