import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import WrapView from "./layout/WrapView";
import { withRouter } from "react-router-dom";

// Bootstrap
import { Button } from "react-bootstrap";

// Forms
import { useForm } from "react-hook-form";

import Helmet from 'react-helmet';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import {
  Accordion,
  AccordionCard,
  AccordionInside,
  AccordionContent,
} from "../components/ui/accordion";

// Material - UI
import {
  Grid,
  TextField,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  Slide,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { createFilterOptions } from '@material-ui/lab/Autocomplete';

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faTrashAlt,
  faChevronRight,
  faChevronLeft,
  faTruck,
  faMobile,
  faHandHoldingBox,
  faCheck,
  faTimes,
  faCamera,
} from "@fortawesome/pro-light-svg-icons";
// import { faCamera } from "@fortawesome/pro-solid-svg-icons";
import Swal from "sweetalert2";

import $ from "jquery";
import "jquery-mask-plugin";

import CreditCard from "../services/CreditCard";
import Categorization from "../services/Categorization";
import Domain from "../services/Domain";
import Ad from "../services/Ad";
import Endereco from '../services/Endereco';
import Scamber from '../services/Scamber';
import CreateIuguAccount from "../services/CreateIuguAccount";

import ReviewProduct from "./ReviewProduct";

import { connect } from "react-redux";
import { setAd } from "./../redux/actions/adActions";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";



const buttonStyles = {
  width: "auto",
  maxWidth: "none",
  color: "black",
  fontSize: "10px",
  fontFamily: "'Nunito', sans-serif;",
  fontWeight: "700",
  opacity: "0.8"
}

// Styles
const useStyles = makeStyles({
  center: {
    margin: "auto",
  },
  resize: {
    fontSize: "10px",
  },
  inputSize: {
    height: 50,
  },
});

const grid = 8;
const getListStyle = isDraggingOver => ({
  display: 'flex',
  padding: grid,
  overflow: 'auto',
});

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

// Modal Dialog Base
const ModalDialog = forwardRef((props, ref) => {
  // Styles
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:600px)");

  // Material ui Modal
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (props.customMethod) props.customMethod();
  };

  useImperativeHandle(ref, () => ({
    openModal: () => handleClickOpen(),
    closeModal: () => handleClose(),
  }));

  const handleSelectCategory = (selectedCategories, isComfirm) => {
    const lastIndex = selectedCategories.find(category => category.lastLevel === true);

    if (isComfirm) {
      return lastIndex ? handleClose() : Swal.fire("Você deve selecionar mais caractéristicas do seu produto!");
    } else {
      props.setCategories([]);
      return handleClose();
    }
  }

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={true}
        open={open}
        maxWidth={"sm"}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => props.categories ? handleSelectCategory(props.categories, false) : handleClose()}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle className="dialog-title" id="responsive-dialog-title">
          <div className="_view-head-content">
            <button type="button" className="btn" onClick={handleClose}>
              <FontAwesomeIcon
                icon={matches ? faTimes : faChevronLeft}
              ></FontAwesomeIcon>
            </button>
            <div className="_title-view">{props.dialogTitle}</div>
          </div>
        </DialogTitle>
        <DialogContent className={props.className}>
          <Typography component={"span"} variant={"body2"}>
            {props.children}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            className={"btn-step " + classes.center}
            onClick={() => props.categories ? handleSelectCategory(props.categories, true) : handleClose()}
            color="primary"
            autoFocus
            style={{ height: matches ? 35 : 40 }}
          >
            {props.btnTitle}
            <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});

// Material ui Dialog Slide
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function CreateAd(props) {

  // Ref Modals
  const refModalDialogSize = React.createRef();
  const refModalDialogCategory = React.createRef();
  const refModalDialogPrice = React.createRef();
  const refModalDialogHintWeight = React.createRef();

  // React Hook Form
  const {
    register,
    handleSubmit,
    errors,
    formState,
    setValue,
    watch,
  } = useForm({
    mode: "onBlur",
  });


  // Styles
  const classes = useStyles();

  //isEditing
  const [isEditing, setIsEditing] = useState(true);

  //Listagem do cadastro
  const [brands, setBrands] = useState([]);
  const [conditions, setConditions] = useState([]);
  const [weights, setWeights] = useState([]);
  const [sizeGroup, setSizeGroup] = useState({});

  //Parametros selecionados pelo usuario
  const [listSelectedCategorization, setListSelectedCategorization] = useState(
    []
  );
  const [selectedImages, setselectedImages] = useState([0, 0, 0, 0]);
  const [size, setSize] = useState("");
  const [condition, setCondition] = useState({ nome: "" });
  const [brand, setBrand] = useState({ nome: "" });
  const [weight, setWeight] = useState({ nome: "" });
  const [keyReRender, setKeyReRender] = useState();

  const [pricePointsInCurrency, setPricePointsInCurrency] = useState();
  const [pricePointsOriginal, setPricePointsOriginal] = useState();
  const [pricePointsNewOriginal, setPricePointsNewOriginal] = useState();
  const [pricePoints, setPricePoints] = useState("");

  const [priceOriginal, setPriceOriginal] = useState();
  const [priceNewOriginal, setPriceNewOriginal] = useState();
  const [priceOf, setPriceOf] = useState();
  const [pricePer, setPricePer] = useState();

  //Label final concatenato de categorizações
  const [allCategorizationLabel, setAllCategorizationLabel] = useState("");

  //Label categorização selecionada
  const [category, setCategory] = useState("");

  //Lista Original de categorizações
  const [listCategorization, setListCategorization] = useState([]);

  //Lista ativa e visivel para o usuario no momento
  const [listActiveCategorization, setListActiveCategorization] = useState([]);

  //Return next page
  const [returnNextPage, setReturnNextPage] = useState(false);

  useEffect(() => {
    if (props.ad && returnNextPage) {
      setValue("titulo", props.ad.title);
      setValue("descricao", props.ad.description);
      setValue(
        "operador_logistico",
        props.ad.deliveryTypes?.operador_logistico
      );
      //setValue('aplicativo_entrega', props.ad.deliveryTypes?.aplicativo_entrega)
      setValue("entrega_maos", props.ad.deliveryTypes?.entrega_maos);
    }
  }, [isEditing]);

  const handleSize = (size) => {
    setSize(size);
  };

  const handleCondition = (e, condition) => {
    setCondition(condition);
  };

  const handleBrand = (e, brand) => {
    setBrand(brand);
  };

  const handleWeight = (e, weight) => {
    if (weight.ate >= 10) {
      setValue("operador_logistico", false);
      setValue("entrega_maos", true);
    }

    setWeight(weight);
  };

  const handlePrice = (price) => {
    if (!pricePointsNewOriginal) {
      setPricePointsNewOriginal(pricePoints);
    }

    //setPricePoints((Math.round((price * 1.818) * 100) / 100).toFixed(2));
    if (price > 0) {
      setPricePoints((Math.round((price * 1.818))));
    } else {
      setPricePoints("");
    }

  }

  const handlePriceOfCurrency = (priceOfCurrency) => {
    if (priceOfCurrency > 0) {
      setPriceOf(priceOfCurrency);
    } else {
      setPriceOf('');
    }
  }

  const handlePriceCurrency = (priceCurrency) => {
    if (!priceNewOriginal) {
      setPriceNewOriginal(pricePer);
    }

    if (priceCurrency > 0) {
      setPricePer(priceCurrency);
    } else {
      setPricePer('');
    }
  }

  const handleCategorization = (categorization) => {
    setCategory(categorization.nome);

    let listSelected;
    if (categorization.categorias && categorization.categorias.length > 0) {
      setListActiveCategorization(categorization.categorias);

      listSelected = listSelectedCategorization;
      listSelected.push({
        oldCategorizationList: listActiveCategorization,
        categorization: categorization,
      });
      setListSelectedCategorization(listSelected);
    } else if (
      categorization.tipos_produtos &&
      categorization.tipos_produtos.length > 0
    ) {
      setListActiveCategorization(categorization.tipos_produtos);

      listSelected = listSelectedCategorization;
      listSelected.push({
        oldCategorizationList: listActiveCategorization,
        categorization: categorization,
      });
      setListSelectedCategorization(listSelected);
    } else if (categorization.produtos && categorization.produtos.length > 0) {
      setListActiveCategorization(categorization.produtos);

      listSelected = listSelectedCategorization;
      listSelected.push({
        oldCategorizationList: listActiveCategorization,
        categorization: categorization,
      });
      setListSelectedCategorization(listSelected);
    } else {
      listSelected = listSelectedCategorization;
      const lastIndex = listSelected.length > 0 ? listSelected.length - 1 : 0;

      if (listSelected[lastIndex] && listSelected[lastIndex].lastLevel) {
        listSelected[lastIndex] = {
          oldCategorizationList: listActiveCategorization,
          categorization: categorization,
          lastLevel: true,
        };
        setListActiveCategorization([]);
        setListSelectedCategorization(listSelected);
      } else {
        listSelected.push({
          oldCategorizationList: listActiveCategorization,
          categorization: categorization,
          lastLevel: true,
        });
        setListActiveCategorization([]);
        setListSelectedCategorization(listSelected);
      }
    }
  };

  const handleCategorizationBack = (oldCategorizationList, index) => {
    const listSelected = listSelectedCategorization.slice(0, index);
    setListSelectedCategorization(listSelected);

    setListActiveCategorization(oldCategorizationList);
  };

  useEffect(() => {
    setAllCategorizationLabel(
      listSelectedCategorization.reduce((label, selected, index) => {
        return (label += ` ${index > 0 ? ">" : ""} ${selected.categorization.nome
          }`);
      }, "")
    );
  }, [
    listSelectedCategorization.length,
    listSelectedCategorization[listSelectedCategorization.length - 1],
  ]);

  const hasChindren = (categorization) => {
    if (categorization.categorias && categorization.categorias.length > 0) {
      return true;
    } else if (
      categorization.tipos_produtos &&
      categorization.tipos_produtos.length > 0
    ) {
      return true;
    } else if (categorization.produtos && categorization.produtos.length > 0) {
      return true;
    }

    return false;
  };

  const matches = useMediaQuery("(min-width:600px)");

  const checkoutIuguAccount = React.useCallback(async () => {

    const iugu_account = new CreateIuguAccount();

    try {
      const response = await iugu_account.checkAccountStatus();

      if (response.data.payload !== null) {
        return;
      } else {
        props.history.push("/completar-cadastro");
      }

    } catch (err) { }

  }, []);

  // const loadIuguWallet = () => {
  //   extract.loadCashBalance().then((balanceData) => {
  //     if (balanceData.status === 200 && balanceData.data.payload) {
  //       const dataPayload = balanceData.data.payload;

  //       if (dataPayload !== null) {
  //         setDisabledWizard(true);
  //       } else {
  //         setDisabledWizard(false);
  //       }

  //     }
  //   }).catch((error) => {
  //     extract.formatErrorMessage(error.response, 'MWLLIBL');
  //   })
  // }

  const checkUserStatus = (scamber) => {

    const creditCard = new CreditCard();
    const endereco = new Endereco();

    endereco.find().catch((error) => {
      if (!error.response) {
        creditCard.formatErrorMessage(error.response, 'CAFIEN')
        props.history.push("/completar-cadastro");
      } else if (error.response.status === 404) {
        props.history.push("/completar-cadastro");
      }
    });

    // se não tiver cpf e telefone cadastrado manda para completar cadastro

    const scamberInstance = new Scamber();

    scamberInstance.findScamber()
      .then((response) => {
        if (!response.data.payload.cpf && !response.data.payload.cnpj) return props.history.push("/completar-cadastro");
        if (!response.data.payload.telefone) return props.history.push("/completar-cadastro");
        if (!response.data.payload.minha_loja) return props.history.push("/completar-cadastro");
      })
      .catch((error) => {
        if (!error.response) {
          scamber.formatErrorMessage(error.response, 'CAFISC')
          props.history.push("/completar-cadastro");
        } else if (error.response.status === 404) {
          props.history.push("/completar-cadastro");
        }
      });


  };

  const changePosition = (arr, from, to) => {
    arr.splice(to, 0, arr.splice(from, 1)[0]);
    return arr;
  };

  const loadAdInfo = () => {
    const categorization = new Categorization();
    const domain = new Domain();
    const ad = new Ad();

    const idAnuncio = props.match.params.idAnuncio;

    if (idAnuncio) {
      Swal.fire("Carregando anúncio... Aguarde");
      Swal.showLoading();
    }

    categorization
      .list()
      .then((response) => {
        if (response.status === 200) {
          // Ordenando categorias
          // changePosition(response.data.payload,4,0);
          // changePosition(response.data.payload,2,1);
          // changePosition(response.data.payload,4,2);

          setListCategorization(response.data.payload);
          setListActiveCategorization(response.data.payload);
        }
      })
      .catch((error) =>
        categorization.formatErrorMessage(error.response, 'CALICT'),
      );

    domain
      .listBrands()
      .then((response) => {
        if (response.status === 200) {
          setBrands(response.data.payload);
        }
      })
      .catch((error) =>
        domain.formatErrorMessage(error.response, 'CALIBDO'),
      );

    domain
      .listConditions()
      .then((response) => {
        if (response.status === 200) {
          setConditions(response.data.payload);
        }
      })
      .catch((error) =>
        domain.formatErrorMessage(error.response, 'CALICDO'),
      );

    domain
      .listWeights()
      .then((response) => {
        if (response.status === 200) {
          setWeights(response.data.payload);
        }
      })
      .catch((error) =>
        domain.formatErrorMessage(error.response, 'CALIWDO'),
      );

    if (idAnuncio) {
      ad.find(idAnuncio)
        .then((response) => {
          let anuncio = response.data.payload;

          setValue("titulo", anuncio.titulo);
          setValue("descricao", anuncio.descricao);
          setValue("operador_logistico", anuncio.operador_logistico);
          //setValue('aplicativo_entrega', anuncio.aplicativo_entrega)
          setValue("entrega_maos", anuncio.entrega_maos);

          setBrand(anuncio.marca[0]);
          setCondition(anuncio.condicao[0]);
          setWeight(anuncio.peso[0]);

          if (!anuncio.tipo_anuncio || anuncio.tipo_anuncio === 'scamb') {
            setPricePointsOriginal(
              anuncio.pontos_de
                ? anuncio.pontos_de.$numberDecimal
                : anuncio.pontos_por?.$numberDecimal
            );
            setPricePoints(anuncio.pontos_por?.$numberDecimal);
            setPricePointsInCurrency((Math.round((parseFloat(anuncio.pontos_por?.$numberDecimal) / 1.818))));
            setKeyReRender(`${Math.floor((Math.random() * 1000))}-min`);
          } else {
            setPriceOriginal(anuncio.valor_de ? anuncio.valor_de : anuncio.valor_por)
            setPriceOf(anuncio.valor_de);
            setPricePer(anuncio.valor_por);
          }


          setListSelectedCategorization(
            anuncio.categorizacao.map((cat) => {
              return { categorization: cat };
            })
          );

          if (anuncio.tamanho) {
            onSelectCategorization(
              anuncio.categorizacao.map((cat) => {
                return { categorization: cat };
              })
            );
            setSize(anuncio.tamanho.tamanhos[0]);
          }

          anuncio.fotos.map((foto, index) =>
            ad.loadImageFile(foto).then((file) => {
              setSelectedImages(file, index);
            })
          );

          Swal.close();
        })
        .catch((error) => {
          return ad.formatErrorMessage(error.response, 'CAFIAD')
        });
    }
  };

  useEffect(() => {
    if (props.scamber) {
      checkoutIuguAccount();
      checkUserStatus(props.scamber);
      loadAdInfo();
    }
  }, [props.scamber]);

  const onSelectCategorization = (categorizationlist) => {
    const domain = new Domain();

    let selectedCategorizations = categorizationlist
      ? categorizationlist
      : listSelectedCategorization;

    let categorizationIdsSelected = selectedCategorizations.map(
      (selectedCategorization) => {
        return selectedCategorization.categorization._id;
      }
    );

    domain
      .findSizeGroup(categorizationIdsSelected, props.website)
      .then((response) => {
        if (response.status === 200) {
          setSizeGroup(response.data.payload);
        }
      })
      .catch(error => {
        if (error.response.status !== 404) {
          return domain.formatErrorMessage(error.response, 'CAFISDO')
        } else {
          setSizeGroup({});
          setSize("");
        }
      })
  }

  const setAllSelectedImages = (e) => {
    if (!!selectedImages.find(value => value !== 0)) {
      const existFiles = selectedImages.filter(value => value !== 0)
      const totalFiles = e.target.files.length + existFiles.length;

      if (totalFiles > 4) {
        return Swal.fire("Você deve selecionar o máximo de 4 fotos");
      } else {
        let indexof = 0
        selectedImages.map((value, index) => {
          if (value === 0) {
            setSelectedImages(e.target.files[indexof], index);
            ++indexof;
          }
        });
      }
    } else {
      if (e.target.files.length > 4) {
        return Swal.fire("Você deve selecionar o máximo de 4 fotos");
      }

      for (let index = 0; index < e.target.files.length; index++) {
        setSelectedImages(e.target.files[index], index);
      }
    }

  }

  const setSelectedImages = (file, index) => {
    var reader = new FileReader();
    var url = reader.readAsDataURL(file);

    reader.onloadend = function (e) {
      var image = new Image();
      image.src = e.target.result;
      image.onload = function () {
        if (this.width < this.height) {
          selectedImages.splice(index, 1, { show: reader.result, file: file, orientation: '_portrait' });
          props.setAd({ ...props.ad, selectedImages: selectedImages });
          setselectedImages(selectedImages);
        } else if (this.width > this.height) {
          selectedImages.splice(index, 1, { show: reader.result, file: file, orientation: '_landscape' });
          props.setAd({ ...props.ad, selectedImages: selectedImages });
          setselectedImages(selectedImages);
        } else {
          selectedImages.splice(index, 1, { show: reader.result, file: file, orientation: '_square' });
          props.setAd({ ...props.ad, selectedImages: selectedImages });
          setselectedImages(selectedImages);
        }
      }
    }
  };

  const removeImage = (e, index) => {
    selectedImages.splice(index, 1, 0);
    props.setAd({ ...props.ad, selectedImages: selectedImages });
  };

  const onSubmit = (data) => {
    data.aplicativo_entrega = false;

    let selectedBrand;

    if (brand && brand.nome !== data.brand) {
      selectedBrand = { nome: data.brand };
      setBrand({ nome: data.brand })
    } else if (brand) {
      selectedBrand = brand;
    } else {
      selectedBrand = { nome: data.brand };
      setBrand({ nome: data.brand })
    }

    const formatedData = {
      ...props.ad,
      selectedImages,
      title: data.titulo,
      description: data.descricao,
      condition,
      listSelectedCategorization,
      brand: selectedBrand,
      size,
      weight,
      allCategorizationLabel,
      deliveryTypes: {
        operador_logistico: data.operador_logistico,
        aplicativo_entrega: data.aplicativo_entrega,
        entrega_maos: data.entrega_maos,
      },
    };

    if (pricePoints) {
      formatedData.pricePointsOriginal = pricePointsNewOriginal
        ? pricePointsNewOriginal
        : pricePointsOriginal;
      formatedData.pricePoints = pricePoints;

    } else if (pricePer) {

      formatedData.priceOriginal = priceOf ?
        priceOf : '';
      /*priceNewOriginal
      ? priceNewOriginal
      : priceOriginal;*/
      formatedData.price = pricePer;

    }

    if (
      !data.operador_logistico &&
      !data.aplicativo_entrega &&
      !data.entrega_maos
    ) {
      return Swal.fire("Você deve selecionar ao menos um tipo de entrega!");
    }

    //Calcular valor do cashback
    const ad = new Ad();
    ad.calculateCashbackAndTax(formatedData.pricePoints ? formatedData.pricePoints : formatedData.price, props.website)
      .then((response) => {

        if (response.data.payload && response.data.payload.cashback !== undefined && response.data.payload.cashback >= 0) {
          formatedData.cashback = response.data.payload.cashback;
        }

        if (response.data.payload && response.data.payload.taxa_scamb !== undefined && response.data.payload.taxa_scamb >= 0) {
          formatedData.shippingTax = response.data.payload.taxa_scamb;
        }


        for (let i = 0; i < selectedImages.length; i++) {
          if (selectedImages[i]) {
            props.setAd(formatedData);
            setIsEditing(false);
            break;
          } else {
            return Swal.fire("Você deve selecionar ao menos uma foto!");
          }
        }
      })
      .catch((error) => {

        for (let i = 0; i < selectedImages.length; i++) {
          if (selectedImages[i]) {
            props.setAd(formatedData);
            setIsEditing(false);
            break;
          } else {
            return Swal.fire("Você deve selecionar ao menos uma foto!");
          }
        }

      });

  };

  const openCategorizationModal = () => {
    setListActiveCategorization(listCategorization);
    setListSelectedCategorization([]);
    setAllCategorizationLabel("");

    refModalDialogCategory.current.openModal();
  };

  const filterOptions = createFilterOptions({
    matchFrom: 'start'
  });

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      selectedImages,
      result.source.index,
      result.destination.index
    );

    props.setAd({ ...props.ad, selectedImages: items });

    setselectedImages(
      items
    );
  }

  return (
    <>
      <Helmet title={`${props.site_properties?.title} - Criar novo`} />
      {isEditing ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalDialog
            dialogTitle="Selecione o tamanho"
            btnTitle="Aplicar"
            className="_modal-weight"
            ref={refModalDialogSize}
          >
            <div className="_content">
              {sizeGroup.nome ? (
                <>
                  <div className="tag-size">{sizeGroup.nome}</div>

                  <Grid container spacing={1}>
                    {sizeGroup.tamanhos.map((tamanho, index) => (
                      <Grid item xs={2} key={index}>
                        <div className="wrap-size">
                          <label>
                            <input
                              type="radio"
                              name="select_weight"
                              value={size}
                              onChange={() => handleSize(tamanho)}
                              checked={
                                size.nome === tamanho.nome ? "checked" : ""
                              }
                            ></input>
                            <span className="_mark">{tamanho.nome}</span>
                          </label>
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                </>
              ) : (
                <></>
              )}
            </div>
          </ModalDialog>

          <ModalDialog
            customMethod={onSelectCategorization}
            dialogTitle="Categorias"
            btnTitle="Aplicar"
            categories={listSelectedCategorization}
            setCategories={setListSelectedCategorization}
            className="_modal-category"
            ref={refModalDialogCategory}
          >
            <span className="_content">
              <a href="#!" className="tag-selected-radio">
                {listSelectedCategorization.map(
                  (selectedCategorization, index) => {
                    if (index === 0) {
                      return (
                        <div
                          onClick={() =>
                            handleCategorizationBack(
                              selectedCategorization.oldCategorizationList,
                              index
                            )
                          }
                          key={index}
                          className="_title-selected _only"
                        >
                          <FontAwesomeIcon
                            className="icon"
                            icon={faChevronLeft}
                          ></FontAwesomeIcon>
                          <span class="_text">

                            {selectedCategorization.categorization.nome}
                          </span>
                        </div>
                      );
                    } else {
                      return (
                        <div
                          onClick={() =>
                            handleCategorizationBack(
                              selectedCategorization.oldCategorizationList,
                              index
                            )
                          }
                          key={index}
                          className="_title-selected _only _multiple"
                        >
                          <span class="_text">

                            {selectedCategorization.categorization.nome}
                          </span>
                        </div>
                      );
                    }
                  }
                )}
              </a>

              <ul id="drilldown">
                {listActiveCategorization.map((categorization, index) => (
                  <li key={index}>
                    <label>
                      <input
                        type="radio"
                        name="select_category_lvl_1"
                        checked={
                          category === categorization.nome ? "checked" : ""
                        }
                        onChange={() => handleCategorization(categorization)}
                      />
                      <span className="_name">{categorization.nome}</span>
                      {hasChindren(categorization) ? (
                        <div className="icon">
                          <FontAwesomeIcon
                            icon={faChevronRight}
                          ></FontAwesomeIcon>
                        </div>
                      ) : (
                        <></>
                      )}
                    </label>
                  </li>
                ))}
              </ul>
            </span>
          </ModalDialog>

          <ModalDialog
            dialogTitle="Tabela de pesos"
            btnTitle="Ok"
            className="_modal-price"
            ref={refModalDialogHintWeight}
          >
            <span className="_content">

              <div className="price-info">
                <h3>Calma que vamos te ajudar nessa questão!</h3>
                <h3 style={{ borderBottom: "1px solid #f6f6f9", paddingBottom: "15px" }}>
                  Aqui temos a lista de referência de peso de alguns produtos.
                  Esses são pesos médios, então vc pode ajustar de acordo com as características da sua peça.<br /><br />
                  Vc pode utilizar também uma balança de banheiro para pesar produtos maiores!
                </h3>

                <Accordion child="Adulto">
                  <ul>
                    <li>
                      <p>Bermuda jeans = 400g</p>
                    </li>
                    <li>
                      <p>Blusa feminina = 150g</p>
                    </li>
                    <li>
                      <p>Bota cano alto = 1,5k</p>
                    </li>
                    <li>
                      <p>Calça jeans adulto = 700g</p>
                    </li>
                    <li>
                      <p>Camisa = 250g</p>
                    </li>
                    <li>
                      <p>Camiseta = 180g</p>
                    </li>
                    <li>
                      <p>Conjunto moletom adulto = 700g</p>
                    </li>
                    <li>
                      <p>Jaqueta jeans = 400g</p>
                    </li>
                    <li>
                      <p>Pijamas = 300g</p>
                    </li>
                    <li>
                      <p>Sandálias = 500g</p>
                    </li>
                    <li>
                      <p>Short jeans = 300g</p>
                    </li>
                    <li>
                      <p>Tênis = 850g</p>
                    </li>
                    <li>
                      <p>Tricots = 350g</p>
                    </li>
                    <li>
                      <p>Vestido = 200g</p>
                    </li>
                  </ul>
                </Accordion>

                <Accordion child="Infantil">
                  <ul>
                    <li>
                      <p>Calça jeans infantil = 400g</p>
                    </li>
                    <li>
                      <p>Conjunto moletom infantil = 500g</p>
                    </li>
                    <li>
                      <p>Roupas de bebê = 80g</p>
                    </li>
                    <li>
                      <p>Roupas de criança = 120g</p>
                    </li>
                  </ul>
                </Accordion>

              </div>
            </span>
          </ModalDialog>

          <ModalDialog
            dialogTitle="Preço do produto"
            btnTitle="Aplicar"
            className="_modal-price"
            ref={refModalDialogPrice}
          >
            <span className="_content">
              <Grid container spacing={2}>
                {/* Price #1 */}
                <Grid item xs={12}>
                  <div className="_label">Valor em real para conversão *</div>
                  <TextField
                    id="outlined-state"
                    label=" "
                    type="number"
                    variant="outlined"
                    size={matches ? "small" : "small"}
                    name="add_real_price"
                    fullWidth
                    defaultValue={pricePointsInCurrency}
                    key={keyReRender}
                    onChange={(e) => {
                      handlePrice(e.target.value);
                    }}
                    inputProps={{
                      //className:'money',
                      startadornment: (
                        <InputAdornment
                          className="_adornment-price"
                          position="start"
                        >
                          R$
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                {/* Price #2 */}
                <Grid item xs={12}>
                  <div className="_label">Valor convertido em pontos *</div>
                  <TextField
                    id="outlined-state"
                    label=" "
                    type="number"
                    variant="outlined"
                    size={matches ? "small" : "small"}
                    name="add_points_price"
                    fullWidth
                    value={pricePoints}
                    inputProps={{
                      //className:'money',
                      startadornment: (
                        <InputAdornment
                          className="_adornment-price"
                          position="start"
                        >
                          Pontos
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>

              <div className="price-info">
                <h3>Ajudante de precificação</h3>
                <h3>
                  Você vai avaliar seu produto em reais e nós vamos converter em
                  pontos Scamb para você. Essas são algumas dicas:
                </h3>
                <ul>
                  <li>
                    <p>- seja justx</p>
                  </li>
                  <li>
                    <p>
                      - é legal dar uma pesquisada no site da marca para ter uma
                      base
                    </p>
                  </li>
                  <li>
                    <p>
                      - veja aqui no Scamb se há produtos similares ou da mesma
                      marca
                    </p>
                  </li>
                  <li>
                    <p>
                      - arranhões e desgastes contam na hora da precificação
                    </p>
                  </li>
                  <li>
                    <p>- qual a sua pressa em vender o item?</p>
                  </li>
                  <li>
                    <p>- lembre-se que sempre pode haver negociação</p>
                  </li>
                </ul>
              </div>
            </span>
          </ModalDialog>

          <div id="view_create-ad">
            <WrapView
              headline="Capriche nas fotos
              e na descrição do seu produto!"
              stepTitle="O que você está anunciando?"
              className="_screen-conf create-new-add"
            >
              <section className="_add-photos">
                <div className="form-group _photos">
                  <div className="_label">
                    Fotos *
                    <small className="form-text text-muted">
                      Upload máximo de 4 fotos.
                      <br />
                      Arraste as fotos para ordenar como preferir!
                    </small>
                  </div>

                  <div className="_wrap-photos">
                    <div className="_wrap-photo-item _upload-action">
                      <div className="_photo-item first-item-upload">
                        <input
                          type="file"
                          accept="image/x-png, image/jpeg"
                          name="photo-upload1"
                          id="photo-upload1"
                          multiple
                          className="form-control-file"
                          onChange={(e) => setAllSelectedImages(e)}
                        />

                        <label htmlFor="photo-upload1">
                          <div className="upload-icon">
                            <FontAwesomeIcon className="camera-icon" icon={faCamera} />
                            <div className="plus">
                              <FontAwesomeIcon icon={faPlus} />
                            </div>
                          </div>
                          <div className="_title-label">{!!props.ad?.selectedImages.find(value => value !== 0) ? "Selecionar novas" : "Adicionar fotos"}</div>
                          <div className="_accept-formats-label">
                            JPG, GIF E PNG Somente
                          </div>
                        </label>
                      </div>
                    </div>

                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId="droppable-1" direction="horizontal">
                        {(provided, snapshot) => (
                          <div ref={provided.innerRef} {...provided.droppableProps} style={getListStyle(snapshot.isDraggingOver)}
                          >
                            {props.ad?.selectedImages.map((value, index) => {
                              if (value !== 0) {
                                return (
                                  <Draggable className="_wrap-photo-item" key={value.file.name} draggableId={`draggable-${value.file.name}`} index={index} >
                                    {(provided, snapshot) => (
                                      <>
                                        <div className="_wrap-photo-item" id={`item-${index}`} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                          <div className="_photo-item" >
                                            <div className="photo-count">
                                              <span>
                                                {index + 1}
                                              </span>
                                            </div>
                                            <div className="_image-selected">
                                              <img src={selectedImages[index].show} alt="" className={"my-image " + props.ad.selectedImages[index].orientation} />

                                            </div>
                                          </div>
                                          <button
                                            type="button"
                                            className="btn btn-transparent _delete-image"
                                            onClick={(e) => removeImage(e, index)}
                                          >
                                            <FontAwesomeIcon icon={faTrashAlt} />
                                          </button>
                                        </div>

                                      </>
                                    )}
                                  </Draggable>
                                )
                              }
                            })}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>

                  </div>
                </div>
              </section>

              <section className="form-body">
                <Grid container spacing={2}>
                  {/* Title */}
                  <Grid item xs={12}>
                    <TextField
                      id="outlined-add-title"
                      label="Título do anúncio *"
                      type="text"
                      variant="outlined"
                      size={matches ? "small" : "small"}
                      name="titulo"
                      fullWidth
                      //InputProps={{ style: {height: (matches ? 40 : 53)}}}
                      helperText={errors.titulo?.type === "minLength" && "é muito curto (minimo 10 caracteres)" || !!errors.titulo && "não pode ficar em branco"}
                      error={!!errors.titulo}
                      inputRef={register({
                        required: true,
                        minLength: 10,
                      })}
                      inputProps={{
                        maxLength: 40,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  {/* Description */}
                  <Grid item xs={12}>
                    <TextField
                      id="outlined-add-description"
                      label="Descrição *"
                      variant="outlined"
                      size={matches ? "small" : "small"}
                      type="textarea"
                      multiline
                      rows={4}
                      name="descricao"
                      fullWidth
                      helperText={errors.descricao?.type === "minLength" && "é muito curto (minimo 30 caracteres)" || !!errors.descricao && "não pode ficar em branco"}
                      error={!!errors.descricao}
                      inputRef={register({
                        required: true,
                        minLength: 30,
                      })}
                      inputProps={{
                        maxLength: 1500,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  {/* Condition */}
                  <Grid item xs={12}>
                    <Autocomplete
                      id="outlined-condition"
                      autoComplete={true}
                      options={conditions}
                      getOptionLabel={(option) => option.nome}
                      getOptionSelected={(option) => option._id}
                      size="small"
                      fullWidth
                      value={condition}
                      onChange={(e, value) => handleCondition(e, value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Condição *"
                          name="add_condition"
                          variant="outlined"
                          size={matches ? "small" : "small"}
                          error={!!errors.add_condition}
                          inputRef={register({
                            required: true,
                          })}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  </Grid>

                  {/* Category */}
                  <Grid item xs={12}>
                    <TextField
                      id="outlined-category"
                      label="Categoria *"
                      type="text"
                      variant="outlined"
                      size={matches ? "small" : "small"}
                      name="add_category"
                      value={allCategorizationLabel}
                      fullWidth
                      error={!!errors.add_category}
                      inputRef={register({
                        required: true,
                      })}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onClick={() => openCategorizationModal()}
                      inputProps={{
                        endadornment: (
                          <InputAdornment position="end">
                            <FontAwesomeIcon
                              icon={faChevronRight}
                            ></FontAwesomeIcon>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  {/* Brand */}
                  <Grid item xs={12}>
                    <Autocomplete
                      filterOptions={filterOptions}
                      id="outlined-brand"
                      options={brands}
                      includeInputInList
                      freeSolo
                      getOptionLabel={(option) => option.nome}
                      getOptionSelected={(option) => option._id}
                      fullWidth
                      value={brand}
                      onChange={(e, value) => handleBrand(e, value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Marca *"
                          name="brand"
                          variant="outlined"
                          size={matches ? "small" : "medium"}
                          error={!!errors.brand}
                          inputRef={register({
                            required: true,
                          })}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  </Grid>

                  {/* Size */}
                  {sizeGroup.nome ? (<Grid item xs={12}>
                    <TextField
                      id="outlined-size"
                      label="Tamanho *"
                      type="text"
                      variant="outlined"
                      size={matches ? "small" : "small"}
                      name="add_size"
                      value={size.nome}
                      fullWidth
                      error={!!errors.add_size}
                      inputRef={register({
                        required: true,
                      })}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onClick={() => refModalDialogSize.current.openModal()}
                      inputProps={{
                        endadornment: (
                          <InputAdornment position="end">
                            <FontAwesomeIcon
                              icon={faChevronRight}
                            ></FontAwesomeIcon>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>) : (<></>)}

                  {/* Weight */}
                  <Grid item xs={12}>
                    <Autocomplete
                      id="outlined-weight"
                      options={weights}
                      getOptionLabel={(option) => option.nome}
                      getOptionSelected={(option) => option._id}
                      fullWidth
                      value={weight}
                      onChange={(e, value) => handleWeight(e, value)}
                      inputprops={{ classes: classes.resize }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Peso *"
                          name="add_weight"
                          variant="outlined"
                          size={matches ? "small" : "small"}
                          error={!!errors.add_weight}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputRef={register({
                            required: true,
                          })}
                        />
                      )}
                    />

                    <button type="button" style={buttonStyles} className="btn btn-transparent" onClick={() => refModalDialogHintWeight.current.openModal()}>Tabela de pesos</button>
                  </Grid>

                  {/* Price */}
                  {props.website === 'scamb' ?
                    (<Grid item xs={12}>
                      {/* <div className="_label">Preço *</div> */}
                      <TextField
                        id="outlined-price"
                        label="Preço *"
                        type="text"
                        variant="outlined"
                        size={matches ? "small" : "small"}
                        name="add_price"
                        fullWidth
                        error={!!errors.add_price}
                        // error={!!errors.add_weight}
                        // inputRef={register({
                        //   required: true,
                        // })}
                        value={pricePoints}
                        onClick={() => refModalDialogPrice.current.openModal()}
                        inputRef={register({
                          required: true
                        })}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          endadornment: (
                            <InputAdornment position="end">
                              <FontAwesomeIcon
                                icon={faChevronRight}
                              ></FontAwesomeIcon>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>) :

                    (
                      <>
                        <Grid item xs={12}>
                          {/* <div className="_label">Preço *</div> */}
                          <TextField
                            id="outlined-price"
                            label="Preço de"
                            type="text"
                            variant="outlined"
                            size={matches ? "small" : "small"}
                            name="price_currency_of"
                            fullWidth
                            value={priceOf}
                            error={!!errors.price_currency_of}
                            inputRef={register({
                              required: false
                            })}
                            onChange={(e) => {
                              handlePriceOfCurrency(e.target.value);
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              endadornment: (
                                <InputAdornment position="end">
                                  <FontAwesomeIcon
                                    icon={faChevronRight}
                                  ></FontAwesomeIcon>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          {/* <div className="_label">Preço *</div> */}
                          <TextField
                            id="outlined-price"
                            label="Preço por*"
                            type="text"
                            variant="outlined"
                            size={matches ? "small" : "small"}
                            name="price_currency_per"
                            fullWidth
                            value={pricePer}
                            error={!!errors.price_currency_per}
                            inputRef={register({
                              required: true
                            })}
                            onChange={(e) => {
                              handlePriceCurrency(e.target.value);
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              endadornment: (
                                <InputAdornment position="end">
                                  <FontAwesomeIcon
                                    icon={faChevronRight}
                                  ></FontAwesomeIcon>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                      </>)
                  }

                </Grid>

                <section className="_select-freight">
                  <div className="_label">Tipo de entrega *</div>
                  <Grid container spacing={2} className="_wrap-freight">
                    <Grid item md={2}>
                      <div className="_wrap-check-radio">
                        <label className="_check-radio">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            name="operador_logistico"
                            disabled={weight?.ate >= 10 ? 'disabled' : ''}
                            //error={!!errors.operador_logistico}
                            ref={register({
                              required: false
                            })}
                          />
                          <span className="overlay"></span>
                          <div className="_wrap-alt-icon">
                            <div className="_alt-icon">
                              <FontAwesomeIcon icon={faTruck}></FontAwesomeIcon>
                            </div>
                            <span>Correios</span>
                          </div>
                          <span className="check">
                            <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                          </span>
                        </label>
                      </div>
                    </Grid>
                    {false ? (<Grid item md={2}>
                      <div className="_wrap-check-radio">
                        <label className="_check-radio">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            name="aplicativo_entrega"
                            //error={!!errors.aplicativo_entrega}
                            ref={register({
                              required: false
                            })}
                          />
                          <span className="overlay"></span>
                          <div className="_wrap-alt-icon">
                            <div className="_alt-icon">
                              <FontAwesomeIcon icon={faMobile}></FontAwesomeIcon>
                            </div>
                            <span>Aplicativo de entrega</span>
                          </div>
                          <span className="check">
                            <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                          </span>
                        </label>
                      </div>
                    </Grid>) : (<></>)}
                    {props.website === 'scamb' && (
                      <Grid item md={2}>
                        <div className="_wrap-check-radio">
                          <label className="_check-radio">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              name="entrega_maos"
                              //error={!!errors.entrega_maos}
                              ref={register({
                                required: false
                              })}
                            />
                            <span className="overlay"></span>
                            <div className="_wrap-alt-icon">
                              <div className="_alt-icon">
                                <FontAwesomeIcon icon={faHandHoldingBox}></FontAwesomeIcon>
                              </div>
                              <span>Entrega em mãos</span>
                            </div>
                            <span className="check">
                              <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                            </span>
                          </label>
                        </div>
                      </Grid>
                    )}
                  </Grid>
                </section>

                <Button
                  type="submit"
                  className="btn-step"
                  style={{ height: matches ? 35 : 35 }}
                  disabled={!formState.isValid && !setReturnNextPage}
                >
                  Continuar
                  <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
                </Button>
              </section>
            </WrapView>
          </div>
        </form>
      ) : (
        <ReviewProduct
          setIsEditing={setIsEditing}
          setReturnNextPage={setReturnNextPage}
        ></ReviewProduct>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    scamber: state.scamberReducer.scamber,
    ad: state.adReducer.ad,
    site_properties: state.websiteReducer.site_properties,
    website: state.websiteReducer.website
  };
};

export default withRouter(connect(mapStateToProps, { setAd })(CreateAd));
