//===========================================================
// #1 - Base Imports
//===========================================================
import React from "react";

//===========================================================
// #2 - Import * FontAwesome Icons
//===========================================================
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faExclamationCircle } from "@fortawesome/pro-light-svg-icons";

export const StatusMessage = (props) => {

  return (
    <>
        {
            props.type === "success" && (
                <div className="_status-message success" style={{margin: true ? `auto` : 0 }}>
                    <div className="icon">
                        <Icon icon={faCheckCircle} />
                    </div>
                    {props.messageText}
                </div>
            ) || props.type  === "error" && (
                <div className="_status-message error" style={{margin: true ? `auto` : 0 }}>
                <div className="icon">
                    <Icon icon={faExclamationCircle} />
                </div>
                    {props.messageText}
                </div>
            )
        }

        
    </>
  );
};
