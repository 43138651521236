//=====================================================================================
// #1 - Base Imports
//=====================================================================================
import React from "react";

export const Container = ({ children, className, withContainer }) => {

    return (
        <section className={className}>
            <div className={withContainer ? "_med-container" : "no-container"}>
                {children}
            </div>
        </section>
    )
}