//===========================================================
// #1 - Base Imports
//===========================================================
import React from "react";

//===========================================================
// #2 - Import * FontAwesome Icons
//===========================================================
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faCheck, faChevronRight } from "@fortawesome/pro-light-svg-icons";

export const Checkbox = (props) => {

    return (
        <div className="_check-box" style={{ margin: props.alignCenter ? `0 auto` : 0 }}>
            <label id={`reason_${props.index}`} className="_check-box--label">

                {props.children}

                <div className="_cols">
                    <div className="_col">
                        <div className="icon mark">
                            <Icon icon={faCheck} />
                        </div>
                        <div className="title">
                            {props.title}
                        </div>
                    </div>

                    <div className="_col">
                        <div className="icon">
                            <Icon icon={faChevronRight} />
                        </div>
                    </div>
                </div>
            </label>
        </div>
    )
}