import Service from './Service';
import axios from 'axios';

export default class Endereco extends Service {

  constructor(){
    super();
  }

  update( address ) {
    return this.axiosInstance.put(`/address`, address);
  }

  create( address ) {
    return this.axiosInstance.post(`/address`, address);
  }

  find(){
    return this.axiosInstance.get(`/address`);
  }
  
  findCep(cep) {
    return axios.get(`https://viacep.com.br/ws/${cep}/json`);
  }

  delete(idEndereco){
    return this.axiosInstance.delete(`/address`, {data:{idEndereco}});
  }

}
