//=====================================================================================
// #1 - Import * Styled Components
//=====================================================================================
import styled from 'styled-components';

export const WrapAvatar = styled.div `
    background-color: #FFFFFF;
    overflow: hidden;
    border-radius: 100%;
    width: ${props => props.size === "small" && "32px" || props.size === "medium" && "50px" || props.size === "large" && "68px"};
    min-width: ${props => props.size === "small" && "32px" || props.size === "medium" && "50px" || props.size === "large" && "68px"};
    height: ${props => props.size === "small" && "32px" || props.size === "medium" && "50px" || props.size === "large" && "68px"};
    border: 0;
`;

export const Image = styled.img `
        width: 100%;
        height: 100%;
        object-fit: cover;
        border: 0;
        max-width: 100%;
        display: inline-block;
        vertical-align: middle;      
`;