//===============================================================================================================
// #1 - Base Imports
//===============================================================================================================
import React from "react";

//===============================================================================================================
// #2 - Import * React Router Dom
//===============================================================================================================
import { Link } from "react-router-dom";

export default function ScamberPoints({title, points}) {

    return (
        <Link to="/minha-carteira" className="scamb-points">
            <div className="title">
                {title}
            </div>
            <div className="_product-price _1x">
                <div className="ui-item__price">
                    <span className="price-tag-fraction">
                        {points}
                    </span>
                    <span className="price-tag-symbol">pts</span>
                </div>
            </div>
        </Link>
    )
}