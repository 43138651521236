//===============================================================================================================
// #1 - Base Imports
//===============================================================================================================
import React from "react";

//===============================================================================================================
// #2 - Import * React Router Dom
//===============================================================================================================
import { Link } from "react-router-dom";

//===============================================================================================================
// #2 - Import * Styles
//===============================================================================================================
import { Price, Fraction, Symbol } from "./styles";

export const Points = ({value}) => {

    return (
        <Price>
            <Fraction className="fraction">{value} <Symbol className="symbol">pts</Symbol></Fraction>
        </Price>
    )
}