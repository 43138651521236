import React, { forwardRef, useImperativeHandle } from "react";

//=======================================
// I * React Hook Form
//=======================================
import { useForm } from "react-hook-form";

//=======================================
// I * Sweet Alert
//=======================================
import Swal from "sweetalert2";

// Product Thumbnail
import ProductThumbnail from "./ProductThumbnail";

// Material - UI
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  Slide,
  Typography,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faChevronLeft,
  faCheck,
  faTimes,
} from "@fortawesome/pro-light-svg-icons";
import { faCamera } from "@fortawesome/pro-solid-svg-icons";

// Bootstrap
import { Button } from "react-bootstrap";

// Modal Dialog Base
const ModalDialog = forwardRef((props, ref) => {
  // Form
  const { register, handleSubmit, errors, formState } = useForm({
    mode: "onChnage",
  });

  // Styles
  const matches = useMediaQuery("(min-width:600px)");

  // Material ui Modal
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useImperativeHandle(ref, () => ({
    openModal: () => handleClickOpen(),
    closeModal: () => handleClose(),
  }));

  const onSubmit = (data) => {
    console.log(data);
    setOpen(false);

    Swal.fire({
      icon: "success",
      title:
        "Proposta enviada!",
        text: `Sua proposta foi enviada e, assim que o vendedor responder, mandaremos uma notificação para você!`,
      timer: 6000,
      timerProgressBar: true,
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonText: "fechar",
    }).then((eComplete) => {
      if (eComplete) {
        console.log("ok");
      }
    });
  };

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={true}
        open={open}
        maxWidth={"sm"}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle className="dialog-title" id="responsive-dialog-title">
          <div className="_view-head-content">
            <button type="button" className="btn" onClick={handleClose}>
              <FontAwesomeIcon
                icon={matches ? faTimes : faChevronLeft}
              ></FontAwesomeIcon>
            </button>
            <div className="_title-view">{props.dialogTitle}</div>
          </div>
        </DialogTitle>
        <form
          className="_form _form-proposal"
          onSubmit={handleSubmit(onSubmit)}
        >
          <DialogContent className={props.className}>
            <Typography component={"span"} variant={"body2"}>
              <ProductThumbnail
                productTitle="Clique aqui para comprar"
                productImage="https://picsum.photos/600/600"
                storeTitle="Loja Emilia"
                price="67"
              ></ProductThumbnail>

              <div className="form-group">
                <label htmlFor="price" className="_label">
                  Minha oferta *
                </label>
                <input
                  type="number"
                  id="price"
                  className="form-control"
                  name="points_price"
                  placeholder="Valor da proposta em pontos"
                  ref={register({
                    required: true,
                  })}
                />
                <span className="_error">{errors.points_price && "Proposta não pode ficar em branco"}</span>
              </div>
              <div className="form-group">
                <label htmlFor="message" className="_label">
                  Mensagem
                </label>
                <textarea
                  id="message"
                  className="form-control"
                  placeholder="Escreva uma mensagem"
                  rows="2"
                />
              </div>
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              className="btn-step"
              onClick={handleClose}
              color="primary"
              autoFocus
              style={{ height: matches ? 35 : 40 }}
              type="submit"
              disabled={
                !formState.dirty || (formState.dirty && !formState.isValid)
              }
            >
              {props.btnTitle}
              <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
});

// Material ui Dialog Slide
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default ModalDialog;
