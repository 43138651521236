import Service from './Service';

class CancelarPedido extends Service {

    constructor() {
        super()
    }

    reversalOrder(orderId){
        return this.axiosInstance.post(`/corder/reversalorder`, { orderId: orderId });
    }
}

export default CancelarPedido;