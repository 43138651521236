//===============================================================================================================
// #1 - Import * React
//===============================================================================================================
import React from "react";

//===============================================================================================================
// #1 - Import * React
//===============================================================================================================
import { Link } from "react-router-dom";

//===============================================================================================================
// #1 - Import * Material UI
//===============================================================================================================
import Grid from '@material-ui/core/Grid';

//===============================================================================================================
// #2 - Import * Styled Components
//===============================================================================================================
import { WrapAnchorPicture, AnchorPicture, WrapPicture } from "./styles";

import QuickLink from './../../../../../services/QuickLink';

function Partner({ images, store }) {

    const quickLink = new QuickLink();

    return (
        <Grid item xs={12} sm={6}>
            <WrapAnchorPicture>

                <AnchorPicture to={store ? `/loja/${store}` : "#undefined-link"}>
                    <WrapPicture>
                        <picture>
                            <img alt="" title="" src={quickLink.loadImage(images[0])} />
                        </picture>
                    </WrapPicture>
                </AnchorPicture>

            </WrapAnchorPicture>
        </Grid>
    )
}

export default Partner;