//===========================================================
// #1 - Base Imports
//===========================================================
import React from "react";

export const BoxPrint = (props) => {

    return (

        <div className="_box-print">
            <div className={"icon " + props.type}>
              {props.icon}
            </div>

            <div className="_text">
              {props.title}
            </div>

            {props.children}
            
          </div>
    )
}