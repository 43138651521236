//=====================================================================================
// #1 - Base Imports
//=====================================================================================
import React, { Component } from "react";

//=====================================================================================
// #1 - Import * React Router Dom
//=====================================================================================
import { withRouter } from "react-router-dom";

//=====================================================================================
// #1 - Import * UI Components
//=====================================================================================
import { Section } from "../../../components/ui/section";
import Knowmore from "../../../components/Knowmore";

//=====================================================================================
// #1 - Import * React Helmet
//=====================================================================================
import Helmet from "react-helmet";

//=====================================================================================
// #1 - Import * Redux
//=====================================================================================
import { connect } from "react-redux";

//=====================================================================================
// #1 - Import * Site 
//=====================================================================================
import { getSiteProperties } from "../../../PathFinder";

//=====================================================================================
// #1 - Import * Site 
//=====================================================================================
import QuickCategories from "./quick-categories";

//=====================================================================================
// #1 - Import * Site 
//=====================================================================================
import { banners } from "./slider/data/banners";
import SliderBanners from "../../../components/design/layout/slider";

import { ContainerCards, WrapHeadline } from "./styles";

//===============================================================================================================
// #1 - Import * UI Components
//===============================================================================================================
import Card from "./components/card";
import { Headline } from "../../../components/design/foundation/headline";

//=====================================================================================
// #1 - Import * Site 
//=====================================================================================
import Button from '../../../components/design/form/button'

//=====================================================================================
// #1 - Import * Site 
//=====================================================================================
import ScambShowCases from "./scamb-showcases";

//=====================================================================================
// #1 - Import * Site 
//=====================================================================================
import Choises from "./scamb-choises";

//=====================================================================================
// #1 - Import * Site 
//=====================================================================================
const belezaESaude = [{ _id: "5f2b397f4132b6c235b31545", nome: "Beleza e Saúde" }];
const casaEDecoracao = [{ _id: "5f2b397f4132b6c235b3154a", nome: "Casa e Decoração" }];
const outros = [{ _id: "5f2b397f4132b6c235b31569", nome: "Outros" }];
const infantil = [{ _id: "5f2b397f4132b6c235b314ed", nome: "Infantil" }];
const roupasEtc = [{ _id: "5f2b397f4132b6c235b31490", nome: "Roupas e etc" }];
const mulher = [{ _id: "5f2b397f4132b6c235b31491", nome: "Mulher" }];


class Home extends Component {

  redirect = (childState) => {
    switch (childState.category) {
      case "5f2b397f4132b6c235b31490":
        localStorage.setItem("categorizations", JSON.stringify(roupasEtc));
        break;
      case "5f2b397f4132b6c235b31491":
        localStorage.setItem("categorizations", JSON.stringify(mulher));
        break;
      case "5f2b397f4132b6c235b314ed":
        localStorage.setItem("categorizations", JSON.stringify(infantil));
        break;
      case "5f2b397f4132b6c235b3154a":
        localStorage.setItem("categorizations", JSON.stringify(casaEDecoracao));
        break;
      case "5f2b397f4132b6c235b31545":
        localStorage.setItem("categorizations", JSON.stringify(belezaESaude));
        break;
      case "5f2b397f4132b6c235b31569":
        localStorage.setItem("categorizations", JSON.stringify(outros));
        break;
    }

    this.props.history.push({
      pathname: "/resultados",
      search: `?category=${childState.category}&limit=8&categoryList=${childState.category}`,
    })

  };


  render() {

    return (
      <div id="view_home">
        <Helmet title={`${getSiteProperties()?.title} - Home`} />

        <QuickCategories></QuickCategories>

        <ContainerCards withContainer={true}>

          <WrapHeadline>

            <Headline
              type="big"
              title="O Scamb"
              titleColor="#14233e"
              subTitleColor="#646466"
              withLink={false}
              textAlign="center"
            >
              O Scamb é a nova maneira de consumir segunda mão: cool, divertida, econômica e sustentável.<br/>
               Não somos mais do mesmo!<br/> Somos o futuro do segunda mão e existimos para aumentar a vida útil 
               das coisas e promover a real circularidade.<br /> Aqui você faz o bem e se dá bem!
            </Headline>

            <Button
              type="default"
              buttonSize="medium"
              buttonColor="#14233e"
              textColor="#FFFFFF"
              buttonText="Saiba Mais!"
              buttonLink={"/sobre-o-scamb"}
              maxWidth={180}
            />

          </WrapHeadline>

        </ContainerCards>

        <SliderBanners sliderData={banners} />

        <ScambShowCases />

        <Choises />

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    scamber: state.scamberReducer.scamber,
  };
};

export default withRouter(connect(mapStateToProps)(Home));
