import React from "react";

const Message = (props) => {

    return (
        <div className="wrap-messages">
            <div className={"message-item " + props.direction}>
                <div className="message">
                    {props.children}
                </div>
            </div>
        </div>
    )

}

export default Message;