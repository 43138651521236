import React, { useState } from "react";

//===============================================================================================================
// #4 - Import * Font Awesome Icons
//===============================================================================================================
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faCheck, faChevronRight, faChevronLeft, faChevronUp, faTimes } from "@fortawesome/pro-regular-svg-icons";

//===============================================================================================================
// #4 - Import * Material UI Icons
//===============================================================================================================
import TuneIcon from '@material-ui/icons/Tune';
import IconLabel from "../ui/form/icon-label";

export const Filter = (props) => {
    const [filter, setFilter] = useState(false);

    const handleFilter = () => {
        setFilter(!filter);
    }

    return (
        <>

            <div className={`_filter ${props.open || filter ? "_filter-wrapper" : "no"} ${props.className ? props.className : ""}`}>

                {/* {props.open || !filter && ( */}
                    <button type="button" className={`${props.open || !filter ? "_filter-button" : "_filter-button _is_hidden" } ${props.activeFilterSelected ? props.activeFilterSelected : ""}`} onClick={props.onClick}>
                        <div className="_cols">

                            {/* {props.open || !filter && ( */}
                                <div className={` ${props.open || filter ? "_col-1" : "_col-1"}`}>
                                    <h2 className="button-title">{props.filterTitle}</h2>
                                    <Icon icon={faChevronRight} />
                                </div>
                            {/* )} */}

                            <div className="_col-2">
                                {props.open || !filter && (
                                    <>
                                        {props.activeFilters && (
                                            <div className="categories-selecteds">
                                                {props.activeFilters}
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </button>
                {/* )} */}

                {/* {props.open || filter && ( */}
                    <button type="button" className={`head-mb ${props.open ? "_filter-button" : "_is-hidden"}`}>
                        <div className="_cols">

                            {props.open && (
                                <div className="header-open">
                                    <div className="back" onClick={props.onClick}>
                                        <Icon icon={faChevronLeft} />
                                        <h2 className="button-title">{props.filterTitle}</h2>
                                    </div>

                                    <div className="default-title">
                                        <h2 className="title">Filtros</h2>
                                        <TuneIcon />
                                    </div>

                                    <div className="close-filter">
                                        <IconLabel icon={<Icon icon={faTimes} />} text="Fechar" onClick={props.closeFilter} />
                                    </div>
                                </div>
                            )}

                        </div>
                    </button>
                    {/* )} */}


                <div className={props.open || filter ? "_filter-item _filter-show" : "_filter-item"}>
                    {props.children}
                </div>
            </div>
        </>
    )

}

export const FilterItem = (props) => {

    return (
        <label className="_filter-label">
            <input type={props.inputType} name={props.inputName} />
            <span className="_filter-title">{props.filterTitle}</span>
            {props.filterCount && (
                <span className="_filter-count">({props.filterCount})</span>
            )}
            <div className="check-mark">
                <Icon icon={faCheck} />
            </div>
        </label>
    )

}

export const FilterHead = (props) => {
    return (
        <div className="_filter-head" onClick={props.onClick}>

            <div className="header-open">
                <div className="default-title">
                    <h2 className="title">Filtros</h2>
                    <TuneIcon />
                </div>

                {props.children}


                <div className="close-filter">
                    <IconLabel icon={<Icon icon={faTimes} />} text="Fechar" onClick={props.closeFilter} />
                </div>
            </div>

        </div>
    )
}

export const FilterHeadOrderBy = (props) => {
    return (
        <div className="_filter-head ss">

            <div className="header-open">
                <div className="back" onClick={props.onClick}>
                    <Icon icon={faChevronLeft} />
                    <h2 className="button-title">{props.filterTitle}</h2>
                </div>

                <div className="default-title">
                    <h2 className="title">Filtros</h2>
                    <TuneIcon />
                </div>

                <div className="close-filter">
                    <IconLabel icon={<Icon icon={faTimes} />} text="Fechar" onClick={props.onClick} />
                </div>
            </div>

        </div>
    )
}

export const HistoryFilters = ({ children }) => {

    return (
        <div className="wrap-history-filters">
            {children}
        </div>
    )
}

export const RemoveFilter = ({ text, subtitle, onClick }) => {

    return (
        <button type="button" className="btn-remove-filter" onClick={onClick}>
            <div className="text">
                {subtitle && (
                    <span>{subtitle}</span>
                )}
                {text}
            </div>
            <div className="icon">
                <Icon icon={faTimes} />
            </div>
        </button>
    )
}

export const MobileFiltersBar = ({style, children}) => {

    return (
        <div className="mobile-filters-head" style={style}>
            {children}
        </div>
    )
}

export const FiltersBarItem = ({text, icon, onClick, className}) => {

    return (
        <button type="button" onClick={onClick}>
            <span className="text">{text}</span>
            <div className={`icon ${className ? className : ""}`}>
                {icon}
            </div>
        </button>
    )
}


// export const ActiveFilter = (props) => {
//     return (
//         <>
//             {props.activeFilters && (
//                 <div className="categories-selecteds">
//                     {props.activeFilters}
//                 </div>
//             )}
//         </>
//     )
// }